<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Empresas</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das Empresas.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Nome Fantasia</label>
                                                <input v-model="NomeEmpresa" class="form-control" type="text" placeholder="Nome">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Razão Social</label>
                                                <input v-model="RazaoSocial" class="form-control" type="text" placeholder="Razão Social">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>CNPJ</label>
                                                <input v-model="CNPJ" class="form-control" type="text" placeholder="CNPJ">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Telefone</label>
                                                <input v-model="Telefone" class="form-control" type="text" placeholder="Telefone">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Whatsapp</label>
                                                <input v-model="Whatsapp" class="form-control" type="text" placeholder="Whatsapp">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Endereço</label>
                                                <input v-model="Endereco" class="form-control" type="text" placeholder="Endereço">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <v-select v-model="Estadoselected" @input="MudaEstado"
                                                          :options="Estados"
                                                          :value="Estados.EstadoId"
                                                          single-line
                                                          label="nomeEstado" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Cidade</label>
                                                <v-select v-model="Cidadeselected" @input="MudaCidade"
                                                          :options="Cidades"
                                                          :value="Cidades.CidadeId"
                                                          single-line
                                                          label="nomeCidade" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Bairro</label>
                                                <input v-model="Bairro" class="form-control" type="text" placeholder="Bairro">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>E-mail</label>
                                                <input v-model="Email" class="form-control" type="text" placeholder="E-mail">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="EmpresaId == null || EmpresaId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/empresa/' + NomeEmpresa)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="EmpresaId != null && EmpresaId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Nome</th>
                                <th>Telefone</th>
                                <th>Whatsapp</th>
                                <th>E-mail</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Empresa in Empresas" :key="Empresa.empresaId">
                                <td>{{ Empresa.empresaId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/empresas/' + Empresa.empresaId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Empresa)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Empresa.nomeEmpresa }}</td>
                                <td>{{ Empresa.telefone }}</td>
                                <td>{{ Empresa.whatsapp }}</td>
                                <td>{{ Empresa.email }}</td>
                                <td><span v-if="Empresa.ativo">Ativo</span><span v-else>Inativo</span></td>
                            </tr>
                            <!-- <tr>
                  <td
                    valign="top"
                    v-if="Idiomas.length <= 0"
                    colspan="5"
                    class="dataTables_empty"
                  >
                    Nenhum resultado encontrado
                  </td>
                </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                EmpresaId: this.$route.params.id,
                NomeEmpresa: "",
                RazaoSocial: "",
                CNPJ: "",
                Telefone: "",
                Whatsapp: "",
                Endereco: "",
                PaisId: 0,
                Pais: {},
                Paises: [],
                EstadoId: 0,
                Estados: [],
                Estadoselected: null,
                CidadeId: 0,
                Cidades: [],
                Cidadeselected: null,
                Bairro: "",
                Email: "",
                Ativo: null,
                Empresa: {},
                Empresas: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
            };
        },
        methods: {
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                this.CidadeId = 0;
                this.Cidades = [];
                this.Cidadeselected = null;
                this.$http
                    .get(this.$apiUrl + "/cidades/ativosEstados/" + this.EstadoId)
                    .then((res) => res.json())
                    .then((tiu) => (this.Cidades = tiu));
                console.log(this.EstadoId);
            },
            MudaCidade(value) {
                this.CidadeId = value.cidadeId;
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                let _Empresas = {
                    empresaId: 0,
                    nomeEmpresa: "",
                    razaoSocial: "",
                    cnpj: "",
                    telefone: "",
                    whatsapp: "",
                    endereco: "",
                    pais: { PaisId: 1 },
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    bairro: "",
                    email: "",
                    ativo: null,
                };

                //validações
                if (this.NomeEmpresa.length <= 0) {
                    this.$mensagem_normal("Por favor preencha a empresa");
                } else if (this.Telefone.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o Telefone");
                } else if (this.Email.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                } else if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor preencha o estado");
                } else if (this.Cidadeselected == null) {
                    this.$mensagem_normal("Por favor preencha a cidade");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _Empresas.nomeEmpresa = this.NomeEmpresa;
                    _Empresas.razaoSocial = this.RazaoSocial;
                    _Empresas.cnpj = this.CNPJ;
                    _Empresas.telefone = this.Telefone;
                    _Empresas.whatsapp = this.Whatsapp;
                    _Empresas.endereco = this.Endereco;
                    _Empresas.bairro = this.Bairro;
                    _Empresas.email = this.Email;
                    _Empresas.ativo = this.Ativo;

                    //chama o post da api

                    console.log(_Empresas);
                    this.$http.post(this.$apiUrl + "/empresas/", _Empresas).then(
                        response2 => {
                            // get body data
                            _Empresas = response2.body;
                            this.$redirect_reload("/controle/empresas");
                        },
                        response2 => {
                            _Empresas = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            this.$mensagem_normal(_Empresas);
                        }
                    );
                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/empresas/" + DadosRemover.empresaId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/empresas");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Empresas = {
                    empresaId: 0,
                    nomeEmpresa: "",
                    razaoSocial: "",
                    cnpj: "",
                    telefone: "",
                    whatsapp: "",
                    endereco: "",
                    pais: { PaisId: 1 },
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    bairro: "",
                    email: "",
                    ativo: null,
                };

                //validações
                if (this.NomeEmpresa.length <= 0) {
                    this.$mensagem_normal("Por favor preencha a empresa");
                } else if (this.Telefone.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o Telefone");
                } else if (this.Email.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                } else if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor preencha o estado");
                } else if (this.Cidadeselected == null) {
                    this.$mensagem_normal("Por favor preencha a cidade");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _Empresas.nomeEmpresa = this.NomeEmpresa;
                    _Empresas.razaoSocial = this.RazaoSocial;
                    _Empresas.cnpj = this.CNPJ;
                    _Empresas.telefone = this.Telefone;
                    _Empresas.whatsapp = this.Whatsapp;
                    _Empresas.endereco = this.Endereco;
                    _Empresas.bairro = this.Bairro;
                    _Empresas.email = this.Email;
                    _Empresas.ativo = this.Ativo;
                    _Empresas.empresaId = parseInt(this.EmpresaId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/empresas/" + this.EmpresaId, _Empresas)
                        .then(
                            response2 => {
                                // get body data
                                _Empresas = response2.body;
                                console.log(_Empresas);
                                this.$mensagem_sucesso(
                                    "Empresa #" + this.EmpresaId + " alterada com sucesso"
                                );
                                this.$redirect("/controle/empresas");
                            },
                            async response2 => {
                                _Empresas = response2.body;
                                // error callback
                                //const data = await response2.json();
                                //JSON.parse(data);
                                this.$mensagem_normal(_Empresas);
                            }
                        );
                }
            },
            Pesquisa() {
                try {
                    if (this.NomeEmpresa.length <= 0) {
                        this.$mensagem_normal("Por favor preencha a empresa");
                    } else {
                        this.Empresas = [];
                        let _Empresas = {
                            NomeEmpresa: this.NomeEmpresa,
                        };
                        this.$http
                            .post(this.$apiUrl + "/empresas/busca", _Empresas)
                            .then((res) => res.json())
                            .then((tiu) => (this.Empresas = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/empresas");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.NomeEmpresa = this.$route.params.pesquisa;
            } else {
                this.NomeEmpresa = "";
            }

            if (this.EmpresaId == null) {
                this.EmpresaId = "";
            }

            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res) => res.json())
                .then((tiu) => (this.Estados = tiu));

            //chama o get da api this.$apiUrl
            if (this.EmpresaId != null && this.EmpresaId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/empresas/" + this.EmpresaId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Empresa = idi;

                        //carrega os campos
                        this.NomeEmpresa = this.Empresa.nomeEmpresa;
                        this.RazaoSocial = this.Empresa.razaoSocial;
                        this.CNPJ = this.Empresa.cnpj;
                        this.Telefone = this.Empresa.telefone;
                        this.Whatsapp = this.Empresa.whatsapp;
                        this.Endereco = this.Empresa.endereco;
                        this.Bairro = this.Empresa.bairro;
                        this.Email = this.Empresa.email;
                        this.EstadoId = this.Empresa.estado.estadoId;
                        this.Estadoselected = this.Empresa.estado
                        this.CidadeId = this.Empresa.cidade.cidadeId;
                        this.Cidadeselected = this.Empresa.cidade;
                        this.Statuselected = this.Empresa.ativo;
                        this.Ativo = this.Empresa.ativo;
                    });
            }
            else if (this.NomeEmpresa != null && this.NomeEmpresa.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/empresas")
                    .then((res) => res.json())
                    .then((moe) => (this.Empresas = moe))
                    .then(this.atualiza());
            }
        },
        //metaInfo() {
        //    return {
        //        script: [
        //            { src: '/vendors2/owl-carousel/owl.carousel.min.js', body: true },
        //            { src: '/vendors2/theme.js', body: true },

        //        ],
        //    }
        //},
        mounted() {
            //let externalScript = document.createElement('script')
            //externalScript.setAttribute('src', '/vendors2/owl-carousel/owl.carousel.min.js')
            //document.head.appendChild(externalScript);
            //let externalScript3 = document.createElement('script')
            //externalScript3.setAttribute('src', '/vendors2/magnify-popup/jquery.magnific-popup.min.js')
            //document.head.appendChild(externalScript3);
            //let externalScript4 = document.createElement('script')
            //externalScript4.setAttribute('src', '/vendors2/vertical-slider/js/jQuery.verticalCarousel.js')
            //document.head.appendChild(externalScript4);
            //let externalScript2 = document.createElement('script')
            //externalScript2.setAttribute('src', '/vendors2/theme.js')
            //document.head.appendChild(externalScript2);
        },
        components: {
            vSelect,
        },
    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
