<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Cidades</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das Cidades.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Cidade</label>
                                                <input v-model="NomeCidade" class="form-control" type="text" placeholder="Cidade">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <v-select v-model="Estadoselected" @input="MudaEstado"
                                                          :options="Estados"
                                                          :value="Estados.EstadoId"
                                                          single-line
                                                          label="nomeEstado" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="CidadeId == null || CidadeId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/cidade/' + NomeCidade)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="CidadeId != null && CidadeId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Cidade</th>
                                <th>Estado</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Cidade in Cidades" :key="Cidade.cidadeId">
                                <td>{{ Cidade.cidadeId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/cidades/' + Cidade.cidadeId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Cidade)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Cidade.nomeCidade }}</td>
                                <td>
                                    <span v-if="Cidade.estado != null">
                                        {{ Cidade.estado.nomeEstado }}
                                    </span><span v-else>#</span>
                                </td>
                                <td><span v-if="Cidade.ativo">Ativo</span><span v-else>Inativo</span></td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                CidadeId: this.$route.params.id,
                NomeCidade: "",
                Ativo: null,
                EstadoId: 0,
                Cidade: {},
                Cidades: [],
                Estados: [],
                Estadoselected: null,
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
            };
        },
        methods: {
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                console.log(value.estadoId);
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                let _Cidades = {
                    cidadeId: 0,
                    estadoId: this.EstadoId,
                    nomeCidade: this.NomeCidade,
                    ativo: null,
                };

                //validações
                if (this.NomeCidade.length <= 0) {
                    this.$mensagem_normal("Por favor preencha a cidade");
                } else if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor preencha o estado");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    _Cidades.ativo = this.Ativo;

                    //chama o post da api

                    console.log(_Cidades);
                    this.$http.post(this.$apiUrl + "/cidades/" + _Cidades.estadoId, _Cidades).then(
                        response2 => {
                            // get body data
                            _Cidades = response2.body;
                            this.$redirect_reload("/controle/cidades");
                        },
                        response2 => {
                            _Cidades = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            this.$mensagem_normal(_Cidades);
                        }
                    );
                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/cidades/" + DadosRemover.cidadeId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/cidades");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Cidades = {
                    cidadeId: 0,
                    nomeCidade: this.NomeCidade,
                    estado: {},
                    ativo: null,
                };

                //validações
                if (this.NomeCidade.length <= 0) {
                    this.$mensagem_normal("Por favor preencha a cidade");
                } else if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor preencha o estado");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    this.$http.get(this.$apiUrl + "/estados/" + this.EstadoId).then(
                        (response) => {

                            _Cidades.estado = response.body;
                            _Cidades.ativo = this.Ativo;
                            _Cidades.cidadeId = parseInt(this.CidadeId, 10);

                            //chama o post da api
                            this.$http
                                .put(this.$apiUrl + "/cidades/" + this.CidadeId, _Cidades)
                                .then(
                                    response2 => {
                                        // get body data
                                        _Cidades = response2.body;
                                        console.log(_Cidades);
                                        this.$mensagem_sucesso(
                                            "Cidade #" + this.MoedaId + " alterada com sucesso"
                                        );
                                        this.$redirect("/controle/cidades");
                                    },
                                    async response2 => {
                                        _Cidades = response2.body;
                                        // error callback
                                        //const data = await response2.json();
                                        //JSON.parse(data);
                                        this.$mensagem_normal(_Cidades);
                                    }
                                );
                        },
                    );
                }
            },
            Pesquisa() {
                try {
                    if (this.NomeCidade.length <= 0) {
                        this.$mensagem_normal("Por favor preencha a cidade");
                    } else {
                        this.Cidades = [];
                        let _Cidades = {
                            NomeCidade: this.NomeCidade,
                        };
                        this.$http
                            .post(this.$apiUrl + "/cidades/busca", _Cidades)
                            .then((res) => res.json())
                            .then((tiu) => (this.Cidades = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/cidades");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.NomeCidade = this.$route.params.pesquisa;
            } else {
                this.NomeCidade = "";
            }

            if (this.CidadeId == null) {
                this.CidadeId = "";
            }

            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res) => res.json())
                .then((tiu) => (this.Estados = tiu));

            //chama o get da api this.$apiUrl
            if (this.CidadeId != null && this.CidadeId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/cidades/" + this.CidadeId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Cidade = idi;

                        this.EstadoId = this.Cidade.estado.estadoId;
                        this.Estadoselected = this.Cidade.estado;
                        this.NomeCidade = this.Cidade.nomeCidade;
                        this.Statuselected = this.Cidade.ativo;
                        this.Ativo = this.Cidade.ativo;

                    });
            }
            else if (this.NomeCidade != null && this.NomeCidade.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/cidades")
                    .then((res) => res.json())
                    .then((moe) => (this.Cidades = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
