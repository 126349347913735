<template>
  <div>
    <div class="col-md-6 col-lg-5">
      <div class="login-box bg-white box-shadow border-radius-10">
        <div class="login-title">
          <h2 class="text-center text-primary">Login SGCAD {{ teste }}</h2>
        </div>
        <form>
          <div class="input-group custom">
            <input
              v-model="login"
              type="text"
              class="form-control form-control-lg"
              placeholder="Username"
            />
            <div class="input-group-append custom">
              <span class="input-group-text"
                ><i class="icon-copy dw dw-user1"></i
              ></span>
            </div>
          </div>
          <div class="input-group custom">
            <input
              v-model="pwd"
              type="password"
              class="form-control form-control-lg"
              placeholder="**********"
            />
            <div class="input-group-append custom">
              <span class="input-group-text"
                ><i class="dw dw-padlock1"></i
              ></span>
            </div>
          </div>
          <div class="row pb-30">
            <!-- <div class="col-6">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                />
                <label class="custom-control-label" for="customCheck1"
                  >Remember</label
                >
              </div>
            </div> -->
            <!-- <div class="col-6">
              <div class="forgot-password">
                <a href="forgot-password.html">Forgot Password</a>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="input-group mb-0">
                <!--
											use code for form submit
											<input class="btn btn-primary btn-lg btn-block" type="submit" value="Sign In">
										-->
                <a class="btn btn-primary btn-lg btn-block" @click="entrar()"
                  >Entrar</a
                >
              </div>
              <!-- <div
                class="font-16 weight-600 pt-10 pb-10 text-center"
                data-color="#707373"
                style="color: rgb(112, 115, 115)"
              >
                OR
              </div> -->
              <!-- <div class="input-group mb-0">
										<a class="btn btn-outline-primary btn-lg btn-block" href="register.html">Register To Create Account</a>
									</div> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  data() {
    return {
      login: "",
      pwd: "",
      teste: "",
    };
  },
  components: {},
  methods: {
    entrar() {
      let _usuario = {
        emailUsuario: this.login,
        senha: this.$sha1(this.pwd),
      };
      console.log(_usuario);
       if(localStorage.menu != null)
      localStorage.menu = null;
    if(localStorage.menupai != null)
      localStorage.menupai = null;
      // this.$http
      // .get(this.$apiUrl + "/GrupoTelaInstitucional/completo")
      // .then(response => {
      //   // this.Grupos = response.body;
      //   console.log(response.bodyText)
      //   return response.bodyText;
      // }).then(json => {
      //   console.log("ALO")
      //   this.$cookie.set("menusgcad", json, {
      //       expires: "100m",
      //     });
      //     console.log(this.$cookie.get("menusgcad"))
      // });
      //.then(this.$redirect_reload("/controle/default"));

      // this.$http.get(this.$apiUrl + "/GrupoTelaInstitucional/completo",{emulateJSON: true})
      // .then( response => {
      //     return response.json();
      // }).then( json => {
      //     // some other stuff.
      //     console.log(json);
      //     this.$cookie.set("menusgcad", JSON.stringify(json), {
      //       expires: "100m",
      //     });
      //     console.log(this.$cookie.get("menusgcad"));
      // });
       let menu = {};
       this.$http.get(this.$apiUrl + "/GrupoTelaInstitucional/completo").then(
        (response) => {
          // get body data
          menu = response.body;
          this.$cookie.delete("update1");//JSON.stringify(menu)
          console.log(menu)
          // this.$cookie.set("menusgcad", "aaaaaaaaaaaaaa", {
          //   expires: "10m",
          // });
          localStorage.setItem("menusgcad", JSON.stringify(menu));
          // this.$redirect_reload("/controle/default");
          // window.location.href = window.location.href.replace('/login', '/default');
        },
        (response) => {
          // error callback
          this.$mensagem_erro(response.body);
        }
      );

      let usuario = {};
      this.$http.post(this.$apiUrl + "/usuarios/login", _usuario).then(
        (response) => {
          // get body data
          usuario = response.body;
          _usuario.nomeUsuario = usuario.nomeUsuario;
          _usuario.usuarioId = usuario.usuarioId;
          //console.log(usuario);
          this.$cookie.delete("update1");
          this.$cookie.set("usuarioAvance", JSON.stringify(_usuario), {
            expires: "120m",
          });
          this.$redirect_reload("/controle/default");
          // window.location.href = window.location.href.replace('/login', '/default');
        },
        (response) => {
          // error callback
          this.$mensagem_erro(response.body);
        }
      );
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
a {
  cursor: pointer;
}
/* body {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) !important;
	background-size: 400% 400% !important;
	animation: gradient 15s ease infinite !important;
}

@keyframes gradient {
	0% {
		background-position: 0% 50% !important;
	}
	50% {
		background-position: 100% 50% !important;
	}
	100% {
		background-position: 0% 50% !important;
	}
} */
</style>
