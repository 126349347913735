<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Whatsapps</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das listas de contato de whatsapp.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Número</label>
                                                <input v-model="Numero" class="form-control" type="number" placeholder="Número">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Nome</label>
                                                <input v-model="Nome" class="form-control" type="text" placeholder="Nome">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="CadwhatsappId == null || CadwhatsappId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/cadwhatsapps/' + Numero)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="CadwhatsappId != null && CadwhatsappId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Número</th>
                                <th>Nome</th>
                                <th>Ordem</th>
                                <th>Status</th>
                                <th class="datatable-nosort">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Cadwhatsapp in Cadwhatsapps" :key="Cadwhatsapp.CadwhatsappId">
                                <td>{{ Cadwhatsapp.cadwhatsappId }}</td>
                                <td>{{ Cadwhatsapp.numero }}</td>
                                <td>{{ Cadwhatsapp.nome }}</td>
                                <td>{{ Cadwhatsapp.ordem }}</td>
                                <td><span v-if="Cadwhatsapp.ativo">Ativo</span><span v-else>Inativo</span></td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/cadwhatsapp/' + Cadwhatsapp.cadwhatsappId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Cadwhatsapp)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                CadwhatsappId: this.$route.params.id,
                Ativo: null,
                Numero: "",
                Nome: "",
                IdiomaId: 0,
                Ordem: 0,
                Cadwhatsapp: {},
                Cadwhatsapps: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
            };
        },
        methods: {
            MudaStatus(value) {
                this.Ativo = value;
                
            },
            Add() {
                let _Cadwhatsapp = {
                    cadwhatsappId: 0,
                    ativo: null,
                    numero: "",
                    nome: "",
                    idiomaId: 0,
                    ordem: 0,
                };

                //validações
                if (this.Numero.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o número");
                } else if (this.Nome.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _Cadwhatsapp.ativo = this.Ativo;
                    _Cadwhatsapp.numero = this.Numero;
                    _Cadwhatsapp.nome = this.Nome;
                    _Cadwhatsapp.idiomaId = this.IdiomaId;
                    _Cadwhatsapp.ordem = parseInt(this.Ordem,10);

                    console.log(_Cadwhatsapp);
                    let ID = _Cadwhatsapp.idiomaId;
                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/cadwhatsapp/" + ID, _Cadwhatsapp).then(
                        response2 => {
                            // get body data
                            _Cadwhatsapp = response2.body;
                            this.$redirect_reload("/controle/cadwhatsapp");
                        },
                        response2 => {
                            _Cadwhatsapp = response2.body;
                            this.$mensagem_normal(_Cadwhatsapp);
                        }
                    );
                }
            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/cadwhatsapp/" + dadosRemover.cadwhatsappId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/cadwhatsapp");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Cadwhatsapp = {
                    cadwhatsappId: 0,
                    ativo: null,
                    numero: "",
                    nome: "",
                    idiomaId: 0,
                    ordem: 0,
                };

                console.log(this.Ativo);
                //validações
                if (this.Numero.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o número");
                } else if (this.Nome.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _Cadwhatsapp.ativo = this.Ativo;
                    _Cadwhatsapp.numero = this.Numero;
                    _Cadwhatsapp.nome = this.Nome;
                    _Cadwhatsapp.ordem = parseInt(this.Ordem, 10);
                    _Cadwhatsapp.idiomaId = this.IdiomaId;
                    _Cadwhatsapp.cadwhatsappId = parseInt(this.CadwhatsappId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/cadwhatsapp/" + this.CadwhatsappId, _Cadwhatsapp)
                        .then(
                            response2 => {
                                // get body data
                                _Cadwhatsapp = response2.body;
                                console.log(_Cadwhatsapp);
                                this.$mensagem_sucesso(
                                    "Whatsapp #" + this.CadwhatsappId + " alterado com sucesso"
                                );
                                this.$redirect("/controle/cadwhatsapp");
                            },
                            async response2 => {
                                _Cadwhatsapp = response2.body;
                                // error callback
                                //const data = await response2.json();
                                //JSON.parse(data);
                                this.$mensagem_normal(_Cadwhatsapp);
                            }
                        );
                }
            },
            Pesquisa() {
                try {
                    if (this.Numero.length <= 0) {
                        this.$mensagem_normal("Por favor preencha o número");
                    } else {
                        this.Cadwhatsapps = [];
                        let _Cadwhatsapp = {
                            Numero: this.Numero,
                        };
                        this.$http
                            .post(this.$apiUrl + "/cadwhatsapp/busca", _Cadwhatsapp)
                            .then((res) => res.json())
                            .then((tiu) => (this.Cadwhatsapps = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/cadwhatsapp");

                this.$http
                    .get(this.$apiUrl + "/cadwhatsapp")
                    .then((res) => res.json())
                    .then((idi) => (this.CadwhatsappId = idi)
                        .then(this.$redirect_reload("/controle/cadwhatsapp")));

            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.Numero = this.$route.params.pesquisa;
            } else {
                this.Numero = "";
            }

            if (this.CadwhatsappId == null) {
                this.CadwhatsappId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.CadwhatsappId != null && this.CadwhatsappId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/cadwhatsapp/" + this.CadwhatsappId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Cadwhatsapp = idi;

                        this.Numero = this.Cadwhatsapp.numero;
                        this.Nome = this.Cadwhatsapp.nome;
                        this.Ordem = this.Cadwhatsapp.ordem;
                        this.Statuselected = this.Cadwhatsapp.ativo;
                        this.Ativo = this.Cadwhatsapp.ativo;

                    });
            }
            else if (this.Numero != null && this.Numero.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/cadwhatsapp")
                    .then((res) => res.json())
                    .then((moe) => (this.Cadwhatsapps = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
