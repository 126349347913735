<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">
                        Tela de Pedidos do Financeiro
                      </h4>
                      <p class="mb-30">
                        Tela da lista financeira de pedidos manuais ou do site.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Forma de Pagamento</label>
                        <v-select
                          v-model="Busca.Pagamento"
                          :options="ListaFormasPagamento"
                          single-line
                          :reduce="(x) => x.texto"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Origem</label>
                        <v-select
                          v-model="Busca.Origem"
                          :options="[
                            { nome: 'Pedido Manual', valor: '1' },
                            { nome: 'Site', valor: '1' },
                          ]"
                          single-line
                          :reduce="(x) => x.nome"
                          label="nome"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Data Inicial</label>
                        <input
                          class="form-control"
                          type="date"
                          placeholder=""
                          v-model="Busca.Data1"
                          @change="MudaData1()"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Data Final</label>
                        <input
                          class="form-control"
                          type="date"
                          placeholder=""
                          v-model="Busca.Data2"
                          @change="MudaData2()"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="
                          $redirect_reload('/controle/listapedidosfinanceiro')
                        "
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="Pesquisar"
                      >
                        Pesquisar
                      </button>
                      <!-- <a
                        @click="$redirect_reload('/controle/pedidomanual')"
                        class="btn btn-success"
                        id="sa-custom-position"
                        >Novo Pedido</a
                      > -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Default Basic Forms Start -->
        <div class="pd-20 card-box mb-30">
          <h2 class="h4 pd-20" style="max-width:60%;float:left">Resultado</h2>
          <span
            style="
            float:right;
            max-width:39%;
              padding-right: 10px;
              padding-top: 10px;
              font-size: 20px;
              font-weight: 800;
            "
          >
            Total: R$
            {{
              this.Total.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
                .replace(".", ",")
                .replace("$", "")
            }}
          </span>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th class="datatable-nosort">Pedido</th>
                <th class="datatable-nosort">Tipo</th>
                <th class="datatable-nosort hidden-sm hidden-xs">Produtos</th>
                <th class="datatable-nosort hidden-sm hidden-xs">Pagamento</th>
                <th class="datatable-nosort ">Status</th>
                <th class="datatable-nosort">Data Pagamento</th>
                <th class="datatable-nosort">Valor Taxas</th>
                <th class="datatable-nosort">Valor Cesta</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Pedido, Index) in Pedidos" :key="Index">
                <td>{{ Pedido.pedidoId }}</td>
                <td>
                  {{ Pedido.tipo }}
                </td>
                <td v-html="Pedido.produtos" class="hidden-sm hidden-xs"></td>
                <td class="hidden-sm hidden-xs">
                  {{ Pedido.modoPagamento }}
                </td>
                <td class="tamdado">
                  <a
                    href="#"
                    :class="getClasseBotao(Pedido.situacaoPedido) + ' btn-block'"
                    data-toggle="modal"
                    :data-target="'#modal' + Pedido.pedidoId"
                    type="button"
                    title="Ver Detalhes"
                    style="
                      padding: 3px;
                      border-radius: 4px;
                      color: white;
                      font-size: 11px;
                      text-align: center;
                      font-weight: bold;
                    "
                  >
                    {{ Pedido.situacaoPedido }}
                  </a>
                </td>
                <td class="tamdado">
                  {{ sqlToJsDate(Pedido.dataPedido) }}
                </td>
                <td>
                  {{
                    Pedido.taxa
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .replace(".", ",")
                      .replace("$", "")
                  }}
                </td>
                <td>
                  {{
                    Pedido.total
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .replace(".", ",")
                      .replace("$", "")
                  }}
                </td>
                <div
                  class="modal fade bs-example-modal-lg"
                  :id="'modal' + Pedido.pedidoId"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title" id="myLargeModalLabel">
                          Detalhes #{{ Pedido.pedidoId }}
                        </h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="container">
                          <ul>
                            <li v-if="Pedido.agendamento != null">
                              Data Entrega:
                              {{
                                sqlToJsDate(Pedido.agendamento.diaEntrega) +
                                " - " +
                                Pedido.agendamento.horaIni.toLocaleString(
                                  "en-US",
                                  {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                  }
                                ) +
                                ":" +
                                Pedido.agendamento.minIni.toLocaleString(
                                  "en-US",
                                  {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                  }
                                ) +
                                " as " +
                                Pedido.agendamento.horaFim.toLocaleString(
                                  "en-US",
                                  {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                  }
                                ) +
                                ":" +
                                Pedido.agendamento.minFim.toLocaleString(
                                  "en-US",
                                  {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false,
                                  }
                                )
                              }}
                            </li>
                            <li
                              v-if="
                                Pedido.nomeCliente != null &&
                                Pedido.nomeCliente.length > 0
                              "
                            >
                              Cliente: {{ Pedido.nomeCliente }}
                            </li>
                            <li v-if="Pedido.endereco">
                              Endereço Entrega:{{
                                Pedido.endereco.endereco +
                                ", " +
                                Pedido.endereco.complemento
                              }}
                              <br />
                              {{
                                Pedido.endereco.bairro != null &&
                                Pedido.endereco.bairro.length > 0
                                  ? Pedido.endereco.bairro + ", "
                                  : ""
                              }}
                              {{
                                Pedido.endereco.cidade != null
                                  ? Pedido.endereco.cidade.nomeCidade + " / "
                                  : ""
                              }}
                              {{
                                Pedido.endereco.estado != null
                                  ? Pedido.endereco.estado.nomeEstado + " "
                                  : ""
                              }}
                              {{
                                Pedido.endereco.cep != null &&
                                Pedido.endereco.cep.length > 0
                                  ? "CEP: " + Pedido.endereco.cep
                                  : ""
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <!-- <a href="PedPedidosInvoice.html" class="btn btn-primary"
                          >Gerar Invoice</a
                        > -->
                      </div>
                    </div>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
        <!--modal mensagem-->
        <!-- <div
          class="modal fade"
          id="Medium-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">Mensagem</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <img
                  src="images/img-mensagem.jpg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button type="button" class="btn btn-primary">
                  <i class="icon-copy dw dw-print"></i>Imprimir
                </button>
              </div>
            </div>
          </div>
        </div> -->
        <!--modal mensagem-->
        <!-- <div
          class="modal fade"
          id="Medium-modal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-titl e" id="myLargeModalLabel">Imagem</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <img
                  src="images/img-foto.jpg"
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button type="button" class="btn btn-primary">
                  <i class="icon-copy dw dw-print"></i>Imprimir
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <!--modal mensagem-->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
//import moment from "moment";
import * as momentTemp from "moment";
const moment = momentTemp["default"];

export default {
  data() {
    return {
      Total: 0.0,
      ListaFormasPagamento: [],
      ListaFormasPagamentoBanco: [],
      ListaFormasPagamentoPix: [],
      ListaFormasPagamentoOperadora: [],
      Busca: {
        Pagamento: "",
        Origem: "",
        Data1: "",
        Data2: "",
      },
      Pedidos: [],
      listaPedidoEndereco: [],
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    getClasseBotao(situ) {
      if(situ == null)
        situ = "";
      if (situ == "Aguardando") { 
        //#f0ad4e
        return "amarelo";
      }
      if (situ == "Agendado") {
        //#31b0d5
        return "azul-claro";
      }
      if (situ == "Enviado") {
          //#337ab
        return "azul";
      }
      if (situ == "Entregue") {
        //#40bc40;
        return "verde";
      }
      if (situ == "Cancelado") {
        //#c9302c
        return "vermelho";
      }
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    MudaData1() {
      this.Busca.Data1 = moment(this.Busca.Data1)
        .format("YYYY-MM-DD")
        .replace(" ", "T")
        .toString();
    },
    MudaData2() {
      this.Busca.Data2 = moment(this.Busca.Data2)
        .format("YYYY-MM-DD")
        .replace(" ", "T")
        .toString();
    },
    Pesquisar() {
      this.Total = 0.0;
      let headers = {
        "Content-Type": "application/json",
      };
      let tmp  = {};
      tmp = this.Busca;
      if (tmp.Data1.length == 0) {
        tmp.Data1 = moment(new Date())
          .format("YYYY-MM-DD HH:mm:ss")
          .replace(" ", "T");
      } else {
        tmp.Data1 = moment(tmp.Data1)
          .format("YYYY-MM-DD HH:mm:ss")
          .replace(" ", "T");
      }

      if (tmp.Data2.length == 0) {
        tmp.Data2 = moment("0001-01-01")
          .format("YYYY-MM-DD HH:mm:ss")
          .replace(" ", "T");
      } else {
        tmp.Data2 = moment(tmp.Data2)
          .format("YYYY-MM-DD HH:mm:ss")
          .replace(" ", "T");
      }

      console.log("POST");
      console.log(this.Busca);
      this.$http
        .post(this.$apiUrl + "/TaxaModoPagamento/busca", tmp, {
          headers,
        })
        .then(
          (response) => {
            if (response.ok) {
              this.Pedidos = response.body;
              this.Pedidos = this.Pedidos.filter(x => x.situacaoPedido != "Cancelado");
              this.Pedidos.forEach((element) => {
                this.Total += element.total;
              });
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    Limpar() {
      this.$cookie.delete("listap");
      this.$redirect_reload("/controle/listaprodutos");
    },
  },
  components: {
    vSelect,
  },
  created() {
    
    this.Total = 0.0;
    this.Pesquisar();
    var _formasPagamento = [];
    this.$http
      .get(this.$apiUrl + "/configoperadora")
      .then((res2) => res2.json())
      .then((tiu2) => {
        //carrega informações da lista de opções de pagamento
        this.$http
          .get(this.$apiUrl + "/configpix")
          .then((res2) => res2.json())
          .then((tiu3) => {
            this.ListaFormasPagamentoPix = tiu3.filter((x) => x.status);
            if (this.ListaFormasPagamentoPix != null) {
              this.ListaFormasPagamentoPix.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadPix.cadPixId,
                  Operacao: "cadPix",
                  texto: item.cadPix.nomePix,
                });
              });
            }

            this.ListaFormasPagamentoOperadora = tiu2.filter((x) => x.status);
            if (this.ListaFormasPagamentoOperadora != null) {
              this.ListaFormasPagamentoOperadora.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadOperadora.cadOperadoraId,
                  Operacao: "cadOperadora",
                  texto: item.cadOperadora.nomeOperadora,
                });
              });
            }

            //carrega informações da lista de opções de pagamento
            this.$http
              .get(this.$apiUrl + "/configbanco")
              .then((res2) => res2.json())
              .then((tiu2) => {
                this.ListaFormasPagamentoBanco = tiu2.filter((x) => x.status);
                if (this.ListaFormasPagamentoBanco != null) {
                  this.ListaFormasPagamentoBanco.forEach((item) => {
                    _formasPagamento.push({
                      OperacaoId: item.cadBanco.cadBancoId,
                      Operacao: "cadBanco",
                      texto: item.cadBanco.nomeBanco,
                    });
                  });
                }
              });
          });
      });

    this.ListaFormasPagamento = _formasPagamento;
    this.ListaFormasPagamento.sort(function (a, b) {
      return a.texto - b.texto;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.amarelo {
  background-color: #f0ad4e;
}
.azul-claro {
  background-color: #31b0d5;
}
.azul {
  background-color: #337ab7;
}
.verde {
  background-color: #40bc40;
}
.vermelho {
  background-color: #c9302c;
}
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}
</style>
