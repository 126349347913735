<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
             
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="container mt-5">

        <div class="row">
            <div class="col-lg-12 mb-lg-0" style="text-align:center;">
                <h3 style="padding: 20px;
    margin-top: -58px;">Ordem de Produtos Por Segmento</h3>
            </div>
            <div class="col-lg-4 mb-1 mb-lg-0" style="text-align:center;">
                <v-select v-model="Listaselected" @input="MudaLista"
                          :options="Lista"
                          :value="Lista.segmentoProdNivel1Id"
                          single-line
                          label="nomeSegmento" class="style-chooser w-100"></v-select>
            </div>
            <div class="col-lg-5 mb-1 mb-lg-0" style="text-align:center;">
                <v-select v-model="SubListaselected" @input="MudaSubLista"
                          :options="SubLista"
                          :value="SubLista.segmentoProdNivel12d"
                          single-line
                          label="nomeSegmento" class="style-chooser w-100"></v-select>
            </div>
            <div class="col-lg-3 mb-1 mb-lg-0" style="text-align:center;color:white;">
                <a class="btn btn-success mt-0 w-100"
                   @click="CarregaDados()">Listar</a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-10">
                <div class="drag-container" v-drag-and-drop:options="options">
                    <ul class="drag-list">
                        <li class="drag-column" v-for="group in groups" :key="group.id">
                            <span class="drag-column-header">
                                <h5>{{ group.name }}</h5>
                                <feather-icon type="more-vertical"></feather-icon>
                            </span>
                            <vue-draggable-group v-model="group.items"
                                                 :groups="groups"
                                                 :data-id="group.id"
                                                 @change="onGroupsChange">
                                <ul class="drag-inner-list" :data-id="group.id">
                                    <li class="drag-item" v-for="item in group.items" :key="item.id" :data-id="item.id">
                                        <img v-if="item.produto.imagens!=null && item.produto.imagens.length>0" :src="$Url + item.produto.imagens[0].urlImg" style="width:50px;height:auto;" class="hidden-xs" />
                                        <div class="drag-item-text" style="display: inline-flex;">{{ item.produto.nomeProduto }}</div>
                                    </li>
                                </ul>
                            </vue-draggable-group>
                        </li>.
                    </ul>
                </div>
            </div>
            <div class="col-lg-2">
                <a class="btn btn-info w-100" style="margin-top:70px;color:white;"
                   @click="SalvaDados()">Salvar Resultado</a>
            </div>
        </div>
    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import vSelect from 'vue-select';
    export default {
        components: {
            vSelect,
        },
        data() {
            return {
                ListaId: 0,
                Lista: [],
                Listaselected: null,
                SubListaId: 0,
                SubLista: [],
                SubListaselected: null,
                ProdutosLista: [],
                ProdutosResultado: [],
                resultado: {},
                groups: [],
                options: {
                    dropzoneSelector: ".drag-inner-list",
                    draggableSelector: ".drag-item"
                }
            };
        },
        methods: {
            MudaLista(value) {
                if (this.Listaselected == null) {
                    this.SubListaselected = null
                    this.SubLista = []
                }
                else {
                    this.ListaId = value.segmentoProdNivel1Id;
                    this.SubListaselected = null,
                        this.CarregaSubLista(value.segmentoProdNivel1Id)
                }

            },
            MudaSubLista(value) {
                this.SubListaId = value.segmentoProdNivel2Id;
            },
            CarregaSubLista(value) {
                console.log(value)
                this.$http
                    .get(this.$apiUrl + "/segmentoprodnivel2/nivel1/" + parseInt(value, 10))
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.SubLista = tiu2.sort(function (a, b) {
                            return a.nomeSegmento - b.nomeSegmento;
                        });
                    });
                console.log(this.SubLista)
            },
            onGroupsChange(e) {
                console.log({ e });
                this.resultado = e;
            },
            SalvaDados() {
                if (this.resultado.length > 0) {
                    let salvafinalizado = true;
                    console.log(this.ProdutosResultado)
                    //exclui os retirados
                    this.ProdutosResultado.forEach((itemex) => {
                        let recebe = this.resultado[1].items.filter(x => x.produto.produtoId == itemex.produto.produtoId)
                        if (recebe == '') {
                            this.$http.delete(this.$apiUrl + "/LigacaoSegmentoProduto/" + parseInt(itemex.ligacaoSegmentoProdutoId, 10)).then(
                                (response) => {
                                    console.log(response);
                                },
                                (error) => {
                                    this.$mensagem_erro(error.body);
                                    salvafinalizado = false;
                                }
                            );
                        }
                    });

                    //alterar ou incluir ajustando ordem
                    let vordem = 0;
                    this.resultado[1].items.forEach((itemex2) => {
                        vordem++;
                        let recebe2 = this.ProdutosResultado.filter(x => x.produto.produtoId == itemex2.produto.produtoId)
                        //console.log(recebe2)
                        if (recebe2 == '') {
                            //insere
                            let _ProdutoAdd = {
                                LigacaoSegmentoProdutoId: 0,
                                Produto: { ProdutoId: parseInt(itemex2.produto.produtoId, 10) },
                                SegmentoProdNivel1: { SegmentoProdNivel1Id: parseInt(this.ListaId, 10) },
                                SegmentoProdNivel2: { SegmentoProdNivel2Id: parseInt(this.SubListaId, 10) },
                                Ordem: vordem,
                            }
                            console.log("insere")
                            console.log(_ProdutoAdd)
                            this.$http
                                .post(this.$apiUrl + "/LigacaoSegmentoProduto", _ProdutoAdd)
                                .then(
                                    response4 => {
                                        // get body data
                                        _ProdutoAdd = response4.body;
                                        console.log("gravei produto segmento ligacao")
                                    },
                                    async response4 => {
                                        let recebe2 = response4.body;
                                        console.log(recebe2);
                                        salvafinalizado = false;
                                    }
                                );

                        } else {
                            //altera
                            //console.log(this.Listaselected)
                            let _ProdutoAlt = {
                                LigacaoSegmentoProdutoId: parseInt(recebe2[0].ligacaoSegmentoProdutoId, 10),
                                Produto: { ProdutoId: parseInt(itemex2.produto.produtoId, 10) },
                                SegmentoProdNivel1: { SegmentoProdNivel1Id: parseInt(this.ListaId, 10) },
                                SegmentoProdNivel2: { SegmentoProdNivel2Id: parseInt(this.SubListaId, 10) },
                                Ordem: vordem,
                            }
                            console.log("altera")
                            console.log(_ProdutoAlt)
                            this.$http
                                .put(this.$apiUrl + "/LigacaoSegmentoProduto/" + parseInt(recebe2[0].ligacaoSegmentoProdutoId, 10), _ProdutoAlt)
                                .then(
                                    (response) => {
                                        if (response.ok) {
                                            console.log("Item alterado com sucesso");
                                        }
                                    },
                                    (error) => {
                                        console.log(error.bodyText);
                                        salvafinalizado = false;
                                    }
                                );
                        }
                    });

                    if (salvafinalizado) {
                        this.$mensagem_normal("Resultado atualizado.");
                        this.CarregaDados();
                    }
                    else {
                        this.$mensagem_normal("Houve um erro ao carregar.");
                    }
                }
                else {
                    this.$mensagem_normal("Sem itens para atualizar.");
                }
              

            },
            CarregaDados() {
                if (this.Listaselected != null && this.SubListaselected != null) {

                    this.groups = []
                    let vcount = 0;

                    //carrega lista de todos produtos
                    this.$http
                        .get(this.$apiUrl + "/produto")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ProdutosLista = tiu2.filter(x => x.visivel).sort(function (a, b) {
                                return a.nomeProduto - b.nomeProduto;
                            });

                            //carrega produtos do segmento selecionado
                            this.$http
                                .get(this.$apiUrl + "/LigacaoSegmentoProduto/")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ProdutosResultado = tiu2.filter(x => x.produto.visivel && x.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.SubListaId, 10)).sort(function (a, b) {
                                        return a.ordem - b.ordem;
                                    });
                                    //console.log("produto lista")
                                    //console.log(this.ProdutosLista)
                                    //console.log("ligacao segmento produto")
                                    console.log(this.ProdutosResultado)

                                    this.groups.push({ id: 1, name: "Produtos Geral", items: [] })

                                    this.ProdutosLista.forEach((itemex) => {
                                        vcount++
                                        let recebe = this.ProdutosResultado.filter(x => x.produto.produtoId == itemex.produtoId);
                                        if (recebe == '') {
                                            this.groups[0].items.push({ id: vcount, produto: itemex, groupId: 1 })
                                        }

                                    });

                                    this.groups.push({ id: 3, name: "Ordem dos produtos no segmento", items: [] })
                                    //console.log(this.ProdutosAvulso)
                                    this.ProdutosResultado.forEach((itemex) => {
                                        vcount++
                                        this.groups[1].items.push({ id: vcount, produto: itemex.produto, groupId: 3 })
                                    });
                                });

                        });
                } else {
                    this.$mensagem_normal("Selecione Segmento nivel 1 e 2.");
                }
            },
        },
        created() {
            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Lista = tiu2.sort(function (a, b) {
                        return a.nomeSegmento - b.nomeSegmento;
                    });
                });
        }
    };
</script>
<style scoped>

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .drag-container {
        max-width: 1000px;
        margin: 20px auto;
    }

    .drag-list {
        display: flex;
        align-items: flex-start;
    }

    .drag-column {
        flex: 1;
        margin: 0 10px;
        position: relative;
        background: rgba(black, 0.2);
        overflow: hidden;
    }

    .drag-column-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        user-select: none;
    }

    .drag-inner-list {
        height: 85vh;
        overflow: auto;
    }

    .drag-item {
        margin: 5px;
        height: 60px;
        background: #f2f2f2;
        transition: ease-out;
    }

    .drag-item-text {
        font-size: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
    }


    .drag-header-more {
        cursor: pointer;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }

        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 6rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }

    @media (max-width:600px) {

        .drag-item {
            margin: 2px;
            height: 40px;
            background: #f2f2f2;
            transition: ease-out;
        }

        .drag-item-text {
            font-size: 12px;
            padding-left: 1rem;
            padding-top: 3px;
            line-height: 1.1;
        }

        .hidden-xs {
            display: none !important;
        }

        .item-dropzone-area {
            height: 3rem;
            background: #888;
            opacity: 0.8;
            animation-duration: 0.5s;
            animation-name: nodeInserted;
            margin-left: 0.6rem;
            margin-right: 0.6rem;
        }

        .drag-inner-list {
            height: 55vh;
            overflow: auto;
        }
    }
</style>