<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Idiomas</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos idiomas que serão utilizados.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Idioma</label>
                                                <input class="form-control"
                                                       v-model="NomeIdioma"
                                                       type="text"
                                                       placeholder="Idioma" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Padrão S/N?</label>
                                                <div class="custom-control custom-checkbox mb-5">
                                                    <input type="checkbox"
                                                           v-model="Padrao"
                                                           class="custom-control-input"
                                                           id="customCheck1-1" />
                                                    <label class="custom-control-label"
                                                           for="customCheck1-1">Idioma Padrão</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="idiomaId == null || idiomaId.length <= 0"
                                                    type="button"
                                                    @click="
                          $redirect(
                            '/controle/idiomas/' + NomeIdioma
                          )
                        "
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="idiomaId != null && idiomaId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="UpdateIdioma()"
                                                    id="sa-custom-position">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="AddIdioma()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Idioma</th>
                                <th>Padrão</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Idioma in Idiomas" :key="Idioma.idiomaId">
                                <td>{{ Idioma.idiomaId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      ">
                                            <a class="dropdown-item"
                                               @click="
                          $redirect('/controle/idioma/' + Idioma.idiomaId)
                        "><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="RemoveIdioma(Idioma)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Idioma.nome }}</td>
                                <td><span v-if="Idioma.padrao">S</span><span v-else>N</span></td>
                                <td><span v-if="Idioma.ativo">Ativo</span><span v-else>Inativo</span></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                NomeIdioma: this.$route.params.pesquisa,
                Ativo: null,
                Padrao: 0,
                idiomaId: this.$route.params.id,
                Idioma: {},
                Idiomas: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {}
            };
        },
        methods: {
            MudaStatus(value) {
                this.Ativo = value;
            },
            AddIdioma() {
                let _idioma = {
                    id: 0,
                    nome: this.NomeIdioma,
                    ativo: null,
                    padrao: this.Padrao,
                };

                //validações
                if (_idioma.nome.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o idioma");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _idioma.ativo = this.Ativo;

                    if (this.Padrao == 0) {
                        _idioma.padrao = false;
                    }
                    else {
                        _idioma.padrao = true;
                    }

                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/idiomas", _idioma).then(
                        (response) => {
                            // get body data
                            _idioma = response.body;
                            //this.Idiomas.push(_idioma);
                            // this.T.row.add(_idioma.idiomaId, _idioma.nome, pd, at);
                            this.$redirect_reload("/controle/idiomas");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );

                    this.NomeIdioma = "";
                    this.Ativo = null;
                    this.Padrao = 0;
                }
            },
            RemoveIdioma(IdiomaRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/idiomas/" + IdiomaRemover.idiomaId)
                    .then(
                        () => {
                            // get body data
                            //remove do array
                            //var indice = this.Idiomas.indexOf(IdiomaRemover);
                            //this.Idiomas.splice(indice, 1);
                            this.$redirect_reload("/controle/idiomas");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            UpdateIdioma() {
                let _idioma = {
                    idiomaId: 0,
                    nome: this.NomeIdioma,
                    ativo: null,
                    padrao: this.Padrao,
                };

                //validações
                if (_idioma.nome.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o idioma");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    
                    _idioma.ativo = this.Ativo;
                    _idioma.idiomaId = parseInt(this.idiomaId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/idiomas/" + this.idiomaId, _idioma)
                        .then(
                            (response) => {
                                // get body data
                                console.log(_idioma);
                                _idioma = response.body;
                                this.$mensagem_sucesso(
                                    "Idioma #" + this.idiomaId + " alterado com sucesso"
                                );
                                this.$redirect("/controle/idiomas");
                            },
                            (response) => {
                                // error callback
                                console.log(response);
                            }
                        );
                }
            },
            PesquisaIdioma() {
                try {
                    if (this.NomeIdioma.length <= 0) {
                        this.$mensagem_normal("Por favor preencha o idioma");
                    } else {
                        this.Idiomas = {};
                        let _idioma = {
                            nome: this.NomeIdioma,
                        };
                        this.$http
                            .post(this.$apiUrl + "/idiomas/busca", _idioma)
                            .then((res) => res.json())
                            .then((idi) => (this.Idiomas = idi))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/idiomas");
                this.NomeIdioma = "";
                this.Ativo = 0;
                this.Padrao = 0;

                this.$http
                    .get(this.$apiUrl + "/idiomas")
                    .then((res) => res.json())
                    .then((idi) => (this.Idiomas = idi)
                        .then(this.$redirect_reload("/controle/idiomas")));

            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);

            },
        },
        created() {
            //chama o get da api this.$apiUrl
            if (this.idiomaId != null && this.idiomaId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/idiomas/" + this.idiomaId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Idioma = idi;

                        this.NomeIdioma = this.Idioma.nome;
                        this.Padrao = this.Idioma.padrao;
                        this.Statuselected = this.Idioma.ativo;
                        this.Ativo = this.Idioma.ativo;
                    });
            }
            else if (this.NomeIdioma != null && this.NomeIdioma.length > 0) {
                this.PesquisaIdioma();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/idiomas")
                    .then((res) => res.json())
                    .then((idi) => (this.Idiomas = idi))
                    .then(this.atualiza());
            }

        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
