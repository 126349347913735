<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Bloco Home Itens</h4>
                                            <p class="mb-30">
                                                Tela para configurar Blocos na home.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Nome dos Blocos</label>
                                                <v-select v-model="Blocoselected" @input="MudaBloco"
                                                          :options="Blocos"
                                                          :value="Blocos.homeBannerId"
                                                          single-line
                                                          label="nomeBloco" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível Desktop</label>
                                                <v-select v-model="Statuselected1" @input="MudaStatus1"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                          <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível Mobile</label>
                                                <v-select v-model="Statuselected2" @input="MudaStatus2"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="homeMontaItemId != null && homeMontaItemId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Nome Bloco</th>
                                <th>Visível Desktop</th>
                                <th>Visível Mobile</th>
                                <th>Ordem</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Homeitem in Homeitens" :key="Homeitem.homeMontaItemId">
                                <td>{{ Homeitem.homeMontaItemId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/' + urlTela + '/' + Homeitem.homeMontaItemId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Homeitem)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Homeitem.homeBanner.nomeBloco }}</td>
                                <td><span v-if="Homeitem.visivelDesktop">Sim</span><span v-else>Não</span></td>
                                <td><span v-if="Homeitem.visivelMobile">Sim</span><span v-else>Não</span></td>
                                <td>{{ Homeitem.ordem }}</td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                homeMontaItemId: this.$route.params.id,
                urlTela: 'montahomeitem',
                VisivelD: null,
                VisivelM: null,
                Ordem: 0,
                Homeitem: {},
                Homeitens: [],
                Statuselected1: null,
                Statuselected2: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                Blocoselected: null,
                Blocos: [],
                homeBannerId: 0,
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus1(value) {
                this.VisivelD = value;
            },
             MudaStatus2(value) {
                this.VisivelM = value;
            },
            MudaBloco(value) {
                this.homeBannerId = value.homeBannerId;
            },
            Add() {
                let _Homemontaitem = {
                    homeMontaItemId: 0,
                    visivelDesktop: null,
                    visivelMobile: null,
                    ordem: 0,
                    homeBanner: { HomeBannerId: this.homeBannerId }
                };

                //validações
                if (this.Blocoselected == null) {
                    this.$mensagem_normal("Por favor escolha o bloco");
                } else if (this.VisivelD == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                }else if (this.VisivelM == null)
                {
                    this.$mensagem_normal("Por favor selecione o visível");
                } 
                else {

                    _Homemontaitem.visivelDesktop = this.VisivelD;
                    _Homemontaitem.visivelMobile = this.VisivelM;
                    _Homemontaitem.ordem = parseInt(this.Ordem, 10);

                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/homemontaitem/", _Homemontaitem).then(
                        response2 => {
                            // get body data
                            _Homemontaitem = response2.body;
                            this.$router.go(this.$router.currentRoute);
                        },
                        response2 => {
                            _Homemontaitem = response2.body;
                            this.$mensagem_normal(_Homemontaitem);
                        }
                    );
                }
            },
            Altera() {
                let _Homemontaitem = {
                    homeMontaItemId: 0,
                    visivelDesktop: null,
                    visivelMobile: null,
                    ordem: 0,
                    homeBanner: { HomeBannerId: this.homeBannerId }
                };

                //validações
                if (this.Blocoselected == null) {
                    this.$mensagem_normal("Por favor escolha o bloco");
               } else if (this.VisivelD == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                }else if (this.VisivelM == null)
                {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                     _Homemontaitem.visivelDesktop = this.VisivelD;
                    _Homemontaitem.visivelMobile = this.VisivelM;
                    _Homemontaitem.ordem = parseInt(this.Ordem, 10);
                    _Homemontaitem.homeMontaItemId = parseInt(this.homeMontaItemId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/homemontaitem/" + parseInt(this.homeMontaItemId, 10), _Homemontaitem)
                        .then(
                            response2 => {
                                // get body data
                                _Homemontaitem = response2.body;

                                 this.$mensagem_sucesso(
                                     "Blocos Monta Home #" + this.homeMontaItemId + " alterado com sucesso"
                                );
                                this.Limpar();
                            },
                            async response2 => {
                                _Homemontaitem = response2.body;
                                // error callback
                                this.$mensagem_normal(_Homemontaitem);
                            }
                        );
                }

            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/homemontaitem/" + dadosRemover.homeMontaItemId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Limpar() {
                this.$redirect_reload("/controle/" + this.urlTela);
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.homeMontaItemId == null) {
                this.homeMontaItemId = "";
            }

            this.$http
                .get(this.$apiUrl + "/homebanner/")
                .then((res) => res.json())
                .then((idi) => {
                    this.Blocos = idi;

                    this.Blocos = this.Blocos.sort(function (a, b) {
                        return a.nomeBloco - b.nomeBloco;
                    });
                });

            //chama o get da api this.$apiUrl
            if (this.homeMontaItemId != null && this.homeMontaItemId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/homemontaitem/" + this.homeMontaItemId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Homeitem = idi;

                        this.Statuselected1 = this.Homeitem.visivelDesktop;
                        this.Statuselected2 = this.Homeitem.visivelMobile;
                        this.VisivelD = this.Homeitem.visivelDesktop;
                        this.VisivelM = this.Homeitem.visivelMobile;
                        this.Ordem = this.Homeitem.ordem;
                        this.Blocoselected = this.Homeitem.homeBanner;

                   
                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/homemontaitem")
                    .then((res) => res.json())
                    .then((moe) => (this.Homeitens = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
