<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Tipos de Redes Sociais</h4>
                                            <p class="mb-30">
                                                Tela para cadastro tipos de redes sociais que serão utilizadas no sistema.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>Tipo</label>
                                                <input v-model="NomeRedeSocial" class="form-control" type="text" placeholder="Tipo de rede">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="TipoRedesSociaisId == null || TipoRedesSociaisId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/tiposredesocial/' + NomeRedeSocial)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="TipoRedesSociaisId != null && TipoRedesSociaisId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="UpdateTipoRedeSocial()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="AddTipoRedeSocial()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Nome</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="RedeSocial in RedesSociais" :key="RedeSocial.tipoRedesSociaisId">
                                <td>{{ RedeSocial.tipoRedesSociaisId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      ">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/TipoRedeSocial/' + RedeSocial.tipoRedesSociaisId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="RemoveTipoRedeSocial(RedeSocial)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ RedeSocial.nomeRedeSocial }}</td>
                                <td><span v-if="RedeSocial.ativo">Ativo</span><span v-else>Inativo</span></td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                NomeRedeSocial: "",
                Ativo: 0,
                TipoRedesSociaisId: this.$route.params.id,
                RedeSocial: {},
                RedesSociais: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {}
            };
        },
        methods: {
            MudaStatus(value) {
                this.Ativo = value;
            },
            AddTipoRedeSocial() {
                let _RedeSocial = {
                    TipoRedesSociaisId: 0,
                    NomeRedeSocial: this.NomeRedeSocial,
                    ativo: null,
                };

                //validações
                if (this.NomeRedeSocial.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o Tipo da Rede Social");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _RedeSocial.ativo = this.Ativo;

                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/TipoRedesSociais", _RedeSocial).then(
                        (response) => {
                            // get body data
                            _RedeSocial = response.body;
                            //this.Idiomas.push(_idioma);
                            // this.T.row.add(_idioma.idiomaId, _idioma.nome, pd, at);
                            this.$redirect_reload("/controle/TiposRedeSocial");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
                }
            },
            RemoveTipoRedeSocial(TipoRedeSocialRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/TipoRedesSociais/" + TipoRedeSocialRemover.tipoRedesSociaisId)
                    .then(
                        () => {
                            // get body data
                            //remove do array
                            //var indice = this.Idiomas.indexOf(IdiomaRemover);
                            //this.Idiomas.splice(indice, 1);
                            this.$redirect_reload("/controle/TiposRedeSocial");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            UpdateTipoRedeSocial() {
                let _RedeSocial = {
                    TipoRedesSociaisId: 0,
                    NomeRedeSocial: this.NomeRedeSocial,
                    ativo: null,
                };

                //validações
                if (_RedeSocial.NomeRedeSocial.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o Tipo da Rede Social");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _RedeSocial.ativo = this.Ativo;

                    _RedeSocial.TipoRedesSociaisId = parseInt(this.TipoRedesSociaisId, 10);
                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/TipoRedesSociais/" + this.TipoRedesSociaisId, _RedeSocial)
                        .then(
                            (response) => {
                                // get body data
                                console.log(_RedeSocial);
                                _RedeSocial = response.body;
                                this.$mensagem_sucesso(
                                    "Tipo de Rede Social #" + this.TipoRedesSociaisId + " alterado com sucesso"
                                );
                                this.$redirect("/controle/TiposRedeSocial");
                            },
                            (response) => {
                                // error callback
                                console.log(response);
                            }
                        );
                }
            },
            Pesquisa() {
                try {
                    if (this.NomeRedeSocial.length <= 0) {
                        this.$mensagem_normal("Por favor preencha o nome o Tipo da Rede");
                    } else {
                        this.RedesSociais = [];
                        let _RedeSocial = {
                            NomeRedeSocial: this.NomeRedeSocial,
                        };
                        this.$http
                            .post(this.$apiUrl + "/TipoRedesSociais/busca", _RedeSocial)
                            .then((res) => res.json())
                            .then((tiu) => (this.RedesSociais = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/TiposRedeSocial");

                this.$http
                    .get(this.$apiUrl + "/TipoRedesSociais")
                    .then((res) => res.json())
                    .then((idi) => (this.TiposRedeSocial = idi)
                        .then(this.$redirect_reload("/controle/TiposRedeSocial")));

            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);

            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.NomeRedeSocial = this.$route.params.pesquisa;
            } else {
                this.NomeRedeSocial = "";
            }
            //chama o get da api this.$apiUrl
            if (this.TipoRedesSociaisId != null && this.TipoRedesSociaisId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/TipoRedesSociais/" + this.TipoRedesSociaisId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.RedeSocial = idi;

                        this.NomeRedeSocial = this.RedeSocial.nomeRedeSocial;
                        this.Statuselected = this.RedeSocial.ativo;
                        this.Ativo = this.RedeSocial.ativo;
                    });
            }
            else if (this.NomeRedeSocial != null && this.NomeRedeSocial.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/TipoRedesSociais")
                    .then((res) => res.json())
                    .then((idi) => (this.RedesSociais = idi))
                    .then(this.atualiza());
            }

        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
