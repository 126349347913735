<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Bairros</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos Bairros.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <v-select v-model="Estadoselected" @input="MudaEstado"
                                                          :options="Estados"
                                                          :value="Estados.EstadoId"
                                                          single-line
                                                          label="nomeEstado" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Cidade</label>
                                                <v-select v-model="Cidadeselected" @input="MudaCidade"
                                                          :options="Cidades"
                                                          :value="Cidades.CidadeId"
                                                          single-line
                                                          label="nomeCidade" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>Bairro</label>
                                                <input v-model="NomeBairro" class="form-control" type="text" placeholder="Bairro">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="BairroId != null && BairroId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Bairro</th>
                                <th>Cidade</th>
                                <th>Estado</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Bairro in Bairros" :key="Bairro.bairroId">
                                <td>{{ Bairro.bairroId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/bairros/' + Bairro.bairroId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Bairro)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Bairro.nomeBairro }}</td>
                                  <td>
                                    <span v-if="Bairro.cidade != null">
                                        {{ Bairro.cidade.nomeCidade }}
                                    </span><span v-else>#</span>
                                </td>
                                <!-- <td>{{ NomeEstado(Bairro.cidade.cidadeId)}}</td> -->
                                <td>{{ Bairro.cidade.estado.nomeEstado}}</td>
                                

                                <td><span v-if="Bairro.ativo">Ativo</span><span v-else>Inativo</span></td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                BairroId: this.$route.params.id,
                NomeBairro: "",
                Ativo: null,
                CidadeId: 0,
                Bairro: {},
                Bairros: [],
                Cidades: [],
                Cidadeselected: null,
                EstadoId: 0,
                Estados: [],
                Estado: {},
                Estadoselected: null,
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
            };
        },
        methods: {
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                this.CidadeId = 0;
                this.Cidades = [];
                this.Cidadeselected = null;
                this.$http
                    .get(this.$apiUrl + "/cidades/ativosEstados/" + this.EstadoId)
                    .then((res) => res.json())
                    .then((tiu) => (this.Cidades = tiu));
            },
            MudaCidade(value) {
                this.CidadeId = value.cidadeId;
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            NomeEstado(value) {
                this.$http
                    .get(this.$apiUrl + "/cidades/" + parseInt(value))
                    .then((res3) => res3.json())
                    .then((tiu3) => {
                        this.Estado = tiu3;
                    }
                );
                return this.Estado.estado.nomeEstado;
            },
            Add() {
                let _Bairros = {
                    bairroId: 0,
                    cidade: { CidadeId: parseInt(this.CidadeId) },
                    nomeBairro: this.NomeBairro,
                    ativo: null,
                };

                //validações
                if (this.NomeBairro.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o bairro");
                } else if (this.Cidadeselected == null) {
                    this.$mensagem_normal("Por favor preencha o cidade");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    _Bairros.ativo = this.Ativo;

                    //chama o post da api

                    console.log(_Bairros);
                    this.$http.post(this.$apiUrl + "/bairro/" + parseInt(this.CidadeId), _Bairros).then(
                        response2 => {
                            // get body data
                            _Bairros = response2.body;
                            this.$redirect_reload("/controle/bairros");
                        },
                        response2 => {
                            _Bairros = response2.body;
                            // error callback
                            this.$mensagem_normal(_Bairros);
                        }
                    );
                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/bairro/" + DadosRemover.bairroId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/bairros");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Bairros = {
                    bairroId: 0,
                    cidade: { CidadeId: this.CidadeId },
                    nomeBairro: this.NomeBairro,
                    ativo: null,
                };

                //validações
                if (this.NomeBairro.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o bairro");
                } else if (this.Cidadeselected == null) {
                    this.$mensagem_normal("Por favor preencha o cidade");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    _Bairros.ativo = this.Ativo;
                    _Bairros.bairroId = parseInt(this.BairroId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/bairro/" + parseInt(this.BairroId, 10), _Bairros)
                        .then(
                            response2 => {
                                // get body data
                                _Bairros = response2.body;
                                this.$mensagem_sucesso(
                                    "Bairro #" + this.BairroId + " alterado com sucesso"
                                );
                                this.$redirect("/controle/bairros");
                            },
                            async response2 => {
                                _Bairros = response2.body;
                                // error callback
                                this.$mensagem_normal(_Bairros);
                            }
                        );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/bairros");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.BairroId == null) {
                this.BairroId = "";
            }

            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res2) => res2.json())
                .then((tiu2) => (this.Estados = tiu2));


            this.$http
                .get(this.$apiUrl + "/cidades/ativos")
                .then((res) => res.json())
                .then((tiu) => (this.Cidades = tiu));

            //chama o get da api this.$apiUrl
            if (this.BairroId != null && this.BairroId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/bairro/" + this.BairroId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Bairro = idi;

                        this.CidadeId = this.Bairro.cidade.cidadeId;
                        this.Cidadeselected = this.Bairro.cidade;

                        this.$http
                            .get(this.$apiUrl + "/cidades/" + parseInt(this.CidadeId))
                            .then((res3) => res3.json())
                            .then((tiu3) => {
                                this.Estado = tiu3;

                                this.EstadoId = this.Estado.estado.estadoId;
                                this.Estadoselected = this.Estado.estado;
                            }
                        );

                        this.NomeBairro = this.Bairro.nomeBairro;
                        this.Statuselected = this.Bairro.ativo;
                        this.Ativo = this.Bairro.ativo;

                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/bairro")
                    .then((res) => res.json())
                    .then((moe) => (this.Bairros = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
