<template>
  <div class="footer-wrap pd-20 mb-20 card-box">
    Desenvolvido por
    <a href="https://avancedigital.com.br" target="_blank"
      ><img
        src="/images/avance-logo-transparente.png"
        alt=""
        style="width: 100px; height: auto"
    /></a>
  </div>
</template>

    
<script>
export default {};
</script>

<style>

    .login-box{
      margin-top:10%;
      
    }
</style>