<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Metatags por página</h4>
                                            <p class="mb-30">
                                                Tela para cadastro de códigos metatags para links específicos.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>Link da página</label>
                                                <input v-model="Link" class="form-control" type="text" placeholder="Link da página">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Insira o código</label>
                                                <quill-editor v-model="Codigo" placeholder="Insira o código aqui ..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="SeoMetaPaginaId == null || SeoMetaPaginaId.length <= 0"
                                                    type="button"
                                                    @click="$redirect('/controle/seometapaginas/' + Link)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="SeoMetaPaginaId != null && SeoMetaPaginaId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar 
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Link</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="SeoMetaPagina in SeoMetaPaginas" :key="SeoMetaPagina.seoMetaPaginaId">
                                <td>{{ SeoMetaPagina.seoMetaPaginaId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/seometapagina/' + SeoMetaPagina.seoMetaPaginaId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(SeoMetaPagina)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ SeoMetaPagina.link }}</td>
                                <td><span v-if="SeoMetaPagina.visivel">Sim</span><span v-else>Não</span></td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                SeoMetaPaginaId: this.$route.params.id,
                Visivel: null,
                Link: "",
                Codigo: null,
                SeoMetaPagina: {},
                SeoMetaPaginas: [],
                Idiomas: [],
                Idiomaselected: null,
                Statuselected: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                T: {},
            };
        },
        methods: {
            MudaStatus(value) {
                this.Visivel = value;
            },
            Add() {
                let _SeoMetaPaginas = {
                    seoMetaPaginaId: 0,
                    link: this.Link,
                    codigo: "",
                    visivel: null,
                };

                //validações
                if (this.Link.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o link da meta");
                } else if (this.Codigo == "") {
                    this.$mensagem_normal("Por favor preencha o código da meta");
                } else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    _SeoMetaPaginas.visivel = this.Visivel;
                    _SeoMetaPaginas.link = this.Link;
                    _SeoMetaPaginas.codigo = this.Codigo;
                    _SeoMetaPaginas.codigo = _SeoMetaPaginas.codigo.replaceAll("&gt;", ">").replaceAll("&lt;", "<").replaceAll("<br>", "").replaceAll("<pre>", "").replaceAll("</pre>", "").replaceAll("<div>", "").replaceAll("</div>", "");

                    this.$http.post(this.$apiUrl + "/seometapagina/", _SeoMetaPaginas).then(
                        response2 => {
                            // get body data
                            _SeoMetaPaginas = response2.body;
                            this.$redirect_reload("/controle/seometapagina");
                        },
                        response2 => {
                            _SeoMetaPaginas = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            this.$mensagem_normal(_SeoMetaPaginas);
                        }
                    );
                }
            },
            Remove(DadoRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/seometapagina/" + DadoRemover.seoMetaPaginaId)
                    .then(
                        () => {
                            // get body data
                            //remove do array
                            //var indice = this.Idiomas.indexOf(IdiomaRemover);
                            //this.Idiomas.splice(indice, 1);
                            this.$redirect_reload("/controle/seometapagina");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _SeoMetaPaginas = {
                    seoMetaPaginaId: 0,
                    link: this.Link,
                    codigo: "",
                    visivel: null,
                };
                //validações
                if (this.Link.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o link da pagina");
                } else if (this.Codigo == "") {
                    this.$mensagem_normal("Por favor preencha o código.");
                } else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _SeoMetaPaginas.seoMetaPaginaId = parseInt(this.SeoMetaPaginaId, 10);
                    _SeoMetaPaginas.link = this.Link;
                    _SeoMetaPaginas.codigo = this.Codigo;
                    _SeoMetaPaginas.codigo = _SeoMetaPaginas.codigo.replaceAll("&gt;", ">").replaceAll("&lt;", "<").replaceAll("<br>", "").replaceAll("<pre>", "").replaceAll("</pre>", "").replaceAll("<div>", "").replaceAll("</div>", "");
                    _SeoMetaPaginas.visivel = this.Visivel;

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/seometapagina/" + this.SeoMetaPaginaId, _SeoMetaPaginas)
                        .then(
                            response2 => {
                                // get body data
                                _SeoMetaPaginas = response2.body;
                                console.log(_SeoMetaPaginas);
                                this.$mensagem_sucesso(
                                    "SeoMetaPagina #" + this.SeoMetaPaginaId + " alterada com sucesso"
                                );
                                this.$redirect("/controle/seometapagina");
                            },
                            async response2 => {
                                _SeoMetaPaginas = response2.body;
                                // error callback
                                //const data = await response2.json();
                                //JSON.parse(data);
                                this.$mensagem_normal(_SeoMetaPaginas);
                            }
                        );
                }
            },
            Pesquisa() {
                try {
                    if (this.Link.length <= 0) {
                        this.$mensagem_normal("Por favor preencha o link da meta");
                    } else {
                        this.SeoMetaPaginas = [];
                        let _SeoMetaPaginas = {
                            Link: this.Link,
                        };
                        this.$http
                            .post(this.$apiUrl + "/seometapagina/busca", _SeoMetaPaginas)
                            .then((res) => res.json())
                            .then((tiu) => (this.SeoMetaPaginas = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/seometapagina");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.Link = this.$route.params.pesquisa;
            } else {
                this.Link = "";
            }

            if (this.SeoMetaPaginaId == null) {
                this.SeoMetaPaginaId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.SeoMetaPaginaId != null && this.SeoMetaPaginaId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/seometapagina/" + this.SeoMetaPaginaId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.SeoMetaPagina = idi;

                        //this.SeoMetaPaginaId = this.SeoMetaPagina.seoMetaPaginaId;
                        this.Link = this.SeoMetaPagina.link;
                        this.Codigo = this.SeoMetaPagina.codigo.replaceAll(">", "&gt;").replaceAll("<", "&lt;");
                        this.Statuselected = this.SeoMetaPagina.visivel;
                        this.Visivel = this.SeoMetaPagina.visivel;

                    });
            }
            else if (this.Link != null && this.Link.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/seometapagina")
                    .then((res) => res.json())
                    .then((moe) => (this.SeoMetaPaginas = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
