<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Cupons</h4>
                      <p class="mb-30">
                        Tela para cadastro de cupons para compras no site.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Código</label>
                        <input
                          class="form-control"
                          v-model="Cupom.codigoCupom"
                          type="text"
                          placeholder="Código do cupom"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Gerar Código</label>
                        <button type="button" @click="GeraRandom()" class="btn btn-warning">
                          Gerador
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Ativo</label>
                        <v-select
                          v-model="Cupom.ativo"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Tipo</label>
                        <v-select
                          v-model="Cupom.tipoValor"
                          :options="[
                            { texto: 'Percentual %', valor: '%' },
                            { texto: 'Valor Fixo', valor: '' },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Valor</label>
                        <input
                          class="form-control"
                          v-model="Cupom.valorCupom"
                          @change="
                            Cupom.valorCupom != null
                              ? (Cupom.valorCupom = parseFloat(
                                  Cupom.valorCupom,
                                  10
                                ))
                              : (Cupom.valorCupom = 0)
                          "
                          type="number"
                          placeholder="Valor do cupom"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Desc. Frete</label>
                        <v-select
                          v-model="Cupom.descFrete"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Desc. Promoções</label>
                        <v-select
                          v-model="Cupom.descPromocao"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Data Validade Inicial</label>
                        <input
                          v-model="Cupom.dataIncial"
                          @change="MudaData1()"
                          class="form-control mb-5"
                          type="date"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Data Validade Final</label>
                        <input
                          v-model="Cupom.dataFinal"
                          @change="MudaData2()"
                          class="form-control mb-5"
                          type="date"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Uso Único</label>
                        <v-select
                          v-model="Cupom.usoUnico"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Qtde</label>
                        <input
                          class="form-control mb-5"
                          type="number"
                          placeholder=""
                          v-model="Cupom.qtdeUso"
                          @change="
                            Cupom.qtdeUso != null
                              ? (Cupom.qtdeUso = parseInt(Cupom.qtdeUso, 10))
                              : (Cupom.qtdeUso = 0)
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 1</label>
                        <v-select
                          v-model="Segmento1"
                          :options="Segmentos1"
                          single-line
                          :reduce="(x) => x.segmentoProdNivel1Id"
                          @input="MudaSegmento1"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 2</label>
                        <v-select
                          v-model="Segmento2"
                          :options="Segmentos2"
                          single-line
                          :reduce="(x) => x.segmentoProdNivel2Id"
                          @input="MudaSegmento2"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 3</label>
                        <v-select
                          v-model="Segmento3"
                          :options="Segmentos3"
                          single-line
                          :reduce="(x) => x.segmentoProdNivel3Id"
                          @input="MudaSegmento3"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 4</label>
                        <v-select
                          v-model="Segmento4"
                          :options="Segmentos4"
                          single-line
                          :reduce="(x) => x.segmentoProdNivel4Id"
                          @input="MudaSegmento4"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Produtos</label>
                        <ul style="border:1px solid black;padding:10px;max-width:max-content;overflow-y:scroll;margin:10px;border-radius:5px;;max-height:300px;">
                          <li class="hovertest" v-for="(Produto, Index) in Produtos" :key="Index" style="border-bottom:1px solid black;padding:2px">
                            <input
                              v-bind:checked="
                                ProdutosSelecionados.includes(Produto.produtoId)
                                  ? true
                                  : false
                              "
                              @change="SelecionaProduto(Produto.produtoId)"
                              class="chkProduto"
                              type="checkbox"
                            />
                            <span  style="padding-left: 9px;
    font-weight: bold;
    font-size: 15px;">{{ Produto.nomeProduto }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="button" class="btn btn-link">
                        Cancelar
                      </button>
                      <button
                        type="button"
                        @click="Alterar"
                        class="btn btn-success"
                        id="sa-custom-position"
                        v-if="CupomId > 0"
                      >
                        Alterar
                      </button>
                      <button
                        type="button"
                        @click="Salvar"
                        class="btn btn-success"
                        id="sa-custom-position"
                        v-else
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Cupom</th>
                <th>Ativo</th>
                <th>Valor</th>
                <th>DF</th>
                <th>DP</th>
                <th>Validade</th>
                <th>Único</th>
                <th>Nivel 1</th>
                <th>Nivel 2</th>
                <th>Nivel 3</th>
                <th>Nivel 4</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Cupom, Index) in Cupons" :key="Index">
                <td>{{ Cupom.cupomId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="$redirect('/controle/cupom/' + Cupom.cupomId)"
                        ><i class="dw dw-edit2"></i> Editar</a
                      >

                      <a
                        class="dropdown-item"
                        @click="Remover(Cupom)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Cupom.codigoCupom }}</td>
                <td>
                  <span v-if="Cupom.ativo">Sim</span><span v-else>Não</span>
                </td>
                <td>
                  <span
                    v-if="
                      Cupom.tipoValor == null || Cupom.tipoValor.length <= 0
                    "
                    >R$
                  </span>
                  {{
                    Cupom.valorCupom
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .replace(".", ",")
                      .replace("$", "")
                  }}<span
                    v-if="Cupom.tipoValor != null && Cupom.tipoValor.length > 0"
                    >%</span
                  >
                </td>
                <td>
                  <span v-if="Cupom.descontoFrete">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Cupom.descontoPromocao">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span
                    v-if="Cupom.dataFinal != null && Cupom.dataFinal.length > 0"
                    >{{ getData(Cupom.dataFinal) }}</span
                  >
                </td>
                <td>
                  <span v-if="Cupom.usoUnico">Sim</span><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Cupom.segmentoProdNivel1 != null">{{
                    Cupom.segmentoProdNivel1.nomeSegmento
                  }}</span
                  ><span v-else>Todos</span>
                </td>
                <td>
                  <span v-if="Cupom.segmentoProdNivel2 != null">{{
                    Cupom.segmentoProdNivel2.nomeSegmento
                  }}</span
                  ><span v-else>Todos</span>
                </td>
                <td>
                  <span v-if="Cupom.segmentoProdNivel3 != null">{{
                    Cupom.segmentoProdNivel3.nomeSegmento
                  }}</span
                  ><span v-else>Todos</span>
                </td>
                <td>
                  <span v-if="Cupom.segmentoProdNivel4 != null">{{
                    Cupom.segmentoProdNivel4.nomeSegmento
                  }}</span
                  ><span v-else>Todos</span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import moment from "moment";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      Segmento1: 0,
      Segmentos1: [],
      Segmento2: 0,
      Segmentos2: [],
      Segmento3: 0,
      Segmentos3: [],
      Segmento4: 0,
      Segmentos4: [],

      CupomId: 0,
      Cupom: {
        segmentoProdNivel1: { segmentoProdNivel1Id: 0 },
        segmentoProdNivel2: { segmentoProdNivel2Id: 0 },
        segmentoProdNivel3: { segmentoProdNivel3Id: 0 },
        segmentoProdNivel4: { segmentoProdNivel4Id: 0 },
        codigoCupom: "",
      },
      dataIncial: "",
      DataFinal: "",
      Cupons: [],

      Produtos: [],
      ProdutosSelecionados: [],

      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    GeraRandom(){
      var length = 5;
       var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       var result = '';
       for ( var i = 0; i < length; i++ ) {
           result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
       }

       this.Cupom.codigoCupom = result.toString();
       
    },
    getData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    MudaSegmento1() {
      if (this.Segmento1 > 0) {
        this.$http
          .get(this.$apiUrl + "/segmentoProdNivel2/nivel1/" + this.Segmento1)
          .then((response) => {
            this.Segmentos2 = response.body;
            this.Cupom.segmentoProdNivel1.segmentoProdNivel1Id = this.Segmento1;
            if (this.CupomId > 0 && this.Cupom.segmentoProdNivel2 != null) {
              this.Segmento2 =
                this.Cupom.segmentoProdNivel2.segmentoProdNivel2Id;
              this.MudaSegmento2();
            }
          });
      }
    },
    MudaSegmento2() {
      if (this.Segmento2 > 0) {
        this.$http
          .get(this.$apiUrl + "/segmentoProdnivel3/nivel2/" + this.Segmento2)
          .then((response) => {
            this.Segmentos3 = response.body;
            this.Cupom.segmentoProdNivel2.segmentoProdNivel2Id = this.Segmento2;
            if (this.CupomId > 0 && this.Cupom.segmentoProdNivel3 != null) {
              this.Segmento3 =
                this.Cupom.segmentoProdNivel3.segmentoProdNivel3Id;
              this.MudaSegmento3();
            }
          });
      }
    },
    MudaSegmento3() {
      if (this.Segmento3 > 0) {
        this.$http
          .get(this.$apiUrl + "/segmentoProdnivel4/nivel3/" + this.Segmento3)
          .then((response) => {
            this.Segmentos4 = response.body;
            this.Cupom.segmentoProdNivel3.segmentoProdNivel3Id = this.Segmento3;
            if (this.CupomId > 0 && this.Cupom.segmentoProdNivel3 != null) {
              this.Segmento3 =
                this.Cupom.segmentoProdNivel3.segmentoProdNivel3Id;
              this.MudaSegmento4();
            }
          });
      }
    },
    MudaSegmento4() {
      this.Cupom.segmentoProdNivel4.segmentoProdNivel4Id = this.Segmento4;
    },
    MudaData1() {
      this.dataIncial = moment(this.Cupom.dataIncial)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    MudaData2() {
      this.DataFinal = moment(this.Cupom.dataFinal)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    SelecionaProduto(id) {
      if (this.ProdutosSelecionados.includes(id)) {
        this.ProdutosSelecionados = this.ProdutosSelecionados.filter(
          (x) => x != id
        );
      } else {
        this.ProdutosSelecionados.push(id);
      }
    },
    Remover(seg) {
      this.$http.delete(this.$apiUrl + "/Cupom/" + seg.cupomId).then(
        (response) => {
          console.log(response);
          this.$mensagem_sucesso("Removido com sucesso");
          this.Cupons = this.Cupons.filter((x) => x.cupomId != seg.cupomId);
          this.$redirect_reload('/controle/cupons');
        },
        (error) => {
          this.$mensagem_erro(error.body);
        }
      );
    },
    Salvar() {
      console.log("POST:");
      this.Cupom.dataIncial = this.dataIncial;
      this.Cupom.dataFinal = this.DataFinal;
      console.log(this.Cupom);
      let listaItens = [];
      var cont = 0;
      for (cont = 0; cont < this.ProdutosSelecionados.length; cont++) {
        listaItens.push({ produtoId: this.ProdutosSelecionados[cont] });
      }
      this.$http.post(this.$apiUrl + "/cupom", this.Cupom).then(
        (response) => {
          if (response.ok) {
            this.$http
              .post(
                this.$apiUrl + "/CupomItem/atualiza/" + response.body.cupomId,
                listaItens
              )
              .then(
                (response) => {
                  if (response.ok) {
                    this.$mensagem_sucesso("Cupom cadastrado com sucesso");
                    this.$redirect("/controle/cupom/" + response.body.cupomId);
                  }
                },
                (error) => {
                  this.$mensagem_erro(error.bodyText);
                }
              );
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Alterar() {
      console.log("PUT:");
      this.Cupom.dataIncial = this.dataIncial;
      this.Cupom.dataFinal = this.DataFinal;
      console.log(this.Cupom);
      let listaItens = [];
      var cont = 0;
      for (cont = 0; cont < this.ProdutosSelecionados.length; cont++) {
        listaItens.push({ produtoId: this.ProdutosSelecionados[cont] });
      }
      this.$http.put(this.$apiUrl + "/cupom/" + this.CupomId, this.Cupom).then(
        (response) => {
          if (response.ok) {
            this.$http
              .post(
                this.$apiUrl + "/CupomItem/atualiza/" + this.CupomId,
                listaItens
              )
              .then(
                (response) => {
                  if (response.ok) {
                    this.$mensagem_sucesso("Cupom alterado com sucesso");
                    this.$redirect("/controle/cupons");
                  }
                },
                (error) => {
                  this.$mensagem_erro(error.bodyText);
                }
              );
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Pesquisar() {},
    // CarregaTela() {
    //   console.log(this.TelaSelecionada);
    //   this.$cookie.set("telaseg1", this.TelaSelecionada.toString(), "60m");
    //   this.$router.go(this.$router.currentRoute);
    //   this.$router.go(this.$router.currentRoute);
    // },
    Limpar() {
      this.$redirect_reload("/controle/cupons/");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.$http.get(this.$apiUrl + "/segmentoProdNivel1/").then((result) => {
      this.Segmentos1 = result.body;
    });

    this.$http.get(this.$apiUrl + "/Produto/").then((result) => {
      this.Produtos = result.body;
      this.Produtos = this.Produtos.filter((x) => x.visivel).sort(function (
        a,
        b
      ) {
        if (a.nomeProduto < b.nomeProduto) {
          return -1;
        }
        if (a.nomeProduto > b.nomeProduto) {
          return 1;
        }
        return 0;
      });
    });

    this.$http
      .get(this.$apiUrl + "/cupom/")
      .then((result) => {
        this.Cupons = result.body;
      })
      .then(this.atualiza());

    if (this.CupomId == 0) {
      if (this.$route.params.id != null) {
        this.CupomId = parseInt(this.$route.params.id, 10);
      }
    }
    console.log("EDITANDO:" + this.CupomId);
    if (this.CupomId > 0) {
      this.CupomId = parseInt(this.CupomId, 10);
      this.$http
        .get(this.$apiUrl + "/cupomitem/cupom/" + this.CupomId)
        .then((response) => {
          var lista = response.body;
          console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
          console.log(lista)
          var cont = 0;
          for (cont = 0; cont < lista.length; cont++) {
            var tmp = lista[cont];
            if (tmp != null && tmp.produto != null) {
              this.ProdutosSelecionados.push(tmp.produto.produtoId);
            }
          }
        });
      this.$http.get(this.$apiUrl + "/Cupom/" + this.CupomId).then(
        (response) => {
          console.log("Editando:");
          console.log(response.body);

          this.Cupom = response.body;
          // if (this.Cupom.Produtos != null && this.Cupom.Produtos.length > 0) {
          //   this.Cupom.Produtos.forEach((element) => {
          //     this.ProdutosSelecionados.push(element.produtoId);
          //   });
          // }

          this.dataIncial = this.Cupom.dataIncial = moment(
            this.Cupom.dataIncial
          ).format("YYYY-MM-DD");
          this.DataFinal = this.Cupom.dataFinal = moment(
            this.Cupom.dataFinal
          ).format("YYYY-MM-DD");

          if (this.Cupom.segmentoProdNivel1 != null) {
            this.Segmento1 = this.Cupom.segmentoProdNivel1.segmentoProdNivel1Id;
          }
          if (this.Cupom.segmentoProdNivel2 != null) {
            this.Segmento2 = this.Cupom.segmentoProdNivel2.segmentoProdNivel2Id;
          }
          if (this.Cupom.segmentoProdNivel3 != null) {
            this.Segmento3 = this.Cupom.segmentoProdNivel3.segmentoProdNivel3Id;
          }
          if (this.Cupom.segmentoProdNivel4 != null) {
            this.Segmento4 = this.Cupom.segmentoProdNivel4.segmentoProdNivel4Id;
          }

          this.MudaSegmento1();

          // this.Imagens = this.Segmento.imagens;
          // this.Imagens = this.Imagens.sort(function (a, b) {
          //   return a.ordem - b.ordem;
          // });
        },
        (error) => {
          console.log("Erro ao carregar:");
          console.log(error);
        }
      );
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hovertest:hover{
      background-color: #cadcf3;
    border: 5px solid #cadcf3;
}
.chkProduto
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
  padding: 10px;
}
button {
  margin: 10px;
}
</style>
