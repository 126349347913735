<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Importação Bling!</h4>
                      <p class="mb-30">
                        Tela para importação produtos e segmentos do Bling!.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="ImportarCategorias()"
                        id="sa-custom-position"
                        v-if="!ImportandoC && !ImportandoP"
                      >
                        Importar Categorias
                      </button>
                      <span v-if="ImportandoC">Buscando categorias do Bling! aguarde...</span>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="ImportarProdutos()"
                        id="sa-custom-position"
                        v-if="!ImportandoP && !ImportandoC"
                      >
                        Importar Produtos
                      </button>
                      <span v-if="ImportandoP">Buscando produtos do Bling! aguarde...</span>
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import { VMoney } from "v-money";

export default {
  data() {
    return {
      ImportandoC: false,
      ImportandoP: false,
      Token:
        "518137a73302079657d0ff9579ee7dbd3ae44ae65faab106e27795420054fb96cf216771",
    };
  },
  directives: { money: VMoney },
  methods: {
    ImportarCategorias() {
      this.ImportandoC = true;
      this.$http
        .get(this.$apiUrl + "/bling/importasegmento?apikey=" + this.Token)
        .then(
          (response) => {
            this.ImportandoC = false;
            console.log("RETORNO BLING:");
            console.log(response.body);
          },
          (error) => {
            this.ImportandoC = false;
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    ImportarProdutos() {
      this.ImportandoP = true;
      this.$http
        .get(this.$apiUrl + "/bling/importaproduto?apikey=" + this.Token)
        .then(
          (response) => {
            this.ImportandoP = false;
            console.log("RETORNO BLING:");
            console.log(response.body);
          },
          (error) => {
            this.ImportandoP = false;
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
  },
  created() {},
  mounted() {},
  components: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hovertest:hover {
  background-color: #cadcf3;
  border: 5px solid #cadcf3;
}
.chkProduto {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
  padding: 10px;
}
button {
  margin: 10px;
}
</style>
