<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Controle de Textos Gerais</h4>
                                            <p class="mb-30">
                                                Tela para cadastro de textos genéricos que poderão ser utilizadas junto aos detalhes dos produtos.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                     <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto 1</label>
                                            <input class="form-control" v-model="Texto.texto1" type="text" placeholder="Nome">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto 2</label>
                                            <input class="form-control" v-model="Texto.texto2" type="text" placeholder="Nome">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto 3</label>
                                            <input class="form-control" v-model="Texto.texto3" type="text" placeholder="Nome">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Descrição 1</label>
                                            <quill-editor
                                                v-model="Texto.descricao1"
                                                placeholder="Descrição..."
                                                ></quill-editor>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Descrição 2</label>
                                            <quill-editor
                                                v-model="Texto.descricao2"
                                                placeholder="Descrição..."
                                                ></quill-editor>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Descrição 3</label>
                                             <quill-editor
                                                v-model="Texto.descricao3"
                                                placeholder="Descrição..."
                                                ></quill-editor>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label>Imagem</label>
                                            <input type="file" @change="Upload" class="form-control-file form-control height-auto">
                                            <img
                                            src="/images/cloud-upload.gif"
                                            v-if="Enviando > Enviado"
                                            />
                                            <img :src="Texto.urlImg" v-if="Texto.urlImg != null && Texto.urlImg.length > 0" />
                                             <button v-if="Texto.urlImg != null && Texto.urlImg.length > 0"
                                                type="button"
                                                class="btn btn-warning btn-sm"
                                                id="sa-custom-position"
                                                @click="ApagaImagem()"
                                            >
                                                Apagar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Alterar()"
                                                    id="sa-custom-position2" v-if="TextoId > 0">
                                                Salvar
                                            </button>
                                               <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Salvar()"
                                                    id="sa-custom-position2">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    //import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";

    export default {
        data() {
            return {
                TextoId: 0,
                Texto: {
                    urlImg: ""
                },
                Enviando: 0,
                Enviado: 0
            };
        },
        methods: {
            ApagaImagem(){
                this.Texto.urlImg = "";
            },
            Upload(event){
                var files = event.target.files;
                var x = 0;
                var imageType = /image.*/;
                for (x = 0; x < files.length; x++) {
                    if (!files[x].type.match(imageType)) {
                    continue;
                    }
                    this.Enviando++;
                    let data = new FormData();
                    data.append("arquivo", files[x]);
                    console.log(files[x]);
                    this.$http
                    .post(
                        this.$apiUrl +
                        "/ProdutoTextoGeral/upload/",
                        data
                    )
                    .then(
                        (response) => {
                        console.log(response.body);
                        this.Enviado++;
                        this.Texto.urlImg = response.bodyText;
                        },
                        (response) => {
                        console.log(response);
                        }
                    );
                }
            },
            Salvar(){
                this.$http.post(this.$apiUrl + "/ProdutoTextoGeral", this.Texto)
                .then(response => {
                    if(response.ok)
                    {
                        this.$mensagem_sucesso("Texto geral salvo");
                        this.TextoId = response.body.produtoTextoGeralId
                    }
                }, error => {
                    this.$mensagem_erro(error.bodyText);
                })
            },
            Alterar() {
                this.$http.put(this.$apiUrl + "/ProdutoTextoGeral/" + this.TextoId, this.Texto)
                .then(response => {
                    if(response.ok)
                    {
                        this.$mensagem_sucesso("Texto geral salvo");
                    }
                }, error => {
                    this.$mensagem_erro(error.bodyText);
                })
            },
            Limpar() {
                this.$redirect_reload("/controle/textosgerais");
            },
        },
        created() {
            this.$http.get(this.$apiUrl + "/ProdutoTextoGeral")
            .then(response => {
                if(response.ok)
                {
                    var lista = response.body;
                    if(lista != null && lista.length > 0)
                    {
                        this.Texto = lista[0];
                        this.TextoId = this.Texto.produtoTextoGeralId;
                    }
                }
            })
        },
        mounted() {

        },
        components: {
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
