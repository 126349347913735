<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="container mt-5">
                      <div class="row">
                        <div
                          class="col-lg-12 mb-lg-0"
                          style="text-align: center"
                        >
                          <h3 style="padding: 20px;
    margin-top: -58px;">Ordem e distribuicao de avulsos por produtos</h3>
                        </div>
                        <div
                          class="col-lg-9 mb-1 mb-lg-0"
                          style="text-align: center"
                        >
                          <v-select
                            v-model="Listaselected"
                            @input="MudaLista"
                            :options="Lista"
                            :value="Lista.produtoId"
                            single-line
                            label="nomeProduto"
                            class="style-chooser w-100"
                          ></v-select>
                        </div>
                        <div
                          class="col-lg-3 mb-1 mb-lg-0"
                          style="text-align: center;color:white"
                        >
                          <a
                            class="btn btn-success mt-0 w-100"
                            @click="CarregaDados()"
                            >Listar</a
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-10">
                          <div
                            class="drag-container"
                            v-drag-and-drop:options="options"
                          >
                            <ul class="drag-list">
                              <li
                                class="drag-column"
                                v-for="group in groups"
                                :key="group.id"
                              >
                                <span class="drag-column-header">
                                  <h5>{{ group.name }}</h5>
                                  <feather-icon
                                    type="more-vertical"
                                  ></feather-icon>
                                </span>
                                <vue-draggable-group
                                  v-model="group.items"
                                  :groups="groups"
                                  :data-id="group.id"
                                  @change="onGroupsChange"
                                >
                                  <ul
                                    class="drag-inner-list"
                                    :data-id="group.id"
                                  >
                                    <li
                                      class="drag-item"
                                      v-for="item in group.items"
                                      :key="item.id"
                                      :data-id="item.id"
                                    >
                                      <img
                                        :src="$Url + item.produto.urlImg"
                                        style="width: 50px; height: auto"
                                        class="hidden-xs"
                                      />
                                      <div
                                        class="drag-item-text"
                                        style="display: inline-flex"
                                      >
                                        {{ item.produto.nomeItemAvulso }}
                                      </div>
                                    </li>
                                  </ul>
                                </vue-draggable-group>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <a
                            class="btn btn-info w-100"
                            style="margin-top: 70px;color:white;"
                            @click="SalvaDados()"
                            >Salvar Resultado</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      ListaId: 0,
      Lista: [],
      Listaselected: null,
      ProdutosAvulso: [],
      ItensAvulsos: [],
      resultado: {},
      groups: [],
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item",
      },
    };
  },
  methods: {
    MudaLista(value) {
      this.ListaId = value.produtoId;
    },
    onGroupsChange(e) {
      console.log({ e });
      this.resultado = e;
    },
    SalvaDados() {
      if (this.resultado.length > 0) {
        let salvafinalizado = true;
        //console.log(this.ProdutosAvulso)
        //exclui os retirados
        this.ProdutosAvulso.forEach((itemex) => {
          let recebe = this.resultado[1].items.filter(
            (x) => x.produto.itemAvulsoId == itemex.itemAvulso.itemAvulsoId
          );
          if (recebe == "") {
            this.$http
              .delete(
                this.$apiUrl +
                  "/ProdutoAvulso/" +
                  parseInt(itemex.produtoAvulsoId, 10)
              )
              .then(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  this.$mensagem_erro(error.body);
                  salvafinalizado = false;
                }
              );
          }
        });

        //alterar ou incluir ajustando ordem
        let vordem = 0;
        this.resultado[1].items.forEach((itemex2) => {
          vordem++;
          let recebe2 = this.ProdutosAvulso.filter(
            (x) => x.itemAvulso.itemAvulsoId == itemex2.produto.itemAvulsoId
          );
          //console.log(recebe2)
          if (recebe2 == "") {
            //insere
            let _produtoAvulsoAdd = {
              ProdutoAvulsoId: 0,
              Produto: {
                ProdutoId: parseInt(this.Listaselected.produtoId, 10),
              },
              ItemAvulso: {
                ItemAvulsoId: parseInt(itemex2.produto.itemAvulsoId, 10),
              },
              Ordem: vordem,
            };
            this.$http
              .post(this.$apiUrl + "/ProdutoAvulso", _produtoAvulsoAdd)
              .then(
                (response4) => {
                  // get body data
                  _produtoAvulsoAdd = response4.body;
                  console.log("gravei item pedido adicional balao");
                },
                async (response4) => {
                  let recebe2 = response4.body;
                  console.log(recebe2);
                  salvafinalizado = false;
                }
              );
          } else {
            //altera
            //console.log(this.Listaselected)
            let _produtoAvulsoAlt = {
              ProdutoAvulsoId: parseInt(recebe2[0].produtoAvulsoId, 10),
              Produto: {
                ProdutoId: parseInt(this.Listaselected.produtoId, 10),
              },
              ItemAvulso: {
                ItemAvulsoId: parseInt(itemex2.produto.itemAvulsoId, 10),
              },
              Ordem: vordem,
            };
            this.$http
              .put(
                this.$apiUrl +
                  "/ProdutoAvulso/" +
                  parseInt(recebe2[0].produtoAvulsoId, 10),
                _produtoAvulsoAlt
              )
              .then(
                (response) => {
                  if (response.ok) {
                    console.log("Item alterado com sucesso");
                  }
                },
                (error) => {
                  console.log(error.bodyText);
                  salvafinalizado = false;
                }
              );
          }
        });

        if (salvafinalizado) {
          this.$mensagem_normal("Resultado atualizado.");
          this.CarregaDados();
        } else {
          this.$mensagem_normal("Houve um erro ao carregar.");
        }
      } else {
        this.$mensagem_normal("Sem itens para atualizar.");
      }
    },
    CarregaDados() {
      if (this.Listaselected != null) {
        this.groups = [];
        let vcount = 0;

        //carrega lista de todos avulsos e bal�es
        this.$http
          .get(this.$apiUrl + "/itemAvulso")
          .then((res2) => res2.json())
          .then((tiu2) => {
            this.ItensAvulsos = tiu2
              .filter((x) => x.visivel)
              .sort(function (a, b) {
                return a.nomeItemAvulso - b.nomeItemAvulso;
              });

            //carrega avulsos e bal�es do produtos selecionado
            this.$http
              .get(
                this.$apiUrl +
                  "/ProdutoAvulso/produto/" +
                  parseInt(this.ListaId, 10)
              )
              .then((res2) => res2.json())
              .then((tiu2) => {
                this.ProdutosAvulso = tiu2
                  .filter((x) => x.produto.visivel)
                  .sort(function (a, b) {
                    return a.ordem - b.ordem;
                  });

                this.groups.push({
                  id: 1,
                  name: "Produtos Avulsos",
                  items: [],
                });

                this.ItensAvulsos.forEach((itemex) => {
                  vcount++;
                  let recebe = this.ProdutosAvulso.filter(
                    (x) => x.itemAvulso.itemAvulsoId == itemex.itemAvulsoId
                  );
                  if (recebe == "") {
                    this.groups[0].items.push({
                      id: vcount,
                      produto: itemex,
                      groupId: 1,
                    });
                  }
                });

                this.groups.push({
                  id: 3,
                  name: "Avulsos e ordem no produto ",
                  items: [],
                });
                //console.log(this.ProdutosAvulso)
                this.ProdutosAvulso.forEach((itemex) => {
                  vcount++;
                  this.groups[1].items.push({
                    id: vcount,
                    produto: itemex.itemAvulso,
                    groupId: 3,
                  });
                });
              });
          });
      } else {
        this.$mensagem_normal("Voc� deve selecionar Produto.");
      }
    },
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/produto")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Lista = tiu2
          .filter((x) => x.visivel)
          .sort(function (a, b) {
            return a.nomeProduto - b.nomeProduto;
          });
      });
  },
};
</script>

<style scoped>

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .drag-container {
        max-width: 1000px;
        margin: 20px auto;
    }

    .drag-list {
        display: flex;
        align-items: flex-start;
    }

    .drag-column {
        flex: 1;
        margin: 0 10px;
        position: relative;
        background: rgba(black, 0.2);
        overflow: hidden;
    }

    .drag-column-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        user-select: none;
    }

    .drag-inner-list {
        height: 85vh;
        overflow: auto;
    }

    .drag-item {
        margin: 5px;
        height: 60px;
        background: #f2f2f2;
        transition: ease-out;
    }

    .drag-item-text {
        font-size: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
    }


    .drag-header-more {
        cursor: pointer;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }

        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 6rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }

    @media (max-width:600px) {

        .drag-item {
            margin: 2px;
            height: 40px;
            background: #f2f2f2;
            transition: ease-out;
        }

        .drag-item-text {
            font-size: 12px;
            padding-left: 1rem;
            padding-top: 3px;
            line-height: 1.1;
        }

        .hidden-xs {
            display: none !important;
        }

        .item-dropzone-area {
            height: 3rem;
            background: #888;
            opacity: 0.8;
            animation-duration: 0.5s;
            animation-name: nodeInserted;
            margin-left: 0.6rem;
            margin-right: 0.6rem;
        }

        .drag-inner-list {
            height: 55vh;
            overflow: auto;
        }
    }
</style>
