<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Videos</h4>
                      <p class="mb-30">
                        Tela para cadastro dos Videos disponibilizados na área
                        interna do site para clientes.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 1</label>
                        <v-select
                          v-model="Segmento1"
                          :options="Segmentos1"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel1Id"
                          @input="MudaSegmento1"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 2</label>
                        <v-select
                          v-model="Segmento2"
                          :options="Segmentos2"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel2Id"
                          @input="MudaSegmento2"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 3</label>
                        <v-select
                          v-model="Segmento3"
                          :options="Segmentos3"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel3Id"
                          @input="MudaSegmento3"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 4</label>
                        <v-select
                          v-model="Segmento4"
                          :options="Segmentos4"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel4Id"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Nome do Video</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                          v-model="Video.nomeVideo"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Ordem</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Ordem"
                          v-model="Video.ordem"
                          @change="Video.ordem != null ? Video.ordem = parseInt(Video.ordem, 10):Video.ordem = 0"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Destaque</label>
                        <v-select
                          v-model="Video.destaque"
                          :options="[
                            { nome: 'Sim', destaque: true },
                            { nome: 'Não', destaque: false },
                          ]"
                          single-line
                          :reduce="(x) => x.destaque"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Visivel</label>
                        <v-select
                          v-model="Video.visivel"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-12">
                       <div class="form-group">
                           <label>Link do Vídeo</label>
                           <input class="form-control" v-model="Video.linkVideo" type="text" placeholder="Link">
                       </div>
                   </div>
                   <div class="col-md-12">
                      <div class="form-group">
                          <label>Ou código vídeo</label>
                          <small class="form-text text-muted">
                              *Clique no lápis para editar e inserir ou visualizar o código embed do vídeo.
                          </small>
                           <quill-editor
                            v-model="Video.codigoVideo"
                            placeholder="Código"
                        ></quill-editor>
                      </div>
                  </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="button" class="btn btn-link" @click="$redirect_reload('/controle/Videoscli')">
                        Cancelar
                      </button>
                      <button type="button" class="btn btn-info">
                        Pesquisar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Salvar()"
                        v-if="VideoId == null || VideoId <= 0"
                      >
                        Salvar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Alterar()"
                        v-else
                      >
                        Alterar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Abrir</th>
                <th>Nome</th>
                <th>Nivel 1</th>
                <th>Nivel 2</th>
                <th>Nivel 3</th>
                <th>Nivel 4</th>
                <th>Ordem</th>
                <th>Destaque</th>
                <th>Visível</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Video, index) in Videos" :key="index">
                <td>{{ Video.videoId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect_reload(
                            '/controle/Videocli/' + Video.videoId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Video)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <a class="btn-block" @click="AbreModal(Video)"><i class="icon-copy dw dw-video-player" style="font-size:18px;"></i></a>
                </td>
                <td>{{ Video.nomeVideo }}</td>
                <td>
                  <span v-if="Video.segmentoCliNivel1 != null">{{
                    Video.segmentoCliNivel1.nomeSegmento
                  }}</span>
                </td>
                <td>
                  <span v-if="Video.segmentoCliNivel2 != null">{{
                    Video.segmentoCliNivel2.nomeSegmento
                  }}</span>
                </td>
                <td>
                  <span v-if="Video.segmentoCliNivel3 != null">{{
                    Video.segmentoCliNivel3.nomeSegmento
                  }}</span>
                </td>
                <td>
                  <span v-if="Video.segmentoCliNivel4 != null">{{
                    Video.segmentoCliNivel4.nomeSegmento
                  }}</span>
                </td>
                <td>{{ Video.ordem }}</td>
                <td>
                  <span v-if="Video.destaque">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Video.visivel">Sim</span><span v-else>Não</span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <a href="#" class="btn-block" data-toggle="modal" data-target="#bd-example-modal-lg" id="abreModal" type="button"></a>
    <div class="modal fade bs-example-modal-lg" id="bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="myLargeModalLabel">{{NomeModal}}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body">
                            <div class="container" id="containerHtmlVideo">
                                <!-- <div data-type="youtube" data-video-id="bTqVqk7FSmY"></div> -->
                                {{HtmlModal}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      NomeModal: "",
      HtmlModal: "",
      VideoId: {},
      Video: {},
      Videos: [],
      Segmento1: 0,
      Segmentos1: [],
      Segmento2: 0,
      Segmentos2: [],
      Segmento3: 0,
      Segmentos3: [],
      Segmento4: 0,
      Segmentos4: [],
      T: $(".data-table").DataTable(),
    };
  },
  components: {
    vSelect,
    // Rodape,
  },
  created() {
    this.$http.get(this.$apiUrl + "/Videos/").then((response) => {
      this.Videos = response.body;
    }).then(this.atualiza());

    this.$http.get(this.$apiUrl + "/segmentoCliNivel1/").then((response) => {
      console.log("DDL1:");
      console.log(response.body);
      this.Segmentos1 = response.body;
    });

    if (this.$route.params.id != null) {
      this.VideoId = parseInt(this.$route.params.id, 10);
    } else {
      this.VideoId = 0;
    }

    if(this.VideoId != null && this.VideoId > 0)
    {
       this.$http.get(this.$apiUrl + "/Videos/" + this.VideoId).then((response) => {
        this.Video = response.body;
        this.VideoUpload = this.Video.urlVideo;
        if (this.Video.segmentoCliNivel1 != null) {
          this.Segmento1 =
            this.Video.segmentoCliNivel1.segmentoCliNivel1Id;
        }
        if (this.Video.segmentoCliNivel2 != null) {
          this.Segmento2 =
            this.Video.segmentoCliNivel2.segmentoCliNivel2Id;
        }
        if (this.Video.segmentoCliNivel3 != null) {
          this.Segmento3 =
            this.Video.segmentoCliNivel3.segmentoCliNivel3Id;
        }
        if (this.Video.segmentoCliNivel4 != null) {
          this.Segmento4 =
            this.Video.segmentoCliNivel4.segmentoCliNivel4Id;
        }
        this.MudaSegmento1();

        console.log("Carrego")
      });
    }
  },
  methods: {
    AbreModal(Video)
    {
      this.NomeModal = Video.nomeVideo;
      if(Video.codigoVideo != null && Video.codigoVideo.length > 0)
      {
        alert("cod")
        document.getElementById('containerHtmlVideo').innerHTML = this.$clearHTML(Video.codigoVideo);
      }
      else
      {
        var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;// eslint-disable-line
        var match = Video.linkVideo.match(regExp);
        if (match && match[1].length==11) {
          var code =  match[1];
          document.getElementById('containerHtmlVideo').innerHTML = "<iframe width=\"100%\" height=\"315\" src=\"https://www.youtube.com/embed/"+code+"\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>";
        }
        else
        {
          document.getElementById('containerHtmlVideo').innerHTML = "Não foi possivel carregar o video";
        }
      }
      document.getElementById("abreModal").click();
    },
    Salvar() {
      console.log("SALVAR");
      if(this.Segmento1 != null && this.Segmento1 > 0)
      {
         this.$http
        .get(this.$apiUrl + "/SegmentoCliNivel1/" + this.Segmento1)
        .then((response) => {
          this.Video.segmentoCliNivel1 = response.body;
          console.log("1");
        }, (error) => {this.Video.segmentoCliNivel1 = null;console.log(error)})
        .then(() => {
          this.$http
            .get(this.$apiUrl + "/SegmentoCliNivel2/" + this.Segmento2)
            .then((response) => {
              this.Video.segmentoCliNivel2 = response.body;
              console.log("2");
            }, (error) => {this.Video.segmentoCliNivel2 = null;console.log(error)})
            .then(() => {
              this.$http
                .get(this.$apiUrl + "/SegmentoCliNivel3/" + this.Segmento3)
                .then((response) => {
                  this.Video.segmentoCliNivel3 = response.body;
                  console.log("3");
                } , (error) => {this.Video.segmentoCliNivel3 = null;console.log(error)})
                .then(() => {
                  this.$http
                    .get(this.$apiUrl + "/SegmentoCliNivel4/" + this.Segmento4)
                    .then((response) => {
                      this.Video.segmentoCliNivel4 = response.body;
                      this.$http.post(this.$apiUrl + "/Videos/", this.Video)
                      .then(response => {
                        this.$mensagem_sucesso("Video cadastrado com sucesso");
                        this.$redirect_reload("/controle/Videocli/" + response.body.videoId);
                      }, error => {
                        this.$mensagem_erro(error.bodyText);
                      })
                    }, (error) => {this.Video.segmentoCliNivel1 = null;console.log(error)});
                });
            });
        });
      }
      else
      {
         this.$http.post(this.$apiUrl + "/Videos/", this.Video)
         .then(response => {
           this.$mensagem_sucesso("Video cadastrado com sucesso");
           this.$redirect_reload("/controle/Videocli/" + response.body.videoId);
         }, error => {
           this.$mensagem_erro(error.bodyText);
         });
      }
     
    },
    Alterar() {
      if(this.Segmento1 != null && this.Segmento1 > 0)
      {
      console.log("SALVAR");
      this.$http
        .get(this.$apiUrl + "/SegmentoCliNivel1/" + this.Segmento1)
        .then((response) => {
          this.Video.segmentoCliNivel1 = response.body;
          console.log("1");
        }, (error) => {this.Video.segmentoCliNivel1 = null;console.log(error)})
        .then(() => {
          this.$http
            .get(this.$apiUrl + "/SegmentoCliNivel2/" + this.Segmento2)
            .then((response) => {
              this.Video.segmentoCliNivel2 = response.body;
              console.log("2");
            }, (error) => {this.Video.segmentoCliNivel2 = null;console.log(error)})
            .then(() => {
              this.$http
                .get(this.$apiUrl + "/SegmentoCliNivel3/" + this.Segmento3)
                .then((response) => {
                  this.Video.segmentoCliNivel3 = response.body;
                  console.log("3");
                } , (error) => {this.Video.segmentoCliNivel3 = null;console.log(error)})
                .then(() => {
                  this.$http
                    .get(this.$apiUrl + "/SegmentoCliNivel4/" + this.Segmento4)
                    .then((response) => {
                      this.Video.segmentoCliNivel4 = response.body;
                      this.$http.put(this.$apiUrl + "/Videos/" + this.VideoId, this.Video)
                      .then(response => {
                        this.$mensagem_sucesso("Video alterado com sucesso");
                        this.$redirect_reload("/controle/Videoscli/");
                        console.log(response);
                      }, error => {
                        this.$mensagem_erro(error.bodyText);
                      })
                    }, (error) => {this.Video.segmentoCliNivel1 = null;console.log(error)});
                });
            });
        });
      }
      else
      {
        this.$http.put(this.$apiUrl + "/Videos/" + this.VideoId, this.Video)
       .then(response => {
         this.$mensagem_sucesso("Video alterado com sucesso");
         this.$redirect_reload("/controle/Videoscli/");
         console.log(response);
       }, error => {
         this.$mensagem_erro(error.bodyText);
       });
      }
    },
    Remove(Video) {
      console.log("Apagar id " + Video.videoId);
      this.$http.delete(this.$apiUrl + "/Videos/" + Video.videoId)
      .then(response =>{
        if(response.ok)
          this.$mensagem_sucesso("#" + Video.videoId + " Removido com sucesso.");
          this.$redirect_reload("/controle/Videoscli/");
      }, error => {
        console.log(error);
      });
    },
    MudaSegmento1() {
      this.$http
        .get(this.$apiUrl + "/segmentoCliNivel2/nivel1/" + this.Segmento1)
        .then((response) => {
          this.Segmentos2 = response.body;
          if (this.VideoId > 0 && this.Video.segmentoCliNivel2 != null) {
            this.Segmento2 = this.Video.segmentoCliNivel2.segmentoCliNivel2Id;
            this.MudaSegmento2();
          }
        });
    },
    MudaSegmento2() {
      this.$http
        .get(this.$apiUrl + "/segmentoClinivel3/nivel2/" + this.Segmento2)
        .then((response) => {
          this.Segmentos3 = response.body;
          if (this.VideoId > 0 && this.Video.segmentoCliNivel3 != null) {
            this.Segmento3 = this.Video.segmentoCliNivel3.segmentoCliNivel3Id;
            this.MudaSegmento3();
          }
        });
    },
    MudaSegmento3() {
      this.$http
        .get(this.$apiUrl + "/segmentoClinivel4/nivel3/" + this.Segmento3)
        .then((response) => {
          this.Segmentos4 = response.body;
          if (this.VideoId > 0 && this.Video.segmentoCliNivel4 != null) {
            this.Segmento3 = this.Video.segmentoCliNivel3.segmentoCliNivel3Id;
            this.MudaSegmento3();
          }
        });
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
