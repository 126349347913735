<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Importação IBR Sistemas</h4>
                      <p class="mb-30">
                        Tela para importação produtos e segmentos do IBR
                        Sistemas.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="ImportarCategorias()"
                        id="sa-custom-position"
                        v-if="!ImportandoC && !ImportandoP"
                      >
                        Importar Categorias
                      </button>
                      <span v-if="ImportandoC"
                        >Buscando categorias do IBR Sistemas aguarde...</span
                      >
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="ImportarProdutos()"
                        id="sa-custom-position"
                        v-if="!ImportandoP && !ImportandoC"
                      >
                        Importar Produtos
                      </button>
                      <span v-if="ImportandoP"
                        >Buscando produtos do IBR Sistemas aguarde...</span
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import { VMoney } from "v-money";

export default {
  data() {
    return {
      ImportandoC: false,
      ImportandoP: false,
      Token: "",
    };
  },
  directives: { money: VMoney },
  methods: {
    ImportarCategorias() {
      this.ImportandoC = true;
      this.$http
        .get(this.$apiUrl + "/ibr/importasegmento?apikey=" + this.Token)
        .then(
          (response) => {
            this.ImportandoC = false;
            console.log("RETORNO IBR:");
            console.log(response.body);
          },
          (error) => {
            this.ImportandoC = false;
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    ImportarProdutos() {
      this.ImportandoP = true;
      this.$http
        .get(this.$apiUrl + "/ibr/importaproduto?apikey=" + this.Token)
        .then(
          (response) => {
            this.ImportandoP = false;
            console.log("RETORNO IBR:");
            console.log(response.body);
          },
          (error) => {
            this.ImportandoP = false;
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
  },
  created() {
    var vazio = {};
    var dados = {
      email: "avancodigital@hortifrutileticia.com.br",
      senha: "avanco@ibr123",
      acessos: [
        {
          tipoAcesso: "MinhasVendas",
          identificadorDispositivo: "75CDABDAABA35F5BEE6EB91C5A503C41",
          // identificadorDispositivo: '75CDABDAABA35F5BEE6EB91C5A503C42',
          versao: "AVANCE",
        },
      ],
    };
    this.$http
      .get(this.$apiUrl + "/institucional/tela/IBR Integração")
      .then((response) => {
        if (response.ok) {
          vazio = response.body;
          this.Token = response.body.descricao1;
          dados.senha = vazio.referencia;
          var ac = {
            tipoAcesso: vazio.campoExtra1,
            identificadorDispositivo: vazio.campoExtra2,
            // identificadorDispositivo: '75CDABDAABA35F5BEE6EB91C5A503C42',
            versao: vazio.campoExtra3,
          };
         dados.acessos.pop();
         dados.acessos.push(ac);
          console.log("dados:");
          console.log(dados);
          this.$http
            .get("http://api.ibrsistemas.com.br:5009/api/v1/produto", {
              headers: { Authorization: "bearer " + this.Token },
            })
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
                // dados.email = vazio.nome;
                // dados.senha = vazio.referencia;
                // dados.acessos.tipoAcesso = vazio.campoExtra1;
                // dados.acessos.identificadorDispositivo = vazio.campoExtra2;
                // dados.acessos.versao = vazio.campoExtra3;

                this.$http
                  .post(
                    "http://api.ibrsistemas.com.br:5009/api/v1/auth/login",
                    dados
                  )
                  .then((response) => {
                    if (response.ok) {
                      this.Token = response.body.auth.access_token;
                      vazio.descricao1 = this.Token;
                      this.$http.put(
                        this.$apiUrl +
                          "/institucional/" +
                          vazio.institucionalId,
                        vazio
                      );
                    }
                  });
              }
            );
        }
      });
  },
  mounted() {},
  components: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hovertest:hover {
  background-color: #cadcf3;
  border: 5px solid #cadcf3;
}
.chkProduto {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
  padding: 10px;
}
button {
  margin: 10px;
}
</style>
