import Vue from 'vue';
import Router from 'vue-router'
import Home from './components/Home'
import Testes from './components/Testes'
import Idiomas from './components/Idiomas'
import Login from './components/Login'
import TipoUsuario from './components/TiposUsuario'
import Usuarios from './components/Usuarios'
import SegmentosInst from './components/SegmentosInst'
import Moedas from './components/Moedas'
import Telas from './components/Telas'
import Controlesmtp from './components/Controlesmtp'
import SeoMetaHome from './components/SeoMetaHome'
import SeoAnalitycs from './components/SeoAnalitycs'
import SeoBody from './components/SeoBody'
import SeoHead from './components/SeoHead'
import SeoMetaPagina from './components/SeoMetaPagina'
import SegmentosInst1 from './components/SegmentosInst1'
import SegmentosInst2 from './components/SegmentosInst2'
import SegmentosInst3 from './components/SegmentosInst3'
import SegmentosInst4 from './components/SegmentosInst4'

import SegmentosCli1 from "./components/SegmentosCli1";
import SegmentosCli2 from "./components/SegmentosCli2";
import SegmentosCli3 from "./components/SegmentosCli3";
import SegmentosCli4 from "./components/SegmentosCli4";

import ArquivosCli from "./components/ArquivosCli";
import VideosCli from "./components/VideosCli";
import FotosCli from "./components/FotosCli";
import ConfirmaCadastroCli from "./components/ConfirmaCadastroCli";

import SegmentosProd1 from "./components/produtos/SegmentosProd1";
import SegmentosProd2 from "./components/produtos/SegmentosProd2";
import SegmentosProd3 from "./components/produtos/SegmentosProd3";
import SegmentosProd4 from "./components/produtos/SegmentosProd4";

import Produtos from "./components/produtos/Produtos";
import ListaProdutos from "./components/produtos/ListaProdutos";
import ItensAvulsos from "./components/produtos/ItensAvulsos";
import TabelasPreco from "./components/produtos/TabelasPreco";
import Cupons from "./components/produtos/Cupons";
import TextosGerais from "./components/produtos/TextosGerais";

import ListaPedidos from "./components/pedidos/ListaPedidos";
import ListaPedidosFinanceiro from "./components/pedidos/ListaPedidosFinanceiro";
import Taxas from "./components/pedidos/Taxas";
import RelatoriosVenda from "./components/pedidos/RelatoriosVenda";

import ListaEntregas from "./components/pedidos/ListaEntregas";
import MotoristasEntregas from "./components/pedidos/MotoristasEntregas";

import Pedido from "./components/pedidos/Pedido";
import PedidoEditavel from "./components/pedidos/PedidoEditavel";
import PedidoInvoice from "./components/pedidos/PedidoInvoice";
import PedidoManualMensagem from "./components/pedidos/PedidoManualMensagem";
import PedidoManual from "./components/pedidos/PedidoManual";

import Dashboard from "./components/Dashboard";

import ConfiguraInstitucional from "./components/ConfiguraInstitucional";
import GrupoInstitucional from "./components/GruposInstitucional";
import TelaInstitucional from "./components/TelasInstitucional";
import Clientes from "./components/Clientes";

import TiposRedeSocial from './components/TiposRedeSocial'
import Cotacoes from './components/Cotacoes'
import Cadwhatsapp from './components/Cadwhatsapp'
import Estados from './components/Estados'
import Cidades from './components/Cidades'
import Empresas from './components/Empresas'


import UploadTESTE from './components/_shared/UploadImagem'

import FiltroValor from "./components/FiltroValor";
import FiltroTamanho from "./components/FiltroTamanho";
import FiltroVoltagem from "./components/FiltroVoltagem";
import FiltroCaracteristica from "./components/FiltroCaracteristica";
import FiltroMarca from "./components/FiltroMarca";
import FiltroCor from "./components/FiltroCor";
import FiltroFabrica from "./components/FiltroFabrica";
import FiltroMateriaPrima from "./components/FiltroMateriaPrima";
import CadOperadora from "./components/CadOperadora";
import CadBanco from "./components/CadBanco";
import CadPix from "./components/CadPix";
import ConfigOperadora from "./components/ConfigOperadora";
import ConfigBanco from "./components/ConfigBanco";
import ConfigPix from "./components/ConfigPix";
import LocalOperadora from "./components/LocalOperadora";
import LocalBanco from "./components/LocalBanco";
import LocalPix from "./components/LocalPix";

import Monta1Banner from "./components/Monta1Banner";
import Monta2Banner from "./components/Monta2Banner";
import Monta3Banner from "./components/Monta3Banner";
import Monta4Banner from "./components/Monta4Banner";
import Monta6Banner from "./components/Monta6Banner";
import MontaBannerLinha from "./components/MontaBannerLinha";
import MontaBannerCarro from "./components/MontaBannerCarro";
import MontaBannerSlide from "./components/MontaBannerSlide";

import Monta4ProdutoCarro from "./components/Monta4ProdutoCarro";

import MontaProdutoCarro from "./components/MontaProdutoCarro";
import Monta1Produto from "./components/Monta1Produto";
import Monta2Produto from "./components/Monta2Produto";
import Monta3Produto from "./components/Monta3Produto";
import Monta4Produto from "./components/Monta4Produto";
import Monta6Produto from "./components/Monta6Produto";
import MontaHomeItem from "./components/MontaHomeItem";

import MontaBannerProduto from "./components/MontaBannerProduto"

import EntregaCorreio from "./components/EntregaCorreio";
import EntregaHorario from "./components/EntregaHorario";
import EntregaFrete from "./components/EntregaFrete";
import EntregaExcecao from "./components/EntregaExcecao";
import Bairros from "./components/Bairros";

import PlanosCadastro from "./components/PlanosCadastro";
import PlanosAtuacaoCadastro from "./components/PlanosAtuacaoCadastro";
import PlanosServicoCadastro from "./components/PlanosServicoCadastro";
import PlanosCliente from "./components/PlanosCliente";

import MensagemAvulsa from "./components/MensagemAvulsa"
import FotoAvulsa from "./components/FotoAvulsa"

import Bling from "./components/integracoes/Bling"
import IBR from "./components/integracoes/IBR"

import SetaAvulsos from "./components/emMassa/SetaAvulsos"
import SetaProdutosAvulsos from "./components/emMassa/SetaProdutosAvulsos"
import SetaProdutosSegmento from "./components/emMassa/SetaProdutosSegmento"
import SetaSegmentosProduto from "./components/emMassa/SetaSegmentosProduto"

import ImprimirMensagem from "./components/ImprimirMensagem"
import ImprimirMensagemAvulsa from "./components/ImprimirMensagemAvulsa"
import ImprimirImagem from "./components/ImprimirImagem"
import ImprimirImagemAvulsa from "./components/ImprimirImagemAvulsa"
import ImprimirInvoice from "./components/ImprimirInvoice"


Vue.use(Router);



export default new Router({
  // mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {
      path: "/controle/LocalOperadora",
      nome: "LocalOperadora",
      component: LocalOperadora,
    },
    {
      path: "/controle/LocalOperadora/:id",
      nome: "LocalOperadora",
      component: LocalOperadora,
    },
    {
      path: "/controle/login",
      nome: "Login",
      component: Login,
    },
    {
      path: "/controle/default",
      nome: "Default",
      component: Home,
    },
    {
      path: "/controle/testes",
      nome: "Testes",
      component: Testes,
    },
    {
      path: "/",
      nome: "Default",
      component: Home,
    },
    {
      path: "/controle/idiomas",
      nome: "Idiomas",
      component: Idiomas,
    },
    {
      path: "/controle/idioma/:id",
      nome: "Idiomas",
      component: Idiomas,
    },
    {
      path: "/controle/idiomas/:pesquisa",
      nome: "Idiomas",
      component: Idiomas,
    },
    {
      path: "/controle/tiposusuarios/",
      nome: "TipoUsuario",
      component: TipoUsuario,
    },
    {
      path: "/controle/tipousuario/:id",
      nome: "TipoUsuario",
      component: TipoUsuario,
    },
    {
      path: "/controle/tiposusuarios/:pesquisa",
      nome: "TipoUsuario",
      component: TipoUsuario,
    },
    {
      path: "/controle/usuarios/:pesquisa",
      nome: "Usuarios",
      component: Usuarios,
    },
    {
      path: "/controle/usuarios/",
      nome: "Usuarios",
      component: Usuarios,
    },
    {
      path: "/controle/usuario/:id",
      nome: "Usuarios",
      component: Usuarios,
    },
    {
      path: "/controle/segmentosinst",
      nome: "SegmentosInst",
      component: SegmentosInst,
    },
    {
      path: "/controle/upload",
      nome: "Upload",
      component: UploadTESTE,
    },
    {
      path: "/controle/moedas",
      nome: "Moedas",
      component: Moedas,
    },
    {
      path: "/controle/moeda/:id",
      nome: "Moedas",
      component: Moedas,
    },
    {
      path: "/controle/moedas/:pesquisa",
      nome: "Moedas",
      component: Moedas,
    },
    {
      path: "/controle/telas/:chavetela",
      nome: "Telas",
      component: Telas,
    },
    {
      path: "/controle/telas/:chavetela/:id",
      nome: "Telas",
      component: Telas,
    },
    {
      path: "/controle/controlesmtp",
      nome: "Controlesmtp",
      component: Controlesmtp,
    },
    {
      path: "/controle/controlesmtp/:id",
      nome: "Controlesmtp",
      component: Controlesmtp,
    },
    {
      path: "/controle/seometahome",
      nome: "SeoMetaHome",
      component: SeoMetaHome,
    },
    {
      path: "/controle/seometahome/:id",
      nome: "SeoMetaHome",
      component: SeoMetaHome,
    },
    {
      path: "/controle/seoanalitycs",
      nome: "SeoAnalitycs",
      component: SeoAnalitycs,
    },
    {
      path: "/controle/seoanalitycs/:id",
      nome: "SeoAnalitycs",
      component: SeoAnalitycs,
    },
    {
      path: "/controle/seobody",
      nome: "SeoBody",
      component: SeoBody,
    },
    {
      path: "/controle/seobody/:id",
      nome: "SeoBody",
      component: SeoBody,
    },
    {
      path: "/controle/seohead",
      nome: "SeoHead",
      component: SeoHead,
    },
    {
      path: "/controle/seohead/:id",
      nome: "SeoHead",
      component: SeoHead,
    },
    {
      path: "/controle/seometapagina",
      nome: "SeoMetaPagina",
      component: SeoMetaPagina,
    },
    {
      path: "/controle/seometapagina/:id",
      nome: "SeoMetaPagina",
      component: SeoMetaPagina,
    },
    {
      path: "/controle/seometapaginas/:pesquisa",
      nome: "SeoMetaPagina",
      component: SeoMetaPagina,
    },
    {
      path: "/controle/SegmentosInst1/:pesquisa",
      nome: "SegmentosInst1",
      component: SegmentosInst1,
    },
    {
      path: "/controle/SegmentosInst1/",
      nome: "SegmentosInst1",
      component: SegmentosInst1,
    },
    {
      path: "/controle/SegmentoInst1/:id",
      nome: "SegmentoInst1",
      component: SegmentosInst1,
    },
    {
      path: "/controle/SegmentosInst2/:pesquisa",
      nome: "SegmentosInst2",
      component: SegmentosInst2,
    },
    {
      path: "/controle/SegmentosInst2/",
      nome: "SegmentosInst2",
      component: SegmentosInst2,
    },
    {
      path: "/controle/SegmentoInst2/:id",
      nome: "SegmentoInst2",
      component: SegmentosInst2,
    },
    {
      path: "/controle/SegmentosInst3/:pesquisa",
      nome: "SegmentosInst3",
      component: SegmentosInst3,
    },
    {
      path: "/controle/SegmentosInst3/",
      nome: "SegmentosInst3",
      component: SegmentosInst3,
    },
    {
      path: "/controle/SegmentoInst3/:id",
      nome: "SegmentoInst3",
      component: SegmentosInst3,
    },
    {
      path: "/controle/Segmentosinst4/:pesquisa",
      nome: "Segmentosinst4",
      component: SegmentosInst4,
    },
    {
      path: "/controle/Segmentosinst4/",
      nome: "Segmentosinst4",
      component: SegmentosInst4,
    },
    {
      path: "/controle/Segmentoinst4/:id",
      nome: "Segmentoinst4",
      component: SegmentosInst4,
    },
    {
      path: "/controle/cotacoes",
      nome: "Cotacoes",
      component: Cotacoes,
    },
    {
      path: "/controle/cotacao/:id",
      nome: "Cotacoes",
      component: Cotacoes,
    },
    {
      path: "/controle/cotacoes/:pesquisa",
      nome: "Cotacoes",
      component: Cotacoes,
    },
    {
      path: "/controle/cadwhatsapp",
      nome: "Cadwhatsapp",
      component: Cadwhatsapp,
    },
    {
      path: "/controle/cadwhatsapp/:id",
      nome: "Cadwhatsapp",
      component: Cadwhatsapp,
    },
    {
      path: "/controle/cadwhatsapps/:pesquisa",
      nome: "Estados",
      component: Estados,
    },
    {
      path: "/controle/estados",
      nome: "Estados",
      component: Estados,
    },
    {
      path: "/controle/estados/:id",
      nome: "Estados",
      component: Estados,
    },
    {
      path: "/controle/estado/:pesquisa",
      nome: "Estados",
      component: Estados,
    },
    {
      path: "/controle/cidades",
      nome: "Cidades",
      component: Cidades,
    },
    {
      path: "/controle/cidades/:id",
      nome: "Cidades",
      component: Cidades,
    },
    {
      path: "/controle/cidade/:pesquisa",
      nome: "Cidades",
      component: Cidades,
    },
    {
      path: "/controle/empresas",
      nome: "Empresas",
      component: Empresas,
    },
    {
      path: "/controle/empresas/:id",
      nome: "Empresas",
      component: Empresas,
    },
    {
      path: "/controle/empresa/:pesquisa",
      nome: "Empresas",
      component: Empresas,
    },
    {
      path: "/controle/TiposRedeSocial",
      nome: "TiposRedeSocial",
      component: TiposRedeSocial,
    },
    {
      path: "/controle/TipoRedeSocial/:id",
      nome: "TiposRedeSocial",
      component: TiposRedeSocial,
    },
    {
      path: "/controle/ConfiguraInstitucional",
      nome: "ConfiguraInstitucional",
      component: ConfiguraInstitucional,
    },
    {
      path: "/controle/GruposInstitucional",
      nome: "GrupoInstitucional",
      component: GrupoInstitucional,
    },
    {
      path: "/controle/GrupoInstitucional/:id",
      nome: "GrupoInstitucional",
      component: GrupoInstitucional,
    },
    {
      path: "/controle/GruposInstitucional/:pesquisa",
      nome: "GrupoInstitucional",
      component: GrupoInstitucional,
    },
    {
      path: "/controle/TelasInstitucional",
      nome: "TelaInstitucional",
      component: TelaInstitucional,
    },
    {
      path: "/controle/TelaInstitucional/:id",
      nome: "TelaInstitucional",
      component: TelaInstitucional,
    },
    {
      path: "/controle/TelasInstitucional/:pesquisa",
      nome: "TelasInstitucional",
      component: TelaInstitucional,
    },
    {
      path: "/controle/Clientes",
      nome: "Clientes",
      component: Clientes,
    },
    {
      path: "/controle/Cliente/:id",
      nome: "Clientes",
      component: Clientes,
    },
    {
      path: "/controle/Clientes/:pesquisa",
      nome: "Clientes",
      component: Clientes,
    },
    {
      path: "/controle/SegmentosCli1/:pesquisa",
      nome: "SegmentosCli1",
      component: SegmentosCli1,
    },
    {
      path: "/controle/SegmentosCli1/",
      nome: "SegmentosCli1",
      component: SegmentosCli1,
    },
    {
      path: "/controle/SegmentoCli1/:id",
      nome: "SegmentoCli1",
      component: SegmentosCli1,
    },
    {
      path: "/controle/SegmentosCli2/:pesquisa",
      nome: "SegmentosCli2",
      component: SegmentosCli2,
    },
    {
      path: "/controle/SegmentosCli2/",
      nome: "SegmentosCli2",
      component: SegmentosCli2,
    },
    {
      path: "/controle/SegmentoCli2/:id",
      nome: "SegmentoCli2",
      component: SegmentosCli2,
    },
    {
      path: "/controle/SegmentosCli3/:pesquisa",
      nome: "SegmentosCli3",
      component: SegmentosCli3,
    },
    {
      path: "/controle/SegmentosCli3/",
      nome: "SegmentosCli3",
      component: SegmentosCli3,
    },
    {
      path: "/controle/SegmentoCli3/:id",
      nome: "SegmentoCli3",
      component: SegmentosCli3,
    },
    {
      path: "/controle/SegmentosCli4/:pesquisa",
      nome: "SegmentosCli4",
      component: SegmentosCli4,
    },
    {
      path: "/controle/SegmentosCli4/",
      nome: "SegmentosCli4",
      component: SegmentosCli4,
    },
    {
      path: "/controle/SegmentoCli4/:id",
      nome: "SegmentoCli4",
      component: SegmentosCli4,
    },
    {
      path: "/controle/ArquivosCli/:pesquisa",
      nome: "ArquivosCli",
      component: ArquivosCli,
    },
    {
      path: "/controle/ArquivosCli/",
      nome: "ArquivosCli",
      component: ArquivosCli,
    },
    {
      path: "/controle/ArquivoCli/:id",
      nome: "ArquivosCli",
      component: ArquivosCli,
    },

    {
      path: "/controle/VideosCli/:pesquisa",
      nome: "VideosCli",
      component: VideosCli,
    },
    {
      path: "/controle/videosCli/",
      nome: "VideosCli",
      component: VideosCli,
    },
    {
      path: "/controle/videoCli/:id",
      nome: "VideosCli",
      component: VideosCli,
    },
    {
      path: "/controle/FotosCli/",
      nome: "FotosCli",
      component: FotosCli,
    },
    {
      path: "/controle/FotoCli/:id",
      nome: "FotosCli",
      component: FotosCli,
    },
    {
      path: "/controle/ConfirmaCadastroCli",
      nome: "ConfirmaCadastroCli",
      component: ConfirmaCadastroCli,
    },
    {
      path: "/controle/SegmentosProd1/:pesquisa",
      nome: "SegmentosProd1",
      component: SegmentosProd1,
    },
    {
      path: "/controle/SegmentosProd1/",
      nome: "SegmentosProd1",
      component: SegmentosProd1,
    },
    {
      path: "/controle/SegmentoProd1/:id",
      nome: "SegmentoProd1",
      component: SegmentosProd1,
    },
    {
      path: "/controle/SegmentosProd2/:pesquisa",
      nome: "SegmentosProd2",
      component: SegmentosProd2,
    },
    {
      path: "/controle/SegmentosProd2/",
      nome: "SegmentosProd2",
      component: SegmentosProd2,
    },
    {
      path: "/controle/SegmentoProd2/:id",
      nome: "SegmentoProd2",
      component: SegmentosProd2,
    },
    {
      path: "/controle/SegmentosProd3/:pesquisa",
      nome: "SegmentosProd3",
      component: SegmentosProd3,
    },
    {
      path: "/controle/SegmentosProd3/",
      nome: "SegmentosProd3",
      component: SegmentosProd3,
    },
    {
      path: "/controle/SegmentoProd3/:id",
      nome: "SegmentoProd3",
      component: SegmentosProd3,
    },
    {
      path: "/controle/SegmentosProd4/:pesquisa",
      nome: "SegmentosProd4",
      component: SegmentosProd4,
    },
    {
      path: "/controle/SegmentosProd4/",
      nome: "SegmentosProd4",
      component: SegmentosProd4,
    },
    {
      path: "/controle/SegmentoProd4/:id",
      nome: "SegmentoProd4",
      component: SegmentosProd4,
    },
    {
      path: "/controle/Produtos/:pesquisa",
      nome: "Produtos",
      component: Produtos,
    },
    {
      path: "/controle/Produtos/",
      nome: "Produtos",
      component: Produtos,
    },
    {
      path: "/controle/Produto/:id",
      nome: "Produto",
      component: Produtos,
    },
    {
      path: "/controle/FiltroValor",
      nome: "FiltroValor",
      component: FiltroValor,
    },
    {
      path: "/controle/FiltroValor/:id",
      nome: "FiltroValor",
      component: FiltroValor,
    },
    {
      path: "/controle/FiltrosValor/:pesquisa",
      nome: "FiltrosValor",
      component: FiltroValor,
    },
    {
      path: "/controle/FiltroTamanho",
      nome: "FiltroTamanho",
      component: FiltroTamanho,
    },
    {
      path: "/controle/FiltroTamanho/:id",
      nome: "FiltroTamanho",
      component: FiltroTamanho,
    },
    {
      path: "/controle/FiltrosTamanho/:pesquisa",
      nome: "FiltrosTamanho",
      component: FiltroTamanho,
    },
    {
      path: "/controle/FiltroVoltagem",
      nome: "FiltroVoltagem",
      component: FiltroVoltagem,
    },
    {
      path: "/controle/FiltroVoltagem/:id",
      nome: "FiltroVoltagem",
      component: FiltroVoltagem,
    },
    {
      path: "/controle/FiltrosVoltagem/:pesquisa",
      nome: "FiltrosVoltagem",
      component: FiltroVoltagem,
    },
    {
      path: "/controle/FiltroCaracteristica",
      nome: "FiltroCaracteristica",
      component: FiltroCaracteristica,
    },
    {
      path: "/controle/FiltroCaracteristica/:id",
      nome: "FiltroCaracteristica",
      component: FiltroCaracteristica,
    },
    {
      path: "/controle/FiltrosCaracteristica/:pesquisa",
      nome: "FiltrosCaracteristica",
      component: FiltroCaracteristica,
    },
    {
      path: "/controle/FiltroMarca",
      nome: "FiltroMarca",
      component: FiltroMarca,
    },
    {
      path: "/controle/FiltroMarca/:id",
      nome: "FiltroMarca",
      component: FiltroMarca,
    },
    {
      path: "/controle/FiltrosMarca/:pesquisa",
      nome: "FiltrosMarca",
      component: FiltroMarca,
    },
    {
      path: "/controle/FiltroCor",
      nome: "FiltroCor",
      component: FiltroCor,
    },
    {
      path: "/controle/FiltroCor/:id",
      nome: "FiltroCor",
      component: FiltroCor,
    },
    {
      path: "/controle/FiltrosCor/:pesquisa",
      nome: "FiltrosCor",
      component: FiltroCor,
    },
    {
      path: "/controle/FiltroFabrica",
      nome: "FiltroFabrica",
      component: FiltroFabrica,
    },
    {
      path: "/controle/FiltroFabrica/:id",
      nome: "FiltroFabrica",
      component: FiltroFabrica,
    },
    {
      path: "/controle/FiltrosFabrica/:pesquisa",
      nome: "FiltrosFabrica",
      component: FiltroFabrica,
    },
    {
      path: "/controle/FiltroMateriaPrima",
      nome: "FiltroMateriaPrima",
      component: FiltroMateriaPrima,
    },
    {
      path: "/controle/FiltroMateriaPrima/:id",
      nome: "FiltroMateriaPrima",
      component: FiltroMateriaPrima,
    },
    {
      path: "/controle/FiltrosMateriaPrima/:pesquisa",
      nome: "FiltrosMateriaPrima",
      component: FiltroMateriaPrima,
    },
    {
      path: "/controle/CadOperadora",
      nome: "CadOperadora",
      component: CadOperadora,
    },
    {
      path: "/controle/CadOperadora/:id",
      nome: "CadOperadora",
      component: CadOperadora,
    },
    {
      path: "/controle/CadOperadoras/:pesquisa",
      nome: "CadOperadoras",
      component: CadOperadora,
    },
    {
      path: "/controle/CadBanco",
      nome: "CadBanco",
      component: CadBanco,
    },
    {
      path: "/controle/CadBanco/:id",
      nome: "CadBanco",
      component: CadBanco,
    },
    {
      path: "/controle/CadBancos/:pesquisa",
      nome: "CadBancos",
      component: CadBanco,
    },
    {
      path: "/controle/CadPix",
      nome: "CadPix",
      component: CadPix,
    },
    {
      path: "/controle/CadPix/:id",
      nome: "CadPix",
      component: CadPix,
    },
    {
      path: "/controle/CadPixs/:pesquisa",
      nome: "CadPixs",
      component: CadPix,
    },
    {
      path: "/controle/ConfigOperadora",
      nome: "ConfigOperadora",
      component: ConfigOperadora,
    },
    {
      path: "/controle/ConfigOperadora/:id",
      nome: "ConfigOperadora",
      component: ConfigOperadora,
    },
    {
      path: "/controle/ConfigBanco",
      nome: "ConfigBanco",
      component: ConfigBanco,
    },
    {
      path: "/controle/ConfigBanco/:id",
      nome: "ConfigBanco",
      component: ConfigBanco,
    },
    {
      path: "/controle/ConfigPix",
      nome: "ConfigPix",
      component: ConfigPix,
    },
    {
      path: "/controle/ConfigPix/:id",
      nome: "ConfigPix",
      component: ConfigPix,
    },
    {
      path: "/controle/LocalOperadora",
      nome: "LocalOperadora",
      component: LocalOperadora,
    },
    {
      path: "/controle/LocalOperadora/:id",
      nome: "LocalOperadora",
      component: LocalOperadora,
    },
    {
      path: "/controle/LocalBanco",
      nome: "LocalBanco",
      component: LocalBanco,
    },
    {
      path: "/controle/LocalBanco/:id",
      nome: "LocalBanco",
      component: LocalBanco,
    },
    {
      path: "/controle/LocalPix",
      nome: "LocalPix",
      component: LocalPix,
    },
    {
      path: "/controle/LocalPix/:id",
      nome: "LocalPix",
      component: LocalPix,
    },
    {
      path: "/controle/Monta1Banner",
      nome: "Monta1Banner",
      component: Monta1Banner,
    },
    {
      path: "/controle/Monta1Banner/:id",
      nome: "Monta1Banner",
      component: Monta1Banner,
    },
    {
      path: "/controle/Monta2Banner",
      nome: "Monta2Banner",
      component: Monta2Banner,
    },
    {
      path: "/controle/Monta2Banner/:id",
      nome: "Monta2Banner",
      component: Monta2Banner,
    },
    {
      path: "/controle/Monta3Banner",
      nome: "Monta3Banner",
      component: Monta3Banner,
    },
    {
      path: "/controle/Monta3Banner/:id",
      nome: "Monta3Banner",
      component: Monta3Banner,
    },
    {
      path: "/controle/Monta4Banner",
      nome: "Monta4Banner",
      component: Monta4Banner,
    },
    {
      path: "/controle/Monta4Banner/:id",
      nome: "Monta4Banner",
      component: Monta4Banner,
    },
    {
      path: "/controle/Monta6Banner",
      nome: "Monta6Banner",
      component: Monta6Banner,
    },
    {
      path: "/controle/Monta6Banner/:id",
      nome: "Monta6Banner",
      component: Monta6Banner,
    },
    {
      path: "/controle/MontaBannerLinha",
      nome: "MontaBannerLinha",
      component: MontaBannerLinha,
    },
    {
      path: "/controle/MontaBannerLinha/:id",
      nome: "MontaBannerLinha",
      component: MontaBannerLinha,
    },
    {
      path: "/controle/MontaBannerCarro",
      nome: "MontaBannerCarro",
      component: MontaBannerCarro,
    },
    {
      path: "/controle/MontaBannerCarro/:id",
      nome: "MontaBannerCarro",
      component: MontaBannerCarro,
    },
    {
      path: "/controle/MontaBannerSlide",
      nome: "MontaBannerSlide",
      component: MontaBannerSlide,
    },
    {
      path: "/controle/MontaBannerSlide/:id",
      nome: "MontaBannerSlide",
      component: MontaBannerSlide,
    },
    {
      path: "/controle/MontaProdutoCarro",
      nome: "MontaProdutoCarro",
      component: MontaProdutoCarro,
    },
    {
      path: "/controle/MontaProdutoCarro/:id",
      nome: "MontaProdutoCarro",
      component: MontaProdutoCarro,
    },
    {
      path: "/controle/Monta1Produto",
      nome: "Monta1Produto",
      component: Monta1Produto,
    },
    {
      path: "/controle/Monta1Produto/:id",
      nome: "Monta1Produto",
      component: Monta1Produto,
    },
    {
      path: "/controle/Monta2Produto",
      nome: "Monta2Produto",
      component: Monta2Produto,
    },
    {
      path: "/controle/Monta2Produto/:id",
      nome: "Monta2Produto",
      component: Monta2Produto,
    },
    {
      path: "/controle/Monta3Produto",
      nome: "Monta3Produto",
      component: Monta3Produto,
    },
    {
      path: "/controle/Monta3Produto/:id",
      nome: "Monta3Produto",
      component: Monta3Produto,
    },
    {
      path: "/controle/Monta4Produto",
      nome: "Monta4Produto",
      component: Monta4Produto,
    },
    {
      path: "/controle/Monta4Produto/:id",
      nome: "Monta4Produto",
      component: Monta4Produto,
    },
    {
      path: "/controle/Monta6Produto",
      nome: "Monta6Produto",
      component: Monta6Produto,
    },
    {
      path: "/controle/Monta6Produto/:id",
      nome: "Monta6Produto",
      component: Monta6Produto,
    },
    {
      path: "/controle/MontaHomeItem",
      nome: "MontaHomeItem",
      component: MontaHomeItem,
    },
    {
      path: "/controle/MontaHomeItem/:id",
      nome: "MontaHomeItem",
      component: MontaHomeItem,
    },
    {
      path: "/controle/EntregaCorreio",
      nome: "EntregaCorreio",
      component: EntregaCorreio,
    },
    {
      path: "/controle/EntregaCorreio/:id",
      nome: "EntregaCorreio",
      component: EntregaCorreio,
    },
    {
      path: "/controle/EntregaHorario",
      nome: "EntregaHorario",
      component: EntregaHorario,
    },
    {
      path: "/controle/EntregaHorario/:id",
      nome: "EntregaHorario",
      component: EntregaHorario,
    },
    {
      path: "/controle/EntregaFrete",
      nome: "EntregaFrete",
      component: EntregaFrete,
    },
    {
      path: "/controle/EntregaFrete/:id",
      nome: "EntregaFrete",
      component: EntregaFrete,
    },
    {
      path: "/controle/Bairros",
      nome: "Bairros",
      component: Bairros,
    },
    {
      path: "/controle/Bairros/:id",
      nome: "Bairros",
      component: Bairros,
    },
    {
      path: "/controle/EntregaExcecao",
      nome: "EntregaExcecao",
      component: EntregaExcecao,
    },
    {
      path: "/controle/EntregaExcecao/:id",
      nome: "EntregaExcecao",
      component: EntregaExcecao,
    },
    {
      path: "/controle/ListaProdutos",
      nome: "ListaProdutos",
      component: ListaProdutos,
    },
    {
      path: "/controle/ItensAvulsos",
      nome: "ItensAvulsos",
      component: ItensAvulsos,
    },
    {
      path: "/controle/ItemAvulso/:id",
      nome: "ItensAvulsos",
      component: ItensAvulsos,
    },
    {
      path: "/controle/TabelasPreco",
      nome: "TabelasPreco",
      component: TabelasPreco,
    },
    {
      path: "/controle/TabelaPreco/:id",
      nome: "TabelasPreco",
      component: TabelasPreco,
    },
    {
      path: "/controle/Cupons",
      nome: "Cupons",
      component: Cupons,
    },
    {
      path: "/controle/Cupom/:id",
      nome: "Cupons",
      component: Cupons,
    },
    {
      path: "/controle/textosgerais",
      nome: "TextosGerais",
      component: TextosGerais,
    },
    {
      path: "/controle/listapedidos",
      nome: "ListaPedidos",
      component: ListaPedidos,
    },
    {
      path: "/controle/listapedidosfinanceiro",
      nome: "ListaPedidosFinanceiro",
      component: ListaPedidosFinanceiro,
    },
    {
      path: "/controle/taxas",
      nome: "Taxas",
      component: Taxas,
    },
    {
      path: "/controle/relatoriosvenda",
      nome: "RelatoriosVenda",
      component: RelatoriosVenda,
    },
    {
      path: "/controle/ListaEntregas",
      nome: "ListaEntregas",
      component: ListaEntregas,
    },
    {
      path: "/controle/MotoristasEntregas",
      nome: "MotoristasEntregas",
      component: MotoristasEntregas,
    },
    {
      path: "/controle/Pedido/:id",
      nome: "Pedido",
      component: Pedido,
    },
    {
      path: "/controle/PedidoEditavel/:id",
      nome: "PedidoEditavel",
      component: PedidoEditavel,
    },
    {
      path: "/controle/PedidoInvoice/:id",
      nome: "PedidoInvoice",
      component: PedidoInvoice,
    },
    {
      path: "/controle/PedidoManual",
      nome: "PedidoManual",
      component: PedidoManual,
    },
    {
      path: "/controle/PedidoManualMensagem",
      nome: "PedidoManualMensagem",
      component: PedidoManualMensagem,
    },
    {
      path: "/controle/Monta4ProdutoCarro",
      nome: "Monta4ProdutoCarro",
      component: Monta4ProdutoCarro,
    },
    {
      path: "/controle/Monta4ProdutoCarro/:id",
      nome: "Monta4ProdutoCarro",
      component: Monta4ProdutoCarro,
    },
    {
      path: "/controle/MontaBannerProduto",
      nome: "MontaBannerProduto",
      component: MontaBannerProduto,
    },
    {
      path: "/controle/MontaBannerProduto/:id",
      nome: "MontaBannerProduto",
      component: MontaBannerProduto,
    },
    {
      path: "/controle/Dashboard",
      nome: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/controle/PlanosCadastro",
      nome: "PlanosCadastro",
      component: PlanosCadastro,
    },
    {
      path: "/controle/PlanosCadastro/:id",
      nome: "PlanosCadastro",
      component: PlanosCadastro,
    },
    {
      path: "/controle/PlanosAtuacaoCadastro",
      nome: "PlanosAtuacaoCadastro",
      component: PlanosAtuacaoCadastro,
    },
    {
      path: "/controle/PlanosAtuacaoCadastro/:id",
      nome: "PlanosAtuacaoCadastro",
      component: PlanosAtuacaoCadastro,
    },
    {
      path: "/controle/PlanosServicoCadastro",
      nome: "PlanosServicoCadastro",
      component: PlanosServicoCadastro,
    },
    {
      path: "/controle/PlanosServicoCadastro/:id",
      nome: "PlanosServicoCadastro",
      component: PlanosServicoCadastro,
    },
    {
      path: "/controle/PlanosCliente",
      nome: "PlanosCliente",
      component: PlanosCliente,
    },
    {
      path: "/controle/PlanosCliente/:id",
      nome: "PlanosCliente",
      component: PlanosCliente,
    },
    {
      path: "/controle/MensagemAvulsa",
      nome: "MensagemAvulsa",
      component: MensagemAvulsa,
    },
    {
      path: "/controle/MensagemAvulsa/:id",
      nome: "MensagemAvulsa",
      component: MensagemAvulsa,
    },
    {
      path: "/controle/FotoAvulsa",
      nome: "FotoAvulsa",
      component: FotoAvulsa,
    },
    {
      path: "/controle/FotoAvulsa/:id",
      nome: "FotoAvulsa",
      component: FotoAvulsa,
    },
    {
      path: "/controle/Bling",
      nome: "Bling",
      component: Bling,
    },
    {
      path: "/controle/Ibr",
      nome: "IBR",
      component: IBR,
    },
    {
      path: "/controle/SetaAvulsos",
      nome: "SetaAvulsos",
      component: SetaAvulsos,
    },
    {
      path: "/controle/SetaProdutosAvulsos",
      nome: "SetaProdutosAvulsos",
      component: SetaProdutosAvulsos,
    },
    {
      path: "/controle/SetaProdutosSegmento",
      nome: "SetaProdutosSegmento",
      component: SetaProdutosSegmento,
    },
    {
      path: "/controle/SetaSegmentosProduto",
      nome: "SetaSegmentosProduto",
      component: SetaSegmentosProduto,
    },
    {
      path: "/controle/ImprimirImagem/:id",
      nome: "ImprimirImagem",
      component: ImprimirImagem,
    },
    {
      path: "/controle/ImprimirImagemAvulsa/:id",
      nome: "ImprimirImagemAvulsa",
      component: ImprimirImagemAvulsa,
    },
    {
      path: "/controle/ImprimirMensagem/:id",
      nome: "ImprimirMensagem",
      component: ImprimirMensagem,
    },
    {
      path: "/controle/ImprimirMensagemAvulsa/:id",
      nome: "ImprimirMensagemAvulsa",
      component: ImprimirMensagemAvulsa,
    },
    {
      path: "/controle/ImprimirInvoice/:id",
      nome: "ImprimirInvoice",
      component: ImprimirInvoice,
    },
  
  ],
});
