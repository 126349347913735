<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Bloco {{ NomeTipoBlocoHome }}</h4>
                                            <p class="mb-30">
                                                Tela para configurar {{ NomeTipoBlocoHome }} por linha na home.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Nome do Bloco</label>
                                                <input v-model="NomeBloco" class="form-control" type="text" placeholder="Nome Bloco">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="homeBannerId != null && homeBannerId.length > 0">
                                            <hr />
                                            <div class="row" style="padding-top:20px;">
                                                <div class="col-md-8">
                                                    <div class="form-group">
                                                        <label>
                                                            Arquivo pode ser .png .jpg .gif - com {{ LarguraImg
                                                }} de Largura x {{ AlturaImg }} de Altura.
                                                </label>
                                                <input id="arquivo" type="file" class="form-control-file form-control height-auto" />
</div>
                                                </div>
                                                <div class="col-md-2" style="padding-top:20px;">
                                                    <div class="form-group">
                                                        <button type="button" @click="carregaimagem()" class="btn btn-info">Adicionar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="padding-top:30px;">
                                                <div class="form-group">
                                                    <div class="table-responsive">
                                                        <table class="table nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th>Id</th>
                                                                    <th class="datatable-nosort">imagem</th>
                                                                    <th>Link</th>
                                                                    <th><a @click="AtualizaOrdem()">Ordem <i class="icon-copy dw dw-down-arrow2"></i></a></th>
                                                                    <th>Visível</th>
                                                                    <th>Excluir</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="HomeBannerItem in HomeBannerItens" :key="HomeBannerItem.homeBannerItemId">
                                                                    <td>{{HomeBannerItem.homeBannerItemId
                                                            }}</td>
                                                            <td width="10%">
                                                                <img :src="$Url +HomeBannerItem.urlImg" alt="" />
                                                            </td>
                                                            <td width="40%">
                                                                <input v-model="HomeBannerItem.link" class="form-control mb-5" type="text" placeholder="">
                                                            </td>
                                                            <td width="15%">
                                                                <input v-model="HomeBannerItem.ordem" class="form-control mb-5" type="number" placeholder="">
                                                            </td>
                                                            <td width="20%" style="margin-top:-5px">
                                                                <v-select v-model="HomeBannerItem.visivel"
                                                                          :options="StatusItem"
                                                                          :reduce="nome => nome.valor"
                                                                          single-line
                                                                          label="nome" class="style-chooser" style="margin-top:-5px;"></v-select>
                                                            </td>
                                                            <td width="15%">
                                                                <a class="dropdown-item" @click="RemoveItem(HomeBannerItem)"><i class="dw dw-delete-3"></i> Excluir</a>
                                                            </td>
                                                            </tr>
                                                            </tbody>
                                                            </table>
</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="homeBannerId != null && homeBannerId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Nome Bloco</th>
                                <th>Visível</th>
                                <th>Ordem</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Homebanner in Homebanners" :key="Homebanner.homeBannerId">
                                <td>{{ Homebanner.homeBannerId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/' + urlTela + '/' + Homebanner.homeBannerId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Homebanner)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Homebanner.nomeBloco }}</td>
                                <td><span v-if="Homebanner.visivel">Sim</span><span v-else>Não</span></td>
                                <td>{{ Homebanner.ordem }}</td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                homeBannerId: this.$route.params.id,
                TipoBlocoHomeId: 4,
                NomeTipoBlocoHome: '1 Banner',
                urlTela: 'monta1banner',
                AlturaImg: '400px',
                LarguraImg: '1350px',
                Visivel: null,
                NomeBloco: '',
                Ordem: 0,
                Homebanner: {},
                Homebanners: [],
                Statuselected: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                HomeBannerItens: [],
                HomeBannerItem: {},
                StatusItem: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                VisivelItem: true,
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus(value) {
                this.Visivel = value;
            },
            AtualizaOrdem() {
                this.HomeBannerItens = this.HomeBannerItens.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
            },
            Add() {
                let _Homebanner = {
                    homebannerId: 0,
                    visivel: null,
                    nomeBloco: null,
                    ordem: 0,
                    tipoBlocoHome: { TipoBlocoHomeId: this.TipoBlocoHomeId }
                };

                //validações
                if (this.NomeBloco.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                    _Homebanner.visivel = this.Visivel;
                    _Homebanner.nomeBloco = this.NomeBloco;
                    _Homebanner.ordem = parseInt(this.Ordem, 10);

                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/homebanner/", _Homebanner).then(
                        response2 => {
                            // get body data
                            _Homebanner = response2.body;
                            this.$router.go(this.$router.currentRoute);
                        },
                        response2 => {
                            _Homebanner = response2.body;
                            this.$mensagem_normal(_Homebanner);
                        }
                    );
                }
            },
            Altera() {
                let _Homebanner = {
                    homebannerId: 0,
                    visivel: null,
                    nomeBloco: null,
                    ordem: 0,
                    tipoBlocoHome: { TipoBlocoHomeId: this.TipoBlocoHomeId }
                };

                //validações
                if (this.NomeBloco.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                    _Homebanner.visivel = this.Visivel;
                    _Homebanner.nomeBloco = this.NomeBloco;
                    _Homebanner.ordem = parseInt(this.Ordem, 10);
                    _Homebanner.homebannerId = parseInt(this.homeBannerId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/homebanner/" + parseInt(this.homeBannerId, 10), _Homebanner)
                        .then(
                            response2 => {
                                // get body data
                                _Homebanner = response2.body;

                                this.HomeBannerItens.forEach((element) => {
                                    element.ordem = parseInt(element.ordem, 10);
                                    element.homeBanner = { HomeBannerId: parseInt(this.homeBannerId, 10) };

                                    this.$http
                                        .put(
                                            this.$apiUrl + "/homebanneritem/" + element.homeBannerItemId,element)
                                        .then((response) => {
                                            console.log(response.body);
                                        });
                                });

                                this.$mensagem_sucesso(
                                    this.NomeTipoBlocoHome + " #" + this.homeBannerId + " alterado com sucesso"
                                );
                                this.Limpar();
                            },
                            async response2 => {
                                _Homebanner = response2.body;
                                // error callback
                                this.$mensagem_normal(_Homebanner);
                            }
                        );
                }

            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/homebanner/" + dadosRemover.homeBannerId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            RemoveItem(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/homebanneritem/" + dadosRemover.homeBannerItemId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            carregaimagem() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                let URLIMAGEM = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg" && ext != "webp") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png', '.webp' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                else {
                    this.$mensagem_normal("Por favor selecione arquivo");
                }


                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                } else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);

                        this.$http.post(this.$apiUrl + "/homebanneritem/upload", data).then(
                            (response3) => {
                                // get body data
                                URLIMAGEM = response3.body;
                                this.InsereBannerItem(URLIMAGEM);
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            },
            InsereBannerItem(URL) {

                let _homeBannerItem = {
                    homeBannerItemId: 0,
                    homeBanner: { HomeBannerId: parseInt(this.homeBannerId,10)},
                    produto: { ProdutoId: 0 },
                    urlImg: URL,
                    visivel: this.VisivelItem,
                };

                console.log(_homeBannerItem);
                //chama o post da api
                this.$http.post(this.$apiUrl + "/homebanneritem/", _homeBannerItem).then(
                    response2 => {
                        // get body data
                        _homeBannerItem = response2.body;
                        this.$router.go(this.$router.currentRoute);
                    },
                    response2 => {
                        _homeBannerItem = response2.body;
                        this.$mensagem_normal(_homeBannerItem);
                    }
                );
            },
            Limpar() {
                this.$redirect_reload("/controle/" + this.urlTela);
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.BannerhomeId == null) {
                this.BannerhomeId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.homeBannerId != null && this.homeBannerId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/homebanner/" + this.homeBannerId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Homebanner = idi;

                        this.NomeBloco = this.Homebanner.nomeBloco;
                        this.Statuselected = this.Homebanner.visivel;
                        this.Visivel = this.Homebanner.visivel;
                        this.Ordem = this.Homebanner.ordem;
                        
                        this.$http
                            .get(this.$apiUrl + "/homebanneritem/")
                            .then((res) => res.json())
                            .then((idi) => {
                                this.HomeBannerItens = idi.filter(x => x.homeBanner.homeBannerId == this.homeBannerId);

                                this.HomeBannerItens = this.HomeBannerItens.sort(function (a, b) {
                                    return a.ordem - b.ordem;
                                });
                            });
                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/homebanner")
                    .then((res) => res.json())
                    .then((moe) => (this.Homebanners = moe.filter(x => x.tipoBlocoHome.tipoBlocoHomeId == this.TipoBlocoHomeId)))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
