var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"pd-ltr-20"},[_c('div',{staticClass:"faq-wrap"},[_c('div',{attrs:{"id":"accordion"}},[_c('div',{staticClass:"pd-20 card-box mb-30"},[_vm._m(0),_c('div',{staticClass:"collapse show",attrs:{"id":"Topo","data-parent":"#accordion"}},[_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Grupo.nomeSegmentoTela),expression:"Grupo.nomeSegmentoTela"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Nome"},domProps:{"value":(_vm.Grupo.nomeSegmentoTela)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Grupo, "nomeSegmentoTela", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Ordem")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Grupo.ordem),expression:"Grupo.ordem"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Ordem"},domProps:{"value":(_vm.Grupo.ordem)},on:{"change":function($event){_vm.Grupo.ordem != null ? _vm.Grupo.ordem = parseInt(_vm.Grupo.ordem,10):_vm.Grupo.ordem = 0},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Grupo, "ordem", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('button',{staticClass:"btn btn-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.Limpar()}}},[_vm._v(" Cancelar ")]),(
                        _vm.grupoInstitucionalId == null ||
                        _vm.grupoInstitucionalId.length <= 0
                      )?_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.$redirect_reload(
                          '/controle/gruposInstitucional/' + _vm.Grupo.Nome
                        )}}},[_vm._v(" Pesquisar ")]):_vm._e(),(
                        _vm.grupoInstitucionalId != null &&
                        _vm.grupoInstitucionalId.length > 0
                      )?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","id":"sa-custom-position2"},on:{"click":function($event){return _vm.Altera()}}},[_vm._v(" Alterar ")]):_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","id":"sa-custom-position"},on:{"click":function($event){return _vm.Add()}}},[_vm._v(" Salvar ")])])])])])])])]),_c('div',{staticClass:"card-box mb-30"},[_c('h2',{staticClass:"h4 pd-20"},[_vm._v("Resultado")]),_c('table',{staticClass:"table hover data-table nowrap"},[_vm._m(1),_c('tbody',_vm._l((_vm.Grupos),function(Grupo,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(Grupo.grupoTelaInstitucionalId))]),_c('td',[_c('div',{staticClass:"dropdown"},[_vm._m(2,true),_c('div',{staticClass:"\n                      dropdown-menu\n                      dropdown-menu-right\n                      dropdown-menu-icon-list\n                    "},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$redirect(
                          '/controle/grupoInstitucional/' +
                            Grupo.grupoTelaInstitucionalId
                        )}}},[_c('i',{staticClass:"dw dw-edit2"}),_vm._v(" Editar")]),_c('a',{staticClass:"dropdown-item",attrs:{"id":"sa-warning"},on:{"click":function($event){return _vm.Remove(Grupo)}}},[_c('i',{staticClass:"dw dw-delete-3"}),_vm._v(" Excluir")])])])]),_c('td',[_vm._v(_vm._s(Grupo.nomeSegmentoTela))]),_c('td',[_vm._v(_vm._s(Grupo.ordem))])])}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('button',{staticClass:"btn btn-block",attrs:{"data-toggle":"collapse","data-target":"#Topo"}},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"pull-left"},[_c('h4',{staticClass:"text-black h4"},[_vm._v("Cadastro de Grupos de telas")]),_c('p',{staticClass:"mb-30"},[_vm._v(" Tela para cadastro dos Grupos de tela. ")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Id")]),_c('th',{staticClass:"datatable-nosort"},[_vm._v("Ação")]),_c('th',[_vm._v("Nome")]),_c('th',[_vm._v("Ordem")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"\n                      btn btn-link\n                      font-24\n                      p-0\n                      line-height-1\n                      no-arrow\n                      dropdown-toggle\n                    ",attrs:{"role":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"dw dw-more"})])}]

export { render, staticRenderFns }