<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Cotações</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das cotações por moeda.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Moeda</label>
                                                <v-select v-model="Moedaselected" @input="MudaMoeda"
                                                          :options="Moedas"
                                                          :value="Moedas.moedaId"
                                                          single-line
                                                          label="nomeMoeda" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Data</label>
                                                <input v-model="DataDia" class="form-control" placeholder="" type="date">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Cotação <small>3 casas decimais</small></label>
                                                <money v-model.lazy="ValorCotacao"
                                                       class="form-control decimal"
                                                       type="text"
                                                       v-bind="money" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="true == false"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/cotacoes/' + Moedaselected.nomeMoeda)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="CotacaoId != null && CotacaoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Moeda</th>
                                <th>Data</th>
                                <th>Cotação</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Cotacao in Cotacoes" :key="Cotacao.cotacaoId">
                                <td>{{ Cotacao.cotacaoId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/cotacao/' + Cotacao.cotacaoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Cotacao)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span v-if="Cotacao.moedas != null">
                                        {{ Cotacao.moedas.nomeMoeda }}
                                    </span>
                                    <span v-else>#</span>
                                </td>
                                <td>{{ currentDateTime(Cotacao.dataDia) }}</td>
                                <td>{{ Cotacao.valorCotacao.toString().replace('.',',') }}</td>
                                <td><span v-if="Cotacao.ativo">Ativo</span><span v-else>Inativo</span></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import dateFormat from "./../../public/date.format.js";
    import moment from 'moment';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                CotacaoId: this.$route.params.id,
                MoedaMoedaId: "",
                NomeMoeda: "",
                DataDia: null,
                ValorCotacao: 0,
                Ativo: null,
                Cotacao: {},
                Cotacoes: [],
                Moedas: [],
                Moedaselected: null,
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 3,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            currentDateTime(vdate) {
                //moment.locale("pt-br");
                //moment().endOf('second');
                return moment(vdate).format('DD/MM/YYYY')
            },
            MudaMoeda(value) {
                this.MoedaMoedaId = value.moedaId;
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                let _Cotacoes = {
                    cotacaoId: 0,
                    moedaMoedaId: this.MoedaMoedaId,
                    dataDia: null,
                    valorCotacao: 0,
                    ativo: null,
                };

                //validações
                if (this.Moedaselected == null) {
                    this.$mensagem_normal("Por favor preencha o nome da moeda");
                } else if (this.DataDia == null) {
                    this.$mensagem_normal("Por favor preencha data");
                } else if (this.ValorCotacao <= 0) {
                    this.$mensagem_normal("Por favor preencha valor cotação");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _Cotacoes.dataDia = this.DataDia;

                    _Cotacoes.valorCotacao = parseFloat(this.ValorCotacao.replace(".", "").replace(",", ".")); 
                    _Cotacoes.ativo = this.Ativo;
                    console.log(_Cotacoes.valorCotacao);
                    console.log(this.ValorCotacao);
                    console.log(_Cotacoes.valorCotacao);



                    //chama o post da api
                    let ID = _Cotacoes.moedaMoedaId;
                    this.$http.post(this.$apiUrl + "/cotacoes/" + ID, _Cotacoes).then(
                        response2 => {
                            // get body datadd/mm/yyyy
                            _Cotacoes = response2.body;
                            this.$redirect_reload("/controle/cotacoes");
                        },
                        response2 => {
                            _Cotacoes = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            //this.$mensagem_normal(_Cotacoes);
                            console.log(_Cotacoes);
                        }
                    );
                }
            },
            Remove(DadoRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/cotacoes/" + DadoRemover.cotacaoId)
                    .then(
                        () => {
                            // get body data
                            //remove do array
                            //var indice = this.Idiomas.indexOf(IdiomaRemover);
                            //this.Idiomas.splice(indice, 1);
                            this.$redirect_reload("/controle/cotacoes");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Cotacoes = {
                    cotacaoId: 0,
                    moedaMoedaId: this.MoedaMoedaId,
                    dataDia: null,
                    valorCotacao: 0,
                    cotacao: {},
                    ativo: null,
                };

                //validações
                if (this.Moedaselected == null) {
                    this.$mensagem_normal("Por favor preencha o nome da moeda");
                } else if (this.DataDia == null) {
                    this.$mensagem_normal("Por favor preenchaa data");
                } else if (this.ValorCotacao <= 0) {
                    this.$mensagem_normal("Por favor preencha valor cotação");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    this.$http.get(this.$apiUrl + "/moedas/" + this.MoedaMoedaId).then(
                        (response) => {

                            _Cotacoes.moedas = response.body;
                            _Cotacoes.dataDia = this.DataDia;
                            _Cotacoes.valorCotacao = parseFloat(this.ValorCotacao.replace(".", "").replace(",", "."));
                            _Cotacoes.ativo = this.Ativo;
                            _Cotacoes.cotacaoId = parseInt(this.CotacaoId, 10);

                            //chama o post da api
                            this.$http
                                .put(this.$apiUrl + "/cotacoes/" + this.CotacaoId, _Cotacoes)
                                .then(
                                    response2 => {
                                        // get body data
                                        _Cotacoes = response2.body;
                                        this.$mensagem_sucesso(
                                            "Cotação #" + this.MoedaId + " alterada com sucesso"
                                        );
                                        this.$redirect("/controle/cotacoes");
                                    },
                                    async response2 => {
                                        _Cotacoes = response2.body;
                                        // error callback
                                        //const data = await response2.json();
                                        //JSON.parse(data);
                                        this.$mensagem_normal(_Cotacoes);
                                    }
                                );
                        },
                    );
                }
            },
            Pesquisa() {
                //try {
                //    this.Cotacoes = [];
                //    let _Cotacoes = {
                //        moedas: [{ moedaid: this.MoedaMoedaId, nomemoeda: this.NomeMoeda }],
                //    };
                //    console.log(_Cotacoes);
                //    this.$http
                //        .post(this.$apiUrl + "/cotacoes/busca", _Cotacoes)
                //        .then((res) => res.json())
                //        .then((tiu) => (this.Cotacoes = tiu))
                //        .then(this.atualiza());
                //} catch {
                    this.$mensagem_normal("Pesquisa desabilitada");
                //}
            },
            Limpar() {
                this.$redirect_reload("/controle/cotacoes");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.NomeMoeda = this.$route.params.pesquisa;
            } else {
                this.NomeMoeda = "";
            }

            if (this.CotacaoId == null) {
                this.CotacaoId = "";
            }

            this.$http
                .get(this.$apiUrl + "/moedas/ativos")
                .then((res) => res.json())
                .then((tiu) => (this.Moedas = tiu));


            //chama o get da api this.$apiUrl
            if (this.CotacaoId != null && this.CotacaoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/cotacoes/" + this.CotacaoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Cotacao = idi;

                        console.log(this.Cotacao);

                        this.MoedaMoedaId = this.Cotacao.moedas.moedaId;
                        this.DataDia = dateFormat(this.Cotacao.dataDia, "yyyy-mm-dd");
                        this.ValorCotacao = this.Cotacao.valorCotacao;
                        this.Moedaselected = this.Cotacao.moedas;
                        this.Statuselected = this.Cotacao.ativo;
                        this.Ativo = this.Cotacao.ativo;

                    });
            }
            else if (this.NomeMoeda != "") {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/cotacoes")
                    .then((res) => res.json())
                    .then((moe) => (this.Cotacoes = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
