<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <div class="faq-wrap">
          <div id="accordion">
            <div class="card-box pd-20 height-100-p mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                ></button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <img src="vendors/images/banner-img.png" alt="" />
                    </div>
                    <div class="col-md-8">
                      <h4 class="font-20 weight-500 mb-10 text-capitalize">
                        Olá,
                        <div class="weight-600 font-30 text-blue">
                          {{ nome }}
                        </div>
                      </h4>
                      <p class="font-18 max-width-600">
                       
                      </p>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="$redirect_reload('/controle/dashboard')"
                      >
                        Ver Dashboard
                      </button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

export default {
  data() {
    return {
      nome: "",
      update: "",
    };
  },
  components: {
    // Cabecalho,
    // Rodape,
  },
  methods: {
  
  },
  created() {
    if(localStorage.menu != null)
      localStorage.menu = null;
    if(localStorage.menupai != null)
      localStorage.menupai = null;
    var usuario = JSON.parse(this.$cookie.get("usuarioAvance"));
    if (usuario != null) {
      this.nome = usuario.nomeUsuario;
    }
    var upt = this.$cookie.get("update1");

    this.update = upt;
    if (upt == null) {
      this.$cookie.set("update1", "ok", {
        expires: "1m",
      });
      new Promise((resolve) => setTimeout(resolve, 1000));
      this.$router.go("/controle/default");
      this.$router.go("/controle/default");
      console.log("aa");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
