<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Itens Avulsos</h4>
                      <p class="mb-30">
                        Tela para cadastro de itens avulsos que poderão ser
                        utilizadas nos produtos.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Nome Item Avulso</label>
                        <input
                          v-model="Item.nomeItemAvulso"
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Visível</label>
                        <v-select
                          v-model="Item.visivel"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Customizável</label>
                        <v-select
                          v-model="Item.vendaAvulsa"
                          :options="[
                            { nome: 'Sim', vendaAvulsa: true },
                            { nome: 'Não', vendaAvulsa: false },
                          ]"
                          single-line
                          :reduce="(x) => x.vendaAvulsa"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Descrição</label>
                        <quill-editor
                          placelder="Descrição..."
                          v-model="Item.descricao"
                        ></quill-editor>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Valor</label>
                        <input
                          class="form-control"
                          v-model="Item.valorDe"
                          @change="
                            Item.valorDe != null
                              ? (Item.valorDe = parseFloat(Item.valorDe, 10))
                              : (Item.valorDe = 0.0)
                          "
                          type="number"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Tempo Produção (Horas)</label>
                        <input
                          class="form-control"
                          v-model="Item.tempoProducao"
                          @change="
                            Item.tempoProducao != null
                              ? (Item.tempoProducao = parseInt(Item.tempoProducao, 10))
                              : (Item.tempoProducao = 0)
                          "
                          type="number"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-8" v-if="ItemId > 0">
                      <img
                        src="/images/cloud-upload.gif"
                        v-if="Enviando > Enviado"
                      />
                      <div class="form-group">
                        <label>Imagem</label>
                        <input
                          type="file"
                          id="arquivo"
                          @change="Upload"
                          v-if="Enviando == Enviado"
                          class="form-control-file form-control height-auto"
                          multiple
                        />
                        <div
                          class="row"
                          v-if="Item.urlImg != null && Item.urlImg.length > 0"
                        >
                          <img :src="Item.urlImg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="$redirect_reload('/controle/itensavulsos')"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="Alterar()"
                        v-if="ItemId != null && ItemId > 0"
                        id="sa-custom-position"
                      >
                        Salvar alterações
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="Salvar()"
                        v-else
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>Visível</th>
                <th>Customizável</th>
                <th>Valor</th>
                <th class="datatable-nosort">imagem</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Item, index) in Itens" :key="index">
                <td>{{ Item.itemAvulsoId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect('/controle/itemAvulso/' + Item.itemAvulsoId)
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >

                      <a
                        class="dropdown-item"
                        @click="Remover(Item.itemAvulsoId)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Item.nomeItemAvulso }}</td>
                <!-- <td>
                   <a class="dropdown-item tamdado" @click="SalvarItem(Pedido)"
                    ><i class="dw dw-edit2 tamdado"></i> Salvar</a
                  >
                </td> -->
                 <td>
                    <!-- <v-select
                    style="font-size:10px"
                    v-model="Item.visivel"
                    :options="[
                      { nome: 'Sim', valor: true },
                      { nome: 'Não', valor: false },
                    ]"
                    single-line
                    :reduce="(x) => x.valor"
                    label="nome"
                    class="col-12"
                  ></v-select> -->
                  <span v-if="Item.visivel == true">Sim</span><span v-else>Não</span>
                </td>
                <td>
                  <!-- <v-select
                  style="font-size:10px"
                    v-model="Item.vendaAvulsa"
                    :options="[
                      { nome: 'Sim', valor: true },
                      { nome: 'Não', valor: false },
                    ]"
                    single-line
                    :reduce="(x) => x.valor"
                    label="nome"
                    class="col-12"
                  ></v-select> -->
                  <span v-if="Item.vendaAvulsa == true">Sim</span
                  ><span v-else>Não</span>
                </td>
               
                <td>
                  R$
                  {{
                    Item.valorDe
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .replace(".", ",")
                      .replace("$", "")
                  }}
                </td>
                <td width="10%">
                  <img :src="Item.urlImg" alt="" />
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      ItemId: 0,
      NomeItem: "",
      Item: {},
      Itens: [],
      Enviando: 0,
      Enviado: 0,
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    SalvarItem(Item) {
      this.$http.put(this.$apiUrl + "/ItemAvulso/" + Item.itemAvulsoId, Item).then(
        (response) => {
          if (response.ok) {
            this.$mensagem_sucesso("Item alterado com sucesso.");
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    ApagaImagem(url) {
      console.log(url);
    },
    Upload(event) {
      var files = event.target.files;

      var x = 0;
      for (x = 0; x < files.length; x++) {
        let file = files[x];
        if (file != null) {
          var ext = file.name.split(".").pop();
          if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg" && ext != "webp") {
            this.$mensagem_erro(
              "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png', '.webp' ou '.gif')"
            );
          } else {
            this.Enviando++;
            let data = new FormData();
            data.append("arquivo", file);
            console.log(file);
            this.$http.post(this.$apiUrl + "/ItemAvulso/upload/", data).then(
              (response) => {
                this.Item.urlImg = response.bodyText;
                this.Enviado++;
              },
              (response) => {
                console.log(response);
              }
            );
          }
        }
      }
    },
    Remover(id) {
      this.$http.delete(this.$apiUrl + "/ItemAvulso/" + id).then(
        (response) => {
          console.log(response);
          this.$mensagem_sucesso("Removido com sucesso");
          this.$http
            .get(this.$apiUrl + "/ItemAvulso/")
            .then((result) => {
              this.Itens = result.body;
              this.$redirect_reload("/controle/itensavulsos")
            })
            .then(this.atualiza());
        },
        (error) => {
          this.$mensagem_erro(error.body);
        }
      );
    },
    Salvar() {
      this.Item.ordem = parseInt(this.Item.ordem, 10);
      console.log("POSTANDO:");
      console.log(this.Item);
      this.$http.post(this.$apiUrl + "/ItemAvulso", this.Item).then(
        (response) => {
          if (response.ok) {
            let temp = response.body;
            this.$mensagem_sucesso("Item cadastrado com sucesso");
            this.$redirect("/controle/ItemAvulso/" + temp.itemAvulsoId);
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Alterar() {
      this.Item.ordem = parseInt(this.Item.ordem, 10);
      console.log("POSTANDO:");
      console.log(this.Item);
      this.$http
        .put(this.$apiUrl + "/ItemAvulso/" + this.ItemId, this.Item)
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso("Item alterado com sucesso");
              this.$redirect("/controle/ItensAvulsos");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    Pesquisar() {},
    // CarregaTela() {
    //   console.log(this.TelaSelecionada);
    //   this.$cookie.set("telaseg1", this.TelaSelecionada.toString(), "60m");
    //   this.$router.go(this.$router.currentRoute);
    //   this.$router.go(this.$router.currentRoute);
    // },
    Limpar() {
      this.$redirect_reload("/controle/itensavulsos/");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/ItemAvulso/")
      .then((result) => {
        this.Itens = result.body;
      })
      .then(this.atualiza());

    if (this.$route.params.pesquisa != null) {
      this.nomeItem = this.$route.params.pesquisa;
    } else {
      this.nomeItem = "";
    }
    if (this.ItemId == 0) {
      if (this.$route.params.id != null) {
        this.ItemId = parseInt(this.$route.params.id, 10);
      }
    }
    console.log("EDITANDO:" + this.ItemId);
    if (this.ItemId > 0) {
      this.ItemId = parseInt(this.ItemId, 10);
      this.$http.get(this.$apiUrl + "/ItemAvulso/" + this.ItemId).then(
        (response) => {
          console.log("Editando:");
          console.log(response.body);

          this.Item = response.body;

          // this.Imagens = this.Item.imagens;
          // this.Imagens = this.Imagens.sort(function (a, b) {
          //   return a.ordem - b.ordem;
          // });
        },
        (error) => {
          console.log("Erro ao carregar:");
          console.log(error);
        }
      );
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
