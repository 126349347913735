<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Grupos de telas</h4>
                      <p class="mb-30">
                        Tela para cadastro dos Grupos de tela.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nome</label>
                        <input
                          v-model="Grupo.nomeSegmentoTela"
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Ordem</label>
                        <input
                          v-model="Grupo.ordem"
                          class="form-control"
                          type="number"
                          @change="Grupo.ordem != null ? Grupo.ordem = parseInt(Grupo.ordem,10):Grupo.ordem = 0"
                          placeholder="Ordem"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        v-if="
                          grupoInstitucionalId == null ||
                          grupoInstitucionalId.length <= 0
                        "
                        type="button"
                        @click="
                          $redirect_reload(
                            '/controle/gruposInstitucional/' + Grupo.Nome
                          )
                        "
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <button
                        v-if="
                          grupoInstitucionalId != null &&
                          grupoInstitucionalId.length > 0
                        "
                        type="button"
                        class="btn btn-success"
                        @click="Altera()"
                        id="sa-custom-position2"
                      >
                        Alterar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        @click="Add()"
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>Ordem</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Grupo, index) in Grupos" :key="index">
                <td>{{ Grupo.grupoTelaInstitucionalId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/grupoInstitucional/' +
                              Grupo.grupoTelaInstitucionalId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Grupo)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Grupo.nomeSegmentoTela }}</td>
                <td>{{ Grupo.ordem }}</td>
                
              </tr>
              <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
            </tbody>
          </table>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
//import vSelect from "vue-select";

export default {
  data() {
    return {
      grupoInstitucionalId: this.$route.params.id,
      Ativo: null,
      Grupo: {},
      Grupos: [],
      T: {},
    };
  },
  methods: {
    MudaStatus(value) {
      this.Ativo = value;
    },
    Add() {
      
      //validações
      if (this.Grupo.nomeSegmentoTela.length <= 0) {
        this.$mensagem_normal("Por favor preencha o nome");
      } 
      else {

        //chama o post da api
        this.$http
          .post(this.$apiUrl + "/GrupoTelaInstitucional/", this.Grupo)
          .then(
            (response2) => {
              // get body data
              if(response2.ok)
              {
                this.$atualiza_cabecalho();
                this.$mensagem_sucesso("Grupo salvo com sucesso");
                this.$redirect_reload("/controle/gruposInstitucional");
                this.$router.go(this.$router.currentRoute);
                this.$router.go(this.$router.currentRoute);
              }
            },
            (response2) => {
              this.$mensagem_normal(response2.body);
            }
          );
      }
    },
    Remove(DadosRemover) {
      //chama o delete da api
      this.$http
        .delete(
          this.$apiUrl +
            "/GrupoTelaInstitucional/" +
            DadosRemover.grupoTelaInstitucionalId
        )
        .then(
          () => {
            // get body data
            this.$atualiza_cabecalho();
            this.$redirect_reload("/controle/gruposInstitucional");
            this.$mensagem_sucesso("Removido com sucesso");
            this.$router.go(this.$router.currentRoute);
                this.$router.go(this.$router.currentRoute);
          },
          (response) => {
            // error callback
            console.log(response);
          }
        );
    },
    Altera() {

      //validações
      if (this.Grupo.nomeSegmentoTela.length <= 0) {
        this.$mensagem_normal("Por favor preencha o grupoInstitucional");
      }
       else {
        this.Grupo.grupoInstitucionalId = parseInt(
          this.grupoInstitucionalId,
          10
        );

        //chama o post da api
        this.$http
          .put(
            this.$apiUrl + "/GrupoTelaInstitucional/" + this.grupoInstitucionalId,
            this.Grupo
          )
          .then(
            (response2) => {
              // get body data
              if(response2.ok)
              {
                this.$atualiza_cabecalho();
                this.$mensagem_sucesso("Grupo #" + this.grupoInstitucionalId + " alterado com sucesso");
                this.$redirect("/controle/gruposInstitucional");
                this.$router.go(this.$router.currentRoute);
                this.$router.go(this.$router.currentRoute);
              }
            },
            async (response2) => {
              // error callback
              //const data = await response2.json();
              //JSON.parse(data);
              this.$mensagem_normal(response2.body);
            }
          );
      }
    },
    Pesquisa() {
      try {
        if (this.Grupo.nomeSegmentoTela.length <= 0) {
          this.$mensagem_normal("Por favor preencha o nome");
        } else {
          this.$http
            .post(
              this.$apiUrl + "/GrupoTelaInstitucional/busca",
              this.Grupo
            )
            .then((res) => res.json())
            .then((tiu) => (this.Grupos = tiu))
            .then(this.atualiza());
        }
      } catch {
        this.$mensagem_normal("Nenhum resultado encontrado");
      }
    },
    Limpar() {
      this.$redirect_reload("/controle/grupoInstitucional");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: false,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  created() {
    if (this.$route.params.pesquisa != null) {
      this.Grupo.nomeSegmentoTela = this.$route.params.pesquisa;
    } else {
      this.Grupo.nomeSegmentoTela = "";
    }

    if (this.grupoInstitucionalId == null) {
      this.grupoInstitucionalId = 0;
    }

    //chama o get da api this.$apiUrl
    if (
      this.grupoInstitucionalId != null &&
      this.grupoInstitucionalId > 0
    ) {
      this.$http
        .get(this.$apiUrl + "/GrupoTelaInstitucional/" + this.grupoInstitucionalId)
        .then((res) => res.json())
        .then((idi) => {
          this.Grupo = idi;
        });
    } else if (
      this.Grupo != null &&
      this.Grupo.nomeSegmentoTela.length > 0
    ) {
      this.Pesquisa();
    } else {
      this.$http
        .get(this.$apiUrl + "/GrupoTelaInstitucional")
        .then((res) => res.json())
        .then((moe) => (this.Grupos = moe))
        .then(this.atualiza());
    }
  },
  mounted() {},
  components: {
    //vSelect,
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
