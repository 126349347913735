<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="pd-20 card-box mb-30">
          <div class="clearfix">
            <div class="pull-left">
              <h4 class="text-black h4">Segmentos Institucionais</h4>
              <p class="mb-30">
                Tela para Liberação de quantos segmentos serão utilizados nas
                telas institucionais do sistema SGCAD.
              </p>
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="tab">
                    <div class="row clearfix">
                      <div class="col-md-3 col-sm-12">
                        <ul
                          class="nav flex-column vtabs nav-tabs"
                          role="tablist"
                        >
                          <li
                            class="nav-item"
                            v-for="Segmento in Segmentos"
                            :key="Segmento.segmentoInstTelaId"
                          >
                            <a
                              v-bind:class="{
                                'nav-link': true,
                                active:
                                  Segmento.segmentoInstTelaId ==
                                  Segmentos[0].segmentoInstTelaId,
                              }"
                              data-toggle="tab"
                              :href="'#' + Segmento.segmentoInstTelaId"
                              @click="Seleciona(Segmento)"
                              role="tab"
                              v-bind:aria-selected="{
                                true:
                                  Segmento.segmentoInstTelaId ==
                                  Segmentos[0].segmentoInstTelaId,
                              }"
                              >{{ Segmento.nomeSegmento }}</a
                            >
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-8 col-sm-12">
                        <div class="tab-content">
                          <div class="tab-pane fade show active">
                            <!-- <div  v-for="Segmento in Segmentos" :key="Segmento.segmentoInstTelaId"
                            v-bind:class="{ 'tab-pane fade': true, 'show active': Segmento.segmentoInstTelaId == Segmentos[0].segmentoInstTelaId}"
                            v-bind:id="Segmento.segmentoInstTelaId"
                            role="tabpanel"
                          > -->
                            <div class="pd-20">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label></label>
                                    <input
                                      v-model="Segmento.nomeSegmento"
                                      class="form-control mb-5"
                                      type="text"
                                      placeholder="Nome Segmento"
                                    />
                                    <div
                                      class="
                                        custom-control custom-checkbox
                                        mb-5
                                      "
                                    >
                                      <input
                                        v-model="Segmento.visivel"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck2"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck2"
                                        >Visivel</label
                                      >
                                    </div>
                                    <div
                                      class="
                                        custom-control custom-checkbox
                                        mb-5
                                      "
                                    >
                                      <input
                                        v-model="Segmento.ordem"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck3"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck3"
                                        >Ordem</label
                                      >
                                    </div>
                                    <div
                                      class="
                                        custom-control custom-checkbox
                                        mb-5
                                      "
                                    >
                                      <input
                                        v-model="Segmento.resumo"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck4"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck4"
                                        >Resumo</label
                                      >
                                    </div>
                                    <div
                                      class="
                                        custom-control custom-checkbox
                                        mb-5
                                      "
                                    >
                                      <input
                                        v-model="Segmento.descricao_1"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck5"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck5"
                                        >Descrição 1</label
                                      >
                                    </div>
                                    <div
                                      class="
                                        custom-control custom-checkbox
                                        mb-5
                                      "
                                    >
                                      <input
                                        v-model="Segmento.descricao_2"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="customCheck6"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck6"
                                        >Descrição 2</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div
                                    class="custom-control custom-checkbox mb-5"
                                  >
                                    <input
                                      v-model="Segmento.uploadImg"
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="customCheck7"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customCheck7"
                                      >Upload de Imagem</label
                                    >
                                  </div>
                                  <div class="form-group">
                                    <label>Altura</label>
                                    <input
                                      v-model="Segmento.alturaImg"
                                      class="form-control"
                                      type="number"
                                      placeholder="Altura"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>Largura</label>
                                    <input
                                      v-model="Segmento.larguraImg"
                                      class="form-control"
                                      type="number"
                                      placeholder="Largura"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <label>Quantidade</label>
                                    <input
                                      v-model="Segmento.qtdeImg"
                                      class="form-control"
                                      type="number"
                                      placeholder="Quantidade Máxima"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <button
                                    @click="
                                      $redirect_reload(
                                        '/controle/segmentosinst'
                                      )
                                    "
                                    type="button"
                                    class="btn btn-link"
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    v-if="IdSegmento != 0"
                                    type="button"
                                    class="btn btn-success"
                                    id="sa-custom-position"
                                    @click="UpdateSegmento"
                                  >
                                    Salvar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" style="padding: 20px">
                        <div class="col-md-12">
                          <label>Telas:</label>
                          <v-select
                            v-model="TelaSelecionada"
                            :options="TelasSegmento"
                            single-line
                            :reduce="(x) => x.configuraInstitucionalId"
                            @input="CarregaTela"
                            label="nomeTela"
                          ></v-select>
                          <label>Níveis de segmentação:</label>
                          <ul>
                            <li>
                              <div class="custom-control custom-checkbox mb-5">
                                <input
                                 class="custom-control-input"
                                  id="customCheck8"
                                  type="checkbox"
                                  v-model="ConfiguraTela.segmentoInstNivel1"
                                />
                                <label
                                class="custom-control-label"
                                 for="customCheck8"
                                 style="padding-left: 10px"
                                >Nível 1
                              </label>
                              </div>
                            </li>
                             <li>
                              <div class="custom-control custom-checkbox mb-5">
                                <input
                                 class="custom-control-input"
                                  id="customCheck9"
                                  type="checkbox"
                                  v-model="ConfiguraTela.segmentoInstNivel2"
                                />
                                <label style="padding-left: 10px"
                                 class="custom-control-label"
                                 for="customCheck9"
                                >Nível 2
                              </label>
                              </div>
                            </li>
                             <li>
                              <div class="custom-control custom-checkbox mb-5">
                                <input
                                 class="custom-control-input"
                                  id="customCheck10"
                                  type="checkbox"
                                  v-model="ConfiguraTela.segmentoInstNivel3"
                                />
                                <label style="padding-left: 10px"
                                 class="custom-control-label"
                                 for="customCheck10"
                                >Nível 3
                              </label>
                              </div>
                            </li>
                             <li>
                              <div class="custom-control custom-checkbox mb-5">
                                <input
                                 class="custom-control-input"
                                  id="customCheck11"
                                  type="checkbox"
                                  v-model="ConfiguraTela.segmentoInstNivel4"
                                />
                                <label style="padding-left: 10px"
                                 class="custom-control-label"
                                 for="customCheck11"
                                >Nível 4
                              </label>
                              </div>
                            </li>
                          </ul>
                          <button
                            type="button"
                            class="btn btn-success"
                            @click="SalvaTela(ConfiguraTela)"
                          >
                            Salvar Tela
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
//import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      T: {},
      Segmentos: [],
      Segmento: {},
      TelasSegmento: [],
      TelaSelecionada: 0,
      ConfiguraTela: {},
      IdSegmento: 0,
    };
  },
  methods: {
    Seleciona(seg) {
      this.Segmento = seg;
      this.IdSegmento = seg.segmentoInstId;
    },
    UpdateSegmento() {
      let _erros = "";
      let seg = this.Segmento;
      if (_erros.length > 0) {
        this.$mensagem_normal(_erros);
      } else {
        console.log(seg);
        seg.alturaImg = parseInt(seg.alturaImg, 10);
        seg.larguraImg = parseInt(seg.larguraImg, 10);
        seg.qtdeImg = parseInt(seg.qtdeImg, 10);
        this.$http
          .put(
            this.$apiUrl + "/SegmentoInstTelas/" + seg.segmentoInstTelaId,
            seg
          )
          .then(
            (response2) => {
              // get body data
              console.log(response2);
              this.$mensagem_sucesso(
                seg.nomeSegmento + " alterado com sucesso"
              );
            },
            (response2) => {
              // error callback
              this.$mensagem_erro(response2.body);
            }
          );
      }
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
    CarregaTela() {
      console.log(this.TelaSelecionada);
      this.$http
        .get(this.$apiUrl + "/configurainstitucional/" + this.TelaSelecionada)
        .then(
          (response) => {
            this.ConfiguraTela = response.body;
            console.log(this.ConfiguraTela);
          },
          (response) => {
            console.log(response);
          }
        );
    },
    SalvaTela(configuraTela) {
      this.$http
        .put(
          this.$apiUrl +
            "/configurainstitucional/" +
            configuraTela.configuraInstitucionalId,
          configuraTela
        )
        .then(
          (response) => {
            console.log(response);
            this.$mensagem_sucesso("Níveis da tela salvos");
          },
          (response) => {
            console.log(response);
            this.$mensagem_erro(response.bodyText);
          }
        );
      console.log(configuraTela);
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/SegmentoInstTelas")
      .then((res) => res.json())
      .then((segs) => (this.Segmentos = segs))
      .then(() => {
        if (this.Segmentos != null && this.Segmentos.length >= 0) {
          this.IdSegmento = this.Segmentos[0].segmentoInstTelaId;
          this.Segmento = this.Segmentos[0];
        }
      });
    this.$http
      .get(this.$apiUrl + "/ConfiguraInstitucional")
      .then((response) => {
        var i = 0;
        for (i = 0; i < response.body.length; i++) {
          var tmp = response.body[i];
          var insert = {
            nomeTela: tmp.tela.nomeTela,
            configuraInstitucionalId: tmp.configuraInstitucionalId,
          };
          this.TelasSegmento.push(insert);
        }
        this.TelaSelecionada = this.TelasSegmento[0].configuraInstitucionalId;
        this.CarregaTela();
      });
  },
};
/*
 na o segmentoinst
 telaacesso string link
 crirar tela configura institucional com bool e label
 criar grupo de telas e tela instiucional
*/
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
ss
