<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <input type="file" @change="UploadImagem" multiple/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ImagensSubir: [],
      ImagensSubidas: [],
    };
  },
  methods: {
     UploadImagem(event) {
      //console.log(event.target.files);
      var files = event.target.files;
      let data = new FormData();
      data.append('arquivo', files[0])
      this.$http.post("http://localhost:7416/api/usuarios/upload", data);
      
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
