<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Pix</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das chaves pixs que poderão ser utilizadas no sistema.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Banco Idenficador Pix</label>
                                                <input v-model="Nomepix" class="form-control" type="text" placeholder="Identificador Pix">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label>Visível</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label>Imagem</label>
                                                <input id="arquivo"
                                                       type="file"
                                                       class="form-control-file form-control height-auto" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <!--<button v-if="CadpixId == null || CadpixId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/cadpixs/' + Nomepix)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>-->
                                            <button v-if="CadpixId != null && CadpixId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                
                                <th>Nome</th>
                                <th>Visível</th>
                                <th class="datatable-nosort">imagem</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Cadpix in Cadpixs" :key="Cadpix.cadPixId">
                                <td>{{ Cadpix.cadPixId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/cadpix/' + Cadpix.cadPixId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Cadpix)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Cadpix.nomePix }}</td>
                                <td><span v-if="Cadpix.visivel">Sim</span><span v-else>Não</span></td>
                                  <td width="10%">
                                    <img :src="Cadpix.urlImg" alt="" />
                                </td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                CadpixId: this.$route.params.id,
                Visivel: null,
                Nomepix: '',
                Urlimg:'',
                ImagemPix: "",
                Cadpix: {},
                Cadpixs: [],
                Statuselected: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus(value) {
                this.Visivel = value;
            },
            Add() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                let URLIMAGEM = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                } 

                let _Cadpix = {
                    cadpixid: 0,
                    visivel: null,
                    nomepix: null,
                    urlimg: this.ImagemPix
                };

                //validações
                console.log(this.Nomepix.length);
                if (this.Nomepix.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                } else {

                    _Cadpix.visivel = this.Visivel;
                    _Cadpix.nomepix = this.Nomepix;
                    _Cadpix.urlimg = this.Urlimg;

                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        console.log(file);
                        this.$http.post(this.$apiUrl + "/cadpix/upload", data).then(
                            (response3) => {
                                // get body data
                                URLIMAGEM = response3.body;
                                console.log("SUBIU AQUI: " + URLIMAGEM);
                               
                                this.CriarUsuarioComImagem(_Cadpix, URLIMAGEM);
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    } else {
                        this.CriarUsuarioComImagem(_Cadpix, URLIMAGEM);
                    }
                }
            },
            CriarUsuarioComImagem(_cadpix, URL) {
                console.log("_cadpix:");
                console.log(_cadpix);
                _cadpix.urlImg = URL;
                
                //chama o post da api
                this.$http.post(this.$apiUrl + "/cadpix/", _cadpix).then(
                    response2 => {
                        // get body data
                        _cadpix = response2.body;
                        this.$router.go(this.$router.currentRoute);
                    },
                    response2 => {
                        _cadpix = response2.body;
                        this.$mensagem_normal(_cadpix);
                    }
                );
            },
            Remove(dadosRemover) {
                //chama o delete da api
                console.log(dadosRemover.cadPixId);
                this.$http
                    .delete(this.$apiUrl + "/cadpix/" + dadosRemover.cadPixId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let file = document.getElementById("arquivo").files[0];
                console.log("ARQ")

                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                } 

                let _Cadpix = {
                    cadpixid: 0,
                    visivel: null,
                    nomepix: null,
                    urlimg: this.ImagemPix,
                };

                //validações
                if (this.Nomepix.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                } else {

                    _Cadpix.visivel = this.Visivel;
                    _Cadpix.nomepix = this.Nomepix;
                    _Cadpix.cadpixid = parseInt(this.CadpixId, 10);


                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        console.log(file);
                        this.$http.post(this.$apiUrl + "/cadpix/upload", data).then(
                            (response3) => {
                                // get body data
                                _Cadpix.urlimg = response3.body;
                                this.AtualizarUsuarioComImagem(_Cadpix, _Cadpix.urlimg);
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                    else {
                        this.AtualizarUsuarioComImagem(_Cadpix, this.ImagemPix)
                    }
                }
            },
            AtualizarUsuarioComImagem(_cadpix, URL) {
                if (URL == null)
                    URL = "";
                let ID = _cadpix.cadpixid;
                if (URL != null && URL.length > 0)
                    _cadpix.urlImg = URL;
                //chama o post da api
                this.$http
                    .put(this.$apiUrl + "/cadpix/" + ID, _cadpix)
                    .then(
                        response2 => {
                            // get body data
                            _cadpix = response2.body;
                            this.$mensagem_sucesso(
                                "Pix #" + this.CadpixId + " alterado com sucesso"
                            );
                            this.Limpar();
                        },
                        async response2 => {
                            _cadpix = response2.body;
                            // error callback
                            this.$mensagem_normal(_cadpix);
                        }
                    );
            },
            Pesquisa() {
                try {
                    if (this.Nomepix.length <= 0) {
                        this.$mensagem_normal("Por favor preencha nome da pix");
                    } else {
                        this.Cadpixs = [];
                        let _Cadpixs = {
                            Nomepix: this.Nomepix,
                        };
                        this.$http
                            .post(this.$apiUrl + "/cadpixs/busca", _Cadpixs)
                            .then((res) => res.json())
                            .then((tiu) => (this.Cadpixs = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/cadpix");
                this.$http
                    .get(this.$apiUrl + "/cadpix")
                    .then((res) => res.json())
                    .then((moe) => (this.Cadpixs = moe))
                    .then(this.atualiza());
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.Nomepix = this.$route.params.pesquisa;
            } else {
                this.Nomepix = "";
            }

            if (this.CadpixId == null) {
                this.CadpixId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.CadpixId != null && this.CadpixId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/cadpix/" + this.CadpixId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Cadpix = idi;

                        this.Nomepix = this.Cadpix.nomePix;
                        this.Statuselected = this.Cadpix.visivel;
                        this.ImagemPix = this.Cadpix.urlImg;
                        this.Visivel = this.Cadpix.visivel;

                    });
            }
            else if (this.Nomepix != null && this.Nomepix.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/cadpix")
                    .then((res) => res.json())
                    .then((moe) => (this.Cadpixs = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
