<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela Tabelas de Preços</h4>
                      <p class="mb-30">
                        Tela de tabelas de preços e estoque dos produtos.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <div class="row">
                  <table>
                    <tr>
                      <td style="padding: 5px"><span>Nova Tabela: </span></td>
                      <td style="padding: 5px">
                        <input
                          type="text"
                          v-model="NomeTabelaNova"
                          class="form-control"
                        />
                      </td>
                      <td style="padding: 5px">
                        <button
                          type="button"
                          @click="CriarTabela"
                          class="btn btn-success"
                        >
                          Criar
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                 <div class="row">
                  <table>
                    <tr>
                      <td v-for="Tabela, index in Tabelas" :key="index">
                        <button class="btn btn-info" :style="Tabela.tabelaPrecoId == TabelaId ? 'border:2px solid black':''" @click="SelecionaTabela(Tabela.tabelaPrecoId)">{{Tabela.identificador}}</button>
                      </td>
                    </tr>
                  </table>
                  </div>
                <div class="tab" v-if="Tabelas != null && Tabelas.length > 0 && TabelaId > 0">
                  <!-- <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active text-blue"
                        data-toggle="tab"
                        href="#tabela1"
                        role="tab"
                        aria-selected="true"
                        >{{ Tabela.identificador }}</a
                      >
                    </li>
                  </ul> -->
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      id="tabela1"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <form>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Identificador</label>
                                <input
                                  v-model="Tabela.identificador"
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Validade</label>
                                <input
                                  @change="MudaValidade"
                                  v-model="Validade"
                                  class="form-control"
                                  type="date"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Status</label>
                                <v-select
                                  v-model="Tabela.status"
                                  :options="[
                                    { nome: 'Ativo' },
                                    { nome: 'Inativo' },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.nome"
                                  label="nome"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Tipo</label>
                                <v-select
                                  v-model="Tabela.tipo"
                                  :options="[
                                    { nome: 'Pessoa Física', valor: 'pf' },
                                    { nome: 'Pessoa Jurídica', valor: 'pj' },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.valor"
                                  label="nome"
                                ></v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 text-right">
                              <button type="button" class="btn btn-link" @click="$redirect_reload('/controle/tabelaspreco')">
                                Cancelar
                              </button>
                              <button
                                type="button"
                                class="btn btn-success"
                                id="sa-custom-position"
                                @click="AtualizaTabela"
                              >
                                Salvar
                              </button>
                            </div>
                            <div
                              class="col-md-6 text-left"
                              style="padding-top: 10px"
                            >
                              <div class="dropdown">
                                <a
                                  class="
                                    btn btn-link
                                    font-24
                                    p-0
                                    line-height-1
                                    no-arrow
                                    dropdown-toggle
                                  "
                                  href="#"
                                  role="button"
                                  data-toggle="dropdown"
                                >
                                  <i class="dw dw-more"></i>
                                </a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-icon-list
                                  "
                                >
                                  <!-- <a class="dropdown-item" href="#"
                                    ><i class="dw dw-copy"></i> Duplicar</a
                                  > -->
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    id="sa-warning"
                                    @click="ApagaTabelaPai"
                                    ><i class="dw dw-delete-3"></i> Excluir</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <section style="display: none !important">
                        <hr />
                        <form>
                          <h2 class="h4">Selecione Filtros para Ação</h2>
                          <div class="row">
                            <div class="col-md-9">
                              <div class="form-group">
                                <label
                                  >Palavras-chaves ou Códigos separados por
                                  vírgula.</label
                                >
                                <input
                                  type="text"
                                  data-role="tagsinput"
                                  style="height: 80px; width: 100%"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3 text-center"
                              style="padding-top: 40px"
                            >
                              <button
                                type="button"
                                class="btn btn-info"
                                id="sa-custom-position"
                              >
                                Filtrar
                              </button>
                              <button type="button" class="btn btn-link">
                                Limpar
                              </button>
                            </div>
                          </div>
                        </form>
                        </section>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="tabela2"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <form>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Identificador</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Validade</label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Status</label>
                                <select class="custom-select col-12">
                                  <option selected="">Selecione...</option>
                                  <option value="0">Ativo Padrão</option>
                                  <option value="1">Ativo</option>
                                  <option value="2">Inativo</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Tipo</label>
                                <select class="custom-select col-12">
                                  <option selected="">Selecione...</option>
                                  <option value="1">Pessoa Física</option>
                                  <option value="2">Pessoa Jurídica</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 text-right">
                              <button type="button" class="btn btn-link" >
                                
                                Cancelar
                              </button>
                              <button
                                type="button"
                                class="btn btn-success"
                                id="sa-custom-position"
                                
                              >
                                Salvar
                              </button>
                            </div>
                            <div
                              class="col-md-6 text-left"
                              style="padding-top: 10px"
                            >
                              <div class="dropdown">
                                <a
                                  class="
                                    btn btn-link
                                    font-24
                                    p-0
                                    line-height-1
                                    no-arrow
                                    dropdown-toggle
                                  "
                                  href="#"
                                  role="button"
                                  data-toggle="dropdown"
                                >
                                  <i class="dw dw-more"></i>
                                </a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-icon-list
                                  "
                                >
                                  <a class="dropdown-item" href="#"
                                    ><i class="dw dw-copy"></i> Duplicar</a
                                  >
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    id="sa-warning"
                                    ><i class="dw dw-delete-3"></i> Excluir</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <hr />
                        <form>
                          <h2 class="h4">Selecione Filtros para Ação</h2>
                          <div class="row">
                            <div class="col-md-9">
                              <div class="form-group">
                                <label
                                  >Palavras-chaves ou Códigos separados por
                                  vírgula.</label
                                >
                                <input
                                  type="text"
                                  data-role="tagsinput"
                                  style="height: 80px; width: 100%"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3 text-center"
                              style="padding-top: 40px"
                            >
                              <button
                                type="button"
                                class="btn btn-info"
                                id="sa-custom-position"
                              >
                                Filtrar
                              </button>
                              <button type="button" class="btn btn-link">
                                Limpar
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade show"
                      id="tabela3"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <form>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Identificador</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Validade</label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Status</label>
                                <select class="custom-select col-12">
                                  <option selected="">Selecione...</option>
                                  <option value="0">Ativo Padrão</option>
                                  <option value="1">Ativo</option>
                                  <option value="2">Inativo</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Tipo</label>
                                <select class="custom-select col-12">
                                  <option selected="">Selecione...</option>
                                  <option value="1">Pessoa Física</option>
                                  <option value="2">Pessoa Jurídica</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 text-right">
                              <button type="button" class="btn btn-link">
                                Cancelar
                              </button>
                              <button
                                type="button"
                                class="btn btn-success"
                                id="sa-custom-position"
                              >
                                Salvar
                              </button>
                            </div>
                            <div
                              class="col-md-6 text-left"
                              style="padding-top: 10px"
                            >
                              <div class="dropdown">
                                <a
                                  class="
                                    btn btn-link
                                    font-24
                                    p-0
                                    line-height-1
                                    no-arrow
                                    dropdown-toggle
                                  "
                                  href="#"
                                  role="button"
                                  data-toggle="dropdown"
                                >
                                  <i class="dw dw-more"></i>
                                </a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-icon-list
                                  "
                                >
                                  <!-- <a class="dropdown-item" href="#"
                                    ><i class="dw dw-copy"></i> Duplicar</a
                                  > -->
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    id="sa-warning"
                                    ><i class="dw dw-delete-3" @click="ApagaTabelaPai"></i> Excluir</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <hr />
                        
                        <form class="TMP" v-if="false" style="display:none">
                          <h2 class="h4">Selecione Filtros para Ação</h2>
                          <div class="row">
                            <div class="col-md-9">
                              <div class="form-group">
                                <label
                                  >Palavras-chaves ou Códigos separados por
                                  vírgula.</label
                                >
                                <input
                                  type="text"
                                  data-role="tagsinput"
                                  style="height: 80px; width: 100%"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-3 text-center"
                              style="padding-top: 40px"
                            >
                              <button
                                type="button"
                                class="btn btn-info"
                                id="sa-custom-position"
                              >
                                Filtrar
                              </button>
                              <button type="button" class="btn btn-link">
                                Limpar
                              </button>
                            </div>
                          </div>
                        </form>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div
          class="card-box mb-30"
          v-if="Tabelas != null && Tabelas.length > 0 && TabelaId > 0"
        >
        <section class="TMP" v-if="false">
          <h2 class="h4 pd-20">Ação de alteração de Valores</h2>
          <form>
            <div class="row pd-20">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Tipo</label>
                  <select class="custom-select col-12">
                    <option selected="">Selecione...</option>
                    <option value="1">Valor Fixo</option>
                    <option value="2">Percentual</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Somar/diminuir/alterar</label>
                  <select class="custom-select col-12">
                    <option selected="">Selecione...</option>
                    <option value="1">Somar aos valores</option>
                    <option value="2">Diminuir dos valores</option>
                    <option value="3">Alterar os valores</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Valor ou percentual</label>
                  <input class="form-control" type="number" placeholder="" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Promoção</label>
                  <select class="custom-select col-12">
                    <option selected="">Selecione...</option>
                    <option value="1">Sim</option>
                    <option value="2">Não</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Visível</label>
                  <select class="custom-select col-12">
                    <option selected="">Selecione...</option>
                    <option value="1">Sim</option>
                    <option value="2">Não</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Destaque</label>
                  <select class="custom-select col-12">
                    <option selected="">Selecione...</option>
                    <option value="1">Sim</option>
                    <option value="2">Não</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="button" class="btn btn-link">Cancelar</button>
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  id="sa-custom-position"
                >
                  Limpar Filtros
                </button>
                <button
                  type="button"
                  class="btn btn-outline-info"
                  id="sa-custom-position"
                >
                  Pesquisar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="sa-custom-position"
                >
                  Aplicar Selecionados
                </button>
              </div>
            </div>
          </form>
        </section>
          <hr />
          <h2 class="h4 pd-20">Resultado Tabela Preço {{Tabela.identificador}}</h2>
          <table class="checkbox-datatable data table nowrap">
            <thead>
              <tr>
                <!-- <th>
                  <div class="dt-checkbox">
                    <input
                      type="checkbox"
                      name="select_all"
                      value="1"
                      id="example-select-all"
                      @change="SelecionaTodas"
                    />
                    <span class="dt-checkbox-label"></span>
                  </div>
                </th> -->
                <th></th>
                <th>Id</th>
                <th>Nome</th>
                <th class="datatable-nosort">Valor de</th>
                <th class="datatable-nosort">Valor Por</th>
                <th class="datatable-nosort">Qtde Mínima</th>
                <th class="datatable-nosort">Qtde Estoque</th>
                <th>Salvar</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="index%2 == 0 ? 'bg-light-gray odd':''" v-for="Item, index in Itens" :key="index">
                <td></td>
                <!-- <td><div class="dt-checkbox">
                    <input
                      type="checkbox"
                      name="select_all"
                      value="1"
                      :id="'example-select-all' + index"
                      @change="Indice[index].selected ? Indice[index].selected = false: Indice[index].selected = true"
                    />
                    <span class="dt-checkbox-label"></span>
                  </div></td> -->
                <td>{{Item.produtoId}}</td>
                <td>{{Item.nomeProduto}}</td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Valor de"
                    v-model="Item.valorDe"
                    @change="Item.valorDe != null
                    ? (Item.valorDe = parseFloat(
                        Item.valorDe,
                        10
                      ))
                    : (Item.valorDe = 0.0)"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Valor Por"
                     v-model="Item.valoPor"
                    @change="Item.valoPor != null
                    ? (Item.valoPor = parseFloat(
                        Item.valoPor,
                        10
                      ))
                    : (Item.valoPor = 0.0)"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Mínima"
                    v-model="Item.qtdeMinima"
                      @input="Item.qtdeMinima != null
                    ? (Item.qtdeMinima = parseInt(
                        Item.qtdeMinima,
                        10
                      ))
                    : (Item.qtdeMinima = 0)"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Estoque"
                     v-model="Item.qtdeEstoque"
                      @input="Item.qtdeEstoque != null
                    ? (Item.qtdeEstoque = parseInt(
                        Item.qtdeEstoque,
                        10
                      ))
                    : (Item.qtdeEstoque = 0)"
                  />
                </td>
                <td>
                  <a class="" @click="SalvarItem(Item)" href="#"><i class="dw dw-edit2"></i> Salvar</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import moment from "moment";
//import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      Itens: [],
      TabelaId: 0,
      Tabela: {},
      Validade: "",
      NomeTabelaNova: "",
      Tabelas: [],
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    AtualizaTabela(){
       if(this.Tabela.identificador != null && this.Tabela.identificador.length > 0)
        {
            this.$http.put(this.$apiUrl + "/tabelapreco/" + this.TabelaId, this.Tabela)
            .then(response => {
                if(response.ok)
                {
                    console.log('POSTED');
                    console.log(response);
                    // this.Tabelas.push(this.Tabela);
                    this.$mensagem_sucesso("Alterado com sucesso");
                    this.$redirect("/controle/tabelapreco/" + this.TabelaId);
                }
            })
        }
        else
        {
            this.$mensagem_erro("Por favor preencha o nome de tabela.")
        }
    },
    ApagaTabelaPai()
    {
      this.$http.delete(this.$apiUrl + "/tabelapreco/" + this.TabelaId)
      .then(response => {
        if(response.ok)
        {
          this.$mensagem_sucesso("Tabela e seus valores removidos com sucesso");
          this.$redirect_reload('/controle/tabelaspreco');
        }
      }
      ,error =>{
        this.$mensagem_erro(error.bodyText);
      })
    },
    SalvarItem(Item)
    {
      this.$http.put(this.$apiUrl + "/tabelaprecoproduto/" + Item.tabelaPrecoProdutoId, Item)
      .then(response => {
        if(response.ok)
          this.$mensagem_sucesso("Registro alterado com sucesso");
          this.$redirect('/controle/tabelapreco/' + this.TabelaId);
      }, error =>{
        this.$mensagem_erro(error.bodyText);
      })
    },
    SelecionaTabela(id)
    {
      this.$redirect("/controle/tabelapreco/" + id);
    },
    CriarTabela() {
        if(this.NomeTabelaNova != null && this.NomeTabelaNova.length > 0)
        {
            this.Tabela.identificador = this.NomeTabelaNova;
            this.$http.post(this.$apiUrl + "/tabelapreco", this.Tabela)
            .then(response => {
                if(response.ok)
                {
                    console.log('POSTED');
                    console.log(response);
                    this.Tabela = response.body;
                    this.TabelaId = this.Tabela.tabelaPrecoId
                    // this.Tabelas.push(this.Tabela);
                    this.$redirect("/controle/tabelapreco/" + this.TabelaId);
                }
            })
        }
        else
        {
            this.$mensagem_erro("Por favor preencha o nome de tabela.")
        }
    },
    MudaValidade() {
      this.Tabela.validade = moment(this.Validade)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    Limpar() {
      this.$redirect_reload("/controle/tabelaspreco/");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/tabelapreco/")
      .then((result) => {
        this.Tabelas = result.body;
      })
      .then(this.atualiza());

    // if (this.$route.params.pesquisa != null) {
    //   this.nomeTabela = this.$route.params.pesquisa;
    // } else {
    //   this.nomeTabela = "";
    // }
    if (this.TabelaId == 0) {
      if (this.$route.params.id != null) {
        this.TabelaId = parseInt(this.$route.params.id, 10);
      }
    }
    console.log("EDITANDO:" + this.TabelaId);
    if (this.TabelaId > 0) {
      this.TabelaId = parseInt(this.TabelaId, 10);
      this.$http.get(this.$apiUrl + "/tabelapreco/" + this.TabelaId).then(
        (response) => {
          console.log("Editando:");
          console.log(response.body);

          this.Tabela = response.body;
          this.Tabela.validade = this.Validade = moment(
              this.Tabela.validade
            ).format("YYYY-MM-DD");
            this.$http
            .get(this.$apiUrl + "/tabelaprecoproduto/tabelapreco/" + this.TabelaId)
            .then((result) => {
              this.Itens = result.body;
            })
            .then(this.atualiza());
          // this.Imagens = this.Tabela.imagens;
          // this.Imagens = this.Imagens.sort(function (a, b) {
          //   return a.ordem - b.ordem;
          // });
        },
        (error) => {
          console.log("Erro ao carregar:");
          console.log(error);
        }
      );
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
