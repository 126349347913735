<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">
                        Cadastro de Filtro de Fábricas
                      </h4>
                      <p class="mb-30">
                        Tela para cadastro dos fábricantes ou distribuidores que
                        poderão ser utilizados nos filtros do produto.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nome da Fábrica</label>
                        <input
                          v-model="Nomefabrica"
                          class="form-control"
                          type="text"
                          placeholder="Nome Fabrica"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Ordem</label>
                        <input
                          v-model="Ordem"
                          class="form-control"
                          type="number"
                          placeholder="Ordem"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Visível</label>
                        <v-select
                          v-model="Statuselected"
                          @input="MudaStatus"
                          :options="Status"
                          :reduce="(nome) => nome.valor"
                          single-line
                          label="nome"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                        <label>Imagem</label>
                        <input
                          id="arquivo"
                          type="file"
                          class="form-control-file form-control height-auto"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Texto</label>
                        <quill-editor
                          placeholder="Texto"
                          v-model="Texto"
                        ></quill-editor>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <!--<button v-if="FiltrofabricaId == null || FiltrofabricaId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/filtrosfabrica/' + Nomefabrica)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>-->
                      <button
                        v-if="
                          FiltrofabricaId != null && FiltrofabricaId.length > 0
                        "
                        type="button"
                        class="btn btn-success"
                        @click="Altera()"
                        id="sa-custom-position2"
                      >
                        Alterar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        @click="Add()"
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th class="datatable-nosort">imagem</th>
                <th>Nome</th>
                <th>Ordem</th>
                <th>Visível</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="Filtrofabrica in Filtrosfabrica"
                :key="Filtrofabrica.filtroFabricaId"
              >
                <td>{{ Filtrofabrica.filtroFabricaId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/filtrofabrica/' +
                              Filtrofabrica.filtroFabricaId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Filtrofabrica)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td width="10%">
                  <img :src="$Url + Filtrofabrica.urlImg" alt="" />
                </td>
                <td>{{ Filtrofabrica.nomeFabrica }}</td>
                <td>{{ Filtrofabrica.ordem }}</td>
                <td>
                  <span v-if="Filtrofabrica.visivel">Sim</span
                  ><span v-else>Não</span>
                </td>
              </tr>
              <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
            </tbody>
          </table>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import vSelect from "vue-select";
import { VMoney } from "v-money";

export default {
  data() {
    return {
      FiltrofabricaId: this.$route.params.id,
      Visivel: null,
      Nomefabrica: "",
      Urlimg: "",
      IdiomaId: 0,
      Ordem: 0,
      ImagemFabrica: "",
      Filtrofabrica: {},
      Filtrosfabrica: [],
      Statuselected: null,
      Status: [
        { nome: "Sim", valor: true },
        { nome: "Não", valor: false },
      ],
      T: {},
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true,
      },
      Texto: "",
    };
  },
  directives: { money: VMoney },
  methods: {
    MudaStatus(value) {
      this.Visivel = value;
    },
    Add() {
      let file = document.getElementById("arquivo").files[0];
      let erroarquivo = "";
      let URLIMAGEM = "";
      if (file != null) {
        var ext = file.name.split(".").pop();

        if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
          erroarquivo =
            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
        } else {
          erroarquivo = "";
        }
      }

      let _Filtrofabrica = {
        filtrofabricaid: 0,
        visivel: null,
        nomefabrica: null,
        urlimg: this.ImagemFabrica,
        idiomaId: 0,
        ordem: 0,
        texto: this.Texto,
      };

      //validações
      console.log(this.Nomefabrica.length);
      if (this.Nomefabrica.length <= 0) {
        this.$mensagem_normal("Por favor preencha o nome");
      } else if (this.Visivel == null) {
        this.$mensagem_normal("Por favor selecione o visível");
      } else if (erroarquivo != null && erroarquivo.length > 0) {
        this.$mensagem_normal(erroarquivo);
      } else {
        _Filtrofabrica.visivel = this.Visivel;
        _Filtrofabrica.nomefabrica = this.Nomefabrica;
        _Filtrofabrica.urlimg = this.Urlimg;
        _Filtrofabrica.idiomaId = this.IdiomaId;
        _Filtrofabrica.ordem = parseInt(this.Ordem, 10);

        let data = new FormData();
        if (file != null) {
          data.append("arquivo", file);
          console.log(file);
          this.$http.post(this.$apiUrl + "/filtrofabrica/upload", data).then(
            (response3) => {
              // get body data
              URLIMAGEM = response3.body;
              //console.log("SUBIU AQUI: " + URLIMAGEM);

              this.CriarUsuarioComImagem(_Filtrofabrica, URLIMAGEM);
            },
            (response3) => {
              // error callback
              this.$mensagem_erro(response3.body);
            }
          );
        } else {
          this.CriarUsuarioComImagem(_Filtrofabrica, URLIMAGEM);
        }
      }
    },
    CriarUsuarioComImagem(_filtrofabrica, URL) {
      //console.log("_filtrofabrica:");
      //console.log(_filtrofabrica);
      let ID = _filtrofabrica.idiomaId;
      _filtrofabrica.urlImg = URL;

      //chama o post da api
      this.$http
        .post(this.$apiUrl + "/filtrofabrica/" + ID, _filtrofabrica)
        .then(
          (response2) => {
            // get body data
            _filtrofabrica = response2.body;
            this.$router.go(this.$router.currentRoute);
          },
          (response2) => {
            _filtrofabrica = response2.body;
            this.$mensagem_normal(_filtrofabrica);
          }
        );
    },
    Remove(dadosRemover) {
      //chama o delete da api
      console.log(dadosRemover.filtroFabricaId);
      this.$http
        .delete(this.$apiUrl + "/filtrofabrica/" + dadosRemover.filtroFabricaId)
        .then(
          () => {
            // get body data
            this.$router.go(this.$router.currentRoute);
          },
          (response) => {
            // error callback
            console.log(response);
          }
        );
    },
    Altera() {
      let file = document.getElementById("arquivo").files[0];
      //console.log("ARQ")

      let erroarquivo = "";
      if (file != null) {
        var ext = file.name.split(".").pop();

        if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
          erroarquivo =
            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
        } else {
          erroarquivo = "";
        }
      }

      let _Filtrofabrica = {
        filtrofabricaid: 0,
        visivel: null,
        nomefabrica: null,
        urlimg: this.ImagemFabrica,
        idiomaId: 0,
        ordem: 0,
        texto: this.Texto,
      };

      //validações
      if (this.Nomefabrica.length <= 0) {
        this.$mensagem_normal("Por favor preencha o nome");
      } else if (this.Visivel == null) {
        this.$mensagem_normal("Por favor selecione o visível");
      } else if (erroarquivo != null && erroarquivo.length > 0) {
        this.$mensagem_normal(erroarquivo);
      } else {
        _Filtrofabrica.visivel = this.Visivel;
        _Filtrofabrica.nomefabrica = this.Nomefabrica;
        _Filtrofabrica.idiomaId = this.IdiomaId;
        _Filtrofabrica.ordem = parseInt(this.Ordem, 10);
        _Filtrofabrica.filtrofabricaid = parseInt(this.FiltrofabricaId, 10);

        let data = new FormData();
        if (file != null) {
          data.append("arquivo", file);
          console.log(file);
          this.$http.post(this.$apiUrl + "/filtrofabrica/upload", data).then(
            (response3) => {
              // get body data
              _Filtrofabrica.urlimg = response3.body;
              this.AtualizarUsuarioComImagem(
                _Filtrofabrica,
                _Filtrofabrica.urlimg
              );
            },
            (response3) => {
              // error callback
              this.$mensagem_erro(response3.body);
            }
          );
        } else {
          this.AtualizarUsuarioComImagem(_Filtrofabrica, this.ImagemFabrica);
        }
      }
    },
    AtualizarUsuarioComImagem(_filtrofabrica, URL) {
      if (URL == null) URL = "";
      let ID = _filtrofabrica.filtrofabricaid;
      if (URL != null && URL.length > 0) _filtrofabrica.urlImg = URL;
      //chama o post da api
      this.$http
        .put(this.$apiUrl + "/filtrofabrica/" + ID, _filtrofabrica)
        .then(
          (response2) => {
            // get body data
            _filtrofabrica = response2.body;
            this.$mensagem_sucesso(
              "Filtro Fabrica #" +
                this.FiltrofabricaId +
                " alterado com sucesso"
            );
            this.Limpar();
          },
          async (response2) => {
            _filtrofabrica = response2.body;
            // error callback
            this.$mensagem_normal(_filtrofabrica);
          }
        );
    },
    Pesquisa() {
      try {
        if (this.Nomefabrica.length <= 0) {
          this.$mensagem_normal("Por favor preencha nome da fabrica");
        } else {
          this.Filtrosfabrica = [];
          let _Filtrosfabrica = {
            Nomefabrica: this.Nomefabrica,
          };
          this.$http
            .post(this.$apiUrl + "/filtrosfabrica/busca", _Filtrosfabrica)
            .then((res) => res.json())
            .then((tiu) => (this.Filtrosfabrica = tiu))
            .then(this.atualiza());
        }
      } catch {
        this.$mensagem_normal("Nenhum resultado encontrado");
      }
    },
    Limpar() {
      this.$redirect_reload("/controle/filtrofabrica");
      this.$http
        .get(this.$apiUrl + "/filtrofabrica")
        .then((res) => res.json())
        .then((moe) => (this.Filtrosfabrica = moe))
        .then(this.atualiza());
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: false,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  created() {
    if (this.$route.params.pesquisa != null) {
      this.Nomefabrica = this.$route.params.pesquisa;
    } else {
      this.Nomefabrica = "";
    }

    if (this.FiltrofabricaId == null) {
      this.FiltrofabricaId = "";
    }

    //chama o get da api this.$apiUrl
    if (this.FiltrofabricaId != null && this.FiltrofabricaId.length > 0) {
      this.$http
        .get(this.$apiUrl + "/filtrofabrica/" + this.FiltrofabricaId)
        .then((res) => res.json())
        .then((idi) => {
          this.Filtrofabrica = idi;

          this.Nomefabrica = this.Filtrofabrica.nomeFabrica;
          this.Ordem = this.Filtrofabrica.ordem;
          this.Statuselected = this.Filtrofabrica.visivel;
          this.ImagemFabrica = this.Filtrofabrica.urlImg;
          this.Visivel = this.Filtrofabrica.visivel;
          this.Texto = this.Filtrofabrica.texto;
        });
    } else if (this.Nomefabrica != null && this.Nomefabrica.length > 0) {
      this.Pesquisa();
    } else {
      this.$http
        .get(this.$apiUrl + "/filtrofabrica")
        .then((res) => res.json())
        .then((moe) => (this.Filtrosfabrica = moe))
        .then(this.atualiza());
    }
  },
  mounted() {},
  components: {
    vSelect,
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
