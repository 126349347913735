<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro das exceções de horário</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das exceções dos horários de entrega para o agendamento do cliente.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-3 mb-15">
                                            <span>Data Exceção:</span>
                                            <input v-model="DataExcecao" class="form-control" placeholder="" type="date">
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Qtde</label>
                                                <input v-model="Qtde" class="form-control" type="number" placeholder="Qtde">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Atraso Horas</label>
                                                <input v-model="AtrasoHs" class="form-control" type="number" placeholder="">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <v-select v-model="Estadoselected" @input="MudaEstado"
                                                          :options="Estados"
                                                          :value="Estados.EstadoId"
                                                          single-line
                                                          label="nomeEstado" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Cidade</label>
                                                <v-select v-model="Cidadeselected" @input="MudaCidade"
                                                          :options="Cidades"
                                                          :value="Cidades.CidadeId"
                                                          single-line
                                                          label="nomeCidade" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Bairro</label>
                                                <v-select v-model="Bairroselected" @input="MudaBairro"
                                                          :options="Bairros"
                                                          :value="Bairros.BairroId"
                                                          single-line
                                                          label="nomeBairro" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Hora Inicial</span>
                                            <v-select v-model="HoraIniselected" @input="MudaHoraIni"
                                                      :options="HoraIni"
                                                      :reduce="nome => nome.valor"
                                                      single-line
                                                      label="nome" class="style-chooser"></v-select>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Min Inicial</span>
                                            <v-select v-model="MinIniselected" @input="MudaMinIni"
                                                      :options="MinIni"
                                                      :reduce="minini => minini.valor"
                                                      single-line
                                                      label="minini" class="style-chooser"></v-select>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Hora Final</span>
                                            <v-select v-model="HoraFimselected" @input="MudaHoraFim"
                                                      :options="HoraFim"
                                                      :reduce="horafim => horafim.valor"
                                                      single-line
                                                      label="horafim" class="style-chooser"></v-select>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Min Final</span>
                                            <v-select v-model="MinFimselected" @input="MudaMinFim"
                                                      :options="MinFim"
                                                      :reduce="minfim => minfim.valor"
                                                      single-line
                                                      label="minfim" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="EntregaExcecaoId != null && EntregaExcecaoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th class="datatable-nosort">Ação</th>
                                <th>Data</th>
                                <th>Hora inicial</th>
                                <th>Hora Final</th>
                                <th>Qtde</th>
                                <th>Atraso</th>
                                <th>Estado</th>
                                <th>Cidade</th>
                                <th>Bairro</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="EntregaExcecao in EntregasExcecao" :key="EntregaExcecao.entregaExcecaoId">
                                 <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/entregaexcecao/' + EntregaExcecao.entregaExcecaoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(EntregaExcecao)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ currentDateTime(EntregaExcecao.dataExcecao) }}</td>
                                <td>{{ validaHora(EntregaExcecao.horaIni)  + ':' +  validaHora(EntregaExcecao.minIni) }}</td>
                                <td>{{ validaHora(EntregaExcecao.horaFim) + ':' +  validaHora(EntregaExcecao.minFim) }}</td>
                                <td>{{ validaHora(EntregaExcecao.qtde) }}</td>
                                <td>{{ EntregaExcecao.atrasoHs }}</td>
                                <td>
                                    <span v-if="EntregaExcecao.estado == null ">
                                        Todos
                                    </span><span v-else> {{ EntregaExcecao.estado.nomeEstado }}</span>
                                </td>
                                <td>
                                    <span v-if="EntregaExcecao.cidade == null">
                                        Todos
                                    </span><span v-else>{{ EntregaExcecao.cidade.nomeCidade }}</span>
                                </td>
                                <td>
                                    <span v-if="EntregaExcecao.bairro == null">
                                        Todos
                                    </span><span v-else>{{ EntregaExcecao.bairro.nomeBairro }}</span>
                                </td>
                                <td><span v-if="EntregaExcecao.status">Ativo</span><span v-else>Inativo</span></td>
                               
                            </tr>
                            <!-- <tr>
              <td
                valign="top"
                v-if="Idiomas.length <= 0"
                colspan="5"
                class="dataTables_empty"
              >
                Nenhum resultado encontrado
              </td>
            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import dateFormat from "./../../public/date.format.js";
    import moment from 'moment';

    export default {
        data() {
            return {
                EntregaExcecaoId: this.$route.params.id,
                DataExcecao: null,
                Ativo: null,
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                Qtde: 0,
                AtrasoHs: 0,
                PaisId: 0,
                Pais: {},
                Paises: [],
                EstadoId: 0,
                Estados: [],
                Estadoselected: null,
                CidadeId: 0,
                Cidades: [],
                Cidadeselected: null,
                Bairros: [],
                BairroId: 0,
                Bairroselected: null,
                HoraIniselected: null,
                HoraIni: [
                    { nome: "06", valor: 6 },
                    { nome: "07", valor: 7 },
                    { nome: "08", valor: 8 },
                    { nome: "09", valor: 9 },
                    { nome: "10", valor: 10 },
                    { nome: "11", valor: 11 },
                    { nome: "12", valor: 12 },
                    { nome: "13", valor: 13 },
                    { nome: "14", valor: 14 },
                    { nome: "15", valor: 15 },
                    { nome: "16", valor: 16 },
                    { nome: "17", valor: 17 },
                    { nome: "18", valor: 18 },
                    { nome: "19", valor: 19 },
                    { nome: "20", valor: 20 },
                    { nome: "21", valor: 21 },
                    { nome: "22", valor: 22 },
                    { nome: "23", valor: 23 },
                ],
                HoraFimselected: null,
                HoraFim: [
                    { horafim: "06", valor: 6 },
                    { horafim: "07", valor: 7 },
                    { horafim: "08", valor: 8 },
                    { horafim: "09", valor: 9 },
                    { horafim: "10", valor: 10 },
                    { horafim: "11", valor: 11 },
                    { horafim: "12", valor: 12 },
                    { horafim: "13", valor: 13 },
                    { horafim: "14", valor: 14 },
                    { horafim: "15", valor: 15 },
                    { horafim: "16", valor: 16 },
                    { horafim: "17", valor: 17 },
                    { horafim: "18", valor: 18 },
                    { horafim: "19", valor: 19 },
                    { horafim: "20", valor: 20 },
                    { horafim: "21", valor: 21 },
                    { horafim: "22", valor: 22 },
                    { horafim: "23", valor: 23 },
                ],
                MinIniselected: null,
                MinIni: [
                    { minini: "00", valor: 0 },
                    { minini: "15", valor: 15 },
                    { minini: "30", valor: 30 },
                    { minini: "45", valor: 45 },
                ],
                MinFimselected: null,
                MinFim: [
                    { minfim: "00", valor: 0 },
                    { minfim: "15", valor: 15 },
                    { minfim: "30", valor: 30 },
                    { minfim: "45", valor: 45 },
                ],
                EntregaExcecao: {},
                EntregasExcecao: [],
                T: {},
            };
        },
        methods: {
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                this.CidadeId = 0;
                this.Cidades = [];
                this.Cidadeselected = null;
                this.$http
                    .get(this.$apiUrl + "/cidades/ativosEstados/" + parseInt(this.EstadoId))
                    .then((res) => res.json())
                    .then((tiu) => (this.Cidades = tiu));
            },
            MudaCidade(value) {
                this.CidadeId = value.cidadeId;
                this.BairroId = 0;
                this.Bairros = [];
                this.Bairroselected = null;
                this.$http
                    .get(this.$apiUrl + "/bairro/ativosBairros/" + parseInt(this.CidadeId))
                    .then((res) => res.json())
                    .then((tiu) => (this.Bairros = tiu));
            },
            MudaBairro(value) {
                this.BairroId = value.bairroId;
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            MudaHoraIni(value) {
                this.HoraIniselected = value;
            },
            MudaMinIni(value) {
                this.MinIniselected = value;
            },
            MudaHoraFim(value) {
                this.HoraFimselected = value;
            },
            MudaMinFim(value) {
                this.MinFimselected = value;
            },
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
            Add() {
                let _EntregaExcecao = {
                    entregaExcecaoId: 0,
                    dataExcecao: null,
                    status: null,
                    qtde: 0,
                    atrasoHs: 0,
                    pais: { PaisId: 1 },
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    bairro: { BairroId: this.BairroId },
                    HoraIni: 0,
                    HoraFim: 0,
                    MinIni: 0,
                    MinFim: 0,
                };

                //validações
                if (parseFloat(this.Qtde) < 0) {
                    this.$mensagem_normal("Quantidade não pode ser menor que zero");
                } else if (this.DataExcecao == null) {
                    this.$mensagem_normal("Por favor preencha data");
                } else if (this.Statuselected == null) {
                    this.$mensagem_normal("Por favor selecione o Ativo");
                } else if (this.HoraIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Inicial");
                } else if (this.MinIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Inicial");
                } else if (this.HoraFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Final");
                } else if (this.MinFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Final");
                } else if (parseInt(this.HoraIniselected, 10) > parseInt(this.HoraFimselected, 10)) {
                    this.$mensagem_normal("Hora Inicial deve ser menor que Hora Final");
                } else if ((parseInt(this.HoraIniselected, 10) == parseInt(this.HoraFimselected, 10)) && (parseInt(this.MinIniselected, 10) >= parseInt(this.MinFimselected, 10))) {
                    this.$mensagem_normal("Minuto Inicial deve ser menor que Minuto Final");
                } else {
                    _EntregaExcecao.dataExcecao = this.DataExcecao;
                    _EntregaExcecao.status = this.Ativo;
                    _EntregaExcecao.qtde = parseInt(this.Qtde, 10);
                    _EntregaExcecao.atrasoHs = parseInt(this.AtrasoHs, 10);
                    _EntregaExcecao.HoraIni = parseInt(this.HoraIniselected, 10);
                    _EntregaExcecao.HoraFim = parseInt(this.HoraFimselected, 10);
                    _EntregaExcecao.MinIni = parseInt(this.MinIniselected, 10);
                    _EntregaExcecao.MinFim = parseInt(this.MinFimselected, 10);

                    //chama o post da api
                    console.log(_EntregaExcecao);
                    this.$http.post(this.$apiUrl + "/entregaexcecao/", _EntregaExcecao).then(
                        response2 => {
                            // get body data
                            _EntregaExcecao = response2.body;
                            this.$redirect_reload("/controle/entregaexcecao");
                        },
                        response2 => {
                            _EntregaExcecao = response2.body;
                            // error callback
                            this.$mensagem_normal(_EntregaExcecao);
                        }
                    );
                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/entregaexcecao/" + DadosRemover.entregaExcecaoId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/entregaexcecao");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _EntregaExcecao = {
                    entregaExcecaoId: 0,
                    dataExcecao: null,
                    status: null,
                    qtde: 0,
                    atrasoHs: 0,
                    pais: { PaisId: 1 },
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    bairro: { BairroId: this.BairroId },
                    HoraIni: 0,
                    HoraFim: 0,
                    MinIni: 0,
                    MinFim: 0,
                };

                //validações
                if (parseFloat(this.Qtde) < 0) {
                    this.$mensagem_normal("Quantidade não pode ser menor que zero");
                } else if (this.DataExcecao == null) {
                    this.$mensagem_normal("Por favor preencha data");
                } else if (this.Statuselected == null) {
                    this.$mensagem_normal("Por favor selecione o Ativo");
                } else if (this.HoraIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Inicial");
                } else if (this.MinIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Inicial");
                } else if (this.HoraFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Final");
                } else if (this.MinFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Final");
                } else if (parseInt(this.HoraIniselected, 10) > parseInt(this.HoraFimselected, 10)) {
                    this.$mensagem_normal("Hora Inicial deve ser menor que Hora Final");
                } else if ((parseInt(this.HoraIniselected, 10) == parseInt(this.HoraFimselected, 10)) && (parseInt(this.MinIniselected, 10) >= parseInt(this.MinFimselected, 10))) {
                    this.$mensagem_normal("Minuto Inicial deve ser menor que Minuto Final");
                } else {

                    _EntregaExcecao.dataExcecao = this.DataExcecao;
                    _EntregaExcecao.status = this.Ativo;
                    _EntregaExcecao.qtde = parseInt(this.Qtde, 10);
                    _EntregaExcecao.atrasoHs = parseInt(this.AtrasoHs, 10);
                    _EntregaExcecao.HoraIni = parseInt(this.HoraIniselected, 10);
                    _EntregaExcecao.HoraFim = parseInt(this.HoraFimselected, 10);
                    _EntregaExcecao.MinIni = parseInt(this.MinIniselected, 10);
                    _EntregaExcecao.MinFim = parseInt(this.MinFimselected, 10);
                    _EntregaExcecao.entregaExcecaoId = parseInt(this.EntregaExcecaoId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/entregaexcecao/" + _EntregaExcecao.entregaExcecaoId, _EntregaExcecao)
                        .then(
                            response2 => {
                                // get body data
                                _EntregaExcecao = response2.body;
                                console.log(_EntregaExcecao);
                                this.$mensagem_sucesso(
                                    "Entrega Exceção #" + this.EntregaExcecaoId + " alterada com sucesso"
                                );
                                this.$redirect("/controle/entregaexcecao");
                            },
                            async response2 => {
                                _EntregaExcecao = response2.body;
                                // error callback
                                this.$mensagem_normal(_EntregaExcecao);
                            }
                        );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/entregaexcecao");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: false,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.EntregaExcecaoId == null) {
                this.EntregaExcecaoId = "";
            }

            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res) => res.json())
                .then((tiu) => {
                    this.Estados = tiu
                });

            //chama o get da api this.$apiUrl
            if (this.EntregaExcecaoId != null && this.EntregaExcecaoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/entregaexcecao/" + this.EntregaExcecaoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.EntregaExcecao = idi;

                        //carrega os campos

                        if (this.EntregaExcecao.estado != null) {
                            this.EstadoId = this.EntregaExcecao.estado.estadoId;
                            this.Estadoselected = this.EntregaExcecao.estado;

                            this.$http
                                .get(this.$apiUrl + "/cidades/ativosEstados/" + parseInt(this.EstadoId))
                                .then((res) => res.json())
                                .then((tiu) => {
                                    this.Cidades = tiu
                                });

                            if (this.EntregaExcecao.cidade != null) {
                                this.CidadeId = this.EntregaExcecao.cidade.cidadeId;
                                this.Cidadeselected = this.EntregaExcecao.cidade;

                                this.$http
                                    .get(this.$apiUrl + "/Bairro/ativosCidades/" + parseInt(this.CidadeId))
                                    .then((res2) => res2.json())
                                    .then((tiu2) => {
                                        this.Bairros = tiu2

                                        if (this.EntregaExcecao.Bairro != null) {
                                            this.BairroId = this.EntregaExcecao.Bairro.bairroId;
                                            this.Bairroselected = this.EntregaExcecao.Bairro;
                                        }
                                        else {
                                            this.BairroId = 0;
                                            this.Bairroselected = null;
                                        }
                                    });
                            }
                            else {
                                this.CidadeId = 0;
                                this.Cidadeselected = null;
                                this.BairroId = 0;
                                this.Bairroselected = null;
                            }
                        }
                        else {
                            this.EstadoId = 0;
                            this.Estadoselected = null;
                            this.CidadeId = 0;
                            this.Cidadeselected = null;
                            this.BairroId = 0;
                            this.Bairroselected = null;
                        }

                        this.DataExcecao = dateFormat(this.EntregaExcecao.dataExcecao, "yyyy-mm-dd");
                        this.Qtde = this.EntregaExcecao.qtde;
                        this.AtrasoHs = this.EntregaExcecao.atrasoHs;
                        this.HoraIniselected = this.EntregaExcecao.horaIni;
                        this.MinIniselected = this.EntregaExcecao.minIni;
                        this.HoraFimselected = this.EntregaExcecao.horaFim;
                        this.MinFimselected = this.EntregaExcecao.minFim;
                        this.Statuselected = this.EntregaExcecao.status;
                        this.Ativo = this.EntregaExcecao.status;

                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/entregaexcecao")
                    .then((res) => res.json())
                    .then((moe) => {
                        this.EntregasExcecao = moe.sort(function (a, b) {
                            return a.entregaExcecaoId - b.entregaExcecaoId;
                        }).reverse();
                    })
                    .then(this.atualiza());
            }
        },
        mounted() {
        },
        components: {
            vSelect,
        },
    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
