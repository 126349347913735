<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Tela Mensagens Avulsas</h4>
                                            <p class="mb-30">
                                                Tela para visualizar e imprimir as mensagens avulsas.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <div class="card-box mb-30">
                                    <h2 class="h4 pd-20">Lista</h2>
                                    <table class="table hover data-table nowrap">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th class="datatable-nosort">Visualizar</th>
                                                <th class="datatable-nosort">Excluir</th>
                                                <th>Nome De</th>
                                                <th>Nome Para</th>
                                                <th>Telefone Recebe</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="mensagem in Mensagens" :key="mensagem.mensagemAvulsaId">
                                                <td>{{ mensagem.mensagemAvulsaId }}</td>
                                                  <td>
                                                    <a @click.prevent="CarregaModal(mensagem)"><i class="dw dw-edit2"></i> mensagem</a>
                                                </td>
                                                <td>
                                                    <a @click.prevent="Remove(mensagem)" id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                                </td>
                                                <td>{{ mensagem.nomeDe }} </td>
                                                <td>{{ mensagem.nomePara }} </td>
                                                <td>{{ mensagem.telefoneRecebe }} </td>
                                              
                                            </tr>
                                            <!--<tr>
                                    <td valign="top"
                                        v-if="Configoperadoras.length <= 0"
                                        colspan="5"
                                        class="dataTables_empty">
                                        Nenhum resultado encontrado
                                    </td>
                                </tr>-->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal v-model="showModal_mensagem" title="">
                    <section class="section-modal">
                        <div class="container">
                            <!-- Instruções -->
                            <div class="row justify-content-center">
                                <div class="col-lg-9 col-xl-8">
                                          <div class="row">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>De</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="De"
                                        v-model="Nomede"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Para</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Para"
                                        v-model="NomePara"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Telefone Destinatário</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Telefone"
                                        v-model="TelefoneRecebe"
                                      />
                                    </div>
                                  </div>
                                </div>
                                    <div  ref="content" class="editar-cartao mt-4 text-center">
                                        <!--aqui regra do preenchimento do cartão -->
                                        <div v-if="urlImagem != ''" class="imagemCartao" :style="{ backgroundImage: 'url(' + $baseUrl + urlImagem +  ')' }">
                                            <textarea v-model="texto" class="textareacartao" maxlength="1000" style="font-family: Handlee !important;text-align:center;padding-right:40px;"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button class="btn btn-info" @click="Altera()">Alterar</button>
                                    <!-- <button class="btn btn-success" @click="downloadWithCSS()">Gerar PDF</button> -->
                                    <a target="_blank" :href="'#/controle/imprimirmensagemavulsa/' + mensagemAvulsaId" class="btn btn-success">Gerar PDF</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>
                <!-- Default Basic Forms End -->
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import VueModal from '@kouts/vue-modal'
    import jsPDF from 'jspdf'
    import domtoimage from "dom-to-image";

    export default {
        data() {
            return {
                mensagemAvulsaId:0,
                Mensagens: [],
                mensagem: {},
                showModal_mensagem: false,
                urlImagem: '',
                texto: '',
                Nomede: '',
                NomePara: '',
                TelefoneRecebe:'',
                T: {},
            };
        },
        methods: {
            downloadWithCSS() {
                domtoimage
                    .toPng(this.$refs.content)
                    .then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl;
                        const doc = new jsPDF({
                            orientation: "portrait",
                            // unit: "pt",
                            //format: [1772, 1240]
                        });
                        //doc.addImage(img, "JPEG", 22, -7, 165, 110);
                        //doc.addImage(img, "JPEG", 24, -5, 162, 109);
                        //doc.addImage(img, "JPEG", 25, 0, 160, 107);
                        //doc.addImage(img, "JPEG", 25, -5, 160, 110, '', 'NONE');
                        doc.addImage(img, "JPEG", 15, -5, 180, 110, '', 'NONE');
                        //doc.addImage(img, "JPEG", -10, -7, 0, 0, '', "false", 90);
                        const date = new Date();
                        const filename =
                            "timechart_" +
                            date.getFullYear() +
                            ("0" + (date.getMonth() + 1)).slice(-2) +
                            ("0" + date.getDate()).slice(-2) +
                            ("0" + date.getHours()).slice(-2) +
                            ("0" + date.getMinutes()).slice(-2) +
                            ("0" + date.getSeconds()).slice(-2) +
                            ".pdf";
                        doc.save(filename);
                    })
                    .catch(function (error) {
                        console.error("ocorreu uma falha!", error);
                    });
            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/mensagemavulsa/" + dadosRemover.mensagemAvulsaId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _mensagemavul = {
                    mensagemAvulsaId: parseInt(this.mensagemAvulsaId,10),
                    texto: this.texto,
                    urlImagem: this.urlImagem,
                    Nomede: this.Nomede,
                    NomePara: this.NomePara,
                    TelefoneRecebe: this.TelefoneRecebe,
                }

                this.$http
                    .put(this.$apiUrl + "/mensagemavulsa/" + _mensagemavul.mensagemAvulsaId, _mensagemavul)
                    .then(
                        response2 => {
                            // get body data
                            //_mensagemavul = response2.body;
                            console.log(response2);
                            this.$mensagem_sucesso(
                                "Mensagem #" + this.mensagemAvulsaId + " alterada com sucesso"
                            );
                            this.$http
                            .get(this.$apiUrl + "/mensagemavulsa")
                            .then((res) => res.json())
                            .then((moe) => {
                                this.Mensagens = moe;
                                if(this.Mensagens.length > 0){
                                    this.Mensagens.reverse();
                                }
                            })
                            .then(this.atualiza());
                        },
                        async response2 => {
                            _mensagemavul = response2.body;
                            // error callback
                            this.$mensagem_normal(_mensagemavul);
                        }
                    );
            },
            Limpar() {
                this.$redirect_reload("/controle/mensagemavulsa");
            },
            CarregaModal(value) {
                this.urlImagem = value.urlImagem;
                this.texto = value.texto;
                this.mensagemAvulsaId = value.mensagemAvulsaId;
                this.Nomede = value.nomeDe;
                this.NomePara = value.nomePara;
                this.TelefoneRecebe = value.telefoneRecebe;
                this.showModal_mensagem = true;
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-tabled").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            //carrega operadoras
            this.$http
                .get(this.$apiUrl + "/mensagemavulsa")
                .then((res) => res.json())
                .then((moe) => {
                    this.Mensagens = moe;
                    if(this.Mensagens.length > 0){  
                        this.Mensagens.reverse();
                    }
                })
                .then(this.atualiza());

            if (this.MensagemAvulsaId == null) {
                this.MensagemAvulsaId = "";
            }
        },
        mounted() {

        },
        components: {
            'Modal': VueModal,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button {
        margin: 10px;
    }

    @import '/css/modais.css';


   /* @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }*/
    
    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 89%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 18.2px;
            padding-left: 6.5%;
        }

        .imagemCartao {
            height: 400px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>
