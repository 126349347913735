<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Serviços dos Planos</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos serviços dos planos que poderão ser utilizadas no sistema.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <label>Nome Serviço</label>
                                                <input v-model="NomeServico" class="form-control" type="text" placeholder="Nome Serviço">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="ListaServicosPlanoId != null && ListaServicosPlanoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Serviços</th>
                                <th>Visível</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Servico in Servicos" :key="Servico.listaServicosPlanoId">
                                <td>{{ Servico.listaServicosPlanoId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/planosservicocadastro/' + Servico.listaServicosPlanoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Servico)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Servico.nomeServico }}</td>
                                <td><span v-if="Servico.ativo">Sim</span><span v-else>Não</span></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                ListaServicosPlanoId: this.$route.params.id,
                Ativo: null,
                NomeServico: '',
                Servico: {},
                Servicos: [],
                Statuselected: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                //validações
                if (this.NomeServico.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome do serviço");
                } else if (this.Statuselected == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                    let _ListaServicoPlanoId = {
                        ListaServicosPlanoId: 0,
                        Ativo: this.Statuselected,
                        NomeServico: this.NomeServico,
                    }

                    console.log(_ListaServicoPlanoId)
                    //chama o post da api
                    this.$http
                        .post(this.$apiUrl + "/ListaServicosPlano/", _ListaServicoPlanoId)
                        .then(
                            response2 => {
                                // get body data
                                _ListaServicoPlanoId = response2.body;
                                this.$mensagem_sucesso(
                                    "Serviço #" + this.ListaServicosPlanoId + " incluído com sucesso"
                                );
                                this.Limpar();
                            },
                            async response2 => {
                                _ListaServicoPlanoId = response2.body;
                                // error callback
                                this.$mensagem_normal(_ListaServicoPlanoId);
                            }
                        );

                }
            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/ListaServicosPlano/" + dadosRemover.listaServicosPlanoId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                //validações
                //validações
                if (this.NomeServico.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome do serviço");
                } else if (this.Statuselected == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                    let _ListaServicoPlanoId = {
                        ListaServicosPlanoId: parseInt(this.ListaServicosPlanoId,10),
                        Ativo: this.Statuselected,
                        NomeServico: this.NomeServico,
                    }

                    console.log(_ListaServicoPlanoId)

                    this.$http.put(this.$apiUrl + "/ListaServicosPlano/" + _ListaServicoPlanoId.ListaServicosPlanoId, _ListaServicoPlanoId).then(
                        (response3) => {
                            // get body data
                            _ListaServicoPlanoId = response3.body;
                            this.$mensagem_sucesso(
                                "Serviço #" + this.ListaServicosPlanoId + " alterado com sucesso"
                            );
                            this.Limpar();
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/planosservicocadastro");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.ListaServicosPlanoId == null) {
                this.ListaServicosPlanoId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.ListaServicosPlanoId != null && this.ListaServicosPlanoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/ListaServicosPlano/" + this.ListaServicosPlanoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Servico = idi;

                        this.NomeServico = this.Servico.nomeServico;
                        this.Statuselected = this.Servico.ativo;
                        this.Ativo = this.Servico.ativo;
                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/ListaServicosPlano")
                    .then((res) => res.json())
                    .then((moe) => (this.Servicos = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
