<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Tela Fotos Avulsas</h4>
                                            <p class="mb-30">
                                                Tela para visualizar e imprimir as fotos avulsas.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <div class="card-box mb-30">
                                    <h2 class="h4 pd-20">Lista</h2>
                                    <table class="table hover data-table nowrap">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th class="datatable-nosort">Visualizar</th>
                                                <th class="datatable-nosort">Excluir</th>
                                                <th>Nome De</th>
                                                <th>Nome Para</th>
                                                <th>Telefone Recebe</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="foto in Fotos" :key="foto.fotoAvulsaId">
                                                <td>{{ foto.fotoAvulsaId }}</td>
                                                <td>
                                                    <a @click.prevent="CarregaModal(foto)"><i class="dw dw-edit2"></i> Foto</a>
                                                </td>
                                                <td>
                                                    <a @click.prevent="Remove(foto)" id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                                </td>
                                                <td>{{ foto.nomeDe }} </td>
                                                <td>{{ foto.nomePara }} </td>
                                                <td>{{ foto.telefoneRecebe }} </td>
                                                
                                            </tr>
                                            <!--<tr>
                                    <td valign="top"
                                        v-if="Configoperadoras.length <= 0"
                                        colspan="5"
                                        class="dataTables_empty">
                                        Nenhum resultado encontrado
                                    </td>
                                </tr>-->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal v-model="showModal_mensagem" title="">
                    <section class="section-modal">
                        <div class="container">
                            <!-- Instruções -->
                            <div class="row justify-content-center">
                                <div class="col-lg-8 col-xl-6 text-center">
                                    <div class="editar-cartao mt-3" >
                                        <img id="imagemCheck" ref="content" v-if="FotoCarregada != null" :src="$baseUrl + FotoCarregada" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <a class="btn btn-success" :href="'#/controle/imprimirimagemavulsa/' + FotoAvulsaId" target="_blank">Gerar PDF</a>
                                    <!-- <button class="btn btn-success" @click="downloadWithCSS()">Gerar PDF</button> -->
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>
                <!-- Default Basic Forms End -->
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import VueModal from '@kouts/vue-modal'
    import jsPDF from 'jspdf'
    import domtoimage from "dom-to-image";

    export default {
        data() {
            return {
                FotoAvulsaId:0,
                Fotos: [],
                foto: {},
                showModal_mensagem: false,
                texto: '',
                Nomede: '',
                NomePara: '',
                TelefoneRecebe: '',
                FotoCarregada:'',
                T: {},
            };
        },
        methods: {
            downloadWithCSS() {
                domtoimage
                    .toPng(this.$refs.content)
                    .then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl;

                           const doc = new jsPDF({
                            orientation: "portrait",
                            // unit: "pt",
                            //format: [1772, 1240]
                        });

                        var elemento = document.getElementById("imagemCheck");
                        if(elemento != null)
                        {
                            console.log("clientWidth:" + elemento.clientWidth + "    clientHeight:" + elemento.clientHeight);
                            if(elemento.clientHeight > elemento.clientWidth)
                            {
                                // alert("vert");
                                //vertical
                                doc.addImage(img, "JPEG", 178, -48, 97, 146,'', 'NONE', 90);
                                //doc.addImage(img, "JPEG", 180, -49, 97, 146,'', 'NONE', 90);
                                //181
                                //espaco esquerda / espaco cima / altura(flipped) / largura(flipped)
                                
                                //doc.addImage(img, "JPEG", 180, -50, 100, 153,'', 'NONE', 90);
                                //doc.addImage(img, "JPEG", 185, -52, 107, 160,'', 'NONE', 90);
                            }
                            else
                            {
                              //horizontal 
                              //31
                                doc.addImage(img, "JPEG", 31.5, 0, 148, 99, '', 'NONE');
                            }
                        }

                        const date = new Date();
                        const filename =
                            "timechart_" +
                            date.getFullYear() +
                            ("0" + (date.getMonth() + 1)).slice(-2) +
                            ("0" + date.getDate()).slice(-2) +
                            ("0" + date.getHours()).slice(-2) +
                            ("0" + date.getMinutes()).slice(-2) +
                            ("0" + date.getSeconds()).slice(-2) +
                            ".pdf";
                        doc.save(filename);
                    })
                    .catch(function (error) {
                        console.error("ocorreu uma falha!", error);
                    });
            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/fotoavulsa/" + dadosRemover.fotoAvulsaId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Limpar() {
                this.$redirect_reload("/controle/fotoavulsa");
            },
            CarregaModal(value) {
                this.FotoCarregada = value.urlImagem;
                this.texto = value.texto;
                this.FotoAvulsaId = value.fotoAvulsaId;
                this.Nomede = value.nomeDe;
                this.NomePara = value.nomePara;
                this.TelefoneRecebe = value.telefoneRecebe;
                this.showModal_mensagem = true;
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-tabled").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            //carrega operadoras
            this.$http
                .get(this.$apiUrl + "/fotoavulsa")
                .then((res) => res.json())
                .then((moe) => {
                    this.Fotos = moe;
                    if(this.Fotos.length > 0){  
                        this.Fotos.reverse();
                    }
                })
                .then(this.atualiza());

            if (this.FotoAvulsaId == null) {
                this.FotoAvulsaId = "";
            }
        },
        mounted() {

        },
        components: {
            'Modal': VueModal,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }

    @import '/css/modais.css';


    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>
