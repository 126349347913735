<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Locais Pix</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos locais que aparecem Pix.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Pix Configurados</label>
                                                <v-select v-model="Pixselected" @input="MudaPix"
                                                          :options="CadPixs"
                                                          :value="CadPixs.cadPixId"
                                                          single-line
                                                          label="nomePix" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <v-select v-model="Estadoselected" @input="MudaEstado"
                                                          :options="Estados"
                                                          :value="Estados.EstadoId"
                                                          single-line
                                                          label="nomeEstado" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Cidade</label>
                                                <v-select v-model="Cidadeselected" @input="MudaCidade"
                                                          :options="Cidades"
                                                          :value="Cidades.CidadeId"
                                                          single-line
                                                          label="nomeCidade" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <!--<button v-if="EmpresaId == null || EmpresaId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/empresa/' + NomeEmpresa)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>-->
                                            <button v-if="LocalPixId != null && LocalPixId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Pix</th>
                                <th>Estado</th>
                                <th>Cidade</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="LocalPix in LocalPixs" :key="LocalPix.localPixId">
                                <td>{{ LocalPix.localPixId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/localpix/' + LocalPix.localPixId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(LocalPix)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ LocalPix.configPix.cadPix.nomePix }}</td>
                                <td v-if="LocalPix.estado != null">{{ LocalPix.estado.nomeEstado }}</td>
                                <td v-else></td>
                                <td v-if="LocalPix.cidade != null">{{ LocalPix.cidade.nomeCidade }}</td>
                                <td v-else></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                LocalPixId: this.$route.params.id,
                CadPixId: 0,
                CadPixs: [],
                Pixselected: null,
                EstadoId: 0,
                Estados: [],
                Estadoselected: null,
                CidadeId: 0,
                Cidades: [],
                Cidadeselected: null,
                LocalPix: {},
                LocalPixs: [],
                T: {},
            };
        },
        methods: {
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                this.CidadeId = 0;
                this.Cidades = [];
                this.Cidadeselected = null;
                this.$http
                    .get(this.$apiUrl + "/cidades/ativosEstados/" + this.EstadoId)
                    .then((res) => res.json())
                    .then((tiu) => (this.Cidades = tiu));
                console.log(this.EstadoId);
            },
            MudaCidade(value) {
                if (value != null) {
                    this.CidadeId = value.cidadeId;
                }
                else {
                    this.CidadeId = 0;
                }
            },
            MudaPix(value) {
                this.CadPixId = value.cadPixId;
            },
            Add() {
                let _LocalPix = {
                    LocalPixId: 0,
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    configPix: { configPixId: this.Pixselected.cadPixId },
                };

                //validações
                if (this.Pixselected == null) {
                    this.$mensagem_normal("Por favor preencha pix");
                } else if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor preencha o estado");
                //} else if (this.Cidadeselected == null) {
                //    this.$mensagem_normal("Por favor preencha a cidade");
                } else {

                    this.$http
                        .get(this.$apiUrl + "/configpix")
                        .then((res) => res.json())
                        .then((moe) => {
                            moe = moe.filter(x => x.status && x.cadPix.cadPixId == this.CadPixId);
                            let i = 0;
                            for (i = 0; moe.length > i; i++) {
                                if (moe[i].cadPix.cadPixId == this.CadPixId) {
                                    _LocalPix.configPix = { ConfigPixId: moe[i].configPixId };
                                }
                            }
                            console.log(_LocalPix);
                            //chama o post da api
                            this.$http.post(this.$apiUrl + "/localpix/", _LocalPix).then(
                                response2 => {
                                    // get body data
                                    _LocalPix = response2.body;
                                    this.$redirect_reload("/controle/localpix");
                                },
                                response2 => {
                                    _LocalPix = response2.body;
                                    // error callback
                                    this.$mensagem_normal(_LocalPix);
                                }
                            );
                        });


                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/localpix/" + DadosRemover.localPixId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/localpix");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _LocalPix = {
                    LocalPixId: 0,
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    configPix: { configPixId: this.Pixselected.cadPixId },
                };

                //validações
                if (this.Pixselected == null) {
                    this.$mensagem_normal("Por favor preencha pix");
                } else if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor preencha o estado");
                //} else if (this.Cidadeselected == null) {
                //    this.$mensagem_normal("Por favor preencha a cidade");
                } else {

                    this.$http
                        .get(this.$apiUrl + "/configpix")
                        .then((res) => res.json())
                        .then((moe) => {
                            moe = moe.filter(x => x.status && x.cadPix.cadPixId == this.CadPixId);
                            let i = 0;
                            for (i = 0; moe.length > i; i++) {
                                if (moe[i].cadPix.cadPixId == this.CadPixId) {
                                    _LocalPix.configPix = { ConfigPixId: moe[i].configPixId };
                                }
                            }

                            _LocalPix.LocalPixId = parseInt(this.LocalPixId,10)
                            console.log(_LocalPix);

                            //chama o post da api
                            this.$http
                                .put(this.$apiUrl + "/localpix/" + parseInt(this.LocalPixId,10), _LocalPix)
                                .then(
                                    response2 => {
                                        // get body data
                                        _LocalPix = response2.body;
                                        this.$mensagem_sucesso(
                                            "Local Pix #" + this.LocalPixId + " alterado com sucesso"
                                        );
                                        this.$redirect("/controle/localpix");
                                    },
                                    async response2 => {
                                        _LocalPix = response2.body;
                                        this.$mensagem_normal(_LocalPix);
                                    }
                                );
                        });
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/localpix");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.LocalPixId == null) {
                this.LocalPixId = "";
            }

            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res) => res.json())
                .then((tiu) => {
                    this.Estados = tiu
                });

            this.$http
                .get(this.$apiUrl + "/configpix")
                .then((res) => res.json())
                .then((moe) => {
                    moe = moe.filter(x => x.status);

                    let i = 0;
                    for (i = 0; moe.length > i; i++) {
                        this.CadPixs.push(moe[i].cadPix);
                    }
                });

            //chama o get da api this.$apiUrl
            if (this.LocalPixId != null && this.LocalPixId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/localpix/" + this.LocalPixId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.LocalPix = idi;

                        //carrega os campos
                        this.CadPixId = this.LocalPix.configPix.cadPix.cadPixId;
                        this.Pixselected = this.LocalPix.configPix.cadPix;
                        this.EstadoId = this.LocalPix.estado.estadoId;
                        this.Estadoselected = this.LocalPix.estado

                        this.$http
                            .get(this.$apiUrl + "/cidades/ativosEstados/" + this.EstadoId)
                            .then((res) => res.json())
                            .then((tiu) => (this.Cidades = tiu));

                        this.CidadeId = this.LocalPix.cidade.cidadeId;
                        this.Cidadeselected = this.LocalPix.cidade;
                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/localpix")
                    .then((res) => res.json())
                    .then((moe) => (this.LocalPixs = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {
        },
        components: {
            vSelect,
        },
    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
