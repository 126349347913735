<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Motoristas X Entregas</h4>
                      <!-- <p class="mb-30">
                        Tela para cadastrar o comprovante da entrega
                      </p> -->
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Motorista</label>
                        <v-select
                          v-model="MotoristaId"
                          :options="Motoristas"
                          single-line
                          :reduce="(x) => x.usuarioId"
                          @input="MudaMotorista"
                          label="nomeUsuario"
                          class="col-12"
                           :searchable="false"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Pedido</label>
                        <v-select
                          v-model="PedidoId"
                          :options="Pedidos"
                          single-line
                          :reduce="(x) => x.valor"
                          label="nome"
                          class="col-12"
                           :searchable="false"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Comprovante</label>
                        <input type="file" id="arquivo">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="
                          $redirect_reload('/controle/motoristasentregas')
                        "
                      >
                        Cancelar
                      </button>
                      <button
                        v-if="MotoristaId > 0 && PedidoId > 0"
                        type="button"
                        class="btn btn-primary"
                        @click="Salvar"
                      >
                        Confirmar Entrega
                      </button>
                      <!-- <a
                        @click="$redirect_reload('/controle/pedidomanual')"
                        class="btn btn-success"
                        id="sa-custom-position"
                        >Novo Pedido</a
                      > -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
//import moment from "moment";
// import * as momentTemp from "moment";
// const moment = momentTemp["default"];

export default {
  data() {
    return {
      Motoristas: [],
      Pedidos: [],
      Enviando: 0,
      Enviado: 0,
      MotoristaId: 0,
      PedidoId: 0,
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    Salvar(){
    var files = document.getElementById('arquivo').files;
     var x = 0;
      for (x = 0; x < files.length; x++) {
        let file = files[x];
        if (file != null) {
          var ext = file.name.split(".").pop();
          if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg" && ext != "pdf") {
            this.$mensagem_erro(
              "Por favor selecione um arquivo ('.jpg', '.jpeg', '.png', '.gif' ou '.pdf')"
            );
          } else {
            this.Enviando++;
            let data = new FormData();
            data.append("arquivo", file);

            console.log(file);
            this.$http
              .post(
                this.$apiUrl + "/pedidoagendamento/comprova/" + this.PedidoId,
                data
              )
              .then(
                (response) => {
                  if(response.ok)
                  {
                    this.Enviado++;
                    let email = {
                      nomeEmpresa: "Love Cestas",
                      assunto: "Comprovante de Entrega Enviado - Pedido " + this.PedidoId,
                      Destinatario: "atendimento@lovecestas.com.br",
                      EmailResposta: "contato@lovecestas.com.br",
                      TextoEmail: "Comprovante de entrega do pedido #" + this.PedidoId + " enviado.",
                    }
                    this.$http.post(this.$apiUrl + "/contato/enviamail", email)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log(error.bodyText)
                    });
                    this.$mensagem_sucesso("Entrega do pedido #" + this.PedidoId + " confirmada com sucesso!");
                    this.MudaMotorista();
                  }
                },
                (response) => {
                  console.log(response);
                }
              );
          }
        }
      }
    },
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    MudaMotorista(id) {
      this.Pedidos = [];
      this.PedidoId = 0,
      this.MotoristaId = id;
      this.$http
        .get(
          this.$apiUrl +
            "/PedidoAgendamento/pedidomotorista/" +
            this.MotoristaId
        )
        .then((result) => {
          if (result.ok) {
            var lista = result.body;
            var peds = [];
            lista.forEach((element) => {
              var novo = {
                nome:
                  "#" +
                  element.pedido.pedidoId + " - " + 
                  element.pedido.cliente.nomeEmpresa,
                valor: element.pedido.pedidoId,
              };
              peds.push(novo);
            });
            this.Pedidos = peds;
          }
        });
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
    var usuarioTmp = JSON.parse(this.$cookie.get("usuarioAvance"));
    this.$http
      .get(this.$apiUrl + "/usuarios/" + usuarioTmp.usuarioId)
      .then((result) => {
        if (result.ok) {
          usuarioTmp = result.body;
          if (
            usuarioTmp.tipoUsuario != null &&
            usuarioTmp.tipoUsuario.tipoUsuarioId == 3
          ) {
            this.Motoristas = [usuarioTmp];
          } else {
            this.$http.get(this.$apiUrl + "/Usuarios").then(
              (response) => {
                this.Motoristas = response.body;
                this.Motoristas = this.Motoristas.filter(
                  (x) =>
                    x.tipoUsuario != null && x.tipoUsuario.tipoUsuarioId == 3
                );

              },
              (error) => {
                console.log("ERRO AO CARREGAR Motoristas: " + error.bodyText);
              }
            );
          }
        }
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}
</style>
