<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela de Entregas</h4>
                      <p class="mb-30">Tela da lista de entregas.</p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Motorista</label>
                        <v-select
                          v-model="Busca.idUsuario"
                          :options="Usuarios"
                          single-line
                          :reduce="(x) => x.usuarioId"
                          label="nomeUsuario"
                          class="col-12"
                          @change="MudaMotorista"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Status</label>
                        <v-select
                          v-model="Busca.status"
                          :options="[
                            { nome: 'Aguardando', valor: '1' },
                            { nome: 'Agendado', valor: '2' },
                            { nome: 'Enviado', valor: '3' },
                            { nome: 'Entregue', valor: '4' },
                            { nome: 'Cancelado', valor: '5' },
                          ]"
                          single-line
                          :reduce="(x) => x.nome"
                          label="nome"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Data Incial</label>
                        <input
                          class="form-control"
                          type="date"
                          placeholder=""
                          v-model="Data1"
                          @input="MudaData1"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Data Final</label>
                        <input
                          class="form-control"
                          type="date"
                          placeholder=""
                          v-model="Data2"
                          @input="MudaData2"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="$redirect_reload('/controle/listaentregas')"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="Pesquisar"
                      >
                        Pesquisar
                      </button>
                      <!-- <a
                        @click="$redirect_reload('/controle/pedidomanual')"
                        class="btn btn-success"
                        id="sa-custom-position"
                        >Novo Pedido</a
                      > -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Default Basic Forms Start -->
        <div class="pd-20 card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table>
            <tr class="semana">
              <td>
                <button
                  type="button"
                  style="background-color: #17a2b8 !important"
                  @click="Editar()"
                >
                  Editar
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(1)">
                  Segunda-Feira
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(2)">
                  Terça-Feira
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(3)">
                  Quarta-Feira
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(4)">
                  Quinta-Feira
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(5)">
                  Sexta-Feira
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(6)">
                  Sábado
                </button>
              </td>
              <td>
                <button type="button" @click="SelecionaDiaSemana(7)">
                  Domingo
                </button>
              </td>
            </tr>
          </table>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="0" @change="SelecionaTudo()" />
                </th>
                <th class="datatable-nosort">Pedido</th>
                <th class="datatable-nosort">Cliente</th>
                <th class="datatable-nosort">Data Entrega</th>
                <th>Endereço</th>
                <th>Produtos</th>
                <th class="datatable-nosort">Motorista</th>
                <th class="datatable-nosort">Status</th>
                <!-- <th class="datatable-nosort"></th>
                <th class="datatable-nosort"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Pedido, Index) in Pedidos" :key="Index">
                <td v-if="Pedido.pedido != null">
                  <input
                    type="checkbox"
                    class="chk"
                    :data-json="JSON.stringify(Pedido)"
                    :id="Pedido.pedido.pedidoId"
                  />
                </td>
                <td>
                  {{
                    Pedido.pedido != null
                      ? Pedido.pedido.pedidoId.toString()
                      : "#"
                  }}
                </td>
                <td>
                  {{ Pedido.cliente != null ? Pedido.cliente.nomeEmpresa : "" }}
                </td>
                <td>
                  <span
                    style="font-size: 10px"
                    v-if="Pedido.agendamento != null"
                  >
                    {{
                      sqlToJsDate(Pedido.agendamento.diaEntrega) +
                      " - " +
                      Pedido.agendamento.horaIni.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      }) +
                      ":" +
                      Pedido.agendamento.minIni.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      }) +
                      " as " +
                      Pedido.agendamento.horaFim.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      }) +
                      ":" +
                      Pedido.agendamento.minFim.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })
                    }}
                  </span>
                </td>
                <td>
                  <span v-if="Pedido.endereco != null">
                    {{
                      Pedido.endereco.endereco +
                      ", " +
                      Pedido.endereco.numero +
                      ", " +
                      Pedido.endereco.complemento
                    }}
                    <br />
                    {{
                      Pedido.endereco.bairro != null &&
                      Pedido.endereco.bairro.length > 0
                        ? Pedido.endereco.bairro + ", "
                        : ""
                    }}
                    {{
                      Pedido.endereco.cidade != null
                        ? Pedido.endereco.cidade.nomeCidade + " / "
                        : ""
                    }}
                    {{
                      Pedido.endereco.estado != null
                        ? Pedido.endereco.estado.nomeEstado + " "
                        : ""
                    }}
                    {{
                      Pedido.endereco.cep != null &&
                      Pedido.endereco.cep.length > 0
                        ? "CEP: " + Pedido.endereco.cep
                        : ""
                    }}
                  </span>
                </td>
                <td>
                  <ul style="list-style: initial; padding-left: 5px">
                    <li
                      style="font-size: 9px"
                      v-for="(Item, Index2) in Pedido.lista"
                      :key="Index2"
                    >
                      {{ Item.nomeProduto }}
                    </li>
                  </ul>
                </td>
                <td>
                  {{
                    Pedido.agendamento != null &&
                    Pedido.agendamento.usuario != null
                      ? Pedido.agendamento.usuario.nomeUsuario
                      : ""
                  }}
                </td>
                <td>
                  <a
                    :class="
                      getClasseBotao(Pedido.pedido.situacaoPedido) +
                      ' btn-block'
                    "
                    type="button"
                    title="Ver Detalhes"
                    style="
                      padding: 3px;
                      border-radius: 4px;
                      color: white;
                      font-size: 11px;
                      text-align: center;
                      font-weight: bold;
                    "
                    @click="EditarSingle(Pedido.pedido.pedidoId)"
                  >
                    {{ Pedido.pedido.situacaoPedido }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <a
      href="#"
      class="btn-block"
      data-toggle="modal"
      :data-target="'#modal'"
      type="button"
      title="Ver Detalhes"
      style="display: none"
      id="abreModal"
    >
      x
    </a>
    <div
      class="modal fade bs-example-modal-lg"
      :id="'modal'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myLargeModalLabel">
              Editando
              {{
                Selecionados.toString().replaceAll("[", "").replaceAll("]", "")
              }}
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div class="modal-body" :key="this.Selecionados.toString()">
            <div class="container">
              <ul>
                <li>
                  <label>Motorista</label>
                  <v-select
                    v-model="EditaAgendamento.usuario.usuarioId"
                    :options="Usuarios"
                    single-line
                    :reduce="(x) => x.usuarioId"
                    label="nomeUsuario"
                    class="col-12"
                  ></v-select>
                </li>
                <li>
                  <label>Status</label>
                  <v-select
                    v-model="EditaStatus"
                    :options="[
                      { nome: 'Aguardando', valor: '1' },
                      { nome: 'Agendado', valor: '2' },
                      { nome: 'Enviado', valor: '3' },
                      { nome: 'Entregue', valor: '4' },
                      { nome: 'Cancelado', valor: '5' },
                    ]"
                    single-line
                    :reduce="(x) => x.nome"
                    label="nome"
                    class="col-12"
                  ></v-select>
                </li>
                <li>
                  <label>Data</label>
                  <input
                    type="date"
                    v-model="EditaAgendamento.diaEntrega"
                    class="form-control"
                  />
                </li>
                <li v-if="EditaHorarios.length > 0">
                  <label>Horário</label>
                  <v-select
                    @input="MudaHorario()"
                    v-model="EditaHorario"
                    :options="EditaHorarios"
                    single-line
                    label="nome"
                    class="col-12"
                  ></v-select>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="SalvarAlteracoes()"
            >
              Salvar
            </button>
            <!-- <a href="PedPedidosInvoice.html" class="btn btn-primary"
                          >Gerar Invoice</a
                        > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
//import moment from "moment";
import * as momentTemp from "moment";
const moment = momentTemp["default"];

export default {
  data() {
    return {
      Selecionados: [],
      EditaStatus: "",
      EditaHorarios: [],
      EditaHorario: {
        horaIni: -1,
        minIni: -1,
        horaFim: -1,
        minFim: -1,
      },
      EditaAgendamento: {
        usuario: {
          usuarioId: 0,
        },
        diaEntrega: "",
        horaIni: -1,
        minIni: -1,
        horaFim: -1,
        minFim: -1,
      },
      Busca: {},
      Usuarios: [],
      Data1: "",
      Data2: "",
      Pedidos: [],
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    MudaMotorista(value){
      this.Busca.idUsuario = value;
    },
    getTabelaProd(Pedido) {
      this.$http
        .get(this.$apiUrl + "/pedidoitem/pedido/" + Pedido.pedidoId)
        .then((result) => {
          if (result.ok) {
            var tab = "";
            var tmp = result.body;
            tmp.forEach((element) => {
              tab += "<li>";
              tab += "<span>" + element.produto.nomeProduto + "</span>";
              tab += "</li>";
            });
            console.log(tab);
            //return "<table style='font-size:8px;'>" + tab + "</table>";
            return "<ul>" + tab + "</ul>";
          }
        });
    },
    getClasseBotao(situ) {
      if (situ == null) situ = "";
      if (situ == "Aguardando") {
        //#f0ad4e
        return "amarelo";
      }
      if (situ == "Agendado") {
        //#31b0d5
        return "azul-claro";
      }
      if (situ == "Enviado") {
        //#337ab
        return "azul";
      }
      if (situ == "Entregue") {
        //#40bc40;
        return "verde";
      }
      if (situ == "Cancelado") {
        //#c9302c
        return "vermelho";
      }
    },
    SelecionaDiaSemana(day) {
      //alert(day);
      this.$http
        .get(this.$apiUrl + "/pedidoagendamento/segundasexta/" + day)
        .then((result) => {
          if (result.ok) {
            this.Data1 = this.Data2 = result.bodyText;
            this.MudaData1();
            this.MudaData2();
            this.Pesquisar();
          }
        });
    },
    MudaHorario() {
      if (this.EditaHorario != null && this.EditaHorario.valor > 0) {
        this.$http
          .get(this.$apiUrl + "/entregahorario/" + this.EditaHorario.valor)
          .then((response) => {
            var tmp = response.body;
            console.log(response);
            if (tmp != null) {
              this.EditaAgendamento.horaIni = tmp.horaIni;
              this.EditaAgendamento.horaFim = tmp.horaFim;
              this.EditaAgendamento.minIni = tmp.minIni;
              this.EditaAgendamento.minFim = tmp.minFim;
            }
          });
      }
    },
    SalvarAlteracoes() {
      let edita = this.EditaAgendamento;
      let status = this.EditaStatus;
      let context = JSON.parse(JSON.stringify(this.Pedidos));
      //let cont = 0;
      // for(cont = 0; cont < this.Selecionados.length; cont++){
      //   element = this.Selecionados[cont];
      this.Selecionados.forEach((element) => {
          let send = {
        usuario: {
          usuarioId: 0,
        },
        diaEntrega: "",
        horaIni: -1,
        minIni: -1,
        horaFim: -1,
        minFim: -1,
      };
        let tmp = context.find(
          x => x.pedido != null && x.pedido.pedidoId == element
        );
        if (tmp != null) {
         
          if (edita != null) {
            //mudou agendamento
            console.log("pedidoagendamento++++++++++++  :");
            console.log(edita);
            console.log(tmp);
            let checkEditar = false;
            send.pedidoAgendamentoId = tmp.agendamento.pedidoAgendamentoId;
            if (
              edita.diaEntrega.length == 0 ||
              edita.diaEntrega == null ||
              edita.diaEntrega == undefined
            ) {
              send.diaEntrega = tmp.agendamento.diaEntrega;
            } else {
              send.diaEntrega = edita.diaEntrega;
              checkEditar = true;
            }
            if (
              edita.horaIni < 0 ||
              edita.horaFim < 0 ||
              edita.minFim < 0 ||
              edita.minIni< 0
            ) {
              send.horaIni = tmp.agendamento.horaIni;
              send.horaFim = tmp.agendamento.horaFim;
              send.minIni = tmp.agendamento.minIni;
              send.minFim = tmp.agendamento.minFim;
              checkEditar = true;
            }
            else{
              send.horaIni = edita.horaIni;
              send.horaFim = edita.horaFim;
              send.minIni = edita.minIni;
              send.minFim = edita.minFim;
              checkEditar = true;
            }
            if (edita.usuario != null && edita.usuario.usuarioId > 0) {
              //tmp.agendamento.usuario = edita.usuario;
              send.usuario = edita.usuario;
              checkEditar = true;
            }
            else{
              send.usuario = tmp.agendamento.usuario;
            }
            console.log("pedidoagendamento---:");
            console.log(edita);
            console.log(checkEditar);
            if (checkEditar == true) {
              console.log("pedidoagendamento sent:");
              console.log(send);
              this.$http
                .put(
                  this.$apiUrl +
                    "/pedidoagendamento/" +
                    tmp.agendamento.pedidoAgendamentoId,
                  send
                )
                .then((response) => {
                  console.log("pedidoagendamento:");
                  console.log(response);
                });
            }
          }
          if (status.length > 0) {
            tmp.pedido.situacaoPedido = status;
            //mudou status salva pedido
            this.$http
              .put(this.$apiUrl + "/pedido/" + tmp.pedido.pedidoId, tmp.pedido)
              .then((response) => {
                console.log("pedidostatus:");
                console.log(response);
              });
          }
          this.Selecionados = [];
          var checks = document.getElementsByClassName("chk");
          checks.forEach((element) => {
            element.checked = false;
          });
        }
      });
      setInterval(() => {
        this.Pesquisar();
        clearInterval();
      }, 4000);
    },
    SelecionaTudo() {
      this.Selecionados = [];
      var checks = document.getElementsByClassName("chk");
      var tmp = [];
      checks.forEach((element) => {
        var j = element.getAttribute("data-json");
        var fonte = document.getElementById("0").checked;
        var obj = JSON.parse(j);
        if (obj != null) {
          element.checked = fonte;
          if (element.checked == true) tmp.push(obj.pedido.pedidoId);
        }
      });
      this.Selecionados = tmp;
      this.EditaAgendamento = {
        usuario: {
          usuarioId: 0,
        },
        diaEntrega: "",
        horaIni: -1,
        minIni: -1,
        horaFim: -1,
        minFim: -1,
      };
      this.EditaHorario = {
        horaIni: -1,
        minIni: -1,
        horaFim: -1,
        minFim: -1,
      };
      this.EditaStatus = "";
      //this.EditaHorario = {};
      this.EditaAgendamento.diaEntrega = "";
    },
    EditarSingle(id) {
      this.Selecionados = [];
      var checks = document.getElementsByClassName("chk");
      var tmp = [];
      checks.forEach((element) => {
        var j = element.getAttribute("data-json");
        var obj = JSON.parse(j);
        if (obj != null) {
          if (obj.pedido.pedidoId == id) {
            element.checked = true;
            tmp.push(obj.pedido.pedidoId);
          } else element.checked = false;
        }
      });
      this.Selecionados = tmp;
      document.getElementById("abreModal").click();
      this.EditaStatus = "";
      this.EditaHorario = {};
      this.EditaAgendamento.diaEntrega = "";
    },
    Editar() {
      this.Selecionados = [];
      var checks = document.getElementsByClassName("chk");
      var tmp = [];
      checks.forEach((element) => {
        if (element.checked) {
          if (element.getAttribute("data-json") != null) {
            var j = element.getAttribute("data-json");
            var obj = JSON.parse(j);
            tmp.push(obj.pedido.pedidoId);
          }
        }
      });
      this.Selecionados = tmp;
      if (tmp.length > 0) document.getElementById("abreModal").click();
      this.EditaStatus = "";
      this.EditaHorario = {};
      this.EditaAgendamento.diaEntrega = "";
    },
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    MudaData1() {
      this.Busca.data1 = moment(this.Data1)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T")
        .toString();
    },
    MudaData2() {
      this.Busca.data2 = moment(this.Data2)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T")
        .toString();
    },
    Pesquisar() {
      console.log("POST");
      console.log(this.Busca);
      if(this.Busca.idUsuario == null){
        this.Busca.idUsuario = 0;
      }
      this.$http
        .post(this.$apiUrl + "/pedidoagendamento/financeiro", this.Busca)
        .then(
          (response) => {
            if (response.ok) {
              this.Pedidos = response.body;
              this.Pedidos = this.Pedidos.sort((a, b) => {
                if(a.agendamento != null && b.agendamento.horaIni){
                 return a.agendamento.horaIni - b.agendamento.horaIni; 
                }
                return 0;
              });
              if (
                this.Busca != null &&
                this.Busca.status != null &&
                this.Busca.status == "Cancelado"
              ) {
                console.log("Cancelado");
              } else {
                this.Pedidos = this.Pedidos.filter(
                  (x) =>
                    x.pedido != null && x.pedido.situacaoPedido != "Cancelado"
                );
              }
              console.log(response.body);
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    Limpar() {
      this.$cookie.delete("listap");
      this.$redirect_reload("/controle/listaentregas");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.Data1 = moment(new Date()).format("YYYY-MM-DD").toString();
    this.Data2 = moment(new Date()).format("YYYY-MM-DD").toString();
    this.MudaData1();
    this.MudaData2();
    this.Pesquisar();
    this.$http.get(this.$apiUrl + "/usuarios").then(
      (response) => {
        this.Usuarios = response.body.filter(
          (x) => x.tipoUsuario != null && x.tipoUsuario.tipoUsuarioId == 3
        );
      },
      (error) => {
        console.log("ERRO AO CARREGAR USUARIOS: " + error.bodyText);
      }
    );
    this.$http.get(this.$apiUrl + "/EntregaHorario").then((response) => {
      var temp = response.body;
      var cont = 0;
      for (cont = 0; cont < temp.length; cont++) {
        var item = temp[cont];
        if (item != null) {
          var novo = {
            nome:
              item.horaIni.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }) +
              ":" +
              item.minIni.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }) +
              " as " +
              item.horaFim.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }) +
              ":" +
              item.minFim.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }),
            valor: item.entregaHorarioId,
          };
          this.EditaHorarios.push(novo);
        }
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.amarelo {
  background-color: #f0ad4e;
}
.azul-claro {
  background-color: #31b0d5;
}
.azul {
  background-color: #337ab7;
}
.verde {
  background-color: #40bc40;
}
.vermelho {
  background-color: #c9302c;
}
.semana td button {
  padding: 6px;
  background-color: #26b99a;
  font-size: 11px;
  border: none;
  border-radius: 2px;
  color: #fff;
}

th {
  font-size: 11px !important;
}
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}
</style>
