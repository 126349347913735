<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Lista de Produtos</h4>
                      <p class="mb-30">
                        Tela para pesquisar e editar determinado produto ou
                        cadastradas do zero.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Palavra-chave</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="pesquise a palavra"
                          v-model="Produto.nomeProduto"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Referência</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Referência"
                          v-model="Produto.referencia"
                        />
                      </div>
                    </div>
                     <!-- <div class="col-md-3">
                      <div class="form-group">
                        <label>Estoque</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Estoque"
                          v-model="Produto.qtdeMinima"
                          @change=" Produto.qtdeMinima != null
                                      ? (Produto.qtdeMinima = parseInt(
                                          Produto.qtdeMinima,
                                          10
                                        ))
                                      : (Produto.qtdeMinima = 0)"
                        />
                      </div>
                    </div> -->
                    <!-- <div class="col-md-3">
                      <div class="form-group">
                        <label>Visivel</label>
                        <v-select
                          v-model="Produto.visivel"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div> -->
                    <!-- <div class="col-md-3">
                      <div class="form-group">
                        <label>Destaque</label>
                        <v-select
                          v-model="Produto.destaque"
                          :options="[
                            { nome: 'Sim', destaque: true },
                            { nome: 'Não', destaque: false },
                          ]"
                          single-line
                          :reduce="(x) => x.destaque"
                          label="nome"
                        ></v-select>
                      </div>
                    </div> -->
                    <!-- <div class="col-md-3">
                      <div class="form-group">
                        <label>Promoção</label>
                        <v-select
                          v-model="Produto.promocao"
                          :options="[
                            { nome: 'Sim', promocao: true },
                            { nome: 'Não', promocao: false },
                          ]"
                          single-line
                          :reduce="(x) => x.promocao"
                          label="nome"
                        ></v-select>
                      </div>
                    </div> -->
                    <!-- <div class="col-md-3">
                      <div class="form-group">
                        <label>Indisponível</label>
                        <v-select
                          v-model="Produto.indisponivel"
                          :options="[
                            { nome: 'Sim', indisponivel: true },
                            { nome: 'Não', indisponivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.indisponivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="Limpar"
                      >
                        Cancelar
                      </button>
                      <button
                        @click="Buscar()"
                        type="button"
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <a
                        @click="$redirect_reload('/controle/produtos')"
                        class="btn btn-warning"
                        >Criar Novo Produto</a
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Lista de produtos</h2>
          <table class="table hover data-table">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th class="datatable-nosort">imagem</th>
                <th style="width:30% !important;">Nome</th>

                <th>Referência</th>
                <th>Valor</th>
                <!--<th>Estoque</th>-->
                <th>Visível</th>
                <th>Indisponível</th>
                <!-- <th>Nível 1</th>
                            <th>Nível 2</th>
                            <th>Nível 3</th>
                            <th>Nível 4</th>-->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Produto, index) in Produtos" :key="index">
                <td>{{ Produto.produtoId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/produto/' + Produto.produtoId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <a
                        class="dropdown-item"
                        @click="ApagarProduto(Produto.produtoId)"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td width="10%">
                  <img
                    :src="
                      Produto.imagens != null && Produto.imagens.length > 0
                        ? Produto.imagens[0].urlImg
                        : ''
                    "
                    alt=""
                  />
                </td>
                <td style="width:30% !important;">{{ Produto.nomeProduto }}</td>

                <td>{{ Produto.referencia }}</td>
                <td>
                  {{
                    Produto.valorAte
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      .replace(".", ",")
                      .replace("$", "")
                  }}
                </td>
                <!--<td>
                  {{Produto.qtdeMinima}}
                </td>-->
                <td>
                  <span v-if="Produto.visivel">Sim</span><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Produto.indisponivel">Sim</span
                  ><span v-else>Não</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
//import vSelect from "vue-select";

export default {
  data() {
    return {
      Produto: {},
      Produtos: [],
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    ApagarProduto(id) {
      this.$http.delete(this.$apiUrl + "/produto/" + id).then(
        (result) => {
          if (result.ok) this.$mensagem_sucesso("Produto removido com sucesso");
          this.Buscar();
        },
        (err) => {
          this.$mensagem_erro(err.bodyText);
        }
      );
    },
    Buscar() {
      this.Produtos = [];
      this.$cookie.set("listap", JSON.stringify(this.Produto), 1);
      this.$redirect_reload("/controle/listaprodutos");
    },
    Limpar() {
      this.$cookie.delete("listap");
      this.$redirect_reload("/controle/listaprodutos");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    //vSelect,
  },
  created() {
    this.Produto.visivel = true;
    this.Produto.destaque = false;
    this.Produto.promocao = false;
    this.Produto.indisponivel = false;
    let tmp = this.$cookie.get("listap");
    if (tmp != null) {
      this.Produto = JSON.parse(tmp);
    }

    this.$http.post(this.$apiUrl + "/produto/busca/0", this.Produto).then(
      (result) => {
        this.Produtos = JSON.parse(result.bodyText);
        this.atualiza();
      },
      (err) => {
        this.$mensagem_erro(err.bodyText);
        this.atualiza();
      }
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
