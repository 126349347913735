<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Usuários</h4>
                      <p class="mb-30">
                        Tela para cadastro dos usuários que utilizarão o sistema
                        SGCAD.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nome do Usuário</label>
                        <input
                          v-model="NomeUsuario"
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>E-mail/Login do Usuário</label>
                        <input
                          v-model="EmailUsuario"
                          class="form-control"
                          type="text"
                          placeholder="E-mail/Login"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Telefone do Usuário</label>
                        <input
                          v-model="TelefoneUsuario"
                          class="form-control"
                          type="text"
                          placeholder="Telefone"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Status</label>
                        <select class="custom-select col-12" v-model="Ativo">
                          <option value="1">Ativo</option>
                          <option value="2">Inativo</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Tipo de Usuário</label>
                        <select
                          name="selector"
                          class="custom-select col-12"
                          @change="MudaTipo"
                        >
                          <option></option>
                          <option
                            v-for="Tipo in Tipos"
                            :key="Tipo.tipoUsuarioId"
                            :value="Tipo.tipoUsuarioId"
                          >
                            {{ Tipo.nomeTipoUsuario }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                        <label>Imagem</label>
                        <input
                          id="arquivo"
                          type="file"
                          class="form-control-file form-control height-auto"
                        />
                      </div>
                    </div>
                    <div class="col-md-3" v-if="UsuarioId.length <= 0">
                      <div class="form-group">
                        <label>Senha</label>
                        <input
                          v-model="SenhaUsuario"
                          type="password"
                          id="password"
                          name="password"
                          class="form-control"
                          placeholder="Senha"
                        />
                        <span class="lnr-eye icon-copy dw dw-hide"></span>
                      </div>
                    </div>
                     <div class="col-md-3" v-else>
                      <div class="form-group">
                        <label>Senha</label>
                        <input
                          v-model="SenhaUsuarioEditar"
                          type="password"
                          id="password"
                          name="password"
                          class="form-control"
                          placeholder="Senha"
                        />
                        <span class="lnr-eye icon-copy dw dw-hide"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        @click="$redirect_reload('/controle/usuarios')"
                        type="button"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        @click="
                          $redirect_reload('/controle/usuarios/' + NomeUsuario)
                        "
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <button
                        v-if="UsuarioId != null && UsuarioId.length > 0"
                        type="button"
                        class="btn btn-success"
                        @click="Update()"
                        id="sa-custom-position"
                      >
                        Alterar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        @click="Add()"
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th>Tipo</th>
                <th>Status</th>
                <th class="datatable-nosort">imagem</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="Usuario in Usuarios.filter(x => x.usuarioId != 1)" :key="Usuario.usuarioId">
                <td>{{ Usuario.usuarioId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect('/controle/usuario/' + Usuario.usuarioId)
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Usuario)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
               
                <td>{{ Usuario.nomeUsuario }}</td>
                <td>{{ Usuario.emailUsuario }}</td>
                <td>{{ Usuario.telefoneUsuario }}</td>
                <td>
                  <span v-if="Usuario.tipoUsuario != null">{{
                    Usuario.tipoUsuario.nomeTipoUsuario
                  }}</span
                  ><span v-else>#</span>
                </td>
                <td>
                  <span v-if="Usuario.ativo">Ativo</span
                  ><span v-else>Inativo</span>
                </td>
                 <td width="10%">
                  <img :src="Usuario.urlImagem" alt="" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import dateFormat from "./../../public/date.format.js";

export default {
  data() {
    return {
      UsuarioId: this.$route.params.id,
      Usuario: {},
      Usuarios: [],
      Tipos: [],
      NomeUsuario: "",
      EmailUsuario: "",
      TelefoneUsuario: "",
      Ativo: false,
      TipoUsuarioId: "",
      ImagemUsuario: "",
      SenhaUsuario: "",
      SenhaUsuarioEditar: "",
    };
  },
  methods: {
    MudaTipo() {
      this.TipoUsuarioId = $("select[name=selector] option")
        .filter(":selected")
        .val();
    },
    Limpar() {
      this.$redirect_reload("/controle/usuarios/");
    },
    Add() {
      let file = document.getElementById("arquivo").files[0];
      let erroarquivo = "";
      let URLIMAGEM = "";
      if (file != null) {
        var ext = file.name.split(".").pop();

        if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
          erroarquivo =
            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
        } else {
          erroarquivo = "";
        }
      } 
      
      console.log(erroarquivo);
      let _usuario = {
        usuarioId: 0,
        nomeUsuario: this.NomeUsuario,
        emailUsuario: this.EmailUsuario,
        telefoneUsuario: this.TelefoneUsuario,
        urlImagem: this.ImagemUsuario,
        senha: this.$sha1(this.SenhaUsuario),
        dataCadastro: dateFormat(new Date(), "yyyy-mm-dd hh:MM:ss"),
        tipoUsuario: this.TipoUsuarioId,
        ativo: true,
      };
      _usuario.dataCadastro = _usuario.dataCadastro.replace(" ", "T");

      //validações
      if (this.NomeUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha o nome do usuario");
      } else if (this.EmailUsuario != null && this.EmailUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha o e-mail/login do usuario");
      }
      //  else if (
      //   this.TelefoneUsuario != null &&
      //   this.TelefoneUsuario.length <= 0
      // ) {
      //   this.$mensagem_normal("Por favor preencha o telefone");
      // }
       else if (this.Status != null && this.Status.length <= 0) {
        this.$mensagem_normal("Por favor preencha o status");
      } else if (this.SenhaUsuario != null && this.SenhaUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha a senha");
      } else if (erroarquivo != null && erroarquivo.length > 0) {
        this.$mensagem_normal(erroarquivo);
      } else {
        //converte o input para true/false
        if (this.Ativo == "2") _usuario.ativo = false;
        else _usuario.ativo = true;

        let data = new FormData();
        if (file != null) {
          data.append("arquivo", file);
          console.log(file);
          this.$http.post(this.$apiUrl + "/usuarios/upload", data).then(
            (response3) => {
              // get body data
              URLIMAGEM = response3.body;
              console.log("SUBIU AQUI: " + URLIMAGEM);
              // this.$redirect_reload("/controle/usuarios");
              this.CriarUsuarioComImagem(_usuario, URLIMAGEM);
            },
            (response3) => {
              // error callback
              this.$mensagem_erro(response3.body);
            }
          );
        } else {
          this.CriarUsuarioComImagem(_usuario, URLIMAGEM);
        }
      }
    },
    CriarUsuarioComImagem(_usuario, URL) {
      this.$http.get(this.$apiUrl + "/tipousuarios/" + this.TipoUsuarioId).then(
        (response) => {
          // get body data
          _usuario.tipoUsuario = response.body;
          //chama o post da api
          _usuario.urlImagem = URL;
          this.$http.post(this.$apiUrl + "/usuarios/", _usuario).then(
            (response2) => {
              // get body data
              _usuario = response2.body;
              this.$redirect_reload("/controle/usuarios");
            },
            (response2) => {
              // error callback
              this.$mensagem_erro(response2.body);
            }
          );
          //this.$redirect_reload("/controle/usuarios");
        },
        (response) => {
          // error callback
          this.$mensagem_erro(response.body);
        }
      );
    },
    AtualizarUsuarioComImagem(_usuario, URL) {
      if(URL == null)
        URL = "";
      let ID = _usuario.usuarioId;
      if(this.SenhaUsuarioEditar != null && this.SenhaUsuarioEditar.length > 0)
        _usuario.senha = this.$sha1(this.SenhaUsuarioEditar);


      console.log(_usuario);
      this.$http.get(this.$apiUrl + "/tipousuarios/" + this.TipoUsuarioId).then(
        (response) => {
          // get body data
          _usuario.tipoUsuario = response.body;
          //chama o post da api
          if(URL != null && URL.length > 0)
            _usuario.urlImagem = URL;
          this.$http.put(this.$apiUrl + "/usuarios/" + ID, _usuario).then(
            (response2) => {
              // get body data
              _usuario = response2.body;
              console.log(_usuario);
              this.$mensagem_sucesso(
                "Usuário #" + this.UsuarioId + " alterado com sucesso"
              );
              this.$redirect("/controle/usuarios");
            },
            (response2) => {
              // error callback
              this.$mensagem_erro(response2.body);
            }
          );
          //this.$redirect_reload("/controle/usuarios");
        },
        (response) => {
          // error callback
          this.$mensagem_erro(response.body);
        }
      );
    },
    Update() {
      let file = document.getElementById("arquivo").files[0];
      console.log("ARQ")
      
      let erroarquivo = "";
      if (file != null) {
        var ext = file.name.split(".").pop();

        if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
          erroarquivo =
            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
        } else {
          erroarquivo = "";
        }
      } 
      
      let _usuario = {
        usuarioId: parseInt(this.UsuarioId, 10),
        nomeUsuario: this.NomeUsuario,
        emailUsuario: this.EmailUsuario,
        telefoneUsuario: this.TelefoneUsuario,
        
        senha: this.SenhaUsuario,
        // dataCadastro: (dateFormat(new Date(), "yyyy-mm-dd hh:MM:ss")).replace(' ', 'T'),
        tipoUsuario: this.TipoUsuarioId,
        ativo: true,
      };

      if (this.NomeUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha o nome do usuario");
      } else if (this.EmailUsuario != null && this.EmailUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha o e-mail/login do usuario");
      } 
      // else if (
      //   this.TelefoneUsuario != null &&
      //   this.TelefoneUsuario.length <= 0
      // ) {
      //   this.$mensagem_normal("Por favor preencha o telefone");
      // }
       else if (this.Status != null && this.Status.length <= 0) {
        this.$mensagem_normal("Por favor preencha o status");
      } else if (erroarquivo != null && erroarquivo.length > 0) {
        this.$mensagem_normal(erroarquivo);
      } else {
        //converte o input para true/false
        if (this.Ativo == "2") _usuario.ativo = false;
        else _usuario.ativo = true;
        let data = new FormData();
        if(file != null)
        {
          data.append("arquivo", file);
          console.log(file);
          this.$http.post(this.$apiUrl + "/usuarios/upload", data).then(
            (response3) => {
              // get body data
              _usuario.urlImagem = response3.body;
              this.AtualizarUsuarioComImagem(_usuario, _usuario.urlImagem);
            },
            (response3) => {
              // error callback
              this.$mensagem_erro(response3.body);
            }
          );
        }
        else
        {
          this.AtualizarUsuarioComImagem(_usuario, this.ImagemUsuario)
        }
      }
    },
    Pesquisa() {
      try {
        if (this.NomeUsuario.length <= 0) {
          this.$mensagem_normal("Por favor preencha o nome do usuário");
        } else {
          this.Usuarios = [];
          let _usuario = {
            nomeUsuario: this.NomeUsuario,
          };
          this.$http
            .post(this.$apiUrl + "/usuarios/busca", _usuario)
            .then((res) => res.json())
            .then((tiu) => (this.Usuarios = tiu))
            .then(this.atualiza());
        }
      } catch {
        this.$mensagem_normal("Nenhum resultado encontrado");
      }
    },
    Remove(Usuario) {
      //chama o delete da api
      this.$http.delete(this.$apiUrl + "/usuarios/" + Usuario.usuarioId).then(
        () => {
          // get body data
          this.$redirect_reload("/controle/usuarios");
        },
        (response) => {
          // error callback
          console.log(response);
        }
      );
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {},
  created() {
    if (this.$route.params.pesquisa != null) {
      this.NomeUsuario = this.$route.params.pesquisa;
    } else {
      this.NomeUsuario = "";
    }
    if(this.UsuarioId == null)
    {
      this.UsuarioId = "";
    }
    this.$http
      .get(this.$apiUrl + "/tipousuarios")
      .then((res) => res.json())
      .then((tiu) => (this.Tipos = tiu));

    if (this.UsuarioId != null && this.UsuarioId.length > 0) {
      this.$http
        .get(this.$apiUrl + "/usuarios/" + this.UsuarioId)
        .then((res) => res.json())
        .then((usu) => {
          this.Usuario = usu;
          if (this.Usuario.ativo == true) {
            this.Ativo = "1";
          } else {
            this.Ativo = "2";
          }
          this.TipoUsuarioId = this.Usuario.tipoUsuario.tipoUsuarioId;
          this.NomeUsuario = this.Usuario.nomeUsuario;
          this.EmailUsuario = this.Usuario.emailUsuario;
          this.TelefoneUsuario = this.Usuario.telefoneUsuario;
          this.SenhaUsuario = this.Usuario.senha;
          this.ImagemUsuario = this.Usuario.urlImagem;
          var ddltipo = document.getElementsByName("selector")[0];
          if (ddltipo != null) {
            ddltipo.value = this.TipoUsuarioId;
          }
          console.log(this.Usuario);
        });
    } else if (this.NomeUsuario != null && this.NomeUsuario.length > 0) {
      this.Pesquisa();
    } else {
      this.$http
        .get(this.$apiUrl + "/usuarios")
        .then((res) => res.json())
        .then((tiu) => (this.Usuarios = tiu))
        .then(this.atualiza());
    }
    this.MudaTipo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
