import Vue from "vue";
import App from "./App.vue";
import VueResource from "vue-resource";
import LoadScript from "vue-plugin-load-script";
import router from "./router";
import VueCookie from "vue-cookie";
import swal from "sweetalert";
// import quill-editor from "vue-quill-editor";
import VueQuillEditor from 'vue-quill-editor'


import VueSelect from "vue-select";
import money from "v-money";
import VueMeta from "vue-meta";
import VueHead from "vue-head";


import VueModal from '@kouts/vue-modal'

import VueDraggable from 'vue-draggable'
Vue.use(VueDraggable)


import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)

// import VueHtmlToPaper from 'vue-html-to-paper';
// const options = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     'http://sgcad21.kinghost.net/imagens/imagemMensagem.css'
//   ],
//   timeout: 1000, // default timeout before the print window appears
//   autoClose: true, // if false, the window will not close after printing
//   windowTitle: window.document.title, // override the window title
// }
// Vue.use(VueHtmlToPaper, options);

Vue.component('Modal', VueModal)    
var sha1 = require("js-sha1");

Vue.use(VueHead);
Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(LoadScript);
Vue.use(VueCookie);
//Vue.use(quill-editor, {});
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueSelect);
Vue.use(money, { precision: 4 });
Vue.use(VueMeta);

import '@kouts/vue-modal/dist/vue-modal.css'

// Vue.use(Chartkick.use(Chart))

//define a URL da api
//NO AR
Vue.prototype.$apiUrl = "https://controle.lovecestas.com.br/api";
//LOCAL
//Vue.prototype.$apiUrl = "http://localhost:5000/api";

//Vue.prototype.$baseUrl = "http://localhost:7416";

Vue.prototype.$baseUrl = "https://controle.lovecestas.com.br";


Vue.prototype.$Url = "";
//Vue.prototype.$apiUrl = "http://localhost:7416/api";

Vue.prototype.$redirect_reload = function(path) {
  this.$router.push(path);
  this.$router.go(this.$router.currentRoute);
};

Vue.prototype.$redirect = function(path) {
  this.$router.push(path);
};
Vue.prototype.$sha1 = function(input) {
  return sha1(input);
};
Vue.prototype.$mensagem_sucesso = function(mensagem) {
  swal(" ", mensagem, "success");
};
Vue.prototype.$mensagem_normal = function(mensagem) {
  swal(mensagem);
};
Vue.prototype.$atualiza_cabecalho = function () {
  this.$http
    .get(this.$apiUrl + "/GrupoTelaInstitucional/completo")
    .then((response) => {
      this.Grupos = response.body;
      localStorage.setItem("menusgcad", JSON.stringify(this.Grupos));
    });
};
Vue.prototype.$mensagem_erro = function(mensagem) {
  swal(" ", mensagem, "error");
  /*swal({
  title: "Good job!",
  text: "You clicked the button!",
  icon: "success",
  button: "Aww yiss!",
});*/
};
Vue.prototype.$clearHTML = function(escapedHTML) {
  return escapedHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
};

// Vue.loadScript('vendors/scripts/core.js');
// Vue.loadScript('vendors/scripts/script.min.js');
// Vue.loadScript('vendors/scripts/process.js');
// Vue.loadScript('vendors/scripts/layout-settings.js');
// Vue.loadScript('src/plugins/apexcharts/apexcharts.min.js');
// Vue.loadScript('src/plugins/datatables/js/jquery.dataTables.min.js');
// Vue.loadScript('src/plugins/datatables/js/dataTables.bootstrap4.min.js');
// Vue.loadScript('src/plugins/datatables/js/dataTables.responsive.min.js');
// Vue.loadScript('src/plugins/datatables/js/responsive.bootstrap4.min.js');
// Vue.loadScript('vendors/scripts/dashboard.js');

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
