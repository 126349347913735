<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Tela Configurações Bancos</h4>
                                            <p class="mb-30">
                                                Tela para configurar bancos que serão usados no site.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Banco</label>
                                                <v-select v-model="Bancoselected" @input="MudaBanco"
                                                          :options="CadBancos"
                                                          :value="CadBancos.cadBancoId"
                                                          single-line
                                                          label="nomeBanco" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>E-mail para receber aviso de compra</label>
                                                <input v-model="Emailavisocompra" class="form-control" type="text" placeholder="E-mail">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Dados Conta</label>
                                                <quill-editor v-model="DadosConta" placeholder="Insira texto aqui ..." />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Texto para compra finalizada</label>
                                                <quill-editor v-model="TextoFinalizada" placeholder="Insira texto aqui ..." />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Imagem fundo compra finalizada (1920 x 800px)</label>
                                                <input id="arquivo"
                                                       type="file"
                                                       class="form-control-file form-control height-auto" />
                                            </div>
                                            <img v-if="Configbanco != null && Configbanco.urlImg != null" style="max-width:500px" :src="$baseUrl + Configbanco.urlImg" class="img-responsive">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                           <button v-if="ConfigbancoId != null && ConfigbancoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Banco</th>
                                <th>Status</th>
                                <th>E-mail</th>
                                <th>Ordem</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Configbanco in Configbancos" :key="Configbanco.configBancoId">
                                <td>{{ Configbanco.configBancoId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/configbanco/' + Configbanco.configBancoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!--<a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a>-->
                                            <a class="dropdown-item"
                                               @click="Remove(Configbanco)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Configbanco.cadBanco.nomeBanco }} </td>
                                <td><span v-if="Configbanco.status">Ativo</span><span v-else>Inativo</span></td>
                                <td>{{ Configbanco.emailAvisoCompra }}</td>
                                <td>{{ Configbanco.ordem }}</td>
                                
                            </tr>
                            <!--<tr>
                                <td valign="top"
                                    v-if="Configbancos.length <= 0"
                                    colspan="5"
                                    class="dataTables_empty">
                                    Nenhum resultado encontrado
                                </td>
                            </tr>-->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                ConfigbancoId: this.$route.params.id,
                CadbancoId: null,
                Ativo: null,
                Emailavisocompra: '',
                DadosConta: '',
                TextoFinalizada: '',
                Ordem: '',
                Urlimg:'',
                ImagemFundo: "",
                Configbanco: {},
                Configbancos: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                Bancoselected: null,
                CadBancos: [],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus(value) {
                this.Ativo = value;
            },
            MudaBanco(value) {
                this.CadbancoId = value.cadBancoId;
            },
            Add() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                let URLIMAGEM = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                } 

                let _Configbanco = {
                    cadBancoId: this.CadbancoId,
                    Status: null,
                    EmailAvisoCompra: '',
                    TextoFinalizada: '',
                    Ordem: 0,
                    DadosConta:'',
                    UrlImg: this.ImagemFundo,
                };

                //validações
                if (this.Bancoselected == null) {
                    this.$mensagem_normal("Por favor preencha a banco");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else if (this.Emailavisocompra.length <= 0) {
                    this.$mensagem_normal("Por favor selecione e-mail aviso de compra");
                } else if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                } else {

                    _Configbanco.Status = this.Ativo;
                    _Configbanco.EmailAvisoCompra = this.Emailavisocompra;
                    _Configbanco.TextoFinalizada = this.TextoFinalizada;
                    _Configbanco.DadosConta = this.DadosConta;
                    _Configbanco.Ordem = parseInt(this.Ordem,10);

                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        console.log(file);
                        this.$http.post(this.$apiUrl + "/configbanco/upload", data).then(
                            (response3) => {
                                // get body data
                                URLIMAGEM = response3.body;                               
                                this.CriarConfigBancoComImagem(_Configbanco, URLIMAGEM);
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    } else {
                        this.CriarConfigBancoComImagem(_Configbanco, URLIMAGEM);
                    }
                }
            },
            CriarConfigBancoComImagem(_configbanco, URL) {
                _configbanco.UrlImg = URL;
                console.log("_configbanco:");
                console.log(_configbanco);
                //chama o post da api
                this.$http.post(this.$apiUrl + "/configbanco/" + this.CadbancoId, _configbanco).then(
                    response2 => {
                        // get body data
                        _configbanco = response2.body;
                        this.$router.go(this.$router.currentRoute);
                    },
                    response2 => {
                        _configbanco = response2.body;
                        this.$mensagem_normal(_configbanco);
                    }
                );
            },
            Remove(dadosRemover) {
                //chama o delete da api
                console.log(dadosRemover.configBancoId);
                this.$http
                    .delete(this.$apiUrl + "/configbanco/" + dadosRemover.configBancoId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let file = document.getElementById("arquivo").files[0];
                console.log("ARQ")

                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                } 

                let _Configbanco = {
                    ConfigBancoId: 0,
                    CadBanco: {},
                    Status: null,
                    EmailAvisoCompra: '',
                    TextoFinalizada: '',
                    Ordem: 0,
                    DadosConta: '',
                    UrlImg: this.ImagemFundo,
                };

                //validações
                if (this.Bancoselected == null) {
                    this.$mensagem_normal("Por favor preencha a banco");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else if (this.Emailavisocompra.length <= 0) {
                    this.$mensagem_normal("Por favor selecione e-mail aviso de compra");
                } else if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                } else {

                    this.$http
                        .get(this.$apiUrl + "/cadbanco/" + parseInt(this.CadbancoId, 10))
                        .then((res) => res.json())
                        .then((idi) => {
                            _Configbanco.CadBanco = idi;
                        });

                    _Configbanco.Status = this.Ativo;
                    _Configbanco.EmailAvisoCompra = this.Emailavisocompra;
                    _Configbanco.TextoFinalizada = this.TextoFinalizada;
                    _Configbanco.DadosConta = this.DadosConta;
                    _Configbanco.Ordem = parseInt(this.Ordem, 10);
                    _Configbanco.ConfigBancoId = parseInt(this.ConfigbancoId, 10);

                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        console.log(file);
                        this.$http.post(this.$apiUrl + "/configbanco/upload", data).then(
                            (response3) => {
                                // get body data
                                _Configbanco.UrlImg = response3.body;
                                this.AtualizarConfigBancoComImagem(_Configbanco, _Configbanco.UrlImg);
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                    else {
                        this.AtualizarConfigBancoComImagem(_Configbanco, this.ImagemFundo)
                    }
                }
            },
            AtualizarConfigBancoComImagem(_configbanco, URL) {
                console.log("_configbanco:");
                if (URL == null)
                    URL = "";
                _configbanco.ConfigBancoId = parseInt(_configbanco.ConfigBancoId, 10);
                let ID = _configbanco.ConfigBancoId;
                if (URL != null && URL.length > 0)
                    _configbanco.urlImg = URL;
                //chama o post da api
                this.$http
                    .put(this.$apiUrl + "/configbanco/" + ID, _configbanco)
                    .then(
                        response2 => {
                            // get body data
                            _configbanco = response2.body;
                            this.$mensagem_sucesso(
                                "Config Banco #" + this.ConfigbancoId + " alterado com sucesso"
                            );
                            this.Limpar();
                        },
                        async response2 => {
                            _configbanco = response2.body;
                            // error callback
                            this.$mensagem_normal(_configbanco);
                        }
                    );
            },
            Limpar() {
                this.$redirect_reload("/controle/configbanco");
                this.$http
                    .get(this.$apiUrl + "/configbanco")
                    .then((res) => res.json())
                    .then((moe) => (this.Configbancos = moe))
                    .then(this.atualiza());
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            //carrega bancos
            this.$http
                .get(this.$apiUrl + "/cadbanco")
                .then((res) => res.json())
                .then((moe) => (this.CadBancos = moe.filter(x => x.visivel)));

            if (this.ConfigbancoId == null) {
                this.ConfigbancoId = "";
            }

            //console.log("ConfigbancoId" + this.ConfigbancoId)

            //chama o get da api this.$apiUrl
            if (this.ConfigbancoId != null && this.ConfigbancoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/configbanco/" + this.ConfigbancoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Configbanco = idi;

                        this.CadbancoId = this.Configbanco.cadBanco.cadBancoId;
                        this.Ativo = this.Configbanco.status;
                        this.Statuselected = this.Configbanco.status;
                        this.Emailavisocompra = this.Configbanco.emailAvisoCompra;
                        this.TextoFinalizada = this.Configbanco.textoFinalizada;
                        this.DadosConta = this.Configbanco.dadosConta;
                        this.Ordem = this.Configbanco.ordem;
                        this.Bancoselected = this.Configbanco.cadBanco;
                        
                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/configbanco")
                    .then((res) => res.json())
                    .then((moe) => {
                        this.Configbancos = moe;
                    })
                    .then(this.atualiza());
                
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
