<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Controle Analitycs</h4>
                      <p class="mb-30">
                        Tela para cadastro do código Analitycs.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Insira o código</label>
                        <quill-editor
                          v-model="Codigo"
                          placeholder="Insira o código aqui ..."
                        />
                        <!--<ckeditor :editor="editor" v-model="Codigo" :config="editorConfig"></ckeditor>-->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="Alterar()"
                        id="sa-custom-position2"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

//import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";

export default {
  data() {
    return {
      SeoAnalitycsId: 0,
      Codigo: null,
      MetaAna: [],
    };
  },
  methods: {
    Alterar() {
      let _SeoAna = {
        seoAnalitycsId: this.SeoAnalitycsId,
        codigo: this.Codigo,
      };

      //validações
      if (_SeoAna.codigo == null) {
        this.$mensagem_normal("Por favor preencha o código script");
      } else {
        _SeoAna.seoAnalitycsId = this.SeoAnalitycsId;
        _SeoAna.codigo = this.Codigo;
        _SeoAna.codigo = _SeoAna.codigo
          .replaceAll("&gt;", ">")
          .replaceAll("&lt;", "<")
          .replaceAll("<br>", "")
          .replaceAll("<pre>", "")
          .replaceAll("</pre>", "")
          .replaceAll("<div>", "")
          .replaceAll("</div>", "");

        console.log(_SeoAna.seoAnalitycsId);
        console.log(_SeoAna);

        if (_SeoAna.seoAnalitycsId == null || _SeoAna.seoAnalitycsId == 0) {
          //chama o post da api
          this.$http.post(this.$apiUrl + "/seoanalitycs", _SeoAna).then(
            (response) => {
              // get body data
              //console.log(_RedeSocial);
              _SeoAna = response.body;
              this.$mensagem_sucesso("Código alterado com sucesso");
              this.$redirect("/controle/seoanalitycs");
            },
            (response) => {
              // error callback
              console.log(response);
            }
          );
        } else {
          //chama o post da api
          this.$http
            .put(
              this.$apiUrl + "/seoanalitycs/" + _SeoAna.seoAnalitycsId,
              _SeoAna
            )
            .then(
              (response) => {
                // get body data
                //console.log(_RedeSocial);
                _SeoAna = response.body;
                this.$mensagem_sucesso("Código alterado com sucesso");
                this.$redirect("/controle/seoanalitycs");
              },
              (response) => {
                // error callback
                console.log(response);
              }
            );
        }
      }
    },
    Limpar() {
      this.$redirect_reload("/controle/seoanalitycs");
    },
  },
  created() {
    //chama o get da api this.$apiUrl
    this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.MetaAna = idi;
        console.log(this.MetaAna);
        this.SeoAnalitycsId = this.MetaAna[0].seoAnalitycsId;
        this.Codigo = this.MetaAna[0].codigo
          .replaceAll(">", "&gt;")
          .replaceAll("<", "&lt;");
      });
  },
  mounted() {},
  components: {
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
