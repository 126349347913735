<template>
  <div>
    <div class="main-container">
      <div class="row">
        <ul class="ul-dashboard">
          <li>
            <span><i class="fa fa-cart-plus"></i>Vendas do Mês</span>
            <br />
            <h5>
              R$
              {{
                Dashboard.vendasMes
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .replace(".", ",")
                  .replace("$", "")
              }}
            </h5>
            <p>Total vendas brutas</p>
          </li>
          <li>
            <span><i class="fa fa-clone"></i>Pedidos do Mês</span>
            <br />
            <h5 style="color: #73879c">{{ Dashboard.pedidosMes }}</h5>
            <p>Qtde Pedidos</p>
          </li>
          <li>
            <span><i class="fa fa-user"></i>Clientes Cadastrados</span>
            <br />
            <h5 style="color: #73879c">{{ Dashboard.clientesCadastrados }}</h5>
            <p>Total Clientes</p>
          </li>
          <li>
            <span><i class="fa fa-gift"></i>Cestas Cadastradas</span>
            <br />
            <h5 style="color: #73879c">{{ Dashboard.cestasCadastradas }}</h5>
            <p>Cestas Disponíveis</p>
          </li>
          <li>
            <span
              ><i class="fa fa-cart-plus"></i>Vendas
              {{ new Date().getFullYear() }}</span
            >
            <br />
            <h5 style="color: #73879c">
              R$
              {{
                Dashboard.vendasAno
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .replace(".", ",")
                  .replace("$", "")
              }}
            </h5>
            <p>Total Vendas Brutas</p>
          </li>
          <li>
            <span
              ><i class="fa fa-clone"></i>Pedidos
              {{ new Date().getFullYear() }}</span
            >
            <br />
            <h5 style="color: #73879c">{{ Dashboard.pedidosAno }}</h5>
            <p>Qtde Total Pedidos</p>
          </li>
        </ul>
      </div>
      <div class="row" style="padding-top: 20px">
        <div class="col-md-3 col-sm-3 col-xs-12 itemDashboard">
          <h5>Entregas</h5>
          <table style="width:100%">
            <tr v-for="(Entrega, Index) in EntregasLista" :key="Index">
              <td style="width: 30%;font-size:11px;text-align:center;">{{ Entrega.nome }}</td>
              <td style="width: 60%;padding:5px;">
                <div
                  :style="
                    'height:20px; width:' +
                    Entrega.porcentagem +
                    ';background-color:green;border-radius:4px'
                  "
                ></div>
              </td>
              <td style="width: 10%;font-size:12px;font-weight:bold;">{{ Entrega.total }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 itemDashboard">
          <h5>Representatividade Vendas</h5>
          <hr />
          <GChart type="PieChart" :options="options" :data="data" />
          <br />
          <span style="font-size: 12px; padding-top: 5px"
            ><strong
              >Total: R$
              {{
                Total5.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                  .replace(".", ",")
                  .replace("$", "")
              }}</strong
            ></span
          >
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 itemDashboard">
          <h5>Top 5 Mais Vendidas</h5>
          <hr />
          <ul>
            <li
              style="
                background: #f3f3f3;
                border-radius: 3px;
                position: relative;
                padding: 7px;
                margin-bottom: 5px;
                list-style: none;
                font-size: 13px;
              "
              v-for="(Item, Index) in Dashboard.top"
              :key="Index"
            >
              {{ Item.nome }} - Qtde {{ Item.qtde }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const Chart = require("chart.js");
//import Chart from "vue-chartless";
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

//import $ from "jquery";
// import "datatables.net-dt";
// import "datatables.net-dt/css/jquery.dataTables.css";
// import vSelect from 'vue-select';

import { GChart } from "vue-google-charts";

export default {
  data() {
    return {
      Total5: 0.0,
      EntregasLista: [],
      Dashboard: {
        vendasMes: 0.0,
        pedidosMes: 0,
        clientesCadastrados: 0,
        cestasCadastradas: 0,
        vendasAno: 0.0,
        pedidosAno: 0,
        vendas: [],
        top: [],
        totalVendas: 0.0,
      },
      // def: {
      //   type: "pie",
      // },
      // data: [],
      data: [],
      options: {
        pieHole: 0.3,
        title: "Últimos 5 Meses",
        legend: { textStyle: { fontSize: "8" }, position: "bottom" },
        chartArea: { width: "100%", height: "80%" },
      },
    };
  },
  methods: {
    getDataVendas() {
      var retorno = [["Mês", "Vendas"]];
      if (this.Dashboard != null && this.Dashboard.vendas != null) {
        var cont = 0;
        for (cont = 0; cont < this.Dashboard.vendas.length; cont++) {
          var novo = [
            this.Dashboard.vendas[cont].mes.charAt(0).toUpperCase() +
              this.Dashboard.vendas[cont].mes.slice(1) +
              " R$ " +
              this.Dashboard.vendas[cont].valor
                .toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
                .replace(".", ",")
                .replace("$", ""),
            this.Dashboard.vendas[cont].valor,
          ];
          this.Total5 += this.Dashboard.vendas[cont].valor;
          retorno.push(novo);
        }
      }
      console.log(retorno);
      return retorno;
    },
    Carregar() {
      this.$http
        .get(this.$apiUrl + "/dashboard")
        .then((response) => {
          if (response.ok) {
            this.Dashboard = response.body;
          } else {
            this.$mensagem_erro(response.bodyText);
          }
        })
        .then(() => (this.data = this.getDataVendas()));

      this.$http.get(this.$apiUrl + "/dashboard/EntregasDia").then((result) => {
        if (result.ok) {
          this.EntregasLista = result.body;
        }
      });
    },
  },
  created() {
    this.Carregar();
  },
  components: {
    GChart,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.tile_info {
  font-size: 13px;
}
.itemDashboard {
  border-radius: 10px;
  border: 1px solid #73879c;
  padding: 10px;
  background-color: white;
  margin: 20px;
}

.itemDashboard h5 {
  font-size: 18px;
  color: #73879c;
  font-weight: 500;
  padding: 1px;
}

.ul-dashboard {
  padding-top: 20px;
}

button {
  margin: 10px;
}
.ul-dashboard {
  list-style: none;
}
.ul-dashboard li {
  float: left;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid #73879c;
}
.ul-dashboard li span {
  padding-top: 5px;
  font-size: 12px;
  color: #73879c;
}
.ul-dashboard li span i {
  padding: 5px;
  color: #73879c;
}
.ul-dashboard li h5 {
  text-align: left;
  width: 100%;
  font-size: 20px;
  padding-top: 13px;
  color: #1abb9c;
}
.ul-dashboard li p {
  font-size: 9px;
  text-align: left;
  color: #73879c;
}
</style>
