<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela detalhe do Pedido</h4>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-30">
                    <div class="pd-20 card-box height-100-p">
                      <h5
                        class="text-center h5 mb-0"
                        v-if="this.Pedido.cliente != null"
                      >
                        {{ Pedido.cliente.nomeEmpresa }}
                      </h5>
                      <p class="text-center text-muted font-14">
                        Ped. {{ Pedido.pedidoId }} -
                        {{ sqlToJsDate(Pedido.dataPedido) }}
                        {{
                          (Pedido.origemPedido != null &&
                            Pedido.origemPedido.length) > 0
                            ? " - " + Pedido.origemPedido
                            : " - Pela Loja"
                        }}
                      </p>
                      <div class="profile-info">
                        <ul>
                          <li>
                            <span style="display: inline-block"
                              ><a
                                class=""
                                data-toggle="modal"
                                data-target="#bd-example-modal-lg"
                                type="button"
                                href="#"
                                ><i class="dw dw-edit2"></i> Endereço
                                Entrega:</a
                              ></span
                            ><br />
                            <span v-if="PedidoEndereco.pedidoEnderecoId > 0">
                              Endereço Entrega:{{
                                PedidoEndereco.endereco +
                                ", " +
                                PedidoEndereco.numero +
                                ", " +
                                PedidoEndereco.complemento
                              }}
                              <br />
                              {{
                                PedidoEndereco.bairro != null &&
                                PedidoEndereco.bairro.length > 0
                                  ? PedidoEndereco.bairro + ", "
                                  : ""
                              }}
                              {{
                                PedidoEndereco.cidade != null
                                  ? PedidoEndereco.cidade.nomeCidade + " / "
                                  : ""
                              }}
                              {{
                                PedidoEndereco.estado != null
                                  ? PedidoEndereco.estado.nomeEstado + " "
                                  : ""
                              }}
                              {{
                                PedidoEndereco.cep != null &&
                                PedidoEndereco.cep.length > 0
                                  ? "CEP: " + PedidoEndereco.cep
                                  : ""
                              }}
                            </span>
                          </li>
                          <li>
                            <span>Situação:</span>
                            <v-select
                              v-model="Pedido.situacaoPedido"
                              :options="[
                                { nome: 'Aguardando', valor: '1' },
                                { nome: 'Agendado', valor: '2' },
                                { nome: 'Enviado', valor: '3' },
                                { nome: 'Entregue', valor: '4' },
                                { nome: 'Cancelado', valor: '5' },
                              ]"
                              single-line
                              :reduce="(x) => x.nome"
                              label="nome"
                              class="col-12"
                            ></v-select>
                            <span>Tipo Pagamento:</span>
                            <v-select
                              v-model="Pedido.formaPgto"
                              :options="ListaFormasPagamento"
                              single-line
                              :reduce="(x) => x.texto"
                              label="texto"
                              class="col-12"
                            ></v-select>
                            <button
                              @click="SalvarItem(Pedido)"
                              type="button"
                              class="btn btn-info mt-15"
                            >
                              Salvar
                            </button>
                          </li>
                          <li v-if="Pedido.tidCielo!=''">
                              <span>TID Cielo:</span>
                              {{ Pedido.tidCielo }}
                          </li>
                          <li
                            v-if="
                              Pedido.cliente != null &&
                              Pedido.cliente.email != null &&
                              Pedido.cliente.email.length > 0
                            "
                          >
                            <span>Email:</span>
                            {{ Pedido.cliente.email }}
                          </li>
                          <li
                            v-if="
                              Pedido.cliente != null &&
                              Pedido.cliente.telefone != null &&
                              Pedido.cliente.telefone.length > 0
                            "
                          >
                            <span>Telefone:</span>
                            {{ Pedido.cliente.telefone }}
                          </li>
                          <li
                            v-if="
                              Pedido.cliente != null &&
                              Pedido.cliente.whatsapp != null &&
                              Pedido.cliente.whatsapp.length > 0
                            "
                          >
                            <span>Whatsapp:</span>
                            {{ Pedido.cliente.whatsapp }}
                          </li>
                          <li>
                            <span>Comprovante:</span>
                            <span style="display: inline-block"
                              ><a
                                class=""
                                data-toggle="modal"
                                data-target="#ModalComprovante"
                                type="button"
                                href="#"
                                ><i class="dw dw-edit2"></i> Ver/subir
                                comprovante:</a
                              ></span
                            ><br />
                          </li>
                          <li>
                            <table>
                              <tr>
                                <td style="padding: 10px">
                                  <a
                                    @click="
                                      $redirect_reload('/controle/listapedidos')
                                    "
                                    type="button"
                                    class="btn btn-secondary"
                                    >voltar</a
                                  >
                                </td>
                                <td>
                                  <a
                                    @click="
                                      $redirect_reload(
                                        '/controle/pedidoinvoice/' +
                                          Pedido.pedidoId
                                      )
                                    "
                                    type="button"
                                    class="btn btn-primary"
                                    >Gerar Invoice</a
                                  >
                                </td>
                              </tr>
                            </table>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-30">
                    <div class="card-box height-100-p overflow-hidden">
                      <div class="profile-tab height-100-p">
                        <div class="tab height-100-p">
                          <ul class="nav nav-tabs customtab" role="tablist">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                data-toggle="tab"
                                href="#pedido"
                                role="tab"
                                >Pedido</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                id="menuMensagem"
                                class="nav-link"
                                data-toggle="tab"
                                href="#mensagem"
                                role="tab"
                                >Mensagem</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                id="menuFoto"
                                class="nav-link"
                                data-toggle="tab"
                                href="#foto"
                                role="tab"
                                >Foto</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                data-toggle="tab"
                                href="#adicionais"
                                role="tab"
                                >Adicionais</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                data-toggle="tab"
                                href="#obs"
                                role="tab"
                                >Obs</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                data-toggle="tab"
                                href="#dados"
                                role="tab"
                                >Dados</a
                              >
                            </li>
                          </ul>
                          <div class="tab-content">
                            <!-- Timeline Tab start -->
                            <div
                              class="tab-pane fade show active"
                              id="pedido"
                              role="tabpanel"
                            >
                              <div class="pd-20">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <span
                                        style="display: inline-block"
                                        v-if="
                                          PedidoAgendamento.pedidoAgendamentoId >
                                          0
                                        "
                                        ><a
                                          class=""
                                          data-toggle="modal"
                                          data-target="#ModalHora"
                                          type="button"
                                          ><i class="dw dw-edit2"></i> Data
                                          Entrega:
                                          {{
                                            sqlToJsDate(
                                              PedidoAgendamento.diaEntrega
                                            ) +
                                            " - " +
                                            PedidoAgendamento.horaIni.toLocaleString(
                                              "en-US",
                                              {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                              }
                                            ) +
                                            ":" +
                                            PedidoAgendamento.minIni.toLocaleString(
                                              "en-US",
                                              {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                              }
                                            ) +
                                            " as " +
                                            PedidoAgendamento.horaFim.toLocaleString(
                                              "en-US",
                                              {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                              }
                                            ) +
                                            ":" +
                                            PedidoAgendamento.minFim.toLocaleString(
                                              "en-US",
                                              {
                                                minimumIntegerDigits: 2,
                                                useGrouping: false,
                                              }
                                            )
                                          }}</a
                                        ></span
                                      ><br />
                                      <br />
                                      <span v-if=" PedidoAgendamento.pedidoAgendamentoId >
                                          0 && PedidoAgendamento.urlImgEntrega != null && PedidoAgendamento.urlImgEntrega.length > 0">
                                          <a target="_blank" :href="$Url + PedidoAgendamento.urlImgEntrega">Ver Comprovante de entrega.</a></span><br />
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="table-responsive">
                                        <table class="table table-striped">
                                          <thead>
                                            <tr>
                                              <th>Ref.</th>
                                              <th
                                                class="datatable-nosort"
                                                style="width: 10%"
                                              >
                                                imagem
                                              </th>
                                              <th>Produto</th>
                                              <th class="datatable-nosort">
                                                Unit
                                              </th>
                                              <th class="datatable-nosort">
                                                Qtde
                                              </th>
                                              <th class="datatable-nosort">
                                                Total
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="Item in Pedido
                                                .listaPedidoItem.length > 0
                                                ? Pedido.listaPedidoItem.filter(
                                                    (x) => x.produto != null
                                                  )
                                                : []"
                                              :key="Item.pedidoItemId"
                                            >
                                              <td>
                                                {{ Item.produto.referencia }}
                                              </td>
                                              <td>
                                                <img
                                                  v-if="
                                                    Item.produto.imagens !=
                                                      null &&
                                                    Item.produto.imagens
                                                      .length > 0
                                                  "
                                                  :src="
                                                    Item.produto.imagens[0]
                                                      .urlImg
                                                  "
                                                  alt=""
                                                />
                                              </td>
                                              <td class="tamdado">
                                                {{ Item.produto.nomeProduto }}
                                                <!-- <small
                                                  >Nome dos itens Avulsos</small
                                                ><br />
                                                <small
                                                  >Nome dos itens Avulsos</small
                                                ><br />
                                                <small
                                                  >Nome dos itens Avulsos</small
                                                > -->
                                              </td>

                                              <td class="tamdado">
                                                <span
                                                  style="
                                                    float: left;
                                                    padding-top: 2px;
                                                  "
                                                  >R$</span
                                                >
                                                <input
                                                  style="
                                                    width: 50%;
                                                    float: left;
                                                  "
                                                  type="number"
                                                  step=".01"
                                                  @change="
                                                    Item.valorProduto =
                                                      parseFloat(
                                                        $event.target.value.replace(
                                                          ',',
                                                          '.'
                                                        )
                                                      );
                                                    Item.valorTotalProduto =
                                                      Item.valorProduto *
                                                      Item.qtdeProduto;
                                                  "
                                                  :value="Item.valorProduto"
                                                />
                                              </td>
                                              <td class="tamdado">
                                                <input
                                                  style="
                                                    width: 50%;
                                                    float: left;
                                                  "
                                                  type="number"
                                                  @change="
                                                    Item.qtdeProduto = parseInt(
                                                      $event.target.value.replace(
                                                        ',',
                                                        '.'
                                                      )
                                                    );
                                                    Item.valorTotalProduto =
                                                      Item.valorProduto *
                                                      Item.qtdeProduto;
                                                  "
                                                  :value="Item.qtdeProduto"
                                                />
                                              </td>
                                              <td class="tamdado">
                                                R$
                                                {{
                                                  Item.valorTotalProduto
                                                    .toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "USD",
                                                    })
                                                    .replace(".", ",")
                                                    .replace("$", "")
                                                }}
                                              </td>
                                            </tr>
                                            <tr
                                              v-for="Item in Pedido.listaPedidoItemManual !=
                                                null &&
                                              Pedido.listaPedidoItemManual
                                                .length > 0
                                                ? Pedido.listaPedidoItemManual.filter(
                                                    (x) =>
                                                      x.itemAvulsoManual != null
                                                  )
                                                : []"
                                              :key="Item.pedidoItemManualId"
                                            >
                                              <td>
                                                AVM{{
                                                  Item.itemAvulsoManual
                                                    .itemAvulsoManualId
                                                }}
                                              </td>
                                              <td></td>
                                              <td class="tamdado">
                                                {{
                                                  Item.itemAvulsoManual.nomeItem
                                                }}
                                              </td>
                                              <td class="tamdado">
                                                R$
                                                {{
                                                  Item.valorTotalProduto
                                                    .toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "USD",
                                                    })
                                                    .replace(".", ",")
                                                    .replace("$", "")
                                                }}
                                              </td>
                                              <td class="tamdado">
                                                {{ Item.qtdeProduto }}
                                              </td>
                                              <td class="tamdado">
                                                R$
                                                {{
                                                  Item.valorProduto
                                                    .toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "USD",
                                                    })
                                                    .replace(".", ",")
                                                    .replace("$", "")
                                                }}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <button
                                          @click="SalvarValores"
                                          class="btn btn-info mt-10"
                                          style="padding: 3px; font-size: 12px"
                                          type="button"
                                        >
                                          Salvar Valores
                                        </button>

                                        <div
                                          class="row"
                                          v-if="
                                            Title ==
                                            'SGCAD - Maki & Letícia Hortifrutigranjeiros' && (Pedido.ibr == null || Pedido.ibr.length == 0)
                                          "
                                          style="padding-left:24px"
                                        >
                                          <table>
                                            <tr>
                                              <td style="padding:12px">
                                                <span
                                                  >Espécie de pagamento</span
                                                >
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <v-select
                                                  v-model="EspecieIBR"
                                                  :options="EspeciesIBR"
                                                  single-line
                                                  :reduce="(x) => x._id"
                                                  label="descricao"
                                                  class="col-12"
                                                  style="font-size: 12px"
                                                ></v-select>
                                              </td>
                                              <td>
                                                <button
                                                  @click="EnviarIBR"
                                                  type="button"
                                                  class="btn btn-success mt-10"
                                                  style="
                                                    float: left;
                                                    margin-top: 10px;
                                                
                                                  "
                                                >
                                                  Enviar para o sistema IBR
                                                </button>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                        <div v-else-if="Pedido.ibr != null && Pedido.ibr.length > 0">
                                          {{idIBR}}
                                        </div>
                                        <div
                                          v-if="Pedido != null"
                                          class="col-lg-12"
                                          style="text-align: right"
                                        >
                                          Frete ({{ Pedido.tipoFrete }}): R$
                                          {{
                                            Pedido.valorFrete
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(".", ",")
                                              .replace("$", "")
                                          }}
                                        </div>
                                        <div
                                          class="col-lg-12"
                                          style="text-align: right"
                                        >
                                          Cupom: R$
                                          {{
                                            Pedido.valorCupom
                                              .toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })
                                              .replace(".", ",")
                                              .replace("$", "")
                                          }}
                                        </div>
                                        <div class="col-lg-12">
                                          <div
                                            style="
                                              font-size: 18px;
                                              font-weight: 800;
                                              text-align: right;
                                            "
                                          >
                                            Valor Total R$
                                            {{
                                              CalculaValorPedido(Pedido)
                                                .toLocaleString("en-US", {
                                                  style: "currency",
                                                  currency: "USD",
                                                })
                                                .replace(".", ",")
                                                .replace("$", "")
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Timeline Tab End -->
                            <!-- Tasks Tab start -->
                            <div
                              class="tab-pane fade"
                              id="mensagem"
                              role="tabpanel"
                            >
                              <div
                                class="pd-20 profile-task-wrap"
                                v-if="PedidoMensagem != null"
                              >
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>De</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="De"
                                        v-model="PedidoMensagem.nomeDe"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Para</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Para"
                                        v-model="PedidoMensagem.nomePara"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Telefone Destinatário</label>
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Telefone"
                                        v-model="PedidoMensagem.telefoneRecebe"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <section
                                  v-if="
                                    PedidoMensagem.urlImagem != null &&
                                    PedidoMensagem.urlImagem.length > 0
                                  "
                                >
                                  <div class="row mb-15">
                                    <div class="col-md-12 text-center">
                                      <div
                                        ref="content2"
                                        class="editar-cartao mt-4 text-center"
                                      >
                                        <!--aqui regra do preenchimento do cartão -->
                                        <div
                                          class="imagemCartao"
                                          :style="{
                                            backgroundImage:
                                              'url(' +
                                              $Url +
                                              PedidoMensagem.urlImagem +
                                              ')',
                                          }"
                                        >
                                          <textarea
                                            v-model="PedidoMensagem.texto"
                                            class="textareacartao"
                                            maxlength="1000"
                                            style="
                                              font-family: Handlee !important;
                                              text-align:center;
                                              padding-right:18px;
                                            "
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 text-center">
                                      <button
                                        type="button"
                                        class="btn btn-link"
                                        @click="Carregar"
                                      >
                                        Cancelar
                                      </button>
                                      <a
                                        class="btn btn-success"
                                        id="sa-custom-position"
                                        @click="SalvarMensagem(PedidoMensagem)"
                                        >Aplicar</a
                                      >
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="
                                        abreMensagem()
                                        "
                                      >
                                        <i class="icon-copy dw dw-print"></i
                                        >Imprimir
                                      </button>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                            <!-- Tasks Tab End -->
                            <!-- Setting Tab start -->
                            <div
                              class="tab-pane fade height-100-p"
                              id="foto"
                              role="tabpanel"
                            >
                              <div class="pd-20 profile-task-wrap">
                                <!-- <div class="row">
                                  <div class="col-md-12">
                                    <div class="table-responsive">
                                      <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Ref.</th>
                                            <th
                                              class="datatable-nosort"
                                              style="width: 10%"
                                            >
                                              imagem
                                            </th>
                                            <th>Produto</th>
                                            <th class="datatable-nosort">
                                              Qtde
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <select class="custom-select">
                                                <option>REF02001</option>
                                                <option value="1" selected>
                                                  REF0200
                                                </option>
                                                <option value="2">
                                                  REF0204
                                                </option>
                                                <option value="3">
                                                  REF020056
                                                </option>
                                                <option value="4">
                                                  REF0212
                                                </option>
                                              </select>
                                            </td>
                                            <td>
                                              <img
                                                src="vendors/images/product-3.jpg"
                                                alt=""
                                              />
                                            </td>
                                            <td class="tamdado">
                                              Nome do Porta Retrato <br />
                                            </td>
                                            <td class="tamdado">1</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div> -->
                                <div
                                  class="row mb-15"
                                  v-if="
                                    PedidoFoto != null &&
                                    PedidoFoto.urlImagem.length > 0
                                  "
                                >
                                  <div class="col-md-12 text-center">
                                    <img
                                      ref="content1"
                                      id="imagemCheck"
                                      :src="PedidoFoto.urlImagem"
                                      style="width: 100%; height: auto"
                                    />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 text-center">
                                    <!-- <button type="button" class="btn btn-link">
                                      Cancelar
                                    </button>
                                    <a
                                      href="#"
                                      class="btn btn-success"
                                      id="sa-custom-position"
                                      >Aplicar</a
                                    > -->
                                    <!-- <button
                                      type="button"
                                      v-if="
                                        PedidoFoto != null &&
                                        PedidoFoto.urlImagem.length > 0
                                      "
                                      @click="downloadWithCSS1(Pedido.pedidoId)"
                                      class="btn btn-primary"
                                    >
                                      <i class="icon-copy dw dw-print"></i
                                      >Imprimir
                                    </button> -->
                                       <button
                                      type="button"
                                      v-if="
                                        PedidoFoto != null &&
                                        PedidoFoto.urlImagem.length > 0
                                      "
                                      @click="abreImagem()"
                                      class="btn btn-primary"
                                    >
                                      <i class="icon-copy dw dw-print"></i
                                      >Imprimir
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Setting Tab End -->
                            <!-- Setting Tab start -->
                            <div
                              class="tab-pane fade height-100-p"
                              id="adicionais"
                              role="tabpanel"
                            >
                              <div class="pd-20 profile-task-wrap">
                                <div class="row" style="padding-bottom: 30px">
                                  <div class="col-lg-10">
                                    <span>Tipo Pagamento:</span>
                                    <v-select
                                      v-model="Pedido.formaPgto"
                                      :options="ListaFormasPagamento"
                                      single-line
                                      :reduce="(x) => x.texto"
                                      label="texto"
                                      class="col-12"
                                    ></v-select>
                                  </div>
                                  <div
                                    class="col-lg-2"
                                    style="padding-top: 10px"
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-info mt-15"
                                      @click="SalvarItem(Pedido)"
                                    >
                                      Alterar
                                    </button>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="table-responsive">
                                      <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Ref.</th>
                                            <th
                                              class="datatable-nosort"
                                              style="width: 10%"
                                            >
                                              imagem
                                            </th>
                                            <th>Produto</th>
                                            <th class="datatable-nosort">
                                              Unit
                                            </th>
                                            <th class="datatable-nosort">
                                              Qtde
                                            </th>
                                            <th class="datatable-nosort">
                                              Total
                                            </th>
                                            <th>Excluir</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="Item in (Pedido.listaPedidoItemAdicional !=
                                              null &&
                                              Pedido.listaPedidoItemAdicional
                                                .length) > 0
                                              ? Pedido.listaPedidoItemAdicional.filter(
                                                  (x) => x.itemAvulso != null
                                                )
                                              : []"
                                            :key="Item.pedidoItemAdicionalId"
                                          >
                                            <td>
                                              AV{{
                                                Item.itemAvulso.itemAvulsoId
                                              }}
                                            </td>
                                            <td>
                                              <img
                                                v-if="
                                                  Item.itemAvulso.urlImg !=
                                                    null &&
                                                  Item.itemAvulso.urlImg
                                                    .length > 0
                                                "
                                                :src="Item.itemAvulso.urlImg"
                                                alt=""
                                              />
                                            </td>
                                            <td class="tamdado">
                                              {{
                                                Item.itemAvulso.nomeItemAvulso
                                              }}
                                            </td>

                                            <td class="tamdado">
                                              R$
                                              {{
                                                Item.valorProduto
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(".", ",")
                                                  .replace("$", "")
                                              }}
                                            </td>
                                            <td class="tamdado">
                                              {{ Item.qtdeProduto }}
                                            </td>
                                            <td class="tamdado">
                                              R$
                                              {{
                                                Item.valorTotalProduto
                                                  .toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  })
                                                  .replace(".", ",")
                                                  .replace("$", "")
                                              }}
                                            </td>
                                            <td>
                                              <a
                                                class="dropdown-item"
                                                @click="
                                                  ApagarItemAdicional(Item)
                                                "
                                                ><i class="dw dw-delete-3"></i
                                              ></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <span style="display: inline-block"
                                      ><a
                                        class=""
                                        data-toggle="modal"
                                        data-target="#ModalComprovante"
                                        type="button"
                                        href="#"
                                        ><i class="dw dw-edit2"></i> Ver/subir
                                        comprovante:</a
                                      ></span
                                    ><br />
                                  </div>
                                  <div class="col-lg-6">
                                    <div
                                      style="
                                        font-size: 18px;
                                        font-weight: 800;
                                        text-align: right;
                                      "
                                    >
                                      Valor Total R$
                                      {{
                                        CalculaValorPedido(Pedido)
                                          .toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                          .replace(".", ",")
                                          .replace("$", "")
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Setting Tab End -->
                            <!-- TAB PERSONALIZADA -->
                            <div
                              class="tab-pane fade height-100-p"
                              id="dados"
                              role="tabpanel"
                            >
                              <div class="pd-20 profile-task-wrap">
                                <div class="row mb-15">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label>Dados</label>
                                      <quill-editor
                                        placeholder="Dados ..."
                                        v-model="Pedido.ibr"
                                      ></quill-editor>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 text-center">
                                    <button
                                      type="button"
                                      class="btn btn-link"
                                      @click="Carregar"
                                    >
                                      Cancelar
                                    </button>
                                    <a
                                      class="btn btn-success"
                                      id="sa-custom-position"
                                      @click="SalvarItem(Pedido)"
                                      >Salvar</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- TAB PERSONALIZADA -->
                            <!-- Setting Tab start -->
                            <div
                              class="tab-pane fade height-100-p"
                              id="obs"
                              role="tabpanel"
                            >
                              <div class="pd-20 profile-task-wrap">
                                <div class="row mb-15">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label>Observações</label>
                                      <quill-editor
                                        placeholder="Observações ..."
                                        v-model="Pedido.obs"
                                      ></quill-editor>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 text-center">
                                    <button
                                      type="button"
                                      class="btn btn-link"
                                      @click="Carregar"
                                    >
                                      Cancelar
                                    </button>
                                    <a
                                      class="btn btn-success"
                                      id="sa-custom-position"
                                      @click="SalvarItem(Pedido)"
                                      >Salvar</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Setting Tab End -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--modal mensagem-->
        <div
          class="modal fade bs-example-modal-lg"
          id="bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">
                  Endereço Entrega
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Endereço</label>
                        <input
                          class="form-control mb-5"
                          type="text"
                          placeholder="Endereço"
                          v-model="PedidoEndereco.endereco"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nº</label>
                        <input
                          class="form-control mb-5"
                          type="number"
                          placeholder="Nº"
                          v-model="PedidoEndereco.numero"
                          @change="
                            PedidoEndereco.numero != null &&
                            PedidoEndereco.numero.toString().length > 0
                              ? (PedidoEndereco.numero = parseInt(
                                  PedidoEndereco.numero
                                ))
                              : (PedidoEndereco.numero = 0)
                          "
                        />
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Compl</label>
                        <input
                          class="form-control mb-5"
                          type="text"
                          placeholder="Complemento"
                          v-model="PedidoEndereco.complemento"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>CEP</label>
                        <input
                          class="form-control mb-5"
                          type="text"
                          placeholder="CEP"
                          v-model="PedidoEndereco.cep"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Estado</label>
                        <v-select
                          v-model="PedidoEndereco.estado"
                          @input="MudaEstado"
                          :options="Estados"
                          :value="Estados.estadoId"
                          single-line
                          label="nomeEstado"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Cidade</label>
                        <v-select
                          v-model="PedidoEndereco.cidade"
                          @input="MudaCidade"
                          :options="Cidades"
                          :value="Cidades.CidadeId"
                          single-line
                          label="nomeCidade"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Bairro</label>
                        <input
                          class="form-control mb-5"
                          type="text"
                          placeholder="Bairro"
                          v-model="PedidoEndereco.bairro"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="Carregar()"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  @click="SalvarEndereco(PedidoEndereco)"
                >
                  Aplicar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--modal mensagem-->
        <div
          class="modal fade"
          id="ModalHora"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">
                  Data e Hora Entrega
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12 mb-15">
                      <span>Data Entrega:</span>
                      <input
                        class="form-control mb-5"
                        type="date"
                        placeholder="Data"
                        v-model="Data1"
                        @change="MudaData1()"
                      />
                    </div>
                    <div class="col-lg-3 mb-15">
                      <span>HI:</span>
                      <select
                        v-model="PedidoAgendamento.horaIni"
                        @change="
                          PedidoAgendamento.horaIni != null &&
                          PedidoAgendamento.horaIni.toString().length > 0
                            ? (PedidoAgendamento.horaIni = parseInt(
                                PedidoAgendamento.horaIni
                              ))
                            : (PedidoAgendamento.horaIni = 0)
                        "
                        class="custom-select col-12"
                      >
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="00">00</option>
                      </select>
                    </div>
                    <div class="col-lg-3 mb-15">
                      <span>MI:</span>
                      <input
                        type="number"
                        max="59"
                        min="1"
                        v-model="PedidoAgendamento.minIni"
                        @change="
                          PedidoAgendamento.minIni != null &&
                          PedidoAgendamento.minIni.toString().length > 0
                            ? (PedidoAgendamento.minIni = parseInt(
                                PedidoAgendamento.minIni
                              ))
                            : (PedidoAgendamento.minIni = 0)
                        "
                        class="custom-select col-12"
                      />
                    </div>
                    <div class="col-lg-3 mb-15">
                      <span>HF:</span>
                      <select
                        v-model="PedidoAgendamento.horaFim"
                        @change="
                          PedidoAgendamento.horaFim != null &&
                          PedidoAgendamento.horaFim.toString().length > 0
                            ? (PedidoAgendamento.horaFim = parseInt(
                                PedidoAgendamento.horaFim
                              ))
                            : (PedidoAgendamento.horaFim = 0)
                        "
                        class="custom-select col-12"
                      >
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="00">00</option>
                      </select>
                    </div>
                    <div class="col-lg-3 mb-15">
                      <span>MF:</span>
                      <input
                        type="number"
                        max="59"
                        min="1"
                        v-model="PedidoAgendamento.minFim"
                        @change="
                          PedidoAgendamento.minFim != null &&
                          PedidoAgendamento.minFim.toString().length > 0
                            ? (PedidoAgendamento.minFim = parseInt(
                                PedidoAgendamento.minFim
                              ))
                            : (PedidoAgendamento.minFim = 0)
                        "
                        class="custom-select col-12"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="Carregar()"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  @click="SalvarAgendamento(PedidoAgendamento)"
                >
                  Aplicar
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--modal mensagem-->
        <div
          class="modal fade"
          id="ModalComprovante"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">Comprovante</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row mb-15">
                    <div class="col-md-12 text-center">
                      <img
                        v-if="
                          PedidoPagtoComprova.urlImg &&
                          PedidoPagtoComprova.urlImg.length > 0
                        "
                        :src="PedidoPagtoComprova.urlImg"
                        style="width: 100%; height: auto"
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Subir comprovante</label>
                        <input
                          id="arquivo"
                          type="file"
                          class="form-control-file form-control height-auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <a :href="$baseUrl + PedidoPagtoComprova.urlImg" target="_blank"
                  >Baixar comprovante</a
                >
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  data-dismiss="modal"
                  @click="EnviarComprovante"
                >
                  Subir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
//import moment from "moment";
import * as momentTemp from "moment";
const moment = momentTemp["default"];

import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

export default {
  data() {
    return {
       ListaFormasPagamento: [],
      ListaFormasPagamentoBanco: [],
      ListaFormasPagamentoPix: [],
      ListaFormasPagamentoOperadora: [],
      Title: document.title,
      Data1: "",
      Pedido: {
        pedidoId: 0,
        cliente: { clienteId: 0 },
        situacaoPedido: "",
        dataPedido: "",
        listaPedidoItemManual: [],
        listaPedidoitemAdicional: [],
        listaPedidoItem: [],
        valorFrete: 0.0,
        valorCupom: 0.0,
      },
      PedidoPagtoComprova: {
        pedidoPagtoComprovaId: 0,
        urlImg: "",
      },
      PedidoEndereco: {
        pedidoEndereco: 0,
        ensereco: "",
        pais: {},
        estado: {},
        cidadde: {},
        bairro: "",
        cep: "",
        numero: 0,
      },
      PedidoAgendamento: {
        pedidoAgendamentoId: 0,
        horaIni: 0,
        minIni: 0,
        horaFim: 0,
        minFim: 0,
        diaEntrega: "",
      },
      EstadoId: 0,
      Estados: [],
      Estadoselected: null,
      CidadeId: 0,
      Cidades: [],
      Cidadeselected: null,
      PedidoFoto: {
        pedidoFotoId: 0,
        urlImagem: "",
        valroFoto: 0.0,
      },
      PedidoMensagem: {
        pedidoMensagemId: 0,
        urlImagem: "",
        nomeDe: "",
        nomePara: "",
        texto: "",
        telefoneRecebe: "",
      },
      // Bairros: [],
      // BairroId: 0,
      // Bairroselected: null,
      T: $(".data-table").DataTable(),
      EspeciesIBR: [],
      EspecieIBR: [],
      idIBR: "",
    };
  },
  methods: {
    abreMensagem(){
      window.open(this.$baseUrl + '/#/controle/imprimirmensagem/' + this.PedidoMensagem.pedidoMensagemId, '_blank');
    },
    abreImagem(){
      window.open(this.$baseUrl + '/#/controle/imprimirimagem/' + this.PedidoFoto.pedidoFotoId, '_blank');
    },
    CarregarSelectIBR() {
      this.$http
        .get("http://api.ibrsistemas.com.br:5009/api/v1/especiepagamento", {
          headers: { Authorization: "bearer " + this.Token },
        })
        .then((response) => {
          if (response.ok) this.EspeciesIBR = response.body;
        });
    },
    EnviarIBR() {
      if (this.EspecieIBR == null || this.EspecieIBR.length <= 0) {
        this.$mensagem_erro("Selecione a espécie de pagamento");
      } else {
        this.$cookie.set("idEspeciePagamento", this.EspecieIBR, {
          expires: "5m",
        });
        var tk = "";
        //this.$http.get(this.$apiUrl + "/institucional/tela/IBR Integração").then(response => {response.ok ? tk = response.body.descricao1:false});
        this.$http
          .get(this.$apiUrl + "/institucional/tela/IBR Integração")
          .then((response) => {
            var tmp = response.body;
            tk = tmp.descricao1;
            this.$http
              .post(this.$apiUrl + "/ibr/gravapedido?apikey=" + tk, this.Pedido)
              .then(
                (response) => {
                  if (response.ok) {
                    //this.$redirect_reload('/controle/pedido/' + this.Pedido.pedidoId);
                    console.log("PEDIDO");
                    console.log(response.body);
                  }
                },
                (error) => {
                  this.$mensagem_erro(error.bodyText);
                }
              );
          });
      }
    },
    SalvarValores() {
      var cont = 0;
      for (cont = 0; cont < this.Pedido.listaPedidoItem.length; cont++) {
        var obj = this.Pedido.listaPedidoItem[cont];
        if (obj != null) {
          this.$http
            .put(this.$apiUrl + "/pedidoitem/" + obj.pedidoItemId, obj)
            .then((response) => {
              if (response.ok) {
                this.$mensagem_sucesso("Valores alterados com sucesso.");
              }
            });
        }
      }
    },
    SalvarMensagem(PedidoMensagem) {
      this.$http
        .put(
          this.$apiUrl + "/pedidomensagem/" + PedidoMensagem.pedidoMensagemId,
          PedidoMensagem
        )
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso("Mensagem alterada com sucesso.");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    downloadWithCSS1(id) {
      if (this.PedidoFoto.pedidoFotoId > 0) {
        domtoimage
          .toPng(this.$refs.content1)
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            const doc = new jsPDF({
              orientation: "portrait",
              // unit: "pt",
              //format: [1772, 1240]
            });

            var elemento = document.getElementById("imagemCheck");
            if (elemento != null) {
              console.log(
                "clientWidth:" +
                  elemento.clientWidth +
                  "    clientHeight:" +
                  elemento.clientHeight
              );
              if (elemento.clientHeight > elemento.clientWidth) {
                // alert("vert");
                //vertical
                doc.addImage(img, "JPEG", 178, -48, 97, 146, "", "NONE", 90);
                //espaco esquerda / espaco cima / altura(flipped) / largura(flipped)

                //doc.addImage(img, "JPEG", 180, -50, 100, 153,'', 'NONE', 90);
                //doc.addImage(img, "JPEG", 185, -52, 107, 160,'', 'NONE', 90);
              } else {
                //horizontal
                doc.addImage(img, "JPEG", 31.5, 0, 148, 99, "", "NONE");
              }
            }

            // doc.addImage(img, "JPEG", 25, -5, 160, 107);
            const filename = "foto_pedido-" + id;
            (".pdf");
            doc.save(filename);
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      }
    },
    downloadWithCSS2(id) {
      
        domtoimage
          .toPng(this.$refs.content2)
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            const doc = new jsPDF({
              orientation: "portrait",
              // unit: "pt",
              //format: [1772, 1240]
            });
            //doc.addImage(img, "JPEG", 22, -7, 165, 110);
            //doc.addImage(img, "JPEG", 24, -5, 162, 109);
            doc.addImage(img, "JPEG", 25, -5, 160, 110, "", "NONE");
            //doc.addImage(img, "JPEG", -10, -7, 0, 0, '', "false", 90);
            const filename = "mensagem_pedido-" + id;
            (".pdf");
            doc.save(filename);
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      
    },
    ApagarItemAdicional(Item) {
      this.$http
        .delete(
          this.$apiUrl + "/PedidoItemAdicional/" + Item.pedidoItemAdicionalId,
          Item
        )
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso("Item adicional removido do pedido");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        )
        .then(() => {
          this.Carregar();
        });
    },
    SalvarAgendamento(PedidoAgendamento) {
      console.log(PedidoAgendamento);
      this.$http
        .put(
          this.$apiUrl +
            "/PedidoAgendamento/" +
            PedidoAgendamento.pedidoAgendamentoId,
          PedidoAgendamento
        )
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso("Agendamento alterado com sucesso.");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        )
        .then(() => {
          this.Carregar();
        });
    },
    MudaData1() {
      this.PedidoAgendamento.diaEntrega = moment(this.Data1)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    SalvarEndereco(PedidoEndereco) {
      console.log(PedidoEndereco);
      this.$http
        .put(
          this.$apiUrl + "/pedidoendereco/" + PedidoEndereco.pedidoEnderecoId,
          PedidoEndereco
        )
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso("Endereço alterado com sucesso.");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        )
        .then(this.Carregar());
    },
    MudaEstado(value) {
      this.EstadoId = value.estadoId;
      this.CidadeId = 0;
      this.Cidades = [];
      this.Cidadeselected = null;
      this.$http
        .get(this.$apiUrl + "/cidades/ativosEstados/" + parseInt(this.EstadoId))
        .then((res) => res.json())
        .then((tiu) => (this.Cidades = tiu));
    },
    MudaCidade(value) {
      this.CidadeId = value.cidadeId;
      // this.BairroId = 0;
      // this.Bairros = [];
      // this.Bairroselected = null;
      // this.$http
      //   .get(this.$apiUrl + "/bairro/ativosBairros/" + parseInt(this.CidadeId))
      //   .then((res) => res.json())
      //   .then((tiu) => (this.Bairros = tiu));
    },
    // MudaBairro(value) {
    //   this.BairroId = value.bairroId;
    //   this.$http.get(this.$apiUrl + "/bairro/" + this.BairroId)
    //   .then(response => {
    //     this.PedidoEndereco.bairro = response.body.nomeBairro;
    //   })
    // },
    EnviarComprovante() {
      var novo = false;
      if (this.PedidoPagtoComprova.pedidoPagtoComprovaId > 0) {
        novo = false;
      } else {
        novo = true;
      }
      var erroarquivo = "";
      let file = document.getElementById("arquivo").files[0];
      let URLIMAGEM = "";
      if (file != null) {
        var ext = file.name.split(".").pop();

        if (
          ext != "jpg" &&
          ext != "png" &&
          ext != "gif" &&
          ext != "jpeg" &&
          ext != "pdf"
        ) {
          erroarquivo =
            "Por favor selecione um arquivo ('.jpg', '.jpeg', '.png', '.gif' ou '.pdf')";
        } else {
          erroarquivo = "";
        }
      }

      if (erroarquivo.length > 0) {
        this.$mensagem_erro(erroarquivo);
      } else {
        let data = new FormData();
        if (file != null) {
          data.append("arquivo", file);
          console.log(file);
          this.$http
            .post(this.$apiUrl + "/pedidopagtocomprova/upload", data)
            .then(
              (response3) => {
                // get body data
                URLIMAGEM = response3.body;
                console.log("SUBIU AQUI: " + URLIMAGEM);
                if (novo) {
                  //cria novo
                  var comprovante = {
                    pedidoPagtoComprovaId: 0,
                    pedido: {
                      pedidoId: this.Pedido.pedidoId,
                    },
                    urlImg: URLIMAGEM,
                  };
                  this.$http
                    .post(this.$apiUrl + "/pedidopagtocomprova", comprovante)
                    .then(
                      (response) => {
                        if (response.ok) {
                          this.PedidoPagtoComprova = response.body;
                          this.$mensagem_sucesso(
                            "Comrprovante alterado com sucesso."
                          );
                        }
                      },
                      (error) => {
                        if (error != null)
                          this.$mensagem_erro("Erro ao salvar comprovante.");
                      }
                    );
                } else {
                  //atualiza existente
                  this.PedidoPagtoComprova.urlImg = URLIMAGEM.toString();
                  this.$http
                    .put(
                      this.$apiUrl +
                        "/pedidopagtocomprova/" +
                        this.PedidoPagtoComprova.pedidoPagtoComprovaId,
                      this.PedidoPagtoComprova
                    )
                    .then(
                      (response) => {
                        if (response.ok) {
                          this.$mensagem_sucesso(
                            "Comrprovante alterado com sucesso."
                          );
                        }
                      },
                      (error) => {
                        if (error != null)
                          this.$mensagem_erro("Erro ao salvar comprovante.");
                      }
                    );
                }
                // this.$redirect_reload("/controle/usuarios");
              },
              (response3) => {
                // error callback
                this.$mensagem_erro(response3.body);
              }
            );
        }
      }
    },
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    SalvarItem(Pedido) {
      this.PedidoEndereco.cidade = this.Cidadeselected;
      this.PedidoEndereco.estado = this.Cidadeselected;

      this.$http
        .put(this.$apiUrl + "/pedido/" + Pedido.pedidoId, Pedido)
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso("Pedido alterado com sucesso.");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        )
        .then(this.Carregar());
    },
    CalculaValorPedido(Pedido) {
      var total = 0;
      total += Pedido.valorFrete;
      total -= Pedido.valorCupom;

      if (Pedido.listaPedidoItem != null && Pedido.listaPedidoItem.length > 0) {
        Pedido.listaPedidoItem.forEach((item) => {
          total += item.valorProduto * item.qtdeProduto;
        });
      }

      if (
        Pedido.listaPedidoItemAdicional != null &&
        Pedido.listaPedidoItemAdicional.length > 0
      ) {
        Pedido.listaPedidoItemAdicional.forEach((item) => {
          total += item.valorProduto * item.qtdeProduto;
        });
      }

      if (
        Pedido.listaPedidoItemManual != null &&
        Pedido.listaPedidoItemManual.length > 0
      ) {
        Pedido.listaPedidoItemManual.forEach((item) => {
          total += item.valorProduto * item.qtdeProduto;
        });
      }

      return total;
    },
    Limpar() {
      this.$redirect_reload("/controle/listapedidos");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
    Carregar() {
      if (this.$route.params.id != null) {
        this.$http
          .get(this.$apiUrl + "/estados/ativos")
          .then((res) => res.json())
          .then((tiu) => {
            this.Estados = tiu;
          });
        this.Pedido.pedidoId = parseInt(this.$route.params.id, 10);
        this.$http.get(this.$apiUrl + "/pedido/" + this.Pedido.pedidoId).then(
          (response) => {
            if (response.ok) {
              this.Pedido = response.body;
              if(this.Pedido.ibr != null && this.Pedido.ibr.length > 0)
              {
                var tt = JSON.parse(this.Pedido.ibr);
                this.idIBR = "ID da venda no sistema IBR: " + tt._id;
              }
            }
          }
          // ,
          // (error) => {
          //   this.$mensagem_erro(error.bodyText);
          // }
        );

        //--------------------------------------------------------------------------------

        this.$http
          .get(this.$apiUrl + "/pedidoendereco/pedido/" + this.Pedido.pedidoId)
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoEndereco = response.body;
                if (
                  this.PedidoEndereco.estado != null &&
                  this.PedidoEndereco.estado.estadoId > 0
                )
                  this.MudaEstado(this.PedidoEndereco.estado);
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );

        //--------------------------------------------------------------------------------

        this.$http
          .get(
            this.$apiUrl + "/pedidopagtocomprova/pedido/" + this.Pedido.pedidoId
          )
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoPagtoComprova = response.body;
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );

        //--------------------------------------------------------------------------------

        this.$http
          .get(
            this.$apiUrl + "/pedidoagendamento/pedido/" + this.Pedido.pedidoId
          )
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoAgendamento = response.body;
                this.Data1 = moment(this.PedidoAgendamento.diaEntrega).format(
                  "YYYY-MM-DD"
                );
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );

        //-------------------------------------------------------------------------------
        this.$http
          .get(this.$apiUrl + "/pedidomensagem/pedido/" + this.Pedido.pedidoId)
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoMensagem = response.body;
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );

        //-------------------------------------------------------------------------------
        this.$http
          .get(this.$apiUrl + "/pedidofoto/pedido/" + this.Pedido.pedidoId)
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoFoto = response.body;
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );
      }
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.Carregar();
    // var vazio = {};
    // var dados = {
    //   email: "avancodigital@hortifrutileticia.com.br",
    //   senha: "avanco@ibr123",
    //   acessos: [
    //     {
    //       tipoAcesso: "MinhasVendas",
    //       identificadorDispositivo: "75CDABDAABA35F5BEE6EB91C5A503C41",
    //       // identificadorDispositivo: '75CDABDAABA35F5BEE6EB91C5A503C42',
    //       versao: "AVANCE",
    //     },
    //   ],
    // };
    // this.$http
    //   .get(this.$apiUrl + "/institucional/tela/IBR Integração")
    //   .then((response) => {
    //     if (response.ok) {
    //       vazio = response.body;
    //       this.Token = response.body.descricao1;
    //       dados.senha = vazio.referencia;
    //       var ac = {
    //         tipoAcesso: vazio.campoExtra1,
    //         identificadorDispositivo: vazio.campoExtra2,
    //         // identificadorDispositivo: '75CDABDAABA35F5BEE6EB91C5A503C42',
    //         versao: vazio.campoExtra3,
    //       };
    //       dados.acessos.pop();
    //       dados.acessos.push(ac);
    //       console.log("dados:");
    //       console.log(dados);
    //       this.$http
    //         .get("http://api.ibrsistemas.com.br:5009/api/v1/produto", {
    //           headers: { Authorization: "bearer " + this.Token },
    //         })
    //         .then(
    //           (response) => {
    //             console.log(response);
    //             this.CarregarSelectIBR();
    //           },
    //           (error) => {
    //             console.log(error);
    //             // dados.email = vazio.nome;
    //             // dados.senha = vazio.referencia;
    //             // dados.acessos.tipoAcesso = vazio.campoExtra1;
    //             // dados.acessos.identificadorDispositivo = vazio.campoExtra2;
    //             // dados.acessos.versao = vazio.campoExtra3;

    //             this.$http
    //               .post(
    //                 "http://api.ibrsistemas.com.br:5009/api/v1/auth/login",
    //                 dados
    //               )
    //               .then((response) => {
    //                 if (response.ok) {
    //                   this.Token = response.body.auth.access_token;
    //                   this.CarregarSelectIBR();

    //                   vazio.descricao1 = this.Token;
    //                   this.$http.put(
    //                     this.$apiUrl +
    //                       "/institucional/" +
    //                       vazio.institucionalId,
    //                     vazio
    //                   );
    //                 }
    //               });
    //           }
    //         );
    //     }
    //   });


       var _formasPagamento = [];
    this.$http
      .get(this.$apiUrl + "/configoperadora")
      .then((res2) => res2.json())
      .then((tiu2) => {
        //carrega informações da lista de opções de pagamento
        this.$http
          .get(this.$apiUrl + "/configpix")
          .then((res2) => res2.json())
          .then((tiu3) => {
            this.ListaFormasPagamentoPix = tiu3.filter((x) => x.status);
            if (this.ListaFormasPagamentoPix != null) {
              this.ListaFormasPagamentoPix.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadPix.cadPixId,
                  Operacao: "cadPix",
                  texto: item.cadPix.nomePix,
                });
              });
            }

            this.ListaFormasPagamentoOperadora = tiu2.filter((x) => x.status);
            if (this.ListaFormasPagamentoOperadora != null) {
              this.ListaFormasPagamentoOperadora.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadOperadora.cadOperadoraId,
                  Operacao: "cadOperadora",
                  texto: item.cadOperadora.nomeOperadora,
                });
              });
            }

            //carrega informações da lista de opções de pagamento
            this.$http
              .get(this.$apiUrl + "/configbanco")
              .then((res2) => res2.json())
              .then((tiu2) => {
                this.ListaFormasPagamentoBanco = tiu2.filter((x) => x.status);
                if (this.ListaFormasPagamentoBanco != null) {
                  this.ListaFormasPagamentoBanco.forEach((item) => {
                    _formasPagamento.push({
                      OperacaoId: item.cadBanco.cadBancoId,
                      Operacao: "cadBanco",
                      texto: item.cadBanco.nomeBanco,
                    });
                  });
                }
              });
          });
      });

    this.ListaFormasPagamento = _formasPagamento;
    this.ListaFormasPagamento.sort(function (a, b) {
      return a.texto - b.texto;
    });
  },
  mounted() {
    if (document.title != "SGCAD - Love Cestas") {
      document.getElementById("mensagem").style.display = "none";
      document.getElementById("foto").style.display = "none";
      document.getElementById("menuMensagem").style.display = "none";
      document.getElementById("menuFoto").style.display = "none";
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}

@media (max-width: 600px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 100%;
    outline: none;
    height: 270px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 12px;
  }

  .imagemCartao {
    height: 270px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 90%;
  }
}

@media (min-width: 608px) and (max-width: 991px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 98%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 89%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 18.2px;
  }

  .imagemCartao {
    height: 400px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}
</style>
