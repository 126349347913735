<template>
  <div id="print" style="background:white !important">
   
        <div ref="content2" class="editar-cartao text-center">
          <!--aqui regra do preenchimento do cartão -->
          <div
            class="imagemCartao"
            :style="{
              backgroundImage: 'url(' + $baseUrl + '/' + PedidoMensagem.urlImagem + ')',
            }"
          >
            <textarea
              v-model="PedidoMensagem.texto"
              class="textareacartao"
              disabled
              maxlength="1000"
              style="
                font-family: Handlee !important;
                text-align: center;
                padding-right: 18px;
                width: 659px !important;
              "
            ></textarea>
          </div>
        </div>

    <!-- <button class="noprint" @click="print()" type="button">aaaaaaaa</button> -->
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

export default {
  data() {
    return {
      Id: this.$route.params.id,
      PedidoMensagem: {},
    };
  },
  methods:{
    print() {
      window.print();
    },
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/pedidomensagem/" + this.Id)
      .then(
        (response) => {
          if (response.ok && response.bodyText.length > 0) {
            this.PedidoMensagem = response.body;
          }
        }
      );
  },
  mounted(){
     var side = document.getElementsByClassName("left-side-bar");
      if(side != null){
        side[0].style.display = "none";
      }
      var header = document.getElementsByClassName("header");
      if(header != null){
        header[0].style.display = "none";
      }
       var footer = document.getElementsByClassName("footer-wrap");
      if(footer != null){
        footer[0].style.display = "none";
      }
      var body = document.getElementsByTagName('body')[0];
      body.style.background = "white";
      setTimeout(() => {  this.print() }, 2000);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
  background: white !important;
}
@media print {
   .noprint {
      visibility: hidden;
   }
}
.left-side-bar{
  display:none;
}
.header{
  display:none;
}
.footer-wrap{
  display:none;
}
@media (max-width: 600px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 100%;
    outline: none;
    height: 270px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 12px;
  }

  .imagemCartao {
    height: 270px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 90%;
  }
}

@media (min-width: 608px) and (max-width: 991px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 98%;
    outline: none;
    height: 450px;
    margin-top:16px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 475px;
    width: auto;
    margin-left:-2px;
    margin-top:-8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 89%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 25px;
  }

  .imagemCartao {
    /* height: 79vw;
    width: 62vw; */
    /* width: auto;
    height: 748px; */
    /* width: 9000px;
    height: 9000px; */
    border-left: 122px solid white;
    border-top: 4px solid white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}
</style>

