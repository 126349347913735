<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Opções de Frete</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das opções de frete fora correio.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <v-select v-model="Estadoselected" @input="MudaEstado"
                                                          :options="Estados"
                                                          :value="Estados.EstadoId"
                                                          single-line
                                                          label="nomeEstado" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Cidade</label>
                                                <v-select v-model="Cidadeselected" @input="MudaCidade"
                                                          :options="Cidades"
                                                          :value="Cidades.CidadeId"
                                                          single-line
                                                          label="nomeCidade" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Bairro</label>
                                                <v-select v-model="Bairroselected" @input="MudaBairro"
                                                          :options="Bairros"
                                                          :value="Bairros.BairroId"
                                                          single-line
                                                          label="nomeBairro" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Tipo Entrega</label>
                                                <input v-model="TipoEntrega" class="form-control" type="text" placeholder="Tipo Entrega">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Atraso Opcional (hs)</label>
                                                <input v-model="AtrasoHs" class="form-control" type="number" placeholder="">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Atraso Padrão (hs)</label>
                                                <input v-model="AtrasoPadrao" class="form-control" type="number" placeholder="">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Valor</label>
                                                <money v-model.lazy="ValorFrete"
                                                       class="form-control decimal"
                                                       type="text"
                                                       v-bind="money" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Prazo (dias)</label>
                                                <input v-model="PrazoDias" class="form-control" type="number" placeholder="Prazo (dias)">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Compra mínima</label>
                                                <money v-model.lazy="CompraMinima"
                                                       class="form-control decimal"
                                                       type="text"
                                                       v-bind="money" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="EntregaFreteId != null && EntregaFreteId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Estado</th>
                                <th>Cidade</th>
                                <th>Bairro</th>
                                <th>Tipo</th>
                                <th>Atraso</th>
                                <th>Valor</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="EntregaFrete in EntregaFretes" :key="EntregaFrete.entregaFreteId">
                                <td>{{ EntregaFrete.entregaFreteId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/entregafrete/' + EntregaFrete.entregaFreteId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(EntregaFrete)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span v-if="EntregaFrete.estado == null ">
                                        Todos  
                                    </span><span v-else> {{ EntregaFrete.estado.nomeEstado }}</span>
                                </td>
                                <td>
                                    <span v-if="EntregaFrete.cidade == null">
                                        Todos
                                    </span><span v-else>{{ EntregaFrete.cidade.nomeCidade }}</span>
                                </td>
                                <td>
                                    <span v-if="EntregaFrete.bairro == null">
                                        Todos
                                    </span><span v-else>{{ EntregaFrete.bairro.nomeBairro }}</span>
                                </td>
                                <td>{{ EntregaFrete.tipoEntrega }}</td>
                                <td>{{ EntregaFrete.atrasoHs }}</td>
                                <td>{{ EntregaFrete.valorFrete.toString().replace('.',',') }}</td>
                                <td><span v-if="EntregaFrete.status">Ativo</span><span v-else>Inativo</span></td>
                                
                            </tr>
                            <!-- <tr>
              <td
                valign="top"
                v-if="Idiomas.length <= 0"
                colspan="5"
                class="dataTables_empty"
              >
                Nenhum resultado encontrado
              </td>
            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                EntregaFreteId: this.$route.params.id,
                TipoEntrega: "",
                AtrasoHs: 0,
                AtrasoPadrao: 0,
                ValorFrete: 0,
                PrazoDias: 0,
                CompraMinima: 0,
                PaisId: 0,
                Pais: {},
                Paises: [],
                EstadoId: 0,
                Estados: [],
                Estadoselected: null,
                CidadeId: 0,
                Cidades: [],
                Cidadeselected: null,
                Bairros: [],
                BairroId: 0,
                Bairroselected: null,
                Ativo: null,
                EntregaFrete: {},
                EntregaFretes: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                this.CidadeId = 0;
                this.Cidades = [];
                this.Cidadeselected = null;
                this.$http
                    .get(this.$apiUrl + "/cidades/ativosEstados/" + parseInt(this.EstadoId))
                    .then((res) => res.json())
                    .then((tiu) => (this.Cidades = tiu));
            },
            MudaCidade(value) {
                this.CidadeId = value.cidadeId;
                this.BairroId = 0;
                this.Bairros = [];
                this.Bairroselected = null;
                this.$http
                    .get(this.$apiUrl + "/bairro/ativosBairros/" + parseInt(this.CidadeId))
                    .then((res) => res.json())
                    .then((tiu) => (this.Bairros = tiu));
            },
            MudaBairro(value) {
                this.BairroId = value.bairroId;
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                let _EntregaFrete = {
                    entregaFreteId: 0,
                    tipoEntrega: "",
                    atrasoHs: 0,
                    atrasoPadrao: 0,
                    valorFrete: 0,
                    prazoDias: 0,
                    compraMinima: 0,
                    pais: { PaisId: 1 },
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    bairro: { BairroId: this.BairroId},
                    status: null,
                };

                //validações
                if (this.TipoEntrega.length <= 0) {
                    this.$mensagem_normal("Por favor preencha tipo de entrega");
                } else if (this.ValorFrete == null) {
                    this.$mensagem_normal("Por favor preencha o valor");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _EntregaFrete.tipoEntrega = this.TipoEntrega;
                    _EntregaFrete.atrasoHs = parseInt(this.AtrasoHs.toString(),10);
                    _EntregaFrete.atrasoPadrao = parseInt(this.AtrasoPadrao.toString(),10);
                    _EntregaFrete.valorFrete = parseFloat(this.ValorFrete.toString().replace(".", "").replace(",", ".")); 
                    _EntregaFrete.prazoDias = parseInt(this.PrazoDias.toString(),10);
                    _EntregaFrete.compraMinima = parseFloat(this.CompraMinima.toString().replace(".", "").replace(",", "."));
                    _EntregaFrete.status = this.Ativo;

                    //chama o post da api

                    console.log(_EntregaFrete);
                    this.$http.post(this.$apiUrl + "/entregafrete/", _EntregaFrete).then(
                        response2 => {
                            // get body data
                            _EntregaFrete = response2.body;
                            this.$redirect_reload("/controle/entregafrete");
                        },
                        response2 => {
                            _EntregaFrete = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            this.$mensagem_normal(_EntregaFrete);
                        }
                    );
                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/entregafrete/" + DadosRemover.entregaFreteId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/entregafrete");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _EntregaFrete = {
                    entregaFreteId: 0,
                    tipoEntrega: "",
                    atrasoHs: 0,
                    atrasoPadrao: 0,
                    valorFrete: 0,
                    prazoDias: 0,
                    compraMinima: 0,
                    pais: { PaisId: 1 },
                    estado: { EstadoId: this.EstadoId },
                    cidade: { CidadeId: this.CidadeId },
                    bairro: { BairroId: this.BairroeId },
                    status: null,
                };

                //validações
                if (this.TipoEntrega.length <= 0) {
                    this.$mensagem_normal("Por favor preencha tipo de entrega");
                } else if (this.ValorFrete == null) {
                    this.$mensagem_normal("Por favor preencha o valor");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                      _EntregaFrete.tipoEntrega = this.TipoEntrega;
                    _EntregaFrete.atrasoHs = parseInt(this.AtrasoHs.toString(),10);
                    _EntregaFrete.atrasoPadrao = parseInt(this.AtrasoPadrao.toString(),10);
                    _EntregaFrete.valorFrete = parseFloat(this.ValorFrete.toString().replace(".", "").replace(",", ".")); 
                    _EntregaFrete.prazoDias = parseInt(this.PrazoDias.toString(),10);
                    _EntregaFrete.compraMinima = parseFloat(this.CompraMinima.toString().replace(".", "").replace(",", "."));
                    _EntregaFrete.status = this.Ativo;
                    _EntregaFrete.entregaFreteId = parseInt(this.EntregaFreteId.toString(), 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/entregafrete/" + _EntregaFrete.entregaFreteId, _EntregaFrete)
                        .then(
                            response2 => {
                                // get body data
                                _EntregaFrete = response2.body;
                                console.log(_EntregaFrete);
                                this.$mensagem_sucesso(
                                    "Entrega Frete #" + this.EntregaFreteId + " alterado com sucesso"
                                );
                                this.$redirect("/controle/entregafrete");
                            },
                            async response2 => {
                                _EntregaFrete = response2.body;
                                // error callback
                                this.$mensagem_normal(_EntregaFrete);
                            }
                        );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/entregafrete");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.EntregaFreteId == null) {
                this.EntregaFreteId = "";
            }

            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res) => res.json())
                .then((tiu) => {
                    this.Estados = tiu
                });

            //chama o get da api this.$apiUrl
            if (this.EntregaFreteId != null && this.EntregaFreteId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/entregafrete/" + this.EntregaFreteId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.EntregaFrete = idi;

                        //carrega os campos

                        if (this.EntregaFrete.estado != null) {
                            this.EstadoId = this.EntregaFrete.estado.estadoId;
                            this.Estadoselected = this.EntregaFrete.estado;

                            this.$http
                                .get(this.$apiUrl + "/cidades/ativosEstados/" + parseInt(this.EstadoId))
                                .then((res) => res.json())
                                .then((tiu) => {
                                    this.Cidades = tiu
                                });

                            if (this.EntregaFrete.cidade != null) {
                                this.CidadeId = this.EntregaFrete.cidade.cidadeId;
                                this.Cidadeselected = this.EntregaFrete.cidade;

                                this.$http
                                    .get(this.$apiUrl + "/Bairro/ativosCidades/" + parseInt(this.CidadeId))
                                    .then((res2) => res2.json())
                                    .then((tiu2) => {
                                        this.Bairros = tiu2

                                        if (this.EntregaFrete.Bairro != null) {
                                            this.BairroId = this.EntregaFrete.Bairro.bairroId;
                                            this.Bairroselected = this.EntregaFrete.Bairro;
                                        }
                                        else {
                                            this.BairroId = 0;
                                            this.Bairroselected = null;
                                        }
                                    });
                            }
                            else {
                                this.CidadeId = 0;
                                this.Cidadeselected = null;
                                this.BairroId = 0;
                                this.Bairroselected = null;
                            }
                        }
                        else {
                            this.EstadoId = 0;
                            this.Estadoselected = null;
                            this.CidadeId = 0;
                            this.Cidadeselected = null;
                            this.BairroId = 0;
                            this.Bairroselected = null;
                        }

                        this.TipoEntrega = this.EntregaFrete.tipoEntrega;
                        this.AtrasoHs = this.EntregaFrete.atrasoHs;
                        this.AtrasoPadrao = this.EntregaFrete.atrasoPadrao;
                        this.Statuselected = this.EntregaFrete.status;
                        this.Ativo = this.EntregaFrete.status;
                        this.ValorFrete = this.EntregaFrete.valorFrete;
                        this.PrazoDias = this.EntregaFrete.prazoDias;
                        this.CompraMinima = this.EntregaFrete.compraMinima;
                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/entregafrete")
                    .then((res) => res.json())
                    .then((moe) => (this.EntregaFretes = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {
        },
        components: {
            vSelect,
        },
    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
