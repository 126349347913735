<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Moedas</h4>
                                            <p class="mb-30">
                                                Tela para cadastro da moeda por idioma.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Tipo</label>
                                                <input v-model="NomeMoeda" class="form-control" type="text" placeholder="Moeda">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Idioma</label>
                                                <v-select v-model="Idiomaselected" @input="MudaIdioma"
                                                          :options="Idiomas"
                                                          :value="Idiomas.IdiomaId"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="MoedaId == null || MoedaId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/moedas/' + NomeMoeda)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="MoedaId != null && MoedaId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Moeda</th>
                                <th>Idioma</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Moeda in Moedas" :key="Moeda.moedaId">
                                <td>{{ Moeda.moedaId }}</td>
                                 <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/moeda/' + Moeda.moedaId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Moeda)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Moeda.nomeMoeda }}</td>
                                <td>
                                    <span v-if="Moeda.idioma != null">
                                        {{
                                         Moeda.idioma.nome
                                        }}
                                    </span><span v-else>#</span>
                                </td>
                                <td><span v-if="Moeda.ativo">Ativo</span><span v-else>Inativo</span></td>
                               
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                MoedaId: this.$route.params.id,
                IdiomaId: "",
                Ativo: null,
                NomeMoeda: "",
                Moeda: {},
                Moedas: [],
                Idiomas: [],
                Idiomaselected: null,
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false } ],
                T: {},
            };
        },
        methods: {
            MudaIdioma(value) {
                this.IdiomaId = value.idiomaId;
            },
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                let _Moedas = {
                    MoedaId: 0,
                    IdiomaId: this.IdiomaId,
                    ativo: null,
                    NomeMoeda: this.NomeMoeda,
                };

                //validações
                if (this.NomeMoeda.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome da Moeda");
                } else if (this.Idiomaselected == null) {
                    this.$mensagem_normal("Por favor preencha o Idioma");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    _Moedas.ativo = this.Ativo;
                    //chama o post da api
                    let ID = _Moedas.IdiomaId;
                    this.$http.post(this.$apiUrl + "/moedas/" + ID, _Moedas).then(
                        response2 => {
                            // get body data
                            _Moedas = response2.body;
                            this.$redirect_reload("/controle/Moedas");
                        },
                        response2 => {
                            _Moedas = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            this.$mensagem_normal(_Moedas);
                        }
                    );
                }
            },
            Remove(MoedaRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/moedas/" + MoedaRemover.moedaId)
                    .then(
                        () => {
                            // get body data
                            //remove do array
                            //var indice = this.Idiomas.indexOf(IdiomaRemover);
                            //this.Idiomas.splice(indice, 1);
                            this.$redirect_reload("/controle/moedas");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Moeda = {
                    moedaId: 0,
                    nomeMoeda: this.NomeMoeda,
                    idioma: {},
                    ativo: null,
                };

                console.log(this.Ativo);
                //validações
                if (this.NomeMoeda.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o nome da Moeda");
                } else if (this.Idiomaselected == null) {
                    this.$mensagem_normal("Por favor preencha o Idioma");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    this.$http.get(this.$apiUrl + "/idiomas/" + this.IdiomaId).then(
                        (response) => {

                            _Moeda.idioma = response.body;

                            _Moeda.ativo = this.Ativo;
                            _Moeda.moedaId = parseInt(this.MoedaId, 10);

                            //chama o post da api
                            this.$http
                                .put(this.$apiUrl + "/moedas/" + this.MoedaId, _Moeda)
                                .then(
                                    response2 => {
                                        // get body data
                                        _Moeda = response2.body;
                                        console.log(_Moeda);
                                        this.$mensagem_sucesso(
                                            "Moeda #" + this.MoedaId + " alterada com sucesso"
                                        );
                                        this.$redirect("/controle/moedas");
                                    },
                                    async response2 => {
                                        _Moeda = response2.body;
                                        // error callback
                                        //const data = await response2.json();
                                        //JSON.parse(data);
                                        this.$mensagem_normal(_Moeda);
                                    }
                                );
                        },
                    );
                }
            },
            Pesquisa() {
                try {
                    if (this.NomeMoeda.length <= 0) {
                        this.$mensagem_normal("Por favor preencha o nome da Moeda");
                    } else {
                        this.Moedas = [];
                        let _Moedas = {
                            NomeMoeda: this.NomeMoeda,
                        };
                        this.$http
                            .post(this.$apiUrl + "/moedas/busca", _Moedas)
                            .then((res) => res.json())
                            .then((tiu) => (this.Moedas = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/moedas");

                this.$http
                    .get(this.$apiUrl + "/moedas")
                    .then((res) => res.json())
                    .then((idi) => (this.MoedaId = idi)
                        .then(this.$redirect_reload("/controle/moedas")));

            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.NomeMoeda = this.$route.params.pesquisa;
            } else {
                this.NomeMoeda = "";
            }

            if (this.MoedaId == null) {
                this.MoedaId = "";
            }

            this.$http
                .get(this.$apiUrl + "/idiomas/ativos")
                .then((res) => res.json())
                .then((tiu) => (this.Idiomas = tiu));

            //chama o get da api this.$apiUrl
            if (this.MoedaId != null && this.MoedaId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/moedas/" + this.MoedaId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Moeda = idi;

                        this.IdiomaId = this.Moeda.idioma.idiomaId;
                        this.NomeMoeda = this.Moeda.nomeMoeda;
                        this.Idiomaselected = this.Moeda.idioma;
                        this.Statuselected = this.Moeda.ativo;
                        this.Ativo = this.Moeda.ativo;

                    });
            }
            else if (this.NomeMoeda != null && this.NomeMoeda.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/moedas")
                    .then((res) => res.json())
                    .then((moe) => (this.Moedas = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
