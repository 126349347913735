<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Arquivos</h4>
                      <p class="mb-30">
                        Tela para cadastro dos arquivos disponibilizados na área
                        interna do site para clientes.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 1</label>
                        <v-select
                          v-model="Segmento1"
                          :options="Segmentos1"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel1Id"
                          @input="MudaSegmento1"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 2</label>
                        <v-select
                          v-model="Segmento2"
                          :options="Segmentos2"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel2Id"
                          @input="MudaSegmento2"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 3</label>
                        <v-select
                          v-model="Segmento3"
                          :options="Segmentos3"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel3Id"
                          @input="MudaSegmento3"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Nivel 4</label>
                        <v-select
                          v-model="Segmento4"
                          :options="Segmentos4"
                          single-line
                          :reduce="(x) => x.segmentoCliNivel4Id"
                          label="nomeSegmento"
                          class="col-12"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Nome do Arquivo</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                          v-model="Arquivo.nomeArquivo"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Ordem</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Ordem"
                          v-model="Arquivo.ordem"
                          @change="Arquivo.ordem != null ? Arquivo.ordem = parseInt(Arquivo.ordem, 10):Arquivo.ordem = 0"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Destaque</label>
                        <v-select
                          v-model="Arquivo.destaque"
                          :options="[
                            { nome: 'Sim', destaque: true },
                            { nome: 'Não', destaque: false },
                          ]"
                          single-line
                          :reduce="(x) => x.destaque"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Visivel</label>
                        <v-select
                          v-model="Arquivo.visivel"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="ArquivoId != null && ArquivoId > 0">
                      <div class="form-group">
                        <label>Arquivo</label>
                         <img
                          src="/images/cloud-upload.gif"
                          v-if="Enviando > Enviado"
                        />
                        <input v-else
                          type="file"
                          class="form-control-file form-control height-auto"
                          @change="Upload"
                        />
                        <small class="form-text text-muted">
                          *Aceitos somente pdf, txt ou word com tamanho máximo
                          de 10MB.
                        </small>
                        <br>
                        <a :href="ArquivoUpload" v-if="ArquivoUpload != null && ArquivoUpload.length > 0" target="_blank">Arquivo atual - {{ ArquivoUpload }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="button" class="btn btn-link" @click="$redirect_reload('/controle/arquivoscli')">
                        Cancelar
                      </button>
                      <button type="button" class="btn btn-info">
                        Pesquisar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Salvar()"
                        v-if="ArquivoId == null || ArquivoId <= 0"
                      >
                        Salvar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Alterar()"
                        v-else
                      >
                        Alterar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Abrir</th>
                <th>Nome</th>
                <th>Nivel 1</th>
                <th>Nivel 2</th>
                <th>Nivel 3</th>
                <th>Nivel 4</th>
                <th>Ordem</th>
                <th>Destaque</th>
                <th>Visível</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Arquivo, index) in Arquivos" :key="index">
                <td>{{ Arquivo.arquivoId }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect_reload(
                            '/controle/arquivocli/' + Arquivo.arquivoId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Arquivo)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <a :href="Arquivo.urlArquivo" target="_blank"
                    >{{Arquivo.urlArquivo}}</a>
                </td>
                <td>{{ Arquivo.nomeArquivo }}</td>
                <td>
                  <span v-if="Arquivo.segmentoCliNivel1 != null">{{
                    Arquivo.segmentoCliNivel1.nomeSegmento
                  }}</span>
                </td>
                <td>
                  <span v-if="Arquivo.segmentoCliNivel2 != null">{{
                    Arquivo.segmentoCliNivel2.nomeSegmento
                  }}</span>
                </td>
                <td>
                  <span v-if="Arquivo.segmentoCliNivel3 != null">{{
                    Arquivo.segmentoCliNivel3.nomeSegmento
                  }}</span>
                </td>
                <td>
                  <span v-if="Arquivo.segmentoCliNivel4 != null">{{
                    Arquivo.segmentoCliNivel4.nomeSegmento
                  }}</span>
                </td>
                <td>{{ Arquivo.ordem }}</td>
                <td>
                  <span v-if="Arquivo.destaque">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Arquivo.visivel">Sim</span><span v-else>Não</span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      ArquivoId: {},
      Arquivo: {},
      Arquivos: [],
      Segmento1: 0,
      Segmentos1: [],
      Segmento2: 0,
      Segmentos2: [],
      Segmento3: 0,
      Segmentos3: [],
      Segmento4: 0,
      Segmentos4: [],
      T: $(".data-table").DataTable(),
      Enviando: 0,
      Enviado: 0,
      ArquivoUpload: {}
    };
  },
  components: {
    vSelect,
    // Rodape,
  },
  created() {
    this.$http.get(this.$apiUrl + "/arquivos/").then((response) => {
      this.Arquivos = response.body;
    }).then(this.atualiza());

    this.$http.get(this.$apiUrl + "/segmentoCliNivel1/").then((response) => {
      console.log("DDL1:");
      console.log(response.body);
      this.Segmentos1 = response.body;
    });

    if (this.$route.params.id != null) {
      this.ArquivoId = parseInt(this.$route.params.id, 10);
    } else {
      this.ArquivoId = "";
    }

    if(this.ArquivoId != null && this.ArquivoId > 0)
    {
       this.$http.get(this.$apiUrl + "/arquivos/" + this.ArquivoId).then((response) => {
        this.Arquivo = response.body;
        this.ArquivoUpload = this.Arquivo.urlArquivo;
        if (this.Arquivo.segmentoCliNivel1 != null) {
          this.Segmento1 =
            this.Arquivo.segmentoCliNivel1.segmentoCliNivel1Id;
        }
        if (this.Arquivo.segmentoCliNivel2 != null) {
          this.Segmento2 =
            this.Arquivo.segmentoCliNivel2.segmentoCliNivel2Id;
        }
        if (this.Arquivo.segmentoCliNivel3 != null) {
          this.Segmento3 =
            this.Arquivo.segmentoCliNivel3.segmentoCliNivel3Id;
        }
        if (this.Arquivo.segmentoCliNivel4 != null) {
          this.Segmento4 =
            this.Arquivo.segmentoCliNivel4.segmentoCliNivel4Id;
        }
        this.MudaSegmento1();

        console.log("Carrego")
      });
    }
  },
  methods: {
    Upload(event){
       var files = event.target.files;
       var x = 0;
      for (x = 0; x < files.length; x++) {
        this.Enviando++;
        let data = new FormData();
        data.append("arquivo", files[x]);
        console.log(files[x]);
        this.$http
          .post(
            this.$apiUrl +
              "/arquivos/upload/" +
              this.ArquivoId,
            data
          )
          .then(
            (response) => {
              console.log(response.body);
              this.Enviado++;
              this.ArquivoUpload = response.body;
              this.Arquivo.urlArquivo = response.bodyText;
            },
            (response) => { 
              console.log(response);
              
            }
          );
      }
    },
    Salvar() {
      console.log("SALVAR");
      if(this.Segmento1 != null && this.Segmento1 > 0)
      {
         this.$http
        .get(this.$apiUrl + "/SegmentoCliNivel1/" + this.Segmento1)
        .then((response) => {
          this.Arquivo.segmentoCliNivel1 = response.body;
          console.log("1");
        }, (error) => {this.Arquivo.segmentoCliNivel1 = null;console.log(error)})
        .then(() => {
          this.$http
            .get(this.$apiUrl + "/SegmentoCliNivel2/" + this.Segmento2)
            .then((response) => {
              this.Arquivo.segmentoCliNivel2 = response.body;
              console.log("2");
            }, (error) => {this.Arquivo.segmentoCliNivel2 = null;console.log(error)})
            .then(() => {
              this.$http
                .get(this.$apiUrl + "/SegmentoCliNivel3/" + this.Segmento3)
                .then((response) => {
                  this.Arquivo.segmentoCliNivel3 = response.body;
                  console.log("3");
                } , (error) => {this.Arquivo.segmentoCliNivel3 = null;console.log(error)})
                .then(() => {
                  this.$http
                    .get(this.$apiUrl + "/SegmentoCliNivel4/" + this.Segmento4)
                    .then((response) => {
                      this.Arquivo.segmentoCliNivel4 = response.body;
                      this.$http.post(this.$apiUrl + "/Arquivos/", this.Arquivo)
                      .then(response => {
                        this.$mensagem_sucesso("Arquivo cadastrado com sucesso");
                        this.$redirect_reload("/controle/arquivocli/" + response.body.arquivoId);
                      }, error => {
                        this.$mensagem_erro(error.bodyText);
                      })
                    }, (error) => {this.Arquivo.segmentoCliNivel1 = null;console.log(error)});
                });
            });
        });
      }
      else
      {
         this.$http.post(this.$apiUrl + "/Arquivos/", this.Arquivo)
         .then(response => {
           this.$mensagem_sucesso("Arquivo cadastrado com sucesso");
           this.$redirect_reload("/controle/arquivocli/" + response.body.arquivoId);
         }, error => {
           this.$mensagem_erro(error.bodyText);
         });
      }
     
    },
    Alterar() {
      if(this.Segmento1 != null && this.Segmento1 > 0)
      {
      console.log("SALVAR");
      this.$http
        .get(this.$apiUrl + "/SegmentoCliNivel1/" + this.Segmento1)
        .then((response) => {
          this.Arquivo.segmentoCliNivel1 = response.body;
          console.log("1");
        }, (error) => {this.Arquivo.segmentoCliNivel1 = null;console.log(error)})
        .then(() => {
          this.$http
            .get(this.$apiUrl + "/SegmentoCliNivel2/" + this.Segmento2)
            .then((response) => {
              this.Arquivo.segmentoCliNivel2 = response.body;
              console.log("2");
            }, (error) => {this.Arquivo.segmentoCliNivel2 = null;console.log(error)})
            .then(() => {
              this.$http
                .get(this.$apiUrl + "/SegmentoCliNivel3/" + this.Segmento3)
                .then((response) => {
                  this.Arquivo.segmentoCliNivel3 = response.body;
                  console.log("3");
                } , (error) => {this.Arquivo.segmentoCliNivel3 = null;console.log(error)})
                .then(() => {
                  this.$http
                    .get(this.$apiUrl + "/SegmentoCliNivel4/" + this.Segmento4)
                    .then((response) => {
                      this.Arquivo.segmentoCliNivel4 = response.body;
                      this.$http.put(this.$apiUrl + "/Arquivos/" + this.ArquivoId, this.Arquivo)
                      .then(response => {
                        this.$mensagem_sucesso("Arquivo alterado com sucesso");
                        this.$redirect_reload("/controle/arquivoscli/");
                        console.log(response);
                      }, error => {
                        this.$mensagem_erro(error.bodyText);
                      })
                    }, (error) => {this.Arquivo.segmentoCliNivel1 = null;console.log(error)});
                });
            });
        });
      }
      else
      {
        this.$http.put(this.$apiUrl + "/Arquivos/" + this.ArquivoId, this.Arquivo)
       .then(response => {
         this.$mensagem_sucesso("Arquivo alterado com sucesso");
         this.$redirect_reload("/controle/arquivoscli/");
         console.log(response);
       }, error => {
         this.$mensagem_erro(error.bodyText);
       });
      }
    },
    Remove(Arquivo) {
      console.log("Apagar id " + Arquivo.arquivoId);
      this.$http.delete(this.$apiUrl + "/arquivos/" + Arquivo.arquivoId)
      .then(response =>{
        if(response.ok)
          this.$mensagem_sucesso("#" + Arquivo.arquivoId + " Removido com sucesso.");
          this.$redirect_reload("/controle/arquivoscli/");
      }, error => {
        console.log(error);
      });
    },
    MudaSegmento1() {
      this.$http
        .get(this.$apiUrl + "/segmentoCliNivel2/nivel1/" + this.Segmento1)
        .then((response) => {
          this.Segmentos2 = response.body;
          if (this.ArquivoId > 0 && this.Arquivo.segmentoCliNivel2 != null) {
            this.Segmento2 = this.Arquivo.segmentoCliNivel2.segmentoCliNivel2Id;
            this.MudaSegmento2();
          }
        });
    },
    MudaSegmento2() {
      this.$http
        .get(this.$apiUrl + "/segmentoClinivel3/nivel2/" + this.Segmento2)
        .then((response) => {
          this.Segmentos3 = response.body;
          if (this.ArquivoId > 0 && this.Arquivo.segmentoCliNivel3 != null) {
            this.Segmento3 = this.Arquivo.segmentoCliNivel3.segmentoCliNivel3Id;
            this.MudaSegmento3();
          }
        });
    },
    MudaSegmento3() {
      this.$http
        .get(this.$apiUrl + "/segmentoClinivel4/nivel3/" + this.Segmento3)
        .then((response) => {
          this.Segmentos4 = response.body;
          if (this.ArquivoId > 0 && this.Arquivo.segmentoCliNivel4 != null) {
            this.Segmento3 = this.Arquivo.segmentoCliNivel3.segmentoCliNivel3Id;
            this.MudaSegmento3();
          }
        });
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
