<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Dados Correio</h4>
                      <p class="mb-30">
                        Tela para cadastro dos dados de configuração API
                        correios.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Contrato</label>
                        <input
                          v-model="Contrato"
                          class="form-control"
                          type="text"
                          placeholder="Contrato"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>CEP</label>
                        <input
                          v-model="CEP"
                          class="form-control"
                          type="text"
                          placeholder="CEP"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Senha</label>
                        <input
                          v-model="Senha"
                          type="password"
                          id="password"
                          name="password"
                          class="form-control"
                          placeholder="Senha"
                        />
                        <span class="lnr-eye icon-copy dw dw-hide"></span>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Aviso Rec. (S/N)</label>
                        <input
                          v-model="Aviso"
                          class="form-control"
                          type="text"
                          placeholder="Aviso Rec. (S/N)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="Alterar()"
                        id="sa-custom-position2"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

//import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";

export default {
  data() {
    return {
      EntregaCorreioId: 0,
      Contrato: "",
      CEP: "",
      Senha: "",
      Aviso: "",
      EntregaCorreio: [],
    };
  },
  methods: {
    Alterar() {
      let _EntregaCorreio = {
        EntregaCorreioId: this.EntregaCorreioId,
        contrato: this.Contrato,
        CEP: this.CEP,
        Senha: this.Senha,
        Aviso: this.Aviso,
      };

      //validações
      if (_EntregaCorreio.CEP == null) {
        this.$mensagem_normal("Por favor preencha o CEP");
      } else {
        _EntregaCorreio.EntregaCorreioId = parseInt(this.EntregaCorreioId, 10);
        if (_EntregaCorreio.EntregaCorreioId == 0) {
          this.$http
            .post(this.$apiUrl + "/entregacorreio", _EntregaCorreio)
            .then(
              (response) => {
                // get body data
                //console.log(_RedeSocial);
                _EntregaCorreio = response.body;
                this.$mensagem_sucesso("Entrega alterada com sucesso");
                this.$redirect("/controle/entregacorreio");
              },
              (response) => {
                // error callback
                console.log(response);
              }
            );
        } else {
          //chama o post da api
          this.$http
            .put(
              this.$apiUrl +
                "/entregacorreio/" +
                _EntregaCorreio.EntregaCorreioId,
              _EntregaCorreio
            )
            .then(
              (response) => {
                // get body data
                //console.log(_RedeSocial);
                _EntregaCorreio = response.body;
                this.$mensagem_sucesso("Entrega alterada com sucesso");
                this.$redirect("/controle/entregacorreio");
              },
              (response) => {
                // error callback
                console.log(response);
              }
            );
        }
      }
    },
    Limpar() {
      this.$redirect_reload("/controle/entregacorreio");
    },
  },
  created() {
    //chama o get da api this.$apiUrl
    this.$http
      .get(this.$apiUrl + "/entregacorreio/")
      .then((res) => res.json())
      .then((idi) => {
        this.EntregaCorreio = idi;

        this.EntregaCorreioId = this.EntregaCorreio[0].entregaCorreioId;
        this.Contrato = this.EntregaCorreio[0].contrato;
        this.CEP = this.EntregaCorreio[0].cep;
        this.Senha = this.EntregaCorreio[0].senha;
        this.Aviso = this.EntregaCorreio[0].aviso;
      });
  },
  mounted() {},
  components: {
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
