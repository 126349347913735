<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Taxas</h4>
                      <p class="mb-30">Tela para cadastro das Taxas.</p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Forma de Pagamento</label>
                        <v-select
                          v-model="Selected"
                          :options="ListaFormasPagamento"
                          single-line
                          label="texto"
                          @input="MudaTaxa()"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Porcentagem</label>
                        <input type="number" v-model="Taxa.porcentagem" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Dias para receber</label>
                        <input type="number" v-model="Taxa.diasParaReceber"  class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="Salvar()"
                        id="sa-custom-position2"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";


import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import vSelect from "vue-select";

export default {
  data() {
    return {
      ListaFormasPagamento: [],
      ListaFormasPagamentoBanco: [],
      ListaFormasPagamentoPix: [],
      ListaFormasPagamentoOperadora: [],
      TaxaId: this.$route.params.id,
      Selected: {},
      Taxa: {
      },
    };
  },
  methods: {
    Salvar() {
      this.Taxa.modoPagamento = this.Selected.texto;
      this.Taxa.taxaModoPagamentoId = this.TaxaId;
        if(this.Taxa.modoPagamento != null && this.Taxa.modoPagamento.length > 0)
        {
          this.Taxa.porcentagem = parseFloat(this.Taxa.porcentagem);
          this.Taxa.diasParaReceber = parseInt(this.Taxa.diasParaReceber);
            if(this.TaxaId == 0)
            {
                //novo   
                this.$http.post(this.$apiUrl + "/taxamodopagamento", this.Taxa)
                .then(response => {
                    if(response.ok)
                    {
                        this.Taxa = response.body;
                        this.$mensagem_sucesso("Cadastrado com sucesso");
                    }
                }
                , error => {
                    if(!error.ok)
                    {
                        this.$mensagem_erro(error.bodyText);
                    }
                });
            }
            else
            {
                //editar
                this.$http.put(this.$apiUrl + "/taxamodopagamento/" + this.TaxaId, this.Taxa)
                .then(response => {
                    if(response.ok)
                    {
                        this.Taxa = response.body;
                        this.$mensagem_sucesso("Alterado com sucesso");
                    }
                }
                , error => {
                    if(!error.ok)
                    {
                        this.$mensagem_erro(error.bodyText);
                    }
                });
            }
        }
        else
            this.$mensagem_erro("Por favor selecione um modo de pagamento");
    },
    MudaTaxa(){
      this.TaxaId = 0;
      this.Taxa.porcentagem = null;
      this.Taxa.diasParaReceber = null;
        this.$http.get(this.$apiUrl + "/taxamodopagamento/")
        .then(response => {
            if(response.ok)
            {
              var all = response.body;
              var tmp = all.find(x => x.modoPagamento == this.Selected.texto);
              if(tmp != null)
              {
                this.Taxa = tmp;
                this.TaxaId = this.Taxa.taxaModoPagamentoId;
              }
            }
        }, error => {
            if(!error.ok)
            {
                this.TaxaId = 0;
                this.Taxa = {
                    taxaModoPagamentoId: 0,
                    modoPagamento: this.Selected.texto,
                    porcentagem: 0.0,
                    diasParaReceber: 0
                }
            }
        });
    }
  },
  created() {
    if (this.TaxaId == null) {
      this.TaxaId = 0;
    }
    var _formasPagamento = [];
    //carrega informações da lista de opções de pagamento
    this.$http
      .get(this.$apiUrl + "/configoperadora")
      .then((res2) => res2.json())
      .then((tiu2) => {
        //carrega informações da lista de opções de pagamento
        this.$http
          .get(this.$apiUrl + "/configpix")
          .then((res2) => res2.json())
          .then((tiu3) => {
            this.ListaFormasPagamentoPix = tiu3.filter((x) => x.status);
            if (this.ListaFormasPagamentoPix != null) {
              this.ListaFormasPagamentoPix.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadPix.cadPixId,
                  Operacao: "cadPix",
                  texto: item.cadPix.nomePix,
                });
              });
            }

            this.ListaFormasPagamentoOperadora = tiu2.filter((x) => x.status);
            if (this.ListaFormasPagamentoOperadora != null) {
              this.ListaFormasPagamentoOperadora.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadOperadora.cadOperadoraId,
                  Operacao: "cadOperadora",
                  texto: item.cadOperadora.nomeOperadora,
                });
              });
            }

            //carrega informações da lista de opções de pagamento
            this.$http
              .get(this.$apiUrl + "/configbanco")
              .then((res2) => res2.json())
              .then((tiu2) => {
                this.ListaFormasPagamentoBanco = tiu2.filter((x) => x.status);
                if (this.ListaFormasPagamentoBanco != null) {
                  this.ListaFormasPagamentoBanco.forEach((item) => {
                    _formasPagamento.push({
                      OperacaoId: item.cadBanco.cadBancoId,
                      Operacao: "cadBanco",
                      texto: item.cadBanco.nomeBanco,
                    });
                  });
                }
              });
          });
      });

    this.ListaFormasPagamento = _formasPagamento;
    this.ListaFormasPagamento.sort(function (a, b) {
      return a.texto - b.texto;
    });
  },
  mounted() {},
  components: {
    vSelect,
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
