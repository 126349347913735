<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4
                        class="text-black h4"
                      >
                        Cadastro de Segmentos de cliente 
                      </h4>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                   
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >Nome
                          </label
                        >
                        <input
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="
                            'Nome'
                          "
                          v-model="Segmento.nomeSegmento"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Visível</label>
                        <v-select
                          v-model="Segmento.visivel"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Destaque</label>
                        <v-select
                          v-model="Segmento.destaque"
                          :options="[
                            { nome: 'Sim', destaque: true },
                            { nome: 'Não', destaque: false },
                          ]"
                          single-line
                          :reduce="(x) => x.destaque"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Ordem</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Ordem"
                          v-model="Segmento.ordem"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Resumo</label>
                        <quill-editor
                          v-model="Segmento.resumo"
                          placeholder="Resumo..."
                        ></quill-editor>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Descrição 1</label>
                        <quill-editor
                          v-model="Segmento.descricao1"
                          placeholder="Descrição 1..."
                        ></quill-editor>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Descrição 2</label>
                        <quill-editor
                          v-model="Segmento.descricao2"
                          placeholder="Descrição 2..."
                        ></quill-editor>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        @click="$redirect('/controle/segmentoscli1')"
                        type="button"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        v-if="SegmentoId == null || SegmentoId <= 0"
                        type="button"
                        @click="Pesquisar()"
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <button
                        v-if="SegmentoId == null || SegmentoId <= 0"
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Salvar"
                      >
                        Salvar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Alterar"
                      >
                        Alterar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>Ordem</th>
                <th>Destaque</th>
                <th>Visível</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="Segmento in Segmentos"
                :key="Segmento.segmentoCliNivel1Id"
              >
                <td>{{ Segmento.segmentoCliNivel1Id }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/segmentoCli1/' +
                              Segmento.segmentoCliNivel1Id
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >

                      <a
                        class="dropdown-item"
                        @click="Remover(Segmento)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Segmento.nomeSegmento }}</td>
                <td>{{ Segmento.ordem }}</td>
                <td>
                  <span v-if="Segmento.destaque">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Segmento.visivel">Sim</span
                  ><span v-else>Não</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      SegmentoId: 0,
      NomeSegmento: "",
      Segmento: {},
      Segmentos: [],

      Enviando: 0,
      Enviado: 0,
      Imagens: [],
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
   
    Remover(seg) {
      this.$http
        .delete(
          this.$apiUrl + "/SegmentoCliNivel1/" + seg.segmentoCliNivel1Id
        )
        .then(
          (response) => {
            console.log(response);
            this.$mensagem_sucesso("Removido com sucesso");
          },
          (error) => {
            this.$mensagem_erro(error.body);
          }
        );
    },
    Salvar() {
      this.Segmento.ordem = parseInt(this.Segmento.ordem, 10);
      console.log("POSTANDO:");
      console.log(this.Segmento);
      this.$http.post(this.$apiUrl + "/SegmentoCliNivel1", this.Segmento).then(
        (response) => {
          if (response.ok) {
            this.$mensagem_sucesso(
              "Segmento cadastrado com sucesso"
            );
            this.$redirect(
              "/controle/SegmentoCli1/" + response.body.segmentoCliNivel1Id
            );
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Alterar() {
      this.Segmento.ordem = parseInt(this.Segmento.ordem, 10);
      console.log("POSTANDO:");
      console.log(this.Segmento);
      this.$http
        .put(
          this.$apiUrl + "/SegmentoCliNivel1/" + this.SegmentoId,
          this.Segmento
        )
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso(
                "Segmento alterado com sucesso"
              );
              this.$redirect("/controle/Segmentoscli1");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    Pesquisar() {},
    // CarregaTela() {
    //   console.log(this.TelaSelecionada);
    //   this.$cookie.set("telaseg1", this.TelaSelecionada.toString(), "60m");
    //   this.$router.go(this.$router.currentRoute);
    //   this.$router.go(this.$router.currentRoute);
    // },
    Limpar() {
      this.$redirect_reload("/controle/cadastrasegmentos/");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
       
    this.$http.get(this.$apiUrl + "/SegmentoCliNivel1/")
    .then(result => {
      this.Segmentos = result.body;
    }).then(this.atualiza());
    
    if (this.$route.params.pesquisa != null) {
      this.NomeSegmento = this.$route.params.pesquisa;
    } else {
      this.NomeSegmento = "";
    }
    if (this.SegmentoId == 0) {
      if (this.$route.params.id != null) {
        this.SegmentoId = parseInt(this.$route.params.id, 10);
      }
    }
    console.log("EDITANDO:" + this.SegmentoId);
    if (this.SegmentoId > 0) {
      this.SegmentoId = parseInt(this.SegmentoId, 10);
      this.$http
        .get(this.$apiUrl + "/SegmentoCliNivel1/" + this.SegmentoId)
        .then(
          (response) => {
            console.log("Editando:");
            console.log(response.body);

            this.Segmento = response.body;
            // this.Imagens = this.Segmento.imagens;
            // this.Imagens = this.Imagens.sort(function (a, b) {
            //   return a.ordem - b.ordem;
            // });
          },
          (error) => {
            console.log("Erro ao carregar:");
            console.log(error);
          }
        );
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
