<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela Produtos</h4>
                      <p class="mb-30">Tela de Produtos cadastrados.</p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <div class="tab">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active text-blue ajustetab"
                        data-toggle="tab"
                        href="#dados"
                        role="tab"
                        aria-selected="true"
                        >Dados</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#segmentos"
                        role="tab"
                        aria-selected="false"
                        v-if="ProdutoId != null && ProdutoId > 0"
                        >Segmentos</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#inclusos"
                        role="tab"
                        aria-selected="false"
                        v-if="ProdutoId != null && ProdutoId > 0"
                        >Inclusos</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#avulsos"
                        role="tab"
                        aria-selected="false"
                        v-if="ProdutoId != null && ProdutoId > 0"
                        >Avulsos</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="ProdutoId != null && ProdutoId > 0"
                    >
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#infoadicionais"
                        role="tab"
                        aria-selected="false"
                        >Info Adicionais</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="ProdutoId != null && ProdutoId > 0"
                    >
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#premios"
                        role="tab"
                        aria-selected="false"
                        >Etiquetas</a
                      >
                    </li>
                    <!--<li class="nav-item" v-if="true">
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#valfiltros"
                        role="tab"
                        aria-selected="false"
                        >Tamanhos</a
                      >
                    </li>-->

                    <!--  <li class="nav-item">
                                        <a class="nav-link text-blue ajustetab" data-toggle="tab" href="#premios" role="tab" aria-selected="false">Prêmios</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-blue ajustetab" data-toggle="tab" href="#seo" role="tab" aria-selected="false">SEO</a>
                                    </li> -->
                    <li
                      class="nav-item"
                      v-if="ProdutoId != null && ProdutoId > 0"
                    >
                      <a
                        class="nav-link text-blue ajustetab"
                        data-toggle="tab"
                        href="#fotos"
                        role="tab"
                        aria-selected="false"
                        >Fotos</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      id="dados"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <form>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Nome</label>
                                <input
                                  v-model="Produto.nomeProduto"
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Referência</label>
                                <input
                                  v-model="Produto.referencia"
                                  class="form-control"
                                  type="text"
                                  placeholder="Referência"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Tempo Produção (Horas)</label>
                                <input
                                  v-model="Produto.tempoProducao"
                                  @change="
                                    Produto.tempoProducao != null
                                      ? (Produto.tempoProducao = parseInt(
                                          Produto.tempoProducao,
                                          10
                                        ))
                                      : (Produto.tempoProducao = 0)
                                  "
                                  class="form-control"
                                  type="number"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Valor de </label>
                                <input
                                  v-model="Produto.valorDe"
                                  @change="
                                    Produto.valorDe != null
                                      ? (Produto.valorDe = parseFloat(
                                          Produto.valorDe,
                                          10
                                        ))
                                      : (Produto.valorDe = 0.0)
                                  "
                                  class="form-control"
                                  type="number"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Valor por</label>
                                <input
                                  v-model="Produto.valorAte"
                                  @change="
                                    Produto.valorAte != null
                                      ? (Produto.valorAte = parseFloat(
                                          Produto.valorAte,
                                          10
                                        ))
                                      : (Produto.valorAte = 0.0)
                                  "
                                  class="form-control"
                                  type="number"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Estoque</label>
                                <input
                                  v-model="Produto.qtdeMinima"
                                  @change="
                                    Produto.qtdeMinima != null
                                      ? (Produto.qtdeMinima = parseInt(
                                          Produto.qtdeMinima,
                                          10
                                        ))
                                      : (Produto.qtdeMinima = 0)
                                  "
                                  class="form-control"
                                  type="number"
                                  placeholder="Estoque"
                                />
                              </div>
                            </div>
                            <!-- <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label>Qtde Estoque</label>
                                                            <input @change="Produto.qtdeEstoque != null ? Produto.qtdeMinima = parseInt(Produto.qtdeMinima, 10):Produto.qtdeMinima = 0" class="form-control" type="number" placeholder="Qtde Estoque">
                                                        </div>
                                                    </div> -->
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Tempo de Entrega</label>
                                <input
                                  v-model="Produto.tempoEntrega"
                                  class="form-control"
                                  type="text"
                                  placeholder="Tempo de Entrega"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Visivel</label>
                                <v-select
                                  v-model="Produto.visivel"
                                  :options="[
                                    { nome: 'Sim', visivel: true },
                                    { nome: 'Não', visivel: false },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.visivel"
                                  label="nome"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Destaque</label>
                                <v-select
                                  v-model="Produto.destaque"
                                  :options="[
                                    { nome: 'Sim', destaque: true },
                                    { nome: 'Não', destaque: false },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.destaque"
                                  label="nome"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Promoção</label>
                                <v-select
                                  v-model="Produto.promocao"
                                  :options="[
                                    { nome: 'Sim', promocao: true },
                                    { nome: 'Não', promocao: false },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.promocao"
                                  label="nome"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Indisponível</label>
                                <v-select
                                  v-model="Produto.indisponivel"
                                  :options="[
                                    { nome: 'Sim', indisponivel: true },
                                    { nome: 'Não', indisponivel: false },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.indisponivel"
                                  label="nome"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Resumo</label>
                                <quill-editor
                                  v-model="Produto.resuno"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Descrição</label>
                                <quill-editor
                                  v-model="Produto.descricao"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12" style="display:none">
                              <div class="form-group">
                                <label>Descrição 2</label>
                                <quill-editor
                                  v-model="Produto.descricao2"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12"  style="display:none">
                              <div class="form-group">
                                <label>Descrição 3</label>
                                <quill-editor
                                  v-model="Produto.descricao3"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12"  style="display:none">
                              <div class="form-group">
                                <label>Descrição 4</label>
                                <quill-editor
                                  v-model="Produto.descricao4"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <!--<div class="col-md-12">
                              <div class="form-group">
                                <label>Informação extra</label>
                                <quill-editor
                                  v-model="Produto.infoExtra"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Informação extra 2</label>
                                <quill-editor
                                  v-model="Produto.infoExtra2"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Informação extra 3</label>
                                <quill-editor
                                  v-model="Produto.infoExtra3"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Informação extra 4</label>
                                <quill-editor
                                  v-model="Produto.infoExtra4"
                                  placeholder="Texto ..."
                                ></quill-editor>
                              </div>
                            </div>-->
                            <!-- <div class="col-md-12">
        <div class="form-group">
            <label>Nome Texto</label>
            <textarea class="textarea_editor4 form-control border-radius-0" placeholder="Texto ..."></textarea>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <label>Nome Texto</label>
            <textarea class="textarea_editor5 form-control border-radius-0" placeholder="Texto ..."></textarea>
        </div>
    </div>-->
                            <!--<div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Origem</label>
                                <input
                                  v-model="Produto.origem"
                                  class="form-control"
                                  type="text"
                                  placeholder="Origem"
                                />
                              </div>
                            </div>
                            <div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Pais</label>
                                <input
                                  v-model="Produto.pais"
                                  class="form-control"
                                  type="text"
                                  placeholder="Pais"
                                />
                              </div>
                            </div>-->
                            <div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Customizado</label>
                                <v-select
                                  :options="FiltrosFabrica"
                                  v-model="FiltroFabricaId"
                                  single-line
                                  :reduce="(x) => x.filtroFabricaId"
                                  @input="SelecionaFabrica"
                                  label="nomeFabrica"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Qtd Linha</label>
                                <v-select
                                  :options="FiltrosMateria"
                                  v-model="FiltroMateriaPrimaId"
                                  single-line
                                  :reduce="(x) => x.filtroMateriaPrimaId"
                                  @input="SelecionaMateria"
                                  label="nomeMateriaPrima"
                                ></v-select>
                              </div>
                            </div>
                            <!--<div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Marca</label>
                                <v-select
                                  :options="FiltrosMarca"
                                  v-model="FiltroMarcaId"
                                  single-line
                                  :reduce="(x) => x.filtroMarcaId"
                                  @input="SelecionaMarca"
                                  label="nomeMarca"
                                ></v-select>
                              </div>
                            </div>-->
                            <!-- <div class="col-md-3">
                              <div class="form-group">
                                <label>Cor</label>
                                <select class="custom-select col-12">
                                  <option selected="">Selecione...</option>
                                  <option value="1">Preto</option>
                                  <option value="2">Azul</option>
                                </select>
                              </div>
                            </div> -->
                            <!--<div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Voltagem</label>
                                <v-select
                                  :options="FiltrosVoltagem"
                                  v-model="FiltroVoltagemId"
                                  single-line
                                  :reduce="(x) => x.filtroVoltagemId"
                                  @input="SelecionaVoltagem"
                                  label="nomeVoltagem"
                                ></v-select>
                              </div>
                            </div>-->
                            <div class="col-md-3" v-if="true">
                              <div class="form-group">
                                <label>Tipo de Fonte</label>
                                <v-select
                                  :options="FiltrosCaracteristica"
                                  v-model="FiltroCaracteristicaId"
                                  single-line
                                  :reduce="(x) => x.filtroCaracteristicaId"
                                  @input="SelecionaCaracteristica"
                                  label="nomeCaracteristica"
                                ></v-select>
                              </div>
                            </div>
                            <!--<div class="col-md-6">
                              <div class="form-group">
                                <label>Etiqueta</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Texto Etiqueta"
                                  v-model="Produto.etiqueta"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Cor Fonte</label>
                                <input
                                  class="form-control"
                                  v-model="Produto.etiquetaCorFonte"
                                  type="color"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Cor Fundo</label>
                                <input
                                  class="form-control"
                                  v-model="Produto.etiquetaCorFundo"
                                  type="color"
                                />
                              </div>
                            </div>-->
                            <div class="col-md-3" v-if="false">
                              <div class="form-group">
                                <label>Peso</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="Produto.peso"
                                  @change="
                                    Produto.peso != null
                                      ? (Produto.peso = parseInt(
                                          Produto.peso,
                                          10
                                        ))
                                      : (Produto.peso = 0)
                                  "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-md-3" v-if="false">
                              <div class="form-group">
                                <label>Altura</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  placeholder=""
                                  v-model="Produto.altura"
                                  @change="
                                    Produto.altura != null
                                      ? (Produto.altura = parseInt(
                                          Produto.altura,
                                          10
                                        ))
                                      : (Produto.altura = 0)
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3" v-if="false">
                              <div class="form-group">
                                <label>Largura</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  placeholder=""
                                  v-model="Produto.largura"
                                  @change="
                                    Produto.largura != null
                                      ? (Produto.largura = parseInt(
                                          Produto.largura,
                                          10
                                        ))
                                      : (Produto.largura = 0)
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-md-3" v-if="false">
                              <div class="form-group">
                                <label>Profundidade</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  placeholder=""
                                  v-model="Produto.profundidade"
                                  @change="
                                    Produto.profundidade != null
                                      ? (Produto.profundidade = parseInt(
                                          Produto.profundidade,
                                          10
                                        ))
                                      : (Produto.profundidade = 0)
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12 text-center mt-15">
                              <button
                                type="button"
                                @click="
                                  $redirect_reload('/controle/listaprodutos')
                                "
                                class="btn btn-link"
                              >
                                Cancelar
                              </button>
                              <!--<button type="button" class="btn btn-info">Pesquisar</button>-->
                              <button
                                type="button"
                                class="btn btn-success"
                                id="sa-custom-position"
                                @click="Salvar"
                                v-if="ProdutoId == null || ProdutoId == 0"
                              >
                                Salvar Dados
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-success"
                                id="sa-custom-position"
                                @click="Alterar"
                              >
                                Alterar
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="segmentos" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Nivel 1</label>
                              <v-select
                                v-model="Segmento1"
                                :options="Segmentos1"
                                single-line
                                :reduce="(x) => x.segmentoProdNivel1Id"
                                @input="MudaSegmento1"
                                label="nomeSegmento"
                                class="col-12"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Nivel 2</label>
                              <v-select
                                v-model="Segmento2"
                                :options="Segmentos2"
                                single-line
                                :reduce="(x) => x.segmentoProdNivel2Id"
                                @input="MudaSegmento2"
                                label="nomeSegmento"
                                class="col-12"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-3" v-if="false">
                            <div class="form-group">
                              <label>Nivel 3</label>
                              <v-select
                                v-model="Segmento3"
                                :options="Segmentos3"
                                single-line
                                :reduce="(x) => x.segmentoProdNivel3Id"
                                @input="MudaSegmento3"
                                label="nomeSegmento"
                                class="col-12"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-3" v-if="false">
                            <div class="form-group">
                              <label>Nivel 4</label>
                              <v-select
                                v-model="Segmento4"
                                :options="Segmentos4"
                                @input="MudaSegmento4"
                                single-line
                                :reduce="(x) => x.segmentoProdNivel4Id"
                                label="nomeSegmento"
                                class="col-12"
                              ></v-select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 text-center"
                            style="padding-top: 40px"
                          >
                            <button
                              type="button"
                              @click="$redirect_reload('/controle/produtos')"
                              class="btn btn-link"
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              class="btn btn-success"
                              id="sa-custom-position"
                              @click="SalvarSegmentos"
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <table class="table hover data-table-export nowrap">
                              <thead>
                                <tr>
                                  <th>Produto</th>
                                  <th>Segmento Nível 1</th>
                                  <th>Segmento Nível 2</th>
                                  <!-- <th>Segmento Nível 3</th>
                                  <th>Segmento Nível 4</th> -->
                                  <th class="datatable-nosort">Apagar</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(Ligacao, index) in Ligacoes"
                                  :key="index"
                                >
                                  <td>
                                    <span v-if="Ligacao.produto != null">{{
                                      Ligacao.produto.nomeProduto
                                    }}</span>
                                  </td>
                                  <td>
                                    <span
                                      v-if="Ligacao.segmentoProdNivel1 != null"
                                      >{{
                                        Ligacao.segmentoProdNivel1.nomeSegmento
                                      }}</span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      v-if="Ligacao.segmentoProdNivel2 != null"
                                      >{{
                                        Ligacao.segmentoProdNivel2.nomeSegmento
                                      }}</span
                                    >
                                  </td>
                                  <!-- <td>
                                    <span
                                      v-if="Ligacao.segmentoProdNivel3 != null"
                                      >{{
                                        Ligacao.segmentoProdNivel3.nomeSegmento
                                      }}</span
                                    >
                                  </td>
                                  <td>
                                    <span
                                      v-if="Ligacao.segmentoProdNivel4 != null"
                                      >{{
                                        Ligacao.segmentoProdNivel4.nomeSegmento
                                      }}</span
                                    >
                                  </td> -->
                                  <td>
                                    <a
                                      class=""
                                      @click="
                                        ApagarLigacao(
                                          Ligacao.ligacaoSegmentoProdutoId
                                        )
                                      "
                                      ><i class="dw dw-delete-3"></i> Excluir</a
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="avulsos" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <label>Item Avulso</label>
                              <v-select
                                v-model="ItemAvulso.itemAvulsoId"
                                :options="ItensAvulsos"
                                single-line
                                :reduce="(x) => x.itemAvulsoId"
                                @input="MudaAvulso"
                                label="nomeItemAvulso"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Ordem</label>
                              <input
                                v-model="ProdutoAvulso.ordem"
                                @change="
                                  ProdutoAvulso.ordem != null
                                    ? (ProdutoAvulso.ordem = parseInt(
                                        ProdutoAvulso.ordem,
                                        10
                                      ))
                                    : (ProdutoAvulso.ordem = 0)
                                "
                                class="form-control"
                                type="number"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  type="button"
                                  @click="SalvarProdutoAvulso"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <table class="table hover data-table-export nowrap">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Nome</th>
                                  <th>Valor</th>
                                  <th>Ordem</th>
                                  <th class="datatable-nosort">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(Item, index) in ProdutosAvulsos"
                                  :key="index"
                                >
                                  <td>{{ Item.itemAvulso.itemAvulsoId }}</td>
                                  <td>{{ Item.itemAvulso.nomeItemAvulso }}</td>
                                  <td>
                                    R$
                                    {{
                                      Item.itemAvulso.valorDe
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                  <td>{{ Item.ordem }}</td>
                                  <td>
                                    <a
                                      class="dropdown-item"
                                      @click="
                                        ApagaProdutoAvulso(Item.produtoAvulsoId)
                                      "
                                      ><i class="dw dw-delete-3"></i> Excluir</a
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="inclusos" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Nome</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="ProdutoIncluso.nomeProdutoIncluso"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Quantidade</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="ProdutoIncluso.unidade"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Ordem</label>
                              <input
                                class="form-control"
                                type="number"
                                placeholder=""
                                v-model="ProdutoIncluso.ordem"
                                @change="
                                  ProdutoIncluso.ordem != null
                                    ? (ProdutoIncluso.ordem = parseInt(
                                        ProdutoIncluso.ordem,
                                        10
                                      ))
                                    : (ProdutoIncluso.ordem = 0)
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="AlterarProdutoIncluso"
                                  v-if="ProdutoInclusoId > 0"
                                >
                                  Salvar Alterações
                                </button>
                                <button
                                  v-else
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="SalvarProdutoIncluso"
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <table class="table hover data-table-export nowrap">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Nome</th>
                                  <th>Qtde</th>
                                  <th>Ordem</th>
                                  <th class="datatable-nosort">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(Incluso, index) in ProdutosInclusos"
                                  :key="index"
                                >
                                  <td>{{ Incluso.produtoInclusoId }}</td>
                                  <td>{{ Incluso.nomeProdutoIncluso }}</td>
                                  <td>{{ Incluso.unidade }}</td>
                                  <td>{{ Incluso.ordem }}</td>
                                  <td>
                                    <div class="dropdown">
                                      <a
                                        class="
                                          btn btn-link
                                          font-24
                                          p-0
                                          line-height-1
                                          no-arrow
                                          dropdown-toggle
                                        "
                                        role="button"
                                        data-toggle="dropdown"
                                      >
                                        <i class="dw dw-more"></i>
                                      </a>
                                      <div
                                        class="
                                          dropdown-menu
                                          dropdown-menu-right
                                          dropdown-menu-icon-list
                                        "
                                      >
                                        <a
                                          class="dropdown-item"
                                          @click="
                                            CarregaInclusoEditar(
                                              Incluso.produtoInclusoId
                                            )
                                          "
                                          ><i class="dw dw-edit2"></i> Editar</a
                                        >
                                        <a
                                          class="dropdown-item"
                                          @click="
                                            ApagarIncluso(
                                              Incluso.produtoInclusoId
                                            )
                                          "
                                          id="sa-warning"
                                          ><i class="dw dw-delete-3"></i>
                                          Excluir</a
                                        >
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="infoadicionais"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label>Título</label>
                              <input
                                class="form-control"
                                v-model="Info.tituloInfo"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Ordem</label>
                              <input
                                v-model="Info.ordem"
                                @input="
                                  Info.ordem != null
                                    ? (Info.ordem = parseInt(Info.ordem, 10))
                                    : (Info.ordem = 0)
                                "
                                class="form-control"
                                type="number"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Descrição</label>
                              <quill-editor
                                v-model="Info.descricaoInfo"
                                placeholder="Texto ..."
                              ></quill-editor>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 text-center mt-15">
                            <button
                              type="button"
                              class="btn btn-link"
                              @click="
                                Info = {};
                                InfoId = 0;
                              "
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              class="btn btn-success"
                              id="sa-custom-position"
                              @click="SalvaInfo()"
                              v-if="InfoId == 0"
                            >
                              Inserir Dados
                            </button>
                            <button
                              type="button"
                              class="btn btn-success"
                              id="sa-custom-position"
                              @click="AlteraInfo()"
                              v-else
                            >
                              Alterar Dados
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <div class="table-responsive">
                              <table class="table hover data table nowrap">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Nome</th>
                                    <th>Ordem</th>
                                    <th>Descrição</th>
                                    <th class="datatable-nosort">Ação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(Info, Index) in Infos"
                                    :key="Index"
                                  >
                                    <td>{{ Info.produtoInfoAdicionalId }}</td>
                                    <td>{{ Info.tituloInfo }}</td>
                                    <td>{{ Info.ordem }}</td>
                                    <td
                                      v-html="
                                        Info.descricaoInfo
                                          .replaceAll(`&gt;`, '>')
                                          .replaceAll(`&lt;`, '<')
                                      "
                                    ></td>
                                    <td>
                                      <div class="dropdown">
                                        <a
                                          class="
                                            btn btn-link
                                            font-24
                                            p-0
                                            line-height-1
                                            no-arrow
                                            dropdown-toggle
                                          "
                                          role="button"
                                          data-toggle="dropdown"
                                        >
                                          <i class="dw dw-more"></i>
                                        </a>
                                        <div
                                          class="
                                            dropdown-menu
                                            dropdown-menu-right
                                            dropdown-menu-icon-list
                                          "
                                        >
                                          <a
                                            class="dropdown-item"
                                            @click="EditaInfo(Info)"
                                            ><i class="dw dw-edit2"></i>
                                            Editar</a
                                          >
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              ApagaInfo(
                                                Info.produtoInfoAdicionalId
                                              )
                                            "
                                            id="sa-warning"
                                            ><i class="dw dw-delete-3"></i>
                                            Excluir</a
                                          >
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="premios" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Nome</label>
                              <input
                                v-model="Premio.nomePremio"
                                class="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Sigla</label>
                              <input
                                v-model="Premio.siglaPremio"
                                class="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Ordem</label>
                              <input
                                v-model="Premio.ordem"
                                @input="
                                  Premio.ordem != null
                                    ? (Premio.ordem = parseInt(
                                        Premio.ordem,
                                        10
                                      ))
                                    : (Premio.ordem = 0)
                                "
                                class="form-control"
                                type="number"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-4" v-if="PremioId > 0">
                            <img
                              src="/images/cloud-upload.gif"
                              v-if="Enviando > Enviado"
                            />
                            <div class="form-group" v-else>
                              <label>Imagem</label>
                              <input
                                type="file"
                                @change="UploadPremio"
                                class="
                                  form-control-file form-control
                                  height-auto
                                "
                              />
                            </div>
                            <div
                              class="row"
                              v-if="
                                Premio.urlImg != null &&
                                Premio.urlImg.length > 0
                              "
                            >
                              <img :src="Premio.urlImg" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 text-center"
                            style="padding-top: 40px"
                          >
                            <button
                              type="button"
                              @click="
                                Premio = {};
                                PremioId = 0;
                              "
                              class="btn btn-link"
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              @click="SalvaPremio"
                              v-if="PremioId <= 0"
                              class="btn btn-success"
                              id="sa-custom-position"
                            >
                              Salvar
                            </button>
                            <button
                              type="button"
                              @click="AlteraPremio"
                              v-else
                              class="btn btn-success"
                              id="sa-custom-position"
                            >
                              Alterar
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <div class="table-responsive">
                              <table class="table hover data table nowrap">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th class="datatable-nosort">imagem</th>
                                    <th>Nome</th>
                                    <th>Sigla</th>
                                    <th>Ordem</th>
                                    <th class="datatable-nosort">Ação</th>
                                  </tr>
                                </thead>
                                <tbody
                                  v-for="(Premio, Index) in Premios"
                                  :key="Index"
                                >
                                  <tr>
                                    <td>{{ Premio.produtoPremioId }}</td>
                                    <td width="10%">
                                      <img
                                        :src="
                                          Premio.urlImg != nulll &&
                                          Premio.urlImg.length > 0
                                            ? Premio.urlImg
                                            : ''
                                        "
                                        alt=""
                                      />
                                    </td>
                                    <td>{{ Premio.nomePremio }}</td>
                                    <td>{{ Premio.siglaPremio }}</td>
                                    <td>{{ Premio.ordem }}</td>
                                    <td>
                                      <div class="dropdown">
                                        <a
                                          class="
                                            btn btn-link
                                            font-24
                                            p-0
                                            line-height-1
                                            no-arrow
                                            dropdown-toggle
                                          "
                                          role="button"
                                          data-toggle="dropdown"
                                        >
                                          <i class="dw dw-more"></i>
                                        </a>
                                        <div
                                          class="
                                            dropdown-menu
                                            dropdown-menu-right
                                            dropdown-menu-icon-list
                                          "
                                        >
                                          <a
                                            class="dropdown-item"
                                            @click="EditarPremio(Premio)"
                                            ><i class="dw dw-edit2"></i>
                                            Editar</a
                                          >
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              ApagarPremio(
                                                Premio.produtoPremioId
                                              )
                                            "
                                            id="sa-warning"
                                            ><i class="dw dw-delete-3"></i>
                                            Excluir</a
                                          >
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      v-if="true"
                      id="valfiltros"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Tamanho</label>
                              <v-select
                                v-model="Tamanho"
                                :options="Tamanhos"
                                single-line
                                label="nomeTamanho"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Valor de </label>
                              <input
                                class="form-control"
                                type="number"
                                placeholder=""
                                v-model="TamanhoObjeto.valorDe"
                                @change="
                                  TamanhoObjeto.valorDe != null
                                    ? (TamanhoObjeto.valorDe = parseFloat(
                                        TamanhoObjeto.valorDe,
                                        10
                                      ))
                                    : (TamanhoObjeto.valorDe = 0.0)
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Valor por</label>
                              <input
                                class="form-control"
                                type="number"
                                placeholder=""
                                v-model="TamanhoObjeto.valorAte"
                                @change="
                                  TamanhoObjeto.valorAte != null
                                    ? (TamanhoObjeto.valorAte = parseFloat(
                                        TamanhoObjeto.valorAte,
                                        10
                                      ))
                                    : (TamanhoObjeto.valorAte = 0.0)
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Estoque</label>
                              <input
                                class="form-control"
                                type="number"
                                placeholder=""
                                v-model="TamanhoObjeto.qtdeEstoque"
                                @change="
                                  TamanhoObjeto.qtdeEstoque != null
                                    ? (TamanhoObjeto.qtdeEstoque = parseInt(
                                        TamanhoObjeto.qtdeEstoque,
                                        10
                                      ))
                                    : (TamanhoObjeto.qtdeEstoque = 0)
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 text-center"
                            style="padding-top: 40px"
                          >
                            <button
                              type="button"
                              @click="
                                TamanhoObjeto.qtdeEstoque = 0;
                                TamanhoObjeto.valorAte =
                                  TamanhoObjeto.valorDe = 0;
                                Tamanho = {};TamanhoObjeto.produto = {};TamanhoObjeto.filtrosTamanho = {};
                              "
                              class="btn btn-link"
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              class="btn btn-success"
                              id="sa-custom-position"
                              @click="InserirTamanho"
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <table class="table hover data-table-export nowrap">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Tamanho</th>
                                  <th>Valor de</th>
                                  <th>Valor Por</th>
                                  <th>Estoque</th>
                                  <th class="datatable-nosort">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="Ligacao, Index in TamanhosProduto"
                                  :key="Index"
                                >
                                  <td>{{ Ligacao.produtoTamanhoId }}</td>
                                  <td v-if="Ligacao.filtrosTamanho != null">
                                    {{ Ligacao.filtrosTamanho.siglaTamanho }}
                                  </td>
                                  <td v-else></td>
                                  <td>
                                    R$
                                    {{
                                      Ligacao.valorDe
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                  <td>
                                    R$
                                    {{
                                      Ligacao.valorAte
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                  <td>{{ Ligacao.qtdeEstoque }}</td>
                                  <td>
                                    <a
                                      class="dropdown-item"
                                      @click="
                                        ApagarTamanho(Ligacao.produtoTamanhoId)
                                      "
                                      ><i class="dw dw-delete-3"></i> Excluir</a
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="premios" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Nome</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Sigla</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Ordem</label>
                              <input
                                class="form-control"
                                type="number"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Imagem</label>
                              <input
                                type="file"
                                class="
                                  form-control-file form-control
                                  height-auto
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 text-center"
                            style="padding-top: 40px"
                          >
                            <button type="button" class="btn btn-link">
                              Cancelar
                            </button>
                            <button
                              type="button"
                              class="btn btn-success"
                              id="sa-custom-position"
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <div class="table-responsive">
                              <table class="table hover data-table nowrap">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th class="datatable-nosort">imagem</th>
                                    <th>Nome</th>
                                    <th>Sigla</th>
                                    <th>Ordem</th>
                                    <th class="datatable-nosort">Ação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td width="10%">
                                      <img
                                        src="vendors/images/photo1.jpg"
                                        alt=""
                                      />
                                    </td>
                                    <td>xxxxxxxxxxxxxx</td>
                                    <td>XYZ</td>
                                    <td>10</td>
                                    <td>
                                      <div class="dropdown">
                                        <a
                                          class="
                                            btn btn-link
                                            font-24
                                            p-0
                                            line-height-1
                                            no-arrow
                                            dropdown-toggle
                                          "
                                          role="button"
                                          data-toggle="dropdown"
                                        >
                                          <i class="dw dw-more"></i>
                                        </a>
                                        <div
                                          class="
                                            dropdown-menu
                                            dropdown-menu-right
                                            dropdown-menu-icon-list
                                          "
                                        >
                                          <a class="dropdown-item"
                                            ><i class="dw dw-edit2"></i>
                                            Editar</a
                                          >
                                          <a class="dropdown-item"
                                            ><i class="dw dw-copy"></i>
                                            Duplicar</a
                                          >
                                          <a
                                            class="dropdown-item"
                                            id="sa-warning"
                                            ><i class="dw dw-delete-3"></i>
                                            Excluir</a
                                          >
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td width="10%">
                                      <img
                                        src="vendors/images/photo1.jpg"
                                        alt=""
                                      />
                                    </td>
                                    <td>xxxxxxxxxxxxxx</td>
                                    <td>XYZ</td>
                                    <td>20</td>
                                    <td>
                                      <div class="dropdown">
                                        <a
                                          class="
                                            btn btn-link
                                            font-24
                                            p-0
                                            line-height-1
                                            no-arrow
                                            dropdown-toggle
                                          "
                                          role="button"
                                          data-toggle="dropdown"
                                        >
                                          <i class="dw dw-more"></i>
                                        </a>
                                        <div
                                          class="
                                            dropdown-menu
                                            dropdown-menu-right
                                            dropdown-menu-icon-list
                                          "
                                        >
                                          <a class="dropdown-item"
                                            ><i class="dw dw-edit2"></i>
                                            Editar</a
                                          >
                                          <a class="dropdown-item"
                                            ><i class="dw dw-copy"></i>
                                            Duplicar</a
                                          >
                                          <a
                                            class="dropdown-item"
                                            id="sa-warning"
                                            ><i class="dw dw-delete-3"></i>
                                            Excluir</a
                                          >
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td width="10%">
                                      <img
                                        src="vendors/images/photo1.jpg"
                                        alt=""
                                      />
                                    </td>
                                    <td>xxxxxxxxxxxxxx</td>
                                    <td>XYZ</td>
                                    <td>30</td>
                                    <td>
                                      <div class="dropdown">
                                        <a
                                          class="
                                            btn btn-link
                                            font-24
                                            p-0
                                            line-height-1
                                            no-arrow
                                            dropdown-toggle
                                          "
                                          role="button"
                                          data-toggle="dropdown"
                                        >
                                          <i class="dw dw-more"></i>
                                        </a>
                                        <div
                                          class="
                                            dropdown-menu
                                            dropdown-menu-right
                                            dropdown-menu-icon-list
                                          "
                                        >
                                          <a class="dropdown-item"
                                            ><i class="dw dw-edit2"></i>
                                            Editar</a
                                          >
                                          <a class="dropdown-item"
                                            ><i class="dw dw-copy"></i>
                                            Duplicar</a
                                          >
                                          <a
                                            class="dropdown-item"
                                            id="sa-warning"
                                            ><i class="dw dw-delete-3"></i>
                                            Excluir</a
                                          >
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="seo" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Titulo</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder="Nome"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Imagem</label>
                              <input
                                type="file"
                                class="
                                  form-control-file form-control
                                  height-auto
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <img
                                src="vendors/images/photo1.jpg"
                                alt=""
                                style="width: 115px; height: auto"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Descrição</label>
                              <quill-editor
                                placeholder="Descrição ..."
                              ></quill-editor>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Palavras-chaves</label>
                              <quill-editor
                                placeholder="termos separados por vírgula ..."
                              ></quill-editor>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Scripts</label>
                              <quill-editor
                                placeholder="Clique no lapis e insira o código ..."
                              ></quill-editor>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 text-center"
                            style="padding-top: 40px"
                          >
                            <button type="button" class="btn btn-link">
                              Cancelar
                            </button>
                            <button
                              type="button"
                              class="btn btn-success"
                              id="sa-custom-position"
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade" id="fotos" role="tabpanel">
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Alt Img</label>
                              <input
                                v-model="Imagem.nomeImagem"
                                class="form-control"
                                type="text"
                                placeholder="Alt img"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Imagem</label>
                              <input
                                type="file"
                                id="arquivo"
                                class="
                                  form-control-file form-control
                                  height-auto
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 text-center"
                            style="padding-top: 40px"
                          >
                            <button
                              type="button"
                              @click="$redirect_reload('/controles/produtos')"
                              class="btn btn-link"
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              @click="SalvarImagem()"
                              class="btn btn-success"
                              id="sa-custom-position"
                            >
                              Salvar
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <img
                            src="/images/cloud-upload.gif"
                            v-if="Enviando > Enviado"
                          />
                          <div v-else class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Imagem</th>
                                  <th scope="col">Ordem</th>
                                  <th scope="col">Alt.</th>
                                  <th scope="col">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(Imagem, index) in Imagens"
                                  :key="index"
                                >
                                  <td>
                                    <img
                                      style="max-width: 90px"
                                      :src="Imagem.urlImg"
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <input
                                      class="form-control"
                                      type="number"
                                      v-model="Imagem.ordem"
                                      placeholder=""
                                    />
                                  </td>
                                  <td>
                                    <input
                                      class="form-control"
                                      type="text"
                                      v-model="Imagem.nomeImagem"
                                      placeholder=""
                                    />
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-warning btn-sm"
                                      id="sa-custom-position"
                                      @click="ApagaImagem(Imagem)"
                                    >
                                      Apagar
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-success btn-sm"
                                      @click="SalvarOrdem"
                                    >
                                      Salvar Alterações
                                    </button>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      Tamanho: {},
      Tamanhos: [],
      TamanhosProduto: [],
      TamanhoObjeto: {
        produto: {},
        filtrosTamanho: {},
        valorAte: 0.0,
        valorDe: 0.0,
        qtdeEstoque: 0
      },
      Premio: {},
      Premios: [],
      PremioId: 0,
      Info: {},
      Infos: [],
      InfoId: 0,
      ProdutoInclusoId: 0,
      ProdutoIncluso: {},
      ProdutosInclusos: [],
      ProdutoAvulso: {},
      ItemAvulso: {
        itemAvulsoId: 0,
      },
      ProdutosAvulsos: [],
      ItensAvulsos: [],
      Ligacoes: {},
      Imagens: [],
      ProdutoId: {},
      Produto: {},
      Produtos: [],
      Segmento1: 0,
      Segmentos1: [],
      Segmento2: 0,
      Segmentos2: [],
      Segmento3: 0,
      Segmentos3: [],
      Segmento4: 0,
      Segmentos4: [],
      FiltrosFabrica: [],
      FiltroFabricaId: 0,
      FiltrosMateria: [],
      FiltroMateriaPrimaId: 0,
      FiltrosMarca: [],
      FiltroMarcaId: 0,
      FiltrosVoltagem: [],
      FiltroVoltagemId: 0,
      FiltrosCaracteristica: [],
      FiltroCaracteristicaId: 0,
      Ligacao: {
        segmentoProdNivel1: { segmentoProdNivel1Id: 0 },
        segmentoProdNivel2: { segmentoProdNivel2Id: 0 },
        segmentoProdNivel3: { segmentoProdNivel3Id: 0 },
        segmentoProdNivel4: { segmentoProdNivel4Id: 0 },
        produto: { produtoId: 0 },
      },
      Imagem: {
        produto: { produtoId: 0 },
      },
      Enviando: 0,
      Enviado: 0,
      T: $(".data-table").DataTable(),
    };
  },
  components: {
    vSelect,
    // Rodape,
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/produto/")
      .then((response) => {
        this.Produtos = response.body;
      })
      .then(this.atualiza());

    this.$http.get(this.$apiUrl + "/filtrotamanho/").then((response) => {
      this.Tamanhos = response.body;
    });

    this.$http.get(this.$apiUrl + "/filtrocaracteristica/").then((response) => {
      this.FiltrosCaracteristica = response.body;
    });

    this.$http.get(this.$apiUrl + "/filtrofabrica/").then((response) => {
      this.FiltrosFabrica = response.body;
    });

    this.$http.get(this.$apiUrl + "/filtromarca/").then((response) => {
      this.FiltrosMarca = response.body;
    });

    this.$http.get(this.$apiUrl + "/filtromateriaprima/").then((response) => {
      this.FiltrosMateria = response.body;
    });

    this.$http.get(this.$apiUrl + "/filtrovoltagem/").then((response) => {
      this.FiltrosVoltagem = response.body;
    });

    this.$http.get(this.$apiUrl + "/segmentoprodnivel1/").then((response) => {
      this.Segmentos1 = response.body;
    });

    if (this.$route.params.id != null) {
      this.ProdutoId = parseInt(this.$route.params.id, 10);
      this.Ligacao.produto.produtoId = this.ProdutoId;
      this.Imagem.produto.produtoId = this.ProdutoId;
      this.$http
        .get(this.$apiUrl + "/ligacaosegmentoproduto/produto/" + this.ProdutoId)
        .then(
          (response) => {
            this.Ligacoes = response.body;
          },
          (error) => {
            console.log(error);
            this.Ligacoes = [];
          }
        );
      this.$http
        .get(this.$apiUrl + "/produtotamanho/produto/" + this.ProdutoId)
        .then(
          (response) => {
            this.TamanhosProduto = response.body;
          },
          (error) => {
            console.log(error);
            this.TamanhosProduto = [];
          }
        );

      this.$http
        .get(this.$apiUrl + "/ProdutoImagem/produto/" + this.ProdutoId)
        .then((response) => {
          this.Imagens = response.body;
        });

      this.$http.get(this.$apiUrl + "/ItemAvulso/").then((response) => {
        this.ItensAvulsos = response.body;
      });

      this.$http
        .get(this.$apiUrl + "/ProdutoAvulso/produto/" + this.ProdutoId)
        .then((response) => {
          this.ProdutosAvulsos = response.body;
        });
      this.$http
        .get(this.$apiUrl + "/produtoincluso/produto/" + this.ProdutoId)
        .then((response) => {
          this.ProdutosInclusos = response.body;
        });
      this.$http
        .get(this.$apiUrl + "/ProdutoInfoAdicional/produto/" + this.ProdutoId)
        .then((response) => {
          this.Infos = response.body;
        });
      this.$http
        .get(this.$apiUrl + "/ProdutoPremio/produto/" + this.ProdutoId)
        .then((response) => {
          this.Premios = response.body;
        });
    } else {
      this.ProdutoId = 0;
    }

    if (this.ProdutoId != null && this.ProdutoId > 0) {
      this.$http
        .get(this.$apiUrl + "/Produto/" + this.ProdutoId)
        .then((response) => {
          this.Produto = response.body;
          console.log(this.Produto);
          if (this.Produto.filtroFabrica != null) {
            this.FiltroFabricaId = this.Produto.filtroFabrica.filtroFabricaId;
          }
          if (this.Produto.filtroMateriaPrima != null) {
            this.FiltroMateriaPrimaId =
              this.Produto.filtroMateriaPrima.filtroMateriaPrimaId;
          }
          if (this.Produto.filtroMarca != null) {
            this.FiltroMarcaId = this.Produto.filtroMarca.filtroMarcaId;
          }
          if (this.Produto.filtroVoltagem != null) {
            this.FiltroVoltagemId =
              this.Produto.filtroVoltagem.filtroVoltagemId;
          }
          if (this.Produto.filtroCaracteristica != null) {
            this.FiltroCaracteristicaId =
              this.Produto.filtroCaracteristica.filtroCaracteristicaId;
          }

          console.log("Carrego");
        });
    }
  },
  methods: {
    ApagarTamanho(id) {
      this.$http.delete(this.$apiUrl + "/produtotamanho/" + id).then(
        (response) => {
          if (response.ok) {
            this.TamanhosProduto = this.TamanhosProduto.filter(
              (x) => x.produtoTamanhoId != id
            );
            this.$mensagem_sucesso("Tamanho removido com sucesso.");
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    InserirTamanho() {
      if (this.Tamanho != null && this.Tamanho != {}) {
        this.TamanhoObjeto.filtrosTamanho = this.Tamanho;
        this.TamanhoObjeto.produto = this.Produto;
        console.log("ei")
        console.log(JSON.stringify(this.TamanhoObjeto));
        this.$http
          .post(this.$apiUrl + "/produtotamanho", this.TamanhoObjeto)
          .then((response) => {
            if (response.ok) {
              this.TamanhoObjeto.qtdeEstoque = 0;
              this.TamanhoObjeto.valorAte = this.TamanhoObjeto.valorDe = 0;
              this.TamanhoObjeto.produto = {};
              this.TamanhoObjeto.filtrosTamanho = {};
              this.Tamanho = {};
               this.$http
              .get(this.$apiUrl + "/produtotamanho/produto/" + this.ProdutoId)
              .then(
                (response) => {
                  this.TamanhosProduto = response.body;
                },
                (error) => {
                  console.log(error);
                  this.TamanhosProduto = [];
                }
              );
            }
          });
      }
    },
    UploadPremio(event) {
      var files = event.target.files;

      var x = 0;
      for (x = 0; x < files.length; x++) {
        let file = files[x];
        if (file != null) {
          var ext = file.name.split(".").pop();
          if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg" && ext != "webp") {
            this.$mensagem_erro(
              "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png', '.webp' ou '.gif')"
            );
          } else {
            this.Enviando++;
            let data = new FormData();
            data.append("arquivo", file);

            console.log(file);
            this.$http
              .post(
                this.$apiUrl + "/produtopremio/upload/" + this.PremioId,
                data
              )
              .then(
                (response) => {
                  this.Premio.urlImg = response.bodyText;
                  this.Enviado++;
                },
                (response) => {
                  console.log(response);
                }
              );
          }
        }
      }
    },
    SalvaPremio() {
      this.Premio.produto = { produtoId: this.ProdutoId };
      if (this.Premio.nomePremio != null && this.Premio.nomePremio.length > 0) {
        this.$http.post(this.$apiUrl + "/ProdutoPremio", this.Premio).then(
          (response) => {
            if (response.ok) {
              var novo = response.body;
              this.Premios.push(novo);
              this.Premios.sort(function (a, b) {
                return a.ordem - b.ordem;
              });
              this.Premio = novo;
              this.PremioId = novo.produtoPremioId;
              this.$mensagem_sucesso("Prêmio adicionado com sucesso.");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
      } else this.$mensagem_erro("Por favor, preencha o nome");
    },
    AlteraPremio() {
      this.Premio.produto = { produtoId: this.ProdutoId };
      if (this.Premio.nomePremio != null && this.Premio.nomePremio.length > 0) {
        this.$http
          .put(this.$apiUrl + "/ProdutoPremio/" + this.PremioId, this.Premio)
          .then(
            (response) => {
              if (response.ok) {
                this.Premios = this.Premios.filter(
                  (x) => x.produtoPremioId != this.PremioId
                );
                this.Premios.push(this.Premio);
                this.Premios.sort(function (a, b) {
                  return a.ordem - b.ordem;
                });
                this.$mensagem_sucesso("Prêmio alterado com sucesso.");
              }
            },
            (error) => {
              this.$mensagem_erro(error.bodyText);
            }
          );
      } else this.$mensagem_erro("Por favor, preencha o título da informação");
    },
    ApagarPremio(id) {
      this.$http.delete(this.$apiUrl + "/ProdutoPremio/" + id).then(
        (response) => {
          if (response.ok) {
            this.Premios = this.Premios.filter((x) => x.produtoPremioId != id);
            this.Premios.sort(function (a, b) {
              return a.ordem - b.ordem;
            });
            this.$mensagem_sucesso("Prêmio removido com sucesso.");
            this.Premio = {};
            this.PremioId = 0;
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    EditarPremio(Premio) {
      this.Premio = Premio;
      this.PremioId = Premio.produtoPremioId;
    },
    AlteraInfo() {
      this.Info.produto = { produtoId: this.ProdutoId };
      if (this.Info.tituloInfo != null && this.Info.tituloInfo.length > 0) {
        this.$http
          .put(this.$apiUrl + "/ProdutoInfoAdicional/" + this.InfoId, this.Info)
          .then(
            (response) => {
              if (response.ok) {
                this.Infos = this.Infos.filter(
                  (x) => x.produtoInfoAdicionalId != this.InfoId
                );
                this.Infos.push(this.Info);
                this.Infos.sort(function (a, b) {
                  return a.ordem - b.ordem;
                });
                this.$mensagem_sucesso("Informação adicionada com sucesso.");
              }
            },
            (error) => {
              this.$mensagem_erro(error.bodyText);
            }
          );
      } else this.$mensagem_erro("Por favor, preencha o título da informação");
    },
    ApagaInfo(id) {
      this.$http.delete(this.$apiUrl + "/ProdutoInfoAdicional/" + id).then(
        (response) => {
          if (response.ok) {
            this.Infos = this.Infos.filter(
              (x) => x.produtoInfoAdicionalId != id
            );
            this.Infos.sort(function (a, b) {
              return a.ordem - b.ordem;
            });
            this.$mensagem_sucesso("Informação removida com sucesso.");
            this.Info = {};
            this.InfoId = 0;
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    EditaInfo(Info) {
      this.Info = Info;
      this.InfoId = Info.produtoInfoAdicionalId;
    },
    SalvaInfo() {
      this.Info.produto = { produtoId: this.ProdutoId };
      if (this.Info.tituloInfo != null && this.Info.tituloInfo.length > 0) {
        this.$http.post(this.$apiUrl + "/ProdutoInfoAdicional", this.Info).then(
          (response) => {
            if (response.ok) {
              var novo = response.body;
              this.Infos.push(novo);
              this.Infos.sort(function (a, b) {
                return a.ordem - b.ordem;
              });
              this.$mensagem_sucesso("Informação adicionada com sucesso.");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
      } else this.$mensagem_erro("Por favor, preencha o título da informação");
    },
    AlterarProdutoIncluso() {
      if (
        this.ProdutoIncluso.nomeProdutoIncluso != null &&
        this.ProdutoIncluso.nomeProdutoIncluso.length > 0
      ) {
        //ok
        this.ProdutoIncluso.produto = { produtoId: this.ProdutoId };
        this.$http
          .put(
            this.$apiUrl + "/produtoincluso/" + this.ProdutoInclusoId,
            this.ProdutoIncluso
          )
          .then(
            (response) => {
              if (response.ok) {
                this.ProdutoInclusoId = 0;
                this.ProdutoIncluso.nomeProdutoIncluso = "";
                this.ProdutoIncluso.ordem = 0;
                this.ProdutoIncluso.unidade = "";
                this.ProdutoIncluso.produtoInclusoId = 0;
                //this.$redirect("/controle/produto/" + this.ProdutoId);
                this.$mensagem_sucesso("Produto incluso alterado com sucesso");
                this.$http
                  .get(
                    this.$apiUrl + "/produtoincluso/produto/" + this.ProdutoId
                  )
                  .then((response) => {
                    this.ProdutosInclusos = response.body;
                  });
              }
            },
            (error) => {
              this.$mensagem_erro(error.bodyText);
            }
          );
      } else {
        this.$mensagem_erro("Por favor, preencha o nome do produto incluso");
      }
    },
    CarregaInclusoEditar(id) {
      this.ProdutoIncluso.produtoInclusoId = 0;
      this.$http
        .get(this.$apiUrl + "/produtoincluso/" + id)
        .then((response) => {
          this.ProdutoIncluso = response.body;
          this.ProdutoInclusoId = id;
        });
    },
    SalvarProdutoIncluso() {
      this.ProdutoIncluso.produtoInclusoId = 0;
      if (
        this.ProdutoIncluso.nomeProdutoIncluso != null &&
        this.ProdutoIncluso.nomeProdutoIncluso.length > 0
      ) {
        //ok
        this.ProdutoIncluso.produto = { produtoId: this.ProdutoId };
        this.$http
          .post(this.$apiUrl + "/produtoincluso/", this.ProdutoIncluso)
          .then(
            (response) => {
              var retorno = response.body;
              this.ProdutosInclusos.push(retorno);
              this.$mensagem_sucesso("Produto incluso adicionado com sucesso");
              this.ProdutoIncluso.nomeProdutoIncluso = "";
              this.ProdutoIncluso.ordem = 0;
              this.ProdutoIncluso.unidade = "";
            },
            (error) => {
              this.$mensagem_erro(error.bodyText);
            }
          );
      } else {
        this.$mensagem_erro("Por favor, preencha o nome do produto incluso");
      }
    },
    ApagarIncluso(id) {
      this.$http.delete(this.$apiUrl + "/produtoincluso/" + id).then(
        (response) => {
          if (response.ok) {
            this.$mensagem_sucesso("Item removido");
            this.ProdutosInclusos = this.ProdutosInclusos.filter(
              (x) => x.produtoInclusoId != id
            );
            this.$redirect("/controle/produto/" + this.ProdutoId);
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    ApagaProdutoAvulso(id) {
      this.$http.delete(this.$apiUrl + "/produtoavulso/" + id).then(
        (response) => {
          if (response.ok) {
            this.$mensagem_sucesso("Item removido");
            this.ProdutosAvulsos = this.ProdutosAvulsos.filter(
              (x) => x.produtoAvulsoId != id
            );
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    MudaAvulso(input) {
      this.ItemAvulso.itemAvulsoId = input;
    },
    SalvarProdutoAvulso() {
      if (this.ItemAvulso.itemAvulsoId <= 0) {
        this.$mensagem_erro("Por favor selecione o item avulso.");
      } else {
        this.ProdutoAvulso.produto = { produtoId: this.ProdutoId };
        this.ProdutoAvulso.itemAvulso = this.ItemAvulso;
        this.$http
          .post(this.$apiUrl + "/produtoavulso", this.ProdutoAvulso)
          .then(
            (response) => {
              let tmp = response.body;
              this.ProdutosAvulsos.push(tmp);
              this.$mensagem_sucesso("Item avulso adicionado com sucesso");
              this.ProdutoAvulso.itemAvulso.itemAvulsoId = 0;
              this.ProdutoAvulso.ordem = 0;
              this.atualiza();
            },
            (error) => {
              this.$mensagem_erro(error.bodyText);
            }
          );
      }
    },
    SalvarOrdem() {
      this.Imagens.forEach((element) => {
        // console.log("---");
        // console.log(element);
        element.ordem = parseInt(element.ordem, 10);
        this.$http
          .put(
            this.$apiUrl + "/produtoimagem/" + element.produtoImagemId,
            element
          )
          .then((response) => {
            console.log(response.body);
          });
      });
      this.$mensagem_sucesso("Alterações salvas");
      this.Imagens = this.Imagens.sort(function (a, b) {
        return a.ordem - b.ordem;
      });
    },
    ApagaImagem(Imagem) {
      this.$http
        .delete(this.$apiUrl + "/produtoImagem/" + Imagem.produtoImagemId)
        .then(
          (response) => {
            console.log(response);
            this.$mensagem_sucesso("Removido com sucesso");
            var i = this.Imagens.indexOf(Imagem);
            this.Imagens.splice(i, 1);
          },
          (response) => {
            console.log(response);
          }
        );
    },
    SalvarImagem() {
      let file = document.getElementById("arquivo").files[0];
      let URLIMAGEM = "";
      if (file != null) {
        var ext = file.name.split(".").pop();
          if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg" && ext != "webp") {
          this.$mensagem_erro(
            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png', '.webp' ou '.gif')"
          );
        }
      }
      let data = new FormData();
      this.Enviando++;
      if (file != null) {
        data.append("arquivo", file);
        console.log(file);
        this.$http.post(this.$apiUrl + "/produtoimagem/upload", data).then(
          (response3) => {
            // get body data
            URLIMAGEM = response3.body;
            console.log("SUBIU AQUI: " + URLIMAGEM);
            this.Enviado++;
            this.Imagem.urlImg = URLIMAGEM;
            this.$http
              .post(this.$apiUrl + "/produtoimagem/", this.Imagem)
              .then((response) => {
                if (response.ok) {
                  console.log(response);
                  this.$http
                    .get(
                      this.$apiUrl + "/ProdutoImagem/produto/" + this.ProdutoId
                    )
                    .then((response2) => {
                      this.Imagens = response2.body;
                    });
                } else {
                  this.$mensagem_erro(response.bodyText);
                }
              });
            //this.CriarUsuarioComImagem(_usuario, URLIMAGEM);
          },
          (response3) => {
            // error callback
            this.$mensagem_erro(response3.body);
          }
        );
      } else {
        this.$mensagem_erro("Por favor selecione uma imagem");
      }
    },
    SalvarSegmentos() {
      this.$http
        .post(this.$apiUrl + "/LigacaoSegmentoProduto/", this.Ligacao)
        .then((response) => {
          if (response.ok) {
            this.$http
              .get(
                this.$apiUrl +
                  "/LigacaoSegmentoProduto/produto/" +
                  this.ProdutoId
              )
              .then((response2) => {
                this.Ligacoes = response2.body;
              });
          }
        });
    },
    ApagarLigacao(id) {
      this.$http
        .delete(this.$apiUrl + "/LigacaoSegmentoProduto/" + id)
        .then((response) => {
          if (response.ok) {
            this.$mensagem_sucesso("Ligação de segmento removida com sucesso");
            this.$http
              .get(
                this.$apiUrl +
                  "/LigacaoSegmentoProduto/produto/" +
                  this.ProdutoId
              )
              .then((response2) => {
                this.Ligacoes = response2.body;
              });
          }
        });
    },
    MudaSegmento1() {
      this.$http
        .get(this.$apiUrl + "/segmentoProdNivel2/nivel1/" + this.Segmento1)
        .then((response) => {
          this.Segmentos2 = response.body;
          this.Ligacao.segmentoProdNivel1.segmentoProdNivel1Id = this.Segmento1;
          if (this.ArquivoId > 0 && this.Arquivo.segmentoProdNivel2 != null) {
            this.Segmento2 =
              this.Arquivo.segmentoProdNivel2.segmentoProdNivel2Id;
            this.MudaSegmento2();
          }
        });
    },
    MudaSegmento2() {
      this.$http
        .get(this.$apiUrl + "/segmentoProdnivel3/nivel2/" + this.Segmento2)
        .then((response) => {
          this.Segmentos3 = response.body;
          this.Ligacao.segmentoProdNivel2.segmentoProdNivel2Id = this.Segmento2;
          if (this.ArquivoId > 0 && this.Arquivo.segmentoProdNivel3 != null) {
            this.Segmento3 =
              this.Arquivo.segmentoProdNivel3.segmentoProdNivel3Id;
            this.MudaSegmento3();
          }
        });
    },
    MudaSegmento3() {
      this.$http
        .get(this.$apiUrl + "/segmentoProdnivel4/nivel3/" + this.Segmento3)
        .then((response) => {
          this.Segmentos4 = response.body;
          this.Ligacao.segmentoProdNivel3.segmentoProdNivel3Id = this.Segmento3;
          if (this.ArquivoId > 0 && this.Arquivo.segmentoProdNivel4 != null) {
            this.Segmento3 =
              this.Arquivo.segmentoProdNivel3.segmentoProdNivel3Id;
            this.MudaSegmento3();
          }
        });
    },
    MudaSegmento4() {
      this.Ligacao.segmentoProdNivel4.segmentoProdNivel4Id = this.Segmento4;
    },
    SelecionaFabrica(input) {
      this.Produto.filtroFabrica = {
        filtroFabricaId: input,
      };
    },
    SelecionaMateria(input) {
      this.Produto.filtroMateriaPrima = {
        filtroMateriaPrimaId: input,
      };
    },
    SelecionaMarca(input) {
      this.Produto.filtroMarca = {
        filtroMarcaId: input,
      };
    },
    SelecionaVoltagem(input) {
      this.Produto.filtroVoltagem = {
        filtroVoltagemId: input,
      };
    },
    SelecionaCaracteristica(input) {
      this.Produto.filtroCaracteristica = {
        filtroCaracteristicaId: input,
      };
    },
    Salvar() {
      console.log("SALVAR");
      console.log(this.Produto);
      this.$http.post(this.$apiUrl + "/Produto/", this.Produto).then(
        (response) => {
          this.$mensagem_sucesso("Produto cadastrado com sucesso");
          this.$redirect_reload("/controle/produto/" + response.body.produtoId);
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Alterar() {
      this.$http
        .put(this.$apiUrl + "/Produto/" + this.ProdutoId, this.Produto)
        .then(
          (response) => {
            this.$mensagem_sucesso("Produto alterado com sucesso");
            this.$redirect_reload("/controle/listaprodutos/");
            console.log(response);
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    Remove(Produto) {
      console.log("Apagar id " + Produto.ProdutoId);
      this.$http.delete(this.$apiUrl + "/Produto/" + Produto.ProdutoId).then(
        (response) => {
          if (response.ok)
            this.$mensagem_sucesso(
              "#" + Produto.ProdutoId + " Removido com sucesso."
            );
          this.$redirect_reload("/controle/Produtos/");
        },
        (error) => {
          console.log(error);
        }
      );
    },

    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
