<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Fontes de Produtos Gr</h4>
                                            <p class="mb-30">
                                                Tela para cadastro das Fontes de Produtos Gr que poderão ser utilizadas nos filtros do produto.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Nome da Fonte</label>
                                                <input v-model="Nometamanho" class="form-control" type="text" placeholder="Nome">
                                            </div>
                                        </div>
                                        <div class="col-md-2" style="display:none;">
                                            <div class="form-group">
                                                <label>Sigla do tamanho</label>
                                                <input v-model="Siglatamanho" class="form-control" type="text" placeholder="Sigla">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <!--<button v-if="FiltrovalorId == null || FiltrovalorId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/FiltrosValor/' + Numero)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>-->
                                            <button v-if="FiltrotamanhoId != null && FiltrotamanhoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Nome</th>
                                <th style="display:none;">Sigla</th>
                                <th>Ordem</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Filtrotamanho in Filtrostamanho" :key="Filtrotamanho.filtroTamanhoId">
                                <td>{{ Filtrotamanho.filtroTamanhoId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/filtrotamanho/' + Filtrotamanho.filtroTamanhoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Filtrotamanho)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Filtrotamanho.nomeTamanho }}</td>
                                <td style="display:none;">{{ Filtrotamanho.siglaTamanho }}</td>
                                <td>{{ Filtrotamanho.ordem }}</td>
                                <td><span v-if="Filtrotamanho.visivel">Sim</span><span v-else>Não</span></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                FiltrotamanhoId: this.$route.params.id,
                Visivel: null,
                Nometamanho: null,
                Siglatamanho: null,
                IdiomaId: 0,
                Ordem: 0,
                Filtrotamanho: {},
                Filtrostamanho: [],
                Statuselected: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus(value) {
                this.Visivel = value;
            },
            Add() {
                let _Filtrotamanho = {
                    filtrotamanhoid: 0,
                    visivel: null,
                    nometamanho: null,
                    siglatamanho: null,
                    idiomaId: 0,
                    ordem: 0,
                };

                //validações
                if (this.Nometamanho == null) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } 
                // else if (this.Siglatamanho == null) {
                //     this.$mensagem_normal("Por favor preencha sigla");}
                     else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                    _Filtrotamanho.visivel = this.Visivel;
                    _Filtrotamanho.nometamanho = this.Nometamanho;
                    _Filtrotamanho.siglatamanho = this.Siglatamanho; 
                    _Filtrotamanho.idiomaId = this.IdiomaId;
                    _Filtrotamanho.ordem = parseInt(this.Ordem,10);

                    console.log(_Filtrotamanho);
                    let ID = _Filtrotamanho.idiomaId;
                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/filtrotamanho/" + ID, _Filtrotamanho).then(
                        response2 => {
                            // get body data
                            _Filtrotamanho = response2.body;
                            this.$router.go(this.$router.currentRoute);
                        },
                        response2 => {
                            _Filtrotamanho = response2.body;
                            this.$mensagem_normal(_Filtrotamanho);
                        }
                    );
                }
            },
            Remove(dadosRemover) {
                //chama o delete da api
                console.log(dadosRemover.filtroTamanhoId);
                this.$http
                    .delete(this.$apiUrl + "/filtrotamanho/" + dadosRemover.filtroTamanhoId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Filtrotamanho = {
                    filtrotamanhoid: 0,
                    visivel: null,
                    nometamanho: null,
                    siglatamanho: null,
                    idiomaId: 0,
                    ordem: 0,
                };

                //validações
                if (this.Nometamanho == null) {
                    this.$mensagem_normal("Por favor preencha o nome");
                } 
                // else if (this.Siglatamanho == null) {
                //     this.$mensagem_normal("Por favor preencha sigla");}
                     else if (this.Visivel == null) {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else {

                    _Filtrotamanho.visivel = this.Visivel;
                    _Filtrotamanho.nometamanho = this.Nometamanho;
                    _Filtrotamanho.siglatamanho = this.Siglatamanho;
                    _Filtrotamanho.idiomaId = this.IdiomaId;
                    _Filtrotamanho.ordem = parseInt(this.Ordem, 10);
                    _Filtrotamanho.filtrotamanhoid = parseInt(this.FiltrotamanhoId, 10);
                    console.log(_Filtrotamanho);
                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/filtrotamanho/" + _Filtrotamanho.filtrotamanhoid, _Filtrotamanho)
                        .then(
                            response2 => {
                                // get body data
                                _Filtrotamanho = response2.body;
                                //console.log(_Filtrovalor);
                                this.$mensagem_sucesso(
                                    "Filtro Tamanho #" + this.FiltrotamanhoId + " alterado com sucesso"
                                );
                                this.Limpar();
                            },
                            async response2 => {
                                _Filtrotamanho = response2.body;
                                // error callback
                                //const data = await response2.json();
                                //JSON.parse(data);
                                this.$mensagem_normal(_Filtrotamanho);
                            }
                        );
                }
            },
            //Pesquisa() {
            //    try {
            //        if (this.Numero.length <= 0) {
            //            this.$mensagem_normal("Por favor preencha o número");
            //        } else {
            //            this.Cadwhatsapps = [];
            //            let _Cadwhatsapp = {
            //                Numero: this.Numero,
            //            };
            //            this.$http
            //                .post(this.$apiUrl + "/cadwhatsapp/busca", _Cadwhatsapp)
            //                .then((res) => res.json())
            //                .then((tiu) => (this.Cadwhatsapps = tiu))
            //                .then(this.atualiza());
            //        }
            //    } catch {
            //        this.$mensagem_normal("Nenhum resultado encontrado");
            //    }
            //},
            Limpar() {
                this.$redirect_reload("/controle/filtrotamanho");
                this.$http
                    .get(this.$apiUrl + "/filtrotamanho")
                    .then((res) => res.json())
                    .then((moe) => (this.Filtrostamanho = moe))
                    .then(this.atualiza());
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            //if (this.$route.params.pesquisa != null) {
            //    this.Numero = this.$route.params.pesquisa;
            //} else {
            //    this.Numero = "";
            //}

            if (this.FiltrotamanhoId == null) {
                this.FiltrotamanhoId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.FiltrotamanhoId != null && this.FiltrotamanhoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/filtrotamanho/" + this.FiltrotamanhoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Filtrotamanho = idi;

                        this.Nometamanho = this.Filtrotamanho.nomeTamanho;
                        this.Siglatamanho = this.Filtrotamanho.siglaTamanho;
                        this.Ordem = this.Filtrotamanho.ordem;
                        this.Statuselected = this.Filtrotamanho.visivel;
                        this.Visivel = this.Filtrotamanho.visivel;

                    });
            }
            //else if (this.Numero != null && this.Numero.length > 0) {
            //    this.Pesquisa();
            //}
            else {
                this.$http
                    .get(this.$apiUrl + "/filtrotamanho")
                    .then((res) => res.json())
                    .then((moe) => (this.Filtrostamanho = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
