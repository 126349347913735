<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela Clientes</h4>
                      <p class="mb-30">Tela de clientes cadastrados.</p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <div class="tab">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active text-blue"
                        data-toggle="tab"
                        href="#dados"
                        role="tab"
                        aria-selected="true"
                        >Dados Cliente</a
                      >
                    </li>
                    <!--<li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#arquivos"
                        role="tab"
                        aria-selected="false"
                        >Arquivos</a
                      >
                    </li>
                     <li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#fotos"
                        role="tab"
                        aria-selected="false"
                        >Fotos</a
                      >
                    </li>
                    <li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#videos"
                        role="tab"
                        aria-selected="false"
                        >Vídeos</a
                      >
                    </li>-->
                    <!--<li class="nav-item">
                                            <a class="nav-link text-blue" data-toggle="tab" href="#entregas" role="tab" aria-selected="false">End. Entregas</a>
                                        </li>-->
                    <li
                      class="nav-item"
                      v-if="ClienteId > 0"
                      style="display: none"
                    >
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#upados"
                        role="tab"
                        aria-selected="false"
                        >Doc. Upload</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      v-if="ClienteId > 0"
                      style="display: none"
                    >
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#precos"
                        role="tab"
                        aria-selected="false"
                        >Tabela Preços</a
                      >
                    </li>
                    <!--referente a parte de planos-->
                    <!--<li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#planos"
                        role="tab"
                        aria-selected="false"
                        >Planos</a
                      >
                    </li>
                    <li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#cidades"
                        role="tab"
                        aria-selected="false"
                        >Cidades</a
                      >
                    </li>
                    <li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#servicos"
                        role="tab"
                        aria-selected="false"
                        >Serviços</a
                      >
                    </li>
                    <li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#atuacao"
                        role="tab"
                        aria-selected="false"
                        >Atuação</a
                      >
                    </li>
                    <li class="nav-item" v-if="ClienteId > 0">
                      <a
                        class="nav-link text-blue"
                        data-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-selected="false"
                        >Informações</a
                      >
                    </li>-->
                  </ul>
                  <div class="tab-content">
                    <div
                      class="tab-pane fade show active"
                      id="dados"
                      role="tabpanel"
                    >
                      <div class="pd-20">
                        <form autocomplete="off">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Status</label>
                                <!-- <select class="custom-select col-12">
                              <option selected="">Selecione...</option>
                              <option value="1">Ativo</option>
                              <option value="2">Inativo</option>
                            </select> -->
                                <v-select
                                  v-model="Cliente.ativo"
                                  :options="[
                                    { nome: 'Ativo', valor: true },
                                    { nome: 'Inativo', valor: false },
                                  ]"
                                  single-line
                                  :reduce="(x) => x.valor"
                                  label="nome"
                                  class="col-12 style-chooser"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Tipo</label>
                                <!-- <select class="custom-select col-12">
                              <option selected="">Selecione...</option>
                              <option value="1">Pessoa Física</option>
                              <option value="2">Pessoa Jurídica</option>
                            </select> -->
                                <v-select
                                  v-model="Cliente.tipoCliente"
                                  :options="Tipos"
                                  single-line
                                  label="nomeTipoCliente"
                                  class="col-12 style-chooser"
                                  @input="MudaTipo"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Cadastro</label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder=""
                                  v-model="Cadastro"
                                  @change="MudaDataCadastro"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Senha</label>
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  class="form-control"
                                  placeholder="Senha"
                                  autocomplete="new-password"
                                  v-model="Senha"
                                />
                                <span
                                  class="lnr-eye icon-copy dw dw-hide"
                                ></span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Nome</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome"
                                  v-model="Cliente.nomeEmpresa"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-6"
                              v-if="
                                Cliente.tipoCliente != null &&
                                Cliente.tipoCliente.tipoClienteId == 2
                              "
                            >
                              <div class="form-group">
                                <label>Razão Social</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Razão Social"
                                  v-model="Cliente.razaoSocial"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-6"
                              v-if="
                                Cliente.tipoCliente != null &&
                                Cliente.tipoCliente.tipoClienteId == 1
                              "
                            >
                              <div class="form-group">
                                <label>CPF</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="CPF"
                                  v-model="Cliente.cpf"
                                />
                              </div>
                            </div>
                            <div
                              class="col-md-6"
                              v-if="
                                Cliente.tipoCliente != null &&
                                Cliente.tipoCliente.tipoClienteId == 2
                              "
                            >
                              <div class="form-group">
                                <label>CNPJ</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="CNPJ"
                                  v-model="Cliente.cnpj"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>E-mail</label>
                                <input
                                  autocomplete="new-password"
                                  class="form-control"
                                  type="text"
                                  placeholder="e-mail"
                                  v-model="Cliente.email"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Telefone</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Telefone"
                                  v-model="Cliente.telefone"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Whatsapp</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Whatsapp"
                                  v-model="Cliente.whatsapp"
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                      <div class="form-group">
                        <label>Acesso aos privados</label>
                        <v-select
                          v-model="Cliente.acessoPrivado"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                            </div>
                    </div>
                    <div class="col-md-12">
                            <!--<div class="col-md-3">
                              <div class="form-group">
                                <label>Responsável</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome Responsável"
                                  v-model="Cliente.responsavel"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Aniversário</label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder=""
                                  v-model="Aniversario"
                                  @change="MudaData2"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Campo-extra-1</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Campo-extra-1"
                                  v-model="Cliente.campoExtra1"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Campo-extra-2</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Campo-extra-2"
                                  v-model="Cliente.campoExtra2"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Campo-extra-3</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Campo-extra-3"
                                  v-model="Cliente.campoExtra3"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Campo-extra-4</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Campo-extra-4"
                                  v-model="Cliente.campoExtra4"
                                />
                              </div>
                            </div>-->
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="arquivos"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="sitemap">
                              <!-- <h5 class="h5" style="padding-left: 30px">
                          <div
                            class="custom-control custom-checkbox mb-5"
                            style="padding-left: 10px"
                          >

                            <label
                              class="custom-control-label"
                              for="customCheck25"
                              >{{ SegmentoArquivo.nomeSegmento }}</label
                            >
                          </div>
                        </h5> -->
                              <ul>
                                <li
                                  v-for="(Arquivo, index) in ArquivosIndex"
                                  :key="index"
                                >
                                  <div
                                    class="custom-control custom-checkbox mb-5"
                                    style="padding-left: 10px"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      :id="'customCheck26' + index"
                                      v-model="Arquivo.checked"
                                    /><label
                                      class="custom-control-label"
                                      :for="'customCheck26' + index"
                                      >{{ Arquivo.nomeArquivo }}</label
                                    >
                                    <!-- <input
                                      type="checkbox"
                                      class=""
                                      v-model="Arquivo.checked"
                                    />
                                    <label
                                      class="custom-control-label"

                                      >{{ Arquivo.nomeArquivo }}</label
                                    > -->
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row">
              <div
                class="col-md-12 text-center"
                style="padding-top: 40px"
              >
                <button type="button" class="btn btn-link">
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="sa-custom-position"
                >
                  Salvar
                </button>
              </div>
            </div> -->
                      </div>
                    </div>
                     <div
                      class="tab-pane fade"
                      id="fotos"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="sitemap">
                              <!-- <h5 class="h5" style="padding-left: 30px">
                          <div
                            class="custom-control custom-checkbox mb-5"
                            style="padding-left: 10px"
                          >

                            <label
                              class="custom-control-label"
                              for="customCheck25"
                              >{{ SegmentoArquivo.nomeSegmento }}</label
                            >
                          </div>
                        </h5> -->
                              <ul>
                                <li
                                  v-for="(Foto, index) in FotosIndex"
                                  :key="index"
                                >
                                  <div
                                    class="custom-control custom-checkbox mb-5"
                                    style="padding-left: 10px"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      :id="'customCheck262' + index"
                                      v-model="Foto.checked"
                                    /><label
                                      class="custom-control-label"
                                      :for="'customCheck262' + index"
                                      >{{ Foto.nomeFoto }}</label
                                    >
                                    <!-- <input
                                      type="checkbox"
                                      class=""
                                      v-model="Arquivo.checked"
                                    />
                                    <label
                                      class="custom-control-label"

                                      >{{ Arquivo.nomeArquivo }}</label
                                    > -->
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row">
              <div
                class="col-md-12 text-center"
                style="padding-top: 40px"
              >
                <button type="button" class="btn btn-link">
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="sa-custom-position"
                >
                  Salvar
                </button>
              </div>
            </div> -->
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="videos"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="sitemap">
                              <!-- <h5 class="h5" style="padding-left: 30px">
                          <div
                            class="custom-control custom-checkbox mb-5"
                            style="padding-left: 10px"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              class="custom-control-label"
                              for="customCheck1"
                              >Nome do Segmento</label
                            >
                          </div>
                        </h5> -->
                              <ul>
                                <li
                                  v-for="(Video, index) in VideosIndex"
                                  :key="index"
                                >
                                  <div
                                    class="custom-control custom-checkbox mb-5"
                                    style="padding-left: 10px"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      :id="'customCheck2613' + index"
                                      v-model="Video.checked"
                                    /><label
                                      class="custom-control-label"
                                      :for="'customCheck2613' + index"
                                      >{{ Video.nomeVideo }}</label
                                    >
                                    <!-- <input
                                      type="checkbox"
                                      class=""
                                      v-model="Video.checked"
                                    />
                                    <label
                                      class="custom-control-label"

                                      >{{ Video.nomeVideo }}</label
                                    > -->
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row">
              <div
                class="col-md-12 text-center"
                style="padding-top: 40px"
              >
                <button type="button" class="btn btn-link">
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="sa-custom-position"
                >
                  Salvar
                </button>
              </div>
            </div> -->
                      </div>
                    </div>
                    <!--<div class="tab-pane fade" id="entregas" role="tabpanel">
        <div class="pd-20">
        </div>
    </div>-->
                    <div
                      class="tab-pane fade"
                      id="upados"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="sitemap">
                              <h5 class="h5" style="padding-left: 30px">
                                <label class="">Etapa 1</label>
                              </h5>
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="sitemap">
                              <h5 class="h5" style="padding-left: 30px">
                                <label class="">Etapa 2</label>
                              </h5>
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="sitemap">
                              <h5 class="h5" style="padding-left: 30px">
                                <label class="">Etapa 3</label>
                              </h5>
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    style="margin-left: -30px; margin-top: 5px"
                                  >
                                    <i
                                      class="icon-copy dw dw-download"
                                      style="font-size: 18px"
                                    ></i>
                                    <span>Nome do Arquivo</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="precos"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="sitemap">
                              <ul>
                                <li>
                                  <div
                                    class="custom-control custom-radio mb-5"
                                    style="padding-left: 10px"
                                  >
                                    <input
                                      type="radio"
                                      id="customRadio1"
                                      name="customRadio"
                                      class="custom-control-input"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio1"
                                      >Tabela de Preços 1 PF</label
                                    >
                                  </div>
                                </li>
                                <li>
                                  <div
                                    class="custom-control custom-radio mb-5"
                                    style="padding-left: 10px"
                                  >
                                    <input
                                      type="radio"
                                      id="customRadio2"
                                      name="customRadio"
                                      class="custom-control-input"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio2"
                                      >Tabela de Preços 2 PF</label
                                    >
                                  </div>
                                </li>
                                <li>
                                  <div
                                    class="custom-control custom-radio mb-5"
                                    style="padding-left: 10px"
                                  >
                                    <input
                                      type="radio"
                                      id="customRadio3"
                                      name="customRadio"
                                      class="custom-control-input"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customRadio3"
                                      >Tabela de Preços 1 PJ</label
                                    >
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row">
              <div
                class="col-md-12 text-center"
                style="padding-top: 40px"
              >
                <button type="button" class="btn btn-link">
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="sa-custom-position"
                >
                  Salvar
                </button>
              </div>
            </div> -->
                      </div>
                    </div>
                    <!--refernte a parte de planos-->
                    <div
                      class="tab-pane fade"
                      id="planos"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="sitemap">
                              <ul>
                                <li
                                  v-for="plano in Planos"
                                  :key="plano.planosClienteId"
                                  style="padding-left: 10px !important"
                                >
                                  <div class="mb-5">
                                    <label class=""
                                      >Plano: {{ plano.plano.nomePlano }} -
                                      Valor mensal R$
                                      {{
                                        parseFloat(plano.plano.valorPorPlano)
                                          .toFixed(2)
                                          .replace(".", ",")
                                      }}</label
                                    >
                                    <label class="">
                                      Período:
                                      {{ FormataData(plano.dataInicial) }} até
                                      {{ FormataData(plano.dataFinal) }} -
                                      Situação:
                                      {{ plano.pago ? "Pago" : "Pendente" }}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="cidades"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="sitemap">
                              <ul>
                                <li
                                  v-for="cidade in Cidades"
                                  :key="cidade.planosClienteCidadeId"
                                  style="padding-left: 10px !important"
                                >
                                  <div class="mb-5">
                                    <label class=""
                                      >{{ cidade.estado.nomeEstado }} -
                                      {{ cidade.cidade.nomeCidade }}</label
                                    >
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="servicos"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="sitemap">
                              <ul>
                                <li
                                  v-for="servico in Servicos"
                                  :key="servico.planosClienteServicoId"
                                  style="padding-left: 10px !important"
                                >
                                  <div class="mb-5">
                                    <label class="">{{
                                      servico.listaServicosPlano.nomeServico
                                    }}</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="atuacao"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="sitemap">
                              <ul>
                                <li
                                  v-for="atuacao in Atuacoes"
                                  :key="atuacao.planosClienteAtuacaoId"
                                  style="padding-left: 10px !important"
                                >
                                  <div class="mb-5">
                                    <label class="">{{
                                      atuacao.listaAtuacaoPlano.nomeAtuacao
                                    }}</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="info"
                      role="tabpanel"
                      v-if="ClienteId > 0"
                    >
                      <div class="pd-20">
                        <div class="row">
                          <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="sitemap">
                              <ul>
                                <li
                                  v-for="informa in Informacao"
                                  :key="informa.planosClienteInformacaoId"
                                  style="padding-left: 10px !important"
                                >
                                  <div class="mb-5">
                                    <label class=""
                                      >Facebook : {{ informa.facebook }}</label
                                    >
                                    <label class=""
                                      >Instagram :
                                      {{ informa.instagram }}</label
                                    >
                                    <label class=""
                                      >Linkedin : {{ informa.linkedin }}</label
                                    >
                                    <label class=""
                                      >Outro : {{ informa.outro }}</label
                                    >
                                    <label class=""
                                      >Avatar :
                                      <img
                                        :src="informa.urlImg"
                                        alt=""
                                        class="img-fluid"
                                    /></label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 text-right mt-15">
                    <button
                      type="button"
                      class="btn btn-link"
                      @click="$redirect_reload('/controle/clientes')"
                    >
                      Cancelar
                    </button>
                    <!-- <button type="button" class="btn btn-info">
                                                    Pesquisar
                                                  </button> -->
                  </div>
                  <div class="col-md-6 text-left mt-15">
                    <button
                      v-if="ClienteId <= 0"
                      type="button"
                      class="btn btn-success"
                      id="sa-custom-position"
                      @click="Add()"
                    >
                      Salvar Dados
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-success"
                      id="sa-custom-position"
                      @click="Update()"
                    >
                      Salvar Alterações
                    </button>
                    <button
                      v-if="ClienteId > 0"
                      type="button"
                      class="btn btn-warning"
                      id="sa-custom-position"
                      @click="EnviarSenha()"
                    >
                      Enviar Senha
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Status</th>
                <th>CPF/CNPJ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Cliente, index) in Clientes" :key="index">
                <td>{{ Cliente.clienteId }}</td>
                <td>
                  <div class="dropdown">
                    <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown">
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect_reload(
                            '/controle/cliente/' + Cliente.clienteId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <a
                        class="dropdown-item"
                        @click="Remover(Cliente.clienteId)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Cliente.nomeEmpresa }}
                  <br/><small>E-mail: {{ Cliente.email }}</small>
                </td>
                <td>{{ Cliente.whatsapp }}</td>
                <td>
                  <span v-if="Cliente.ativo">Ativo</span
                  ><span v-else>Inativo</span>
                </td>
                <td>
                  <span
                    v-if="
                      Cliente.tipoCliente != null &&
                      Cliente.tipoCliente.tipoClienteId == 1
                    "
                    >{{ Cliente.cpf }}</span
                  >
                  <span
                    v-if="
                      Cliente.tipoCliente != null &&
                      Cliente.tipoCliente.tipoClienteId == 2
                    "
                    >{{ Cliente.cnpj }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";

export default {
  data() {
    return {
      ArquivosCliente: [],
      ArquivosTodos: [],
      ArquivosIndex: [],
      VideosCliente: [],
      VideosTodos: [],
      VideosIndex: [],
      FotosCliente: [],
      FotosTodos: [],
      FotosIndex: [],
      ClienteId: 0,
      Cadastro: "",
      Aniversario: "",
      Senha: "",
      Cliente: {
        tipoCliente: {},
      },
      Tipos: [],
      Clientes: [],
      T: {},
      Cidades: [],
      cidade: {},
      Servicos: [],
      servico: {},
      Atuacoes: [],
      atuacao: {},
      Informacao: [],
      informa: {},
      Planos: [],
      plano: {},
    };
  },
  components: {
    vSelect,
    // Cabecalho,
    // Rodape,
  },
  methods: {
    FormataData(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    MudaDataCadastro() {
      this.Cliente.dataCadastro = moment(this.Cadastro)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    MudaData2() {
      this.Cliente.dataAniversario = moment(this.Aniversario)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    MudaTipo(event) {
      this.Cliente.tipoCliente = event;
    },
    Add() {
      this.Cliente.senha = this.$sha1(this.Senha);
      this.$http.post(this.$apiUrl + "/clientes/", this.Cliente).then(
        (response) => {
          this.Cliente = response.body;
          this.$mensagem_sucesso("Cliente Cadastrado com sucesso");
          this.$router.go(this.$router.currentRoute);
          this.$router.go(this.$router.currentRoute);
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Update() {
      if (this.Senha.length > 0) {
        this.Cliente.senha = this.$sha1(this.Senha);
      }
      if (this.Cliente.senha.length > 0) {
        this.Cliente.senha = this.$sha1(this.Cliente.senha);
      }
      this.$http
        .put(this.$apiUrl + "/clientes/" + this.Cliente.clienteId, this.Cliente)
        .then(
          (response) => {
            this.Cliente = response.body;
            this.$http
              .post(
                this.$apiUrl +
                  "/arquivosclientes/atualiza/" +
                  this.Cliente.clienteId,
                this.ArquivosIndex.filter((x) => x.checked == true)
              )
              .then(
                (response) => {
                  console.log(response);
                  this.$http.post(
                    this.$apiUrl +
                      "/Videosclientes/atualiza/" +
                      this.Cliente.clienteId,
                    this.VideosIndex.filter((x) => x.checked == true)
                  ).
                  then(() => {
                    this.$http.post(
                      this.$apiUrl +
                        "/fotosclientes/atualiza/" +
                        this.Cliente.clienteId,
                      this.FotosIndex.filter((x) => x.checked == true)
                    );
                  }).then(
                    (response) => {
                      console.log(response);
                      this.$mensagem_sucesso(
                        "Cliente #" +
                          this.Cliente.clienteId +
                          " alterado com sucesso"
                      );
                      this.$redirect("/controle/clientes");
                      this.$router.go(this.$router.currentRoute);
                      this.$router.go(this.$router.currentRoute);
                    },
                    (error) => {
                      this.$mensagem_erro(error.bodyText);
                    }
                  );
                  // this.$mensagem_sucesso(
                  //   "Cliente #" +
                  //     this.Cliente.clienteId +
                  //     " alterado com sucesso"
                  // );
                  // this.$redirect("/controle/clientes");
                  // this.$router.go(this.$router.currentRoute);
                  // this.$router.go(this.$router.currentRoute);
                },
                (error) => {
                  this.$mensagem_erro(error.bodyText);
                }
              );
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    EnviarSenha() {
      if (this.Senha.length > 0 && this.Cliente.email.length > 0) {
        let tmp = { Email: this.Cliente.email, Senha: this.Senha };
        this.$http
          .post(
            this.$apiUrl + "/clientes/salvasenha/" + this.Cliente.clienteId,
            tmp
          )
          .then(
            (response) => {
              if (response.ok) {
                this.$mensagem_sucesso("Senha salva e enviada com sucesso");
              }
            },
            (error) => {
              console.log(error);
              this.$mensagem_erro(error.bodyText);
            }
          );
      } else {
        this.$mensagem_erro("Por favor preencha a senha e o e-mail");
      }
    },
    Remover(id) {
      console.log("Remove " + id);
      this.$http.delete(this.$apiUrl + "/clientes/" + id).then(
        (response) => {
          console.log(response);
          this.$mensagem_sucesso("#" + id + " removido com sucesso");
          this.$redirect("/controle/clientes");
          this.$router.go(this.$router.currentRoute);
          this.$router.go(this.$router.currentRoute);
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          order: [[ 0, "desc" ]],
          scrollCollapse: true,
          autoWidth: false,
          responsive: true,
          pageLength: 100,
          searching: true,
          bDestroy: true,
        });
      }, 2000);
    },
  },
  created() {
    this.$http.get(this.$apiUrl + "/tipoclientes/").then((response) => {
      this.Tipos = response.body;
    });

    if (this.$route.params.id != null) {
      this.ClienteId = parseInt(this.$route.params.id, 10);

      this.$http.get(this.$apiUrl + "/arquivos/").then((response) => {
        this.ArquivosTodos = response.body;
        this.ArquivosTodos.forEach((element) => {
          element.checked = false;
        });
        this.$http
          .get(this.$apiUrl + "/arquivosclientes/cliente/" + this.ClienteId)
          .then((response) => {
            this.ArquivosCliente = response.body;
            this.ArquivosTodos.forEach((element) => {
              let tmp = this.ArquivosCliente.find(
                (x) => x.arquivoId == element.arquivoId
              );
              console.log("tmp");
              console.log(tmp);
              let novo = {
                arquivoId: element.arquivoId,
                nomeArquivo: element.nomeArquivo,
                urlArquivo: element.urlArquivo,
                ordem: element.ordem,
                checked: false,
              };
              if (tmp != null) {
                novo.checked = true;
              } else {
                novo.checked = false;
              }
              this.ArquivosIndex.push(novo);
            });
          });
      });

      this.$http.get(this.$apiUrl + "/Videos/").then((response) => {
        this.VideosTodos = response.body;
        this.VideosTodos.forEach((element) => {
          element.checked = false;
        });
        this.$http
          .get(this.$apiUrl + "/Videosclientes/cliente/" + this.ClienteId)
          .then((response) => {
            this.VideosCliente = response.body;
            this.VideosTodos.forEach((element) => {
              let tmp = this.VideosCliente.find(
                (x) => x.VideoId == element.VideoId
              );
              console.log("tmp");
              console.log(tmp);
              let novo = {
                videoId: element.videoId,
                nomeVideo: element.nomeVideo,
                linkVideo: element.linkVideo,
                codigoVideo: element.codigoVideo,
                ordem: element.ordem,
                checked: false,
              };
              if (tmp != null) {
                novo.checked = true;
              } else {
                novo.checked = false;
              }
              this.VideosIndex.push(novo);
            });
          });
      });
      this.$http.get(this.$apiUrl + "/Fotos/").then((response) => {
        this.FotosTodos = response.body;
        this.FotosTodos.forEach((element) => {
          element.checked = false;
        });
        this.$http
          .get(this.$apiUrl + "/fotosclientes/cliente/" + this.ClienteId)
          .then((response) => {
            this.FotosCliente = response.body;
            this.FotosTodos.forEach((element) => {
              let tmp = this.FotosCliente.find(
                (x) => x.FotoId == element.FotoId
              );
              console.log("tmp");
              console.log(tmp);
              let novo = {
                fotoId: element.fotoId,
                nomeFoto: element.nomeFoto,
                urlFoto: element.urlFoto,
                ordem: element.ordem,
                checked: false,
              };
              if (tmp != null) {
                novo.checked = true;
              } else {
                novo.checked = false;
              }
              this.FotosIndex.push(novo);
            });
          });
      });

      //carrega cidades do plano
      this.$http
        .get(this.$apiUrl + "/PlanosClienteCidade")
        .then((res2) => res2.json())
        .then((tiu2) => {
          this.Cidades = tiu2.filter(
            (x) => x.cliente.clienteId == parseInt(this.ClienteId, 10)
          );
        });

      //carrega servicos do plano
      this.$http
        .get(this.$apiUrl + "/PlanosClienteServico")
        .then((res2) => res2.json())
        .then((tiu2) => {
          this.Servicos = tiu2.filter(
            (x) => x.cliente.clienteId == parseInt(this.ClienteId, 10)
          );
        });

      //carrega Atuacao do plano
      this.$http
        .get(this.$apiUrl + "/PlanosClienteAtuacao")
        .then((res2) => res2.json())
        .then((tiu2) => {
          this.Atuacoes = tiu2.filter(
            (x) => x.cliente.clienteId == parseInt(this.ClienteId, 10)
          );
        });

      //carrega informações extras do plano
      this.$http
        .get(this.$apiUrl + "/PlanosClienteInformacao")
        .then((res2) => res2.json())
        .then((tiu2) => {
          this.Informacao = tiu2.filter(
            (x) => x.cliente.clienteId == parseInt(this.ClienteId, 10)
          );
        });

      //carrega informações do plano
      this.$http
        .get(this.$apiUrl + "/PlanosCliente")
        .then((res2) => res2.json())
        .then((tiu2) => {
          this.Planos = tiu2.filter(
            (x) => x.cliente.clienteId == parseInt(this.ClienteId, 10)
          );
        });

      //carrega cliente
      this.$http
        .get(this.$apiUrl + "/clientes/" + this.ClienteId)
        .then((response) => {
          this.Cliente = response.body;

          this.Cadastro = moment(this.Cliente.dataCadastro).format(
            "YYYY-MM-DD"
          );

          this.Aniversario = moment(this.Cliente.dataAniversario).format(
            "YYYY-MM-DD"
          );
        })
        .then(this.atualiza());
    } else {
      this.ClienteId = 0;
      //adicionando
      this.$http
        .get(this.$apiUrl + "/clientes")
        .then((response) => {
          this.Clientes = response.body;
        })
        .then(this.atualiza());
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
a {
  cursor: pointer;
}
</style>
