<template>
  <div id="print" style="background: white !important">
    <div class="row mb-15">
      <div class="col-md-12 text-center">
        <img
          ref="content1"
          id="imagemCheck"
          :src="$baseUrl + '/' + PedidoFoto.urlImagem"
          style="margin-top:3px;max-width: 80%; height: 463px;max-height: 100%;"
          @load="checkImageHor()"
        />
        <img
          ref="content2"
          id="imagemCheck2"
          :src="$baseUrl + '/' + PedidoFoto.urlImagem"
          style="margin-top: -112px; height: 694px; width: 463px; transform: rotate(90deg);"
          
        />
      </div>
    </div>
    <!-- <button class="noprint" @click="print()" type="button">aaaaaaaa</button> -->
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

export default {
  data() {
    return {
      Id: this.$route.params.id,
      PedidoFoto: {},
    };
  },
  methods: {
    print() {
      window.print();
    },
    checkImageHor(){
      console.log("c1: " + this.$refs.content1.naturalWidth + "X" + this.$refs.content1.naturalHeight);
      console.log("c2: " + this.$refs.content2.naturalWidth + "X" + this.$refs.content2.naturalHeight);
      if(this.$refs.content1.naturalWidth > this.$refs.content1.naturalHeight){
        this.$refs.content2.style.display = "none";
      }else{
        this.$refs.content2.style.display = "initial";
        this.$refs.content1.style.display = "none"
      }
    },
    
  },
  created() {
    this.$http.get(this.$apiUrl + "/fotoAvulsa/" + this.Id).then((response) => {
      if (response.ok && response.bodyText.length > 0) {
        this.PedidoFoto = response.body;
        
      }
    });
  },
  mounted() {
    var side = document.getElementsByClassName("left-side-bar");
    if (side != null) {
      side[0].style.display = "none";
    }
    var header = document.getElementsByClassName("header");
    if (header != null) {
      header[0].style.display = "none";
    }
    var footer = document.getElementsByClassName("footer-wrap");
    if (footer != null) {
      footer[0].style.display = "none";
    }
    var body = document.getElementsByTagName("body")[0];
    body.style.background = "white";
    // let tmp = document.getElementById("imagemCheck");
    //     if(tmp != null){
    //        var height = tmp.height;
    //        var width = tmp.width;
    //        alert("h: " + height + " w: " + width);
    //        if(height > width){
    //          //deitar ela
    //          tmp.remove();
    //          var tmp2 = document.getElementById("imagemCheck2");
    //          if(tmp2 != null){
    //            tmp2.style.display = 'initial';
    //          }
    //        }
    //     }
    setTimeout(() => {
      this.print();
    }, 2000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  background: white !important;
}
@media print {
  .noprint {
    visibility: hidden;
  }
}
.left-side-bar {
  display: none;
}
.header {
  display: none;
}
.footer-wrap {
  display: none;
}
@media (max-width: 600px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 100%;
    outline: none;
    height: 270px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 12px;
  }

  .imagemCartao {
    height: 270px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 90%;
  }
}

@media (min-width: 608px) and (max-width: 991px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 98%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 89%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Handlee !important;
    font-size: 18.5px;
  }

  .imagemCartao {
    height: 400px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}
</style>

