<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Cadastro de Telas de telas</h4>
                      <p class="mb-30">
                        Tela para cadastro dos Telas de tela.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Grupo</label>
                         <v-select v-model="GrupoSelected" 
                          :options="Grupos"
                          :value="Grupos.grupoTelaInstitucionalId"
                          single-line
                          label="nomeSegmentoTela" class="style-chooser"></v-select>
                        <!-- <input
                          v-model="Tela.nomeTela"
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                        /> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nome</label>
                        <input
                          v-model="Tela.nomeTela"
                          class="form-control"
                          type="text"
                          placeholder="Nome"
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        v-if="
                          telaInstitucionalId == null ||
                          telaInstitucionalId.length <= 0
                        "
                        type="button"
                        @click="
                          $redirect_reload(
                            '/controle/telasInstitucional/' + Tela.Nome
                          )
                        "
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <button
                        v-if="
                          telaInstitucionalId != null &&
                          telaInstitucionalId.length > 0
                        "
                        type="button"
                        class="btn btn-success"
                        @click="Altera()"
                        id="sa-custom-position2"
                      >
                        Alterar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        @click="Add()"
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>Grupo</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(Tela, index) in Telas" :key="index">
                <td>{{ Tela.telaInstitucionalId }}</td>
                 <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/telaInstitucional/' +
                              Tela.telaInstitucionalId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Tela)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Tela.nomeTela }}</td>
                <td>{{ Tela.grupoTela != null ? Tela.grupoTela.nomeSegmentoTela:"-Sem grupo-"  }}</td>
                
              </tr>
              <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
            </tbody>
          </table>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import vSelect from "vue-select";

export default {
  data() {
    return {
      telaInstitucionalId: this.$route.params.id,
      Ativo: null,
      Tela: {},
      Telas: [],
      Grupos: [],
      GrupoSelected: {},
      T: {},
    };
  },
  methods: {
    Add() {
      
      //validações
      this.Tela.grupoTela = this.GrupoSelected;
      if (this.Tela.nomeTela.length <= 0) {
        this.$mensagem_normal("Por favor preencha o nome");
      } 
      else {

        //chama o post da api
        this.$http
          .post(this.$apiUrl + "/TelaInstitucional/", this.Tela)
          .then(
            (response2) => {
              // get body data
              console.log("RETORNO ADD");
              console.log(response2);
              if(response2.ok)
              {
                this.$atualiza_cabecalho();
                this.$mensagem_sucesso("Tela salva com sucesso");
                this.$redirect_reload("/controle/telasInstitucional");
                this.Limpar();
                this.$router.go(this.$router.currentRoute);
                this.$router.go(this.$router.currentRoute);
              }
            },
            (response2) => {
              this.$mensagem_normal(response2.body);
            }
          );
      }
    },
    Remove(DadosRemover) {
      //chama o delete da api
      this.$http
        .delete(
          this.$apiUrl +
            "/TelaInstitucional/" +
            DadosRemover.telaInstitucionalId
        )
        .then(
          () => {
            // get body data
            this.$atualiza_cabecalho();
            this.$redirect_reload("/controle/telasInstitucional");
            this.$mensagem_sucesso("Removido com sucesso");
            this.Limpar();
            this.$router.go(this.$router.currentRoute);
                this.$router.go(this.$router.currentRoute);
          },
          (response) => {
            // error callback
            console.log(response);
          }
        );
    },
    Altera() {

      //validações
      this.Tela.grupoTela = this.GrupoSelected;
      if (this.Tela.nomeTela.length <= 0) {
        this.$mensagem_normal("Por favor preencha o telaInstitucional");
      }
       else {
        this.Tela.telaInstitucionalId = parseInt(
          this.telaInstitucionalId,
          10
        );

        //chama o post da api
        this.$http
          .put(
            this.$apiUrl + "/TelaInstitucional/" + this.telaInstitucionalId,
            this.Tela
          )
          .then(
            (response2) => {
              // get body data
              if(response2.ok)
              {
                this.$atualiza_cabecalho();
                this.$mensagem_sucesso("Tela #" + this.telaInstitucionalId + " alterado com sucesso");
                this.$redirect("/controle/telasInstitucional");
                this.Limpar();
                this.$router.go(this.$router.currentRoute);
                this.$router.go(this.$router.currentRoute);
              }
            },
            async (response2) => {
              // error callback
              //const data = await response2.json();
              //JSON.parse(data);
              this.$mensagem_normal(response2.body);
            }
          );
      }
    },
    Pesquisa() {
      try {
        if (this.Tela.nomeTela.length <= 0) {
          this.$mensagem_normal("Por favor preencha o nome");
        } else {
          this.$http
            .post(
              this.$apiUrl + "/TelaInstitucional/busca",
              this.Tela
            )
            .then((res) => res.json())
            .then((tiu) => (this.Telas = tiu))
            .then(this.atualiza());
        }
      } catch {
        this.$mensagem_normal("Nenhum resultado encontrado");
      }
    },
    Limpar() {
      this.$redirect_reload("/controle/telasInstitucional");
      this.Tela = {};
      this.$http.get(this.$apiUrl + "/TelaInstitucional/")
      .then(response => {
        if(response.ok)
        {
          this.Telas = response.body;
        }
      }).then(this.atualiza());
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: false,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  created() {

    this.$http.get(this.$apiUrl + "/GrupoTelaInstitucional/")
    .then(response => {
      if(response.ok){
        this.Grupos = response.body;
      }
    });
    if (this.$route.params.pesquisa != null) {
      this.Tela.nomeTela = this.$route.params.pesquisa;
    } else {
      this.Tela.nomeTela = "";
    }

    if (this.telaInstitucionalId == null) {
      this.telaInstitucionalId = 0;
    }

    //chama o get da api this.$apiUrl
    if (
      this.telaInstitucionalId != null &&
      this.telaInstitucionalId > 0
    ) {
      this.$http
        .get(this.$apiUrl + "/TelaInstitucional/" + this.telaInstitucionalId)
        .then((res) => res.json())
        .then((idi) => {
          this.Tela = idi;
          this.GrupoSelected = this.Tela.grupoTela;
        });
    } else if (
      this.Tela != null &&
      this.Tela.nomeTela.length > 0
    ) {
      this.Pesquisa();
    } else {
      this.$http
        .get(this.$apiUrl + "/TelaInstitucional")
        .then((res) => res.json())
        .then((moe) => (this.Telas = moe))
        .then(this.atualiza());
    }
  },
  mounted() {},
  components: {
    vSelect,
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
