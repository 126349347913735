<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Controle de SMTP</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos dados para envio de e-mails a partir dos formulários para empresa.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>SMTP</label>
                                                <input v-model="SMTP" class="form-control" type="text" placeholder="SMTP">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>E-mail</label>
                                                <input v-model="EmailSMTP" class="form-control" type="text" placeholder="E-mail">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Senha</label>
                                                <input v-model="SenhaSMTP" type="password" id="password" name="password" class="form-control" placeholder="Senha">
                                                <span class="lnr-eye icon-copy dw dw-hide"></span>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label>Porta</label>
                                                <input v-model="PortaSMTP" class="form-control" type="text" placeholder="Porta">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Alterar()"
                                                    id="sa-custom-position2">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    //import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";

    export default {
        data() {
            return {
                ControleSMTPId: 0,
                SMTP: "",
                EmailSMTP: "",
                SenhaSMTP: "",
                PortaSMTP: "",
                Controlesmtp: [],
            };
        },
        methods: {
            Alterar() {
                let _Controlesmtp = {
                    controleSMTPId: this.ControleSMTPId,
                    smtp: this.SMTP,
                    emailsmtp: this.EmailSMTP,
                    senhasmtp: this.SenhaSMTP,
                    portasmtp: this.PortaSMTP,
                };

                //validações
                if (_Controlesmtp.smtp == null) {
                    this.$mensagem_normal("Por favor preencha o SMTP");
                } else if (_Controlesmtp.emailsmtp == null ) {
                    this.$mensagem_normal("Por favor preencha o e-mail remetente SMTP");
                } else if (_Controlesmtp.senhasmtp == null) {
                    this.$mensagem_normal("Por favor preencha a senha do e-mail SMTP");
                } else if (_Controlesmtp.portasmtp == null) {
                    this.$mensagem_normal("Por favor preencha a porta SMTP");
                } else {

                    _Controlesmtp.controleSMTPId = parseInt(this.ControleSMTPId, 10);
                    _Controlesmtp.portasmtp = parseInt(this.PortaSMTP, 10);

                    console.log(_Controlesmtp);
                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/Controlesmtp/" + _Controlesmtp.controleSMTPId, _Controlesmtp)
                        .then(
                            (response) => {
                                // get body data
                                //console.log(_RedeSocial);
                                _Controlesmtp = response.body;
                                this.$mensagem_sucesso(
                                    "SMTP alterado com sucesso"
                                );
                                this.$redirect("/controle/Controlesmtp");
                            },
                            (response) => {
                                // error callback
                                console.log(response);
                            }
                        );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/Controlesmtp");
            },
        },
        created() {
            //chama o get da api this.$apiUrl
                this.$http
                    .get(this.$apiUrl + "/Controlesmtp/")
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Controlesmtp = idi;

                        this.ControleSMTPId = this.Controlesmtp[0].controleSMTPId;
                        this.SMTP = this.Controlesmtp[0].smtp;
                        this.EmailSMTP = this.Controlesmtp[0].emailSMTP;
                        this.SenhaSMTP = this.Controlesmtp[0].senhaSMTP;
                        this.PortaSMTP = this.Controlesmtp[0].portaSMTP;
                    });
        },
        mounted() {

        },
        components: {
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
