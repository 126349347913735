<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro dos horários de entrega</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos horários de entrega para o agendamento do cliente.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label>Qtde</label>
                                                <input v-model="Qtde" class="form-control" type="number" placeholder="Qtde">
                                            </div>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Hora Inicial</span>
                                            <v-select v-model="HoraIniselected" @input="MudaHoraIni"
                                                      :options="HoraIni"
                                                      :reduce="nome => nome.valor"
                                                      single-line
                                                      label="nome" class="style-chooser"></v-select>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Min Inicial</span>
                                            <v-select v-model="MinIniselected" @input="MudaMinIni"
                                                      :options="MinIni"
                                                      :reduce="minini => minini.valor"
                                                      single-line
                                                      label="minini" class="style-chooser"></v-select>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Hora Final</span>
                                            <v-select v-model="HoraFimselected" @input="MudaHoraFim"
                                                      :options="HoraFim"
                                                      :reduce="horafim => horafim.valor"
                                                      single-line
                                                      label="horafim" class="style-chooser"></v-select>
                                        </div>
                                        <div class="col-lg-2" style="padding-top:4px;">
                                            <span>Min Final</span>
                                            <v-select v-model="MinFimselected" @input="MudaMinFim"
                                                      :options="MinFim"
                                                      :reduce="minfim => minfim.valor"
                                                      single-line
                                                      label="minfim" class="style-chooser"></v-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="EntregaHorarioId != null && EntregaHorarioId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <!-- <th></th> -->
                                <th class="datatable-nosort">Ação</th>
                                <th>Hora Inicial</th>
                                <th>Hora Final</th>
                                <th>Quantidade Entregas</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="EntregaHorario in EntregaHorarios" :key="EntregaHorario.entregaHorarioId">
                                <!-- <td></td> -->
                                  <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/entregahorario/' + EntregaHorario.entregaHorarioId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(EntregaHorario)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{validaHora(EntregaHorario.horaIni)  + ':' +  validaHora(EntregaHorario.minIni) }}</td>
                                <td>{{ validaHora(EntregaHorario.horaFim) + ':' +  validaHora(EntregaHorario.minFim) }}</td>
                                <td>{{ validaHora(EntregaHorario.qtde) }}</td>
                                <td><span v-if="EntregaHorario.status">Ativo</span><span v-else>Inativo</span></td>
                              
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                EntregaHorarioId: this.$route.params.id,
                Qtde: 0,
                EntregaHorario: {},
                EntregaHorarios: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                HoraIniselected: null,
                HoraIni: [
                    { nome: "06", valor: 6 },
                    { nome: "07", valor: 7 },
                    { nome: "08", valor: 8 },
                    { nome: "09", valor: 9 },
                    { nome: "10", valor: 10 },
                    { nome: "11", valor: 11 },
                    { nome: "12", valor: 12 },
                    { nome: "13", valor: 13 },
                    { nome: "14", valor: 14 },
                    { nome: "15", valor: 15 },
                    { nome: "16", valor: 16 },
                    { nome: "17", valor: 17 },
                    { nome: "18", valor: 18 },
                    { nome: "19", valor: 19 },
                    { nome: "20", valor: 20 },
                    { nome: "21", valor: 21 },
                    { nome: "22", valor: 22 },
                    { nome: "23", valor: 23 },
                ],
                HoraFimselected: null,
                HoraFim: [
                    { horafim: "06", valor: 6 },
                    { horafim: "07", valor: 7 },
                    { horafim: "08", valor: 8 },
                    { horafim: "09", valor: 9 },
                    { horafim: "10", valor: 10 },
                    { horafim: "11", valor: 11 },
                    { horafim: "12", valor: 12 },
                    { horafim: "13", valor: 13 },
                    { horafim: "14", valor: 14 },
                    { horafim: "15", valor: 15 },
                    { horafim: "16", valor: 16 },
                    { horafim: "17", valor: 17 },
                    { horafim: "18", valor: 18 },
                    { horafim: "19", valor: 19 },
                    { horafim: "20", valor: 20 },
                    { horafim: "21", valor: 21 },
                    { horafim: "22", valor: 22 },
                    { horafim: "23", valor: 23 },
                ],
                MinIniselected: null,
                MinIni: [
                    { minini: "00", valor: 0 },
                    { minini: "15", valor: 15 },
                    { minini: "30", valor: 30 },
                    { minini: "45", valor: 45 },
                ],
                MinFimselected: null,
                MinFim: [
                    { minfim: "00", valor: 0 },
                    { minfim: "15", valor: 15 },
                    { minfim: "30", valor: 30 },
                    { minfim: "45", valor: 45 },
                ],
                T: {},
            };
        },
        methods: {
            MudaStatus(value) {
                this.Statuselected = value;
            },
            MudaHoraIni(value) {
                this.HoraIniselected = value;   
            },
            MudaMinIni(value) {
                this.MinIniselected = value;
            },
            MudaHoraFim(value) {
                this.HoraFimselected = value;
            },
            MudaMinFim(value) {
                this.MinFimselected = value;
            },
            validaHora(value) {
                var _hora = value.toString();
                //console.log(_hora.length + " =" + _hora)
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
            Add() {
                let _EntregaHorario = {
                    EntregaHorarioId: 0,
                    status: null,
                    Qtde: 0,
                    HoraIni: 0,
                    HoraFim: 0,
                    MinIni: 0,
                    MinFim: 0,
                };

    
                //validações
                if (parseFloat(this.Qtde) < 0) {
                    this.$mensagem_normal("Quantidade não pode ser menor que zero");
                } else if (this.Statuselected == null) {
                    this.$mensagem_normal("Por favor selecione o Ativo");
                } else if (this.HoraIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Inicial");
                } else if (this.MinIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Inicial");
                } else if (this.HoraFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Final");
                } else if (this.MinFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Final");
                } else if (parseInt(this.HoraIniselected, 10) > parseInt(this.HoraFimselected,10)) {
                    this.$mensagem_normal("Hora Inicial deve ser menor que Hora Final");
                } else if ((parseInt(this.HoraIniselected, 10) == parseInt(this.HoraFimselected, 10)) && (parseInt(this.MinIniselected, 10) >= parseInt(this.MinFimselected,10)) ) {
                    this.$mensagem_normal("Minuto Inicial deve ser menor que Minuto Final");
                } else {

                    _EntregaHorario.status = this.Statuselected;
                    _EntregaHorario.Qtde = parseInt(this.Qtde, 10);
                    _EntregaHorario.HoraIni = parseInt(this.HoraIniselected, 10);
                    _EntregaHorario.HoraFim = parseInt(this.HoraFimselected, 10);
                    _EntregaHorario.MinIni = parseInt(this.MinIniselected, 10);
                    _EntregaHorario.MinFim = parseInt(this.MinFimselected, 10);

                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/entregahorario/", _EntregaHorario).then(
                        response2 => {
                            // get body data
                            _EntregaHorario = response2.body;
                            this.$router.go(this.$router.currentRoute);
                        },
                        response2 => {
                            _EntregaHorario = response2.body;
                            this.$mensagem_normal(_EntregaHorario);
                        }
                    );
                }
            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/entregahorario/" + dadosRemover.entregaHorarioId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _EntregaHorario = {
                    EntregaHorarioId: 0,
                    status: null,
                    Qtde: 0,
                    HoraIni: 0,
                    HoraFim: 0,
                    MinIni: 0,
                    MinFim: 0,
                };


                //validações
                if (parseFloat(this.Qtde) < 0) {
                    this.$mensagem_normal("Quantidade não pode ser menor que zero");
                } else if (this.Statuselected == null) {
                    this.$mensagem_normal("Por favor selecione o Ativo");
                } else if (this.HoraIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Inicial");
                } else if (this.MinIniselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Inicial");
                } else if (this.HoraFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Hora Final");
                } else if (this.MinFimselected == null) {
                    this.$mensagem_normal("Por favor selecione Minuto Final");
                } else if (parseInt(this.HoraIniselected, 10) > parseInt(this.HoraFimselected, 10)) {
                    this.$mensagem_normal("Hora Inicial deve ser menor que Hora Final");
                } else if ((parseInt(this.HoraIniselected, 10) == parseInt(this.HoraFimselected, 10)) && (parseInt(this.MinIniselected, 10) >= parseInt(this.MinFimselected, 10))) {
                    this.$mensagem_normal("Minuto Inicial deve ser menor que Minuto Final");
                } else {

                    _EntregaHorario.status = this.Statuselected;
                    _EntregaHorario.Qtde = parseInt(this.Qtde, 10);
                    _EntregaHorario.HoraIni = parseInt(this.HoraIniselected, 10);
                    _EntregaHorario.HoraFim = parseInt(this.HoraFimselected, 10);
                    _EntregaHorario.MinIni = parseInt(this.MinIniselected, 10);
                    _EntregaHorario.MinFim = parseInt(this.MinFimselected, 10);
                    _EntregaHorario.EntregaHorarioId = parseInt(this.EntregaHorarioId, 10);

                    //console.log(_EntregaHorario)
                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/entregahorario/" + _EntregaHorario.EntregaHorarioId, _EntregaHorario)
                        .then(
                            response2 => {
                                // get body data
                                _EntregaHorario = response2.body;
                                //console.log(_Filtrovalor);
                                this.$mensagem_sucesso(
                                    "Horário #" + this.EntregaHorarioId + " alterado com sucesso"
                                );
                                this.Limpar();
                            },
                            async response2 => {
                                _EntregaHorario = response2.body;
                                // error callback
                                this.$mensagem_normal(_EntregaHorario);
                            }
                        );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/entregahorario");
                this.$http
                    .get(this.$apiUrl + "/entregahorario")
                    .then((res) => res.json())
                    .then((moe) => (this.EntregaHorarios = moe))
                    .then(this.atualiza());
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {

            if (this.EntregaHorarioId == null) {
                this.EntregaHorarioId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.EntregaHorarioId != null && this.EntregaHorarioId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/entregahorario/" + this.EntregaHorarioId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.EntregaHorario = idi;

                        this.Qtde = this.EntregaHorario.qtde;
                        this.Statuselected = this.EntregaHorario.status;
                        this.HoraIniselected = this.EntregaHorario.horaIni;
                        this.MinIniselected = this.EntregaHorario.minIni;
                        this.HoraFimselected = this.EntregaHorario.horaFim;
                        this.MinFimselected = this.EntregaHorario.minFim;

                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/entregahorario")
                    .then((res) => res.json())
                    .then((moe) => {
                        this.EntregaHorarios = moe.sort(function (a, b) {
                            return a.horaIni - b.horaIni;
                        });
                    })
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
