<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">
                        Cadastro de Tipos de Usuários
                      </h4>
                      <p class="mb-30">
                        Tela para cadastro tipos de usuários do sistema.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Tipo</label>
                        <input
                          v-model="NomeTipoUsuario"
                          class="form-control"
                          type="text"
                          placeholder="Tipo de usuário"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Status</label>
                        <select class="custom-select col-12" v-model="Ativo">
                          <option selected="">Selecione...</option>
                          <option value="1">Ativo</option>
                          <option value="2">Inativo</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="Limpar()"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        v-if="
                          TipoUsuarioId == null || TipoUsuarioId.length <= 0
                        "
                        type="button"
                         @click="
                          $redirect(
                            '/controle/tiposusuarios/' + NomeTipoUsuario
                          )
                        "
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <button
                        v-if="TipoUsuarioId != null && TipoUsuarioId.length > 0"
                        type="button"
                        class="btn btn-success"
                        @click="Update()"
                        id="sa-custom-position"
                      >
                        Alterar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        @click="Add()"
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nome</th>
                <th>Status</th>
                <th class="datatable-nosort">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="Tipo in TiposUsuarios" :key="Tipo.tipoUsuarioId">
                <td>{{ Tipo.tipoUsuarioId }}</td>
                <td>{{ Tipo.nomeTipoUsuario }}</td>
                <td>
                  <span v-if="Tipo.ativo">Ativo</span
                  ><span v-else>Inativo</span>
                </td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/tipousuario/' + Tipo.tipoUsuarioId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Tipo)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";

export default {
  data() {
    return {
      TipoUsuarioId: this.$route.params.id,
      TipoUsuario: {},
      TiposUsuarios: [],
      NomeTipoUsuario: this.$route.params.pesquisa,
      Ativo: 0,
    };
  },
  methods: {
    Limpar() {
        this.$redirect_reload('/controle/tiposusuarios/')
    },
    Add() {
         let _tipousuario = {
        tipoUsuarioId: 0,
        nomeTipoUsuario: this.NomeTipoUsuario,
        ativo: false,
      };

      //validações
      if (_tipousuario.nomeTipoUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha o tipo do usuario");
      } else if (this.Ativo != "2" && this.Ativo != "1") {
        this.$mensagem_normal("Por favor selecione o status");
      } else {
        //converte o input para true/false
        if (this.Ativo == "2") _tipousuario.ativo = false;
        else _tipousuario.ativo = true;

        _tipousuario.tipoUsuarioId = 0;
        //chama o post da api
        console.log(_tipousuario);
        this.$http
          .post(this.$apiUrl + "/tipousuarios/", _tipousuario)
          .then(
            (response) => {
              // get body data
              _tipousuario = response.body;
              
            //   this.$mensagem_sucesso(
            //     "Tipo de usuário #" + this.TipoUsuarioId + " alterado com sucesso"
            //   );
              this.$redirect_reload("/controle/tiposusuarios");
            },
            (response) => {
              // error callback
              console.log(response);
            }
          );
      }
    },
    Update() {
        let _tipousuario = {
        tipoUsuarioId: 0,
        nomeTipoUsuario: this.NomeTipoUsuario,
        ativo: false,
      };

      //validações
      if (_tipousuario.nomeTipoUsuario.length <= 0) {
        this.$mensagem_normal("Por favor preencha o tipo do usuario");
      } else if (this.Ativo != "2" && this.Ativo != "1") {
        this.$mensagem_normal("Por favor selecione o status");
      } else {
        //converte o input para true/false
        if (this.Ativo == "2") _tipousuario.ativo = false;
        else _tipousuario.ativo = true;

        _tipousuario.tipoUsuarioId = parseInt(this.TipoUsuarioId, 10);
        //chama o post da api
        
        this.$http
          .put(this.$apiUrl + "/tipousuarios/" + this.TipoUsuarioId, _tipousuario)
          .then(
            (response) => {
              // get body data
              _tipousuario = response.body;
              this.$mensagem_sucesso(
                "Tipo de usuário #" + this.TipoUsuarioId + " alterado com sucesso"
              );
              this.$redirect("/controle/tiposusuarios");
            },
            (response) => {
              // error callback
              console.log(response);
            }
          );
      }
    },
    Pesquisa() {
         try {
        if (this.NomeTipoUsuario.length <= 0) {
          this.$mensagem_normal("Por favor preencha o tipo de usuário");
        } else {
          this.TiposUsuarios = {};
          let _tipousuario = {
            nomeTipoUsuario: this.NomeTipoUsuario,
          };
          this.$http
            .post(this.$apiUrl + "/tipousuarios/busca", _tipousuario)
            .then((res) => res.json())
            .then((tiu) => (this.TiposUsuarios = tiu))
            .then(this.atualiza());
        }
      } catch {
        this.$mensagem_normal("Nenhum resultado encontrado");
      }
    },
    Remove(Tipo) {
      //chama o delete da api
      this.$http
        .delete(this.$apiUrl + "/tipousuarios/" + Tipo.tipoUsuarioId)
        .then(
          () => {
            // get body data
            //remove do array
            //var indice = this.Idiomas.indexOf(IdiomaRemover);
            //this.Idiomas.splice(indice, 1);
            this.$redirect_reload("/controle/tiposusuarios");
          },
          (response) => {
            // error callback
            console.log(response);
          }
        );
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {},
  created() {
    if (this.TipoUsuarioId != null && this.TipoUsuarioId.length > 0) {
      this.$http
        .get(this.$apiUrl + "/tipousuarios/" + this.TipoUsuarioId)
        .then((res) => res.json())
        .then((tiu) => {
          this.TipoUsuario = tiu;
          //console.log(this.TipoUsuario);

          if (this.TipoUsuario.ativo == true) {
            this.Ativo = "1";
          } else {
            this.Ativo = "2";
          }
          this.NomeTipoUsuario = this.TipoUsuario.nomeTipoUsuario;
        });
    }
    else if( this.NomeTipoUsuario != null &&  this.NomeTipoUsuario.length > 0)
    {
        this.Pesquisa();
    }
    else
    {
       this.$http
      .get(this.$apiUrl + "/tipousuarios")
      .then((res) => res.json())
      .then((tiu) => (this.TiposUsuarios = tiu))
    //   .then(console.log(this.TiposUsuarios))
      .then(this.atualiza());
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
