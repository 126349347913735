<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Planos</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos planos que poderão ser utilizadas no sistema.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Nome do Plano</label>
                                                <input v-model="NomePlano" class="form-control" type="text" placeholder="Nome Plano">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Valor Mensal</label>
                                                <money v-model.lazy="ValorPorPlano"
                                                       class="form-control decimal"
                                                       type="text"
                                                       v-bind="money" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Plano Adicional</label>
                                                <v-select v-model="Adicionalselected" @input="MudaAdicional"
                                                          :options="Adicional"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Descrição Plano</label>
                                                <quill-editor v-model="TextoPlano" placeholder="Insira texto aqui ..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="PlanoId != null && PlanoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Plano</th>
                                <th>Visível</th>
                                <th>Ordem</th>
                                <th>Valor</th>
                                <th>Adicional</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Plano in Planos" :key="Plano.planoId">
                                <td>{{ Plano.planoId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/planoscadastro/' + Plano.planoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Plano)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Plano.nomePlano }}</td>
                                <td><span v-if="Plano.visivel">Sim</span><span v-else>Não</span></td>
                                <td>{{ Plano.ordem }}</td>
                                <td>R$ {{ parseFloat(Plano.valorPorPlano,10).toFixed(2).replace('.',',') }}</td>
                                <td><span v-if="Plano.regra4==1">Sim</span><span v-else>Não</span></td>
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        data() {
            return {
                PlanoId: this.$route.params.id,
                Visivel: null,
                NomePlano: '',
                Ordem: 0,
                ValorPorPlano: 0,
                TextoPlano: '',
                regra4:0,
                Adicionalselected: null,
                Adicional: [{ nome: "Sim", valor: 1 }, { nome: "Não", valor: 0 }],
                Plano: {},
                Planos: [],
                Statuselected: null,
                Status: [{ nome: "Sim", valor: true }, { nome: "Não", valor: false }],
                T: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            };
        },
        directives: { money: VMoney },
        methods: {
            MudaStatus(value) {
                this.Visivel = value;
            },
            MudaAdicional(value) {
                this.Regra4 = value.valor;
            },
            Add() {
                //validações
                if (this.NomePlano.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o plano");
                } else if (this.Statuselected == '') {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else if (this.ValorPorPlano == null) {
                    this.$mensagem_normal("Por favor preecha o valor do plano");
                } else if (this.Adicionalselected == null) {
                    this.$mensagem_normal("Por favor preecha se o tipo do plano é adicional");
                } else {

                    let _Planos = {
                        PlanoId: 0,
                        Visivel: this.Statuselected,
                        NomePlano: this.NomePlano,
                        Ordem: parseInt(this.Ordem, 10),
                        ValorPorPlano: parseFloat(this.ValorPorPlano.replace(".", "").replace(",", ".")),
                        DescricaoPlano: this.TextoPlano,
                        Regra4: parseInt(this.Adicionalselected, 10),
                    }

                    console.log(_Planos)
                    //chama o post da api
                    this.$http
                        .post(this.$apiUrl + "/planos/", _Planos)
                        .then(
                            response2 => {
                                // get body data
                                _Planos = response2.body;
                                this.$mensagem_sucesso(
                                    "Planos #" + this.PlanoId + " incluído com sucesso"
                                );
                                this.Limpar();
                            },
                            async response2 => {
                                _Planos = response2.body;
                                // error callback
                                this.$mensagem_normal(_Planos);
                            }
                        );

                }
            },
            Remove(dadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/planos/" + dadosRemover.planoId)
                    .then(
                        () => {
                            // get body data
                            this.$router.go(this.$router.currentRoute);
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                //validações
                if (this.NomePlano.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o plano");
                } else if (this.Statuselected == '') {
                    this.$mensagem_normal("Por favor selecione o visível");
                } else if (this.ValorPorPlano == null) {
                    this.$mensagem_normal("Por favor preecha o valor do plano");
                } else if (this.Adicionalselected == null) {
                    this.$mensagem_normal("Por favor preecha se o tipo do plano é adicional");
                } else {

                    let tipoRegra = 0;

                    if (this.PlanoId == 1) {
                        tipoRegra = 3
                    }
                    else if (this.PlanoId == 2) {
                        tipoRegra = 2
                    } else if (this.PlanoId == 3) {
                        tipoRegra = 1
                    }

                    let _Planos = {
                        PlanoId: parseInt(this.PlanoId,10),
                        Visivel: this.Statuselected,
                        NomePlano: this.NomePlano,
                        Ordem: parseInt(this.Ordem,10),
                        ValorPorPlano: parseFloat(this.ValorPorPlano.replace(".", "").replace(",", ".")),
                        DescricaoPlano: this.TextoPlano,
                        Regra3: parseInt(tipoRegra, 10),
                        Regra4: parseInt(this.Adicionalselected,10),
                    }

                    this.$http.put(this.$apiUrl + "/planos/" + _Planos.PlanoId, _Planos).then(
                        (response3) => {
                            // get body data
                            _Planos = response3.body;
                            this.$mensagem_sucesso(
                                "Planos #" + this.PlanoId + " alterado com sucesso"
                            );
                            this.Limpar();
                        },
                        (response3) => {
                            // error callback
                            this.$mensagem_erro(response3.body);
                        }
                    );
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/planoscadastro");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.PlanoId == null) {
                this.PlanoId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.PlanoId != null && this.PlanoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/planos/" + this.PlanoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Plano = idi;

                        this.NomePlano = this.Plano.nomePlano;
                        this.Statuselected = this.Plano.visivel;
                        this.Ordem = this.Plano.ordem;
                        this.ValorPorPlano = this.Plano.valorPorPlano;
                        this.Adicionalselected = this.Plano.regra4;
                        this.TextoPlano = this.Plano.descricaoPlano;

                    });
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/planos")
                    .then((res) => res.json())
                    .then((moe) => (this.Planos = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
