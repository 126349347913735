<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">
                        Tela Institucional {{ Tela.nomeTela }}
                      </h4>
                      <p class="mb-30">
                        Tela para cadastro dos dados Institucionais da tela
                        {{ Tela.nomeTela }}.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div v-bind:class="Configuracao.visivelCol > 0 ? 'col-md-' + Configuracao.visivelCol:'col-md-3'" v-if="Configuracao.visivel">
                      <div class="form-group">
                        <label>{{ Configuracao.visivelLabel }}</label>
                        <!-- <select v-model="Institucional.visivel" class="custom-select col-12">
                                                <option value="">Selecione...</option>
                                                <option value="1">Sim</option>
                                                <option value="2">Não</option>
                                            </select> -->
                        <v-select
                          v-model="Institucional.visivel"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.ordemCol > 0 ? 'col-md-' + Configuracao.ordemCol:'col-md-3'" v-if="Configuracao.ordem">
                      <div class="form-group">
                        <label>{{ Configuracao.ordemLabel }}</label>
                        <input
                          class="form-control"
                          v-model="Institucional.ordem"
                          type="number"
                          @change="Institucional.ordem != null ? Institucional.ordem = parseInt(Institucional.ordem, 10):Institucional.ordem = 0"
                          v-bind:placeholder="Configuracao.ordemLabel"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.destaqueCol > 0 ? 'col-md-' + Configuracao.destaqueCol:'col-md-3'" v-if="Configuracao.destaque">
                      <div class="form-group">
                        <label>{{ Configuracao.destaqueLabel }}</label>
                        <!-- <select v-model="Institucional.destaque" class="custom-select col-12">
                                                <option value="">Selecione...</option>
                                                <option value="1">Sim</option>
                                                <option value="2">Não</option>
                                            </select> -->
                        <v-select
                          v-model="Institucional.destaque"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.destaqueExtraCol > 0 ? 'col-md-' + Configuracao.destaqueExtraCol:'col-md-3'" v-if="Configuracao.destaqueExtra">
                      <div class="form-group">
                        <label>{{ Configuracao.destaqueExtraLabel }}</label>
                        <!-- <select v-model="Institucional.destaqueExtra" class="custom-select col-12">
                                                <option value="">Selecione...</option>
                                                <option value="1">Sim</option>
                                                <option value="2">Não</option>
                                            </select> -->
                        <v-select
                          v-model="Institucional.destaqueExtra"
                          :options="[
                            { texto: 'Sim', valor: true },
                            { texto: 'Não', valor: false },
                          ]"
                          single-line
                          :reduce="(x) => x.valor"
                          label="texto"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <!-- DEIXAR SEGmENTOS PARA DEPOIS DA LIGACAO COM AS TELAS NA TELA SEGMENTO -->
                    <div
                      v-bind:class="Configuracao.segmentoInstNivel1Col > 0 ? 'col-md-' + Configuracao.segmentoInstNivel1Col:'col-md-3'"
                      v-if="
                        Configuracao.segmentoInstNivel1 && Segmentos1.length > 0
                      "
                    >
                      <div class="form-group">
                        <label>{{
                          Configuracao.segmentoInstNivel1Label
                        }}</label>
                        <v-select
                          v-model="Segmento1"
                          :options="Segmentos1"
                          single-line
                          :reduce="(x) => x.segmentoInstNivel1Id"
                          @input="MudaSegmento1"
                          label="nomeSegmento"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div
                      v-bind:class="Configuracao.segmentoInstNivel2Col > 0 ? 'col-md-' + Configuracao.segmentoInstNivel2Col:'col-md-3'"
                      v-if="
                        Configuracao.segmentoInstNivel2 && Segmentos2.length > 0
                      "
                    >
                      <div class="form-group">
                        <label>{{
                          Configuracao.segmentoInstNivel2Label
                        }}</label>
                        <v-select
                          v-model="Segmento2"
                          :options="Segmentos2"
                          single-line
                          :reduce="(x) => x.segmentoInstNivel2Id"
                          @input="MudaSegmento2"
                          label="nomeSegmento"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div
                      v-bind:class="Configuracao.segmentoInstNivel3Col > 0 ? 'col-md-' + Configuracao.segmentoInstNivel3Col:'col-md-3'"
                      v-if="
                        Configuracao.segmentoInstNivel3 && Segmentos3.length > 0
                      "
                    >
                      <div class="form-group">
                        <label>{{
                          Configuracao.segmentoInstNivel3Label
                        }}</label>
                        <v-select
                          v-model="Segmento3"
                          :options="Segmentos3"
                          single-line
                          :reduce="(x) => x.segmentoInstNivel3Id"
                          @input="MudaSegmento3"
                          label="nomeSegmento"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <div
                      v-bind:class="Configuracao.segmentoInstNivel4Col > 0 ? 'col-md-' + Configuracao.segmentoInstNivel4Col:'col-md-3'"
                      v-if="
                        Configuracao.segmentoInstNivel4 && Segmentos4.length > 0
                      "
                    >
                      <div class="form-group">
                        <label>{{
                          Configuracao.segmentoInstNivel4Label
                        }}</label>
                        <v-select
                          v-model="Segmento4"
                          :options="Segmentos4"
                          single-line
                          :reduce="(x) => x.segmentoInstNivel4Id"
                          @input="MudaSegmento4"
                          label="nomeSegmento"
                          class="style-chooser"
                        ></v-select>
                      </div>
                    </div>
                    <!-- DEIXAR SEGENTOS PARA DEPOIS DA LIGACAO COM AS TELAS NA TELA SEGMENTO -->
                    <div v-bind:class="Configuracao.nomeCol > 0 ? 'col-md-' + Configuracao.nomeCol:'col-md-4'" v-if="Configuracao.nome">
                      <div class="form-group">
                        <label>{{ Configuracao.nomeLabel }}</label>
                        <input
                          v-model="Institucional.nome"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.nomeLabel"
                        />
                      </div>
                    </div>
                       <div v-bind:class="Configuracao.data1Col > 0 ? 'col-md-' + Configuracao.data1Col:'col-md-3'" v-if="Configuracao.data1">
                      <div class="form-group">
                        <label>{{ Configuracao.data1Label }}</label>
                        <input
                          v-model="Institucional.data1"
                          @change="MudaData1"
                          class="form-control mb-5"
                          type="date"
                          v-bind:placeholder="Configuracao.data1Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.referenciaCol > 0 ? 'col-md-' + Configuracao.referenciaCol:'col-md-4'" v-if="Configuracao.referencia">
                      <div class="form-group">
                        <label>{{ Configuracao.referenciaLabel }}</label>
                        <input
                          v-model="Institucional.referencia"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.referenciaLabel"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.campoExtra1Col > 0 ? 'col-md-' + Configuracao.campoExtra1Col:'col-md-4'" v-if="Configuracao.campoExtra1">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra1Label }}</label>
                        <input
                          v-model="Institucional.campoExtra1"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra1Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.campoExtra2Col > 0 ? 'col-md-' + Configuracao.campoExtra2Col:'col-md-4'" v-if="Configuracao.campoExtra2">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra2Label }}</label>
                        <input
                          v-model="Institucional.campoExtra2"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra2Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.campoExtra3Col > 0 ? 'col-md-' + Configuracao.campoExtra3Col:'col-md-4'" v-if="Configuracao.campoExtra3">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra3Label }}</label>
                        <input
                          v-model="Institucional.campoExtra3"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra3Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.campoExtra4Col > 0 ? 'col-md-' + Configuracao.campoExtra4Col:'col-md-4'" v-if="Configuracao.campoExtra4">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra4Label }}</label>
                        <input
                          v-model="Institucional.campoExtra4"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra4Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.campoExtra5Col > 0 ? 'col-md-' + Configuracao.campoExtra5Col:'col-md-4'" v-if="Configuracao.campoExtra5">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra5Label }}</label>
                        <input
                          v-model="Institucional.campoExtra5"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra5Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.campoExtra6Col > 0 ? 'col-md-' + Configuracao.campoExtra6Col:'col-md-4'" v-if="Configuracao.campoExtra6">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra6Label }}</label>
                        <input
                          v-model="Institucional.campoExtra6"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra6Label"
                        />
                      </div>
                    </div>
                    <div class="col-md-4" v-if="Configuracao.campoExtra7">
                      <div class="form-group">
                        <label>{{ Configuracao.campoExtra7Label }}</label>
                        <input
                          v-model="Institucional.campoExtra7"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.campoExtra7Label"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Nome Texto</label>
                                            <quill-editor class="quill-editor_editor1 form-control border-radius-0" placeholder="Nome Texto ..."></quill-editor>
                                        </div>
                                    </div> -->

                    <div v-bind:class="Configuracao.resumo1Col > 0 ? 'col-md-' + Configuracao.resumo1Col:'col-md-12'" v-if="Configuracao.resumo1">
                      <div class="form-group">
                        <label>{{ Configuracao.resumo1Label }}</label>
                        <quill-editor
                          v-model="Institucional.resumo1"
                          v-bind:placeholder="Configuracao.resumo1Label + '...'"
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.resumo2Col > 0 ? 'col-md-' + Configuracao.resumo2Col:'col-md-12'" v-if="Configuracao.resumo2">
                      <div class="form-group">
                        <label>{{ Configuracao.resumo2Label }}</label>
                        <quill-editor
                          v-model="Institucional.resumo2"
                         
                          v-bind:placeholder="Configuracao.resumo2Label + '...'"
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.descricao1Col > 0 ? 'col-md-' + Configuracao.descricao1Col:'col-md-12'" v-if="Configuracao.descricao1">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao1Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao1"
                         
                          v-bind:placeholder="
                            Configuracao.descricao1Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div>
                    <!-- <div class="col-md-12" v-if="Configuracao.descricao1">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao1Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao1"
                          class="quill-editor_editor3 form-control border-radius-0"
                          v-bind:placeholder="
                            Configuracao.descricao1Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div> -->
                    <div v-bind:class="Configuracao.descricao2Col > 0 ? 'col-md-' + Configuracao.descricao2Col:'col-md-12'" v-if="Configuracao.descricao2">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao2Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao2"
                          
                          v-bind:placeholder="
                            Configuracao.descricao2Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.descricao3Col > 0 ? 'col-md-' + Configuracao.descricao3Col:'col-md-12'" v-if="Configuracao.descricao3">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao3Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao3"
                         
                          v-bind:placeholder="
                            Configuracao.descricao3Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.descricao4Col > 0 ? 'col-md-' + Configuracao.descricao4Col:'col-md-12'" v-if="Configuracao.descricao4">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao4Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao4"
                        
                          v-bind:placeholder="
                            Configuracao.descricao4Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.descricao5Col > 0 ? 'col-md-' + Configuracao.descricao5Col:'col-md-12'" v-if="Configuracao.descricao5">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao5Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao5"
                         
                          v-bind:placeholder="
                            Configuracao.descricao5Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.descricao6Col > 0 ? 'col-md-' + Configuracao.descricao6Col:'col-md-12'" v-if="Configuracao.descricao6">
                      <div class="form-group">
                        <label>{{ Configuracao.descricao6Label }}</label>
                        <quill-editor
                          v-model="Institucional.descricao6"
                          
                          v-bind:placeholder="
                            Configuracao.descricao6Label + '...'
                          "
                        ></quill-editor>
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.txtBtn1Col > 0 ? 'col-md-' + Configuracao.txtBtn1Col:'col-md-3'" v-if="Configuracao.textoBtn1">
                      <div class="form-group">
                        <label>{{ Configuracao.textoBtn1Label }}</label>
                        <input
                          v-model="Institucional.textoBtn1"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.textoBtn1Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.linkBtn1Col > 0 ? 'col-md-' + Configuracao.linkBtn1Col:'col-md-3'" v-if="Configuracao.linkBtn1">
                      <div class="form-group">
                        <label>{{ Configuracao.linkBtn1Label }}</label>
                        <input
                          v-model="Institucional.linkBtn1"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.linkBtn1Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.txtBtn2Col > 0 ? 'col-md-' + Configuracao.txtBtn2Col:'col-md-3'" v-if="Configuracao.textoBtn2">
                      <div class="form-group">
                        <label>{{ Configuracao.textoBtn2Label }}</label>
                        <input
                          v-model="Institucional.textoBtn2"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.textoBtn2Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.linkBtn2Col > 0 ? 'col-md-' + Configuracao.linkBtn2Col:'col-md-3'" v-if="Configuracao.linkBtn2">
                      <div class="form-group">
                        <label>{{ Configuracao.linkBtn2Label }}</label>
                        <input
                          v-model="Institucional.linkBtn2"
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="Configuracao.linkBtn2Label"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto-Extra-1</label>
                                            <quill-editor class="quill-editor_editor4 form-control border-radius-0" placeholder="Texto-Extra-1 ..."></quill-editor>
                                        </div>
                                    </div> -->
                    <div v-bind:class="Configuracao.cor1Col > 0 ? 'col-md-' + Configuracao.cor1Col:'col-md-3'" v-if="Configuracao.cor1">
                      <div class="form-group">
                        <label>{{ Configuracao.cor1Label }}</label>
                        <input
                          v-model="Institucional.cor1"
                          class="form-control"
                          type="color"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.cor2Col > 0 ? 'col-md-' + Configuracao.cor2Col:'col-md-3'" v-if="Configuracao.cor2">
                      <div class="form-group">
                        <label>{{ Configuracao.cor2Label }}</label>
                        <input
                          v-model="Institucional.cor2"
                          class="form-control"
                          type="color"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.cor3Col > 0 ? 'col-md-' + Configuracao.cor3Col:'col-md-3'" v-if="Configuracao.cor3">
                      <div class="form-group">
                        <label>{{ Configuracao.cor3Label }}</label>
                        <input
                          v-model="Institucional.cor3"
                          class="form-control"
                          type="color"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.cor4Col > 0 ? 'col-md-' + Configuracao.cor4Col:'col-md-3'" v-if="Configuracao.cor4">
                      <div class="form-group">
                        <label>{{ Configuracao.cor4Label }}</label>
                        <input
                          v-model="Institucional.cor4"
                          class="form-control"
                          type="color"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto-Extra-2</label>
                                            <quill-editor class="quill-editor_editor5 form-control border-radius-0" placeholder="Texto-Extra-2 ..."></quill-editor>
                                        </div>
                                    </div> -->
                    <div v-bind:class="Configuracao.valor1Col > 0 ? 'col-md-' + Configuracao.valor1Col:'col-md-3'" v-if="Configuracao.valor1">
                      <div class="form-group">
                        <label>{{ Configuracao.valor1Label }}</label>
                        <money
                          v-model.lazy="Institucional.valor1"
                          class="form-control mb-5 decimal"
                          type="text"
                          v-bind:placeholder="Configuracao.valor1Label"
                          v-bind="money"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.valor2Col > 0 ? 'col-md-' + Configuracao.valor2Col:'col-md-3'" v-if="Configuracao.valor2">
                      <div class="form-group">
                        <label>{{ Configuracao.valor2Label }}</label>
                        <input
                          v-model="Institucional.valor2"
                          class="form-control mb-5 decimal"
                          type="text"
                          v-bind:placeholder="Configuracao.valor2Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.valor3Col > 0 ? 'col-md-' + Configuracao.valor3Col:'col-md-3'" v-if="Configuracao.valor3">
                      <div class="form-group">
                        <label>{{ Configuracao.valor3Label }}</label>
                        <input
                          v-model="Institucional.valor3"
                          class="form-control mb-5 decimal"
                          type="text"
                          v-bind:placeholder="Configuracao.valor3Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.valor4Col > 0 ? 'col-md-' + Configuracao.valor4Col:'col-md-3'" v-if="Configuracao.valor4">
                      <div class="form-group">
                        <label>{{ Configuracao.valor4Label }}</label>
                        <input
                          v-model="Institucional.valor4"
                          class="form-control mb-5 decimal"
                          type="text"
                          v-bind:placeholder="Configuracao.valor4Label"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Texto-Extra-3</label>
                                                <quill-editor class="quill-editor_editor6 form-control border-radius-0" placeholder="Texto-Extra-3 ..."></quill-editor>
                                            </div>
                                        </div> -->
                 
                    <div v-bind:class="Configuracao.data2Col > 0 ? 'col-md-' + Configuracao.data2Col:'col-md-3'" v-if="Configuracao.data2">
                      <div class="form-group">
                        <label>{{ Configuracao.data2Label }}</label>
                        <input
                          v-model="Institucional.data2"
                          @change="MudaData2"
                          class="form-control mb-5"
                          type="date"
                          v-bind:placeholder="Configuracao.data2Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.data3Col > 0 ? 'col-md-' + Configuracao.data3Col:'col-md-3'" v-if="Configuracao.data3">
                      <div class="form-group">
                        <label>{{ Configuracao.data3Label }}</label>
                        <input
                          v-model="Institucional.data3"
                          @change="MudaData3"
                          class="form-control mb-5"
                          type="date"
                          v-bind:placeholder="Configuracao.data3Label"
                        />
                      </div>
                    </div>
                    <div v-bind:class="Configuracao.data4Col > 0 ? 'col-md-' + Configuracao.data4Col:'col-md-3'" v-if="Configuracao.data4">
                      <div class="form-group">
                        <label>{{ Configuracao.data4Label }}</label>
                        <input
                          v-model="Institucional.data4"
                          @change="MudaData4"
                          class="form-control mb-5"
                          type="date"
                          v-bind:placeholder="Configuracao.data4Label"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto-Extra-4</label>
                                            <quill-editor class="quill-editor_editor7 form-control border-radius-0" placeholder="Texto-Extra-4 ..."></quill-editor>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Texto-Extra-5</label>
                                            <quill-editor class="quill-editor_editor8 form-control border-radius-0" placeholder="Texto-Extra-5 ..."></quill-editor>
                                        </div>
                                    </div> -->
                    <!-- <div class="col-md-12">
                                        <hr style="border-bottom:2px solid #333333;" />
                                        <div class="form-group">
                                            <label>Grid-informacao-1</label>
                                            <div class="col-md-8">
                                                <input class="form-control mb-5" type="text" placeholder="Titulo">
                                            </div>
                                            <div class="col-md-8 mb-5">
                                                <quill-editor class="quill-editor_editor9 form-control border-radius-0" placeholder="Descrição ..."></quill-editor>
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <input class="form-control mb-5" type="number" placeholder="ordem">
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <button type="button" class="btn btn-primary" id="sa-custom-position">Incluir</button>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Titulo</th>
                                                            <th scope="col">Texto</th>
                                                            <th scope="col">Ordem</th>
                                                            <th scope="col">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor venenatis erat. Curabitur magna neque, congue vitae finibus quis, finibus ac dolor. Phasellus sed dignissim leo. Ut eleifend velit nec felis ullamcorper, sed finibus enim cursus. Etiam ac posuere lorem, vel hendrerit elit. Morbi tempor vehicula eros nec aliquam. Mauris sed tempus orci. </td>
                                                            <td> <input class="form-control" type="number" placeholder=""></td>
                                                            <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor venenatis erat. Curabitur magna neque, congue vitae finibus quis, finibus ac dolor. Phasellus sed dignissim leo. Ut eleifend velit nec felis ullamcorper, sed finibus enim cursus. Etiam ac posuere lorem, vel hendrerit elit. Morbi tempor vehicula eros nec aliquam. Mauris sed tempus orci. </td>
                                                            <td> <input class="form-control" type="number" placeholder=""></td>
                                                            <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <hr style="border-bottom:2px solid #333333;" />
                                        <div class="form-group">
                                            <label>Grid-informacao-2</label>
                                            <div class="col-md-8">
                                                <input class="form-control mb-5" type="text" placeholder="Titulo">
                                            </div>
                                            <div class="col-md-8 mb-5">
                                                <quill-editor class="quill-editor_editor9 form-control border-radius-0" placeholder="Descrição ..."></quill-editor>
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <input class="form-control mb-5" type="number" placeholder="ordem">
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <button type="button" class="btn btn-primary" id="sa-custom-position">Incluir</button>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Titulo</th>
                                                            <th scope="col">Texto</th>
                                                            <th scope="col">Ordem</th>
                                                            <th scope="col">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor venenatis erat. Curabitur magna neque, congue vitae finibus quis, finibus ac dolor. Phasellus sed dignissim leo. Ut eleifend velit nec felis ullamcorper, sed finibus enim cursus. Etiam ac posuere lorem, vel hendrerit elit. Morbi tempor vehicula eros nec aliquam. Mauris sed tempus orci. </td>
                                                            <td> <input class="form-control" type="number" placeholder=""></td>
                                                            <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor venenatis erat. Curabitur magna neque, congue vitae finibus quis, finibus ac dolor. Phasellus sed dignissim leo. Ut eleifend velit nec felis ullamcorper, sed finibus enim cursus. Etiam ac posuere lorem, vel hendrerit elit. Morbi tempor vehicula eros nec aliquam. Mauris sed tempus orci. </td>
                                                            <td> <input class="form-control" type="number" placeholder=""></td>
                                                            <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="col-md-12">
                                        <hr style="border-bottom:2px solid #333333;" />
                                        <div class="form-group">
                                            <label>Grid-informacao-3</label>
                                            <div class="col-md-8">
                                                <input class="form-control mb-5" type="text" placeholder="Titulo">
                                            </div>
                                            <div class="col-md-8 mb-5">
                                                <quill-editor class="quill-editor_editor9 form-control border-radius-0" placeholder="Descrição ..."></quill-editor>
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <input class="form-control mb-5" type="number" placeholder="ordem">
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <button type="button" class="btn btn-primary" id="sa-custom-position">Incluir</button>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Titulo</th>
                                                            <th scope="col">Texto</th>
                                                            <th scope="col">Ordem</th>
                                                            <th scope="col">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor venenatis erat. Curabitur magna neque, congue vitae finibus quis, finibus ac dolor. Phasellus sed dignissim leo. Ut eleifend velit nec felis ullamcorper, sed finibus enim cursus. Etiam ac posuere lorem, vel hendrerit elit. Morbi tempor vehicula eros nec aliquam. Mauris sed tempus orci. </td>
                                                            <td> <input class="form-control" type="number" placeholder=""></td>
                                                            <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td>
                                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor venenatis erat. Curabitur magna neque, congue vitae finibus quis, finibus ac dolor. Phasellus sed dignissim leo. Ut eleifend velit nec felis ullamcorper, sed finibus enim cursus. Etiam ac posuere lorem, vel hendrerit elit. Morbi tempor vehicula eros nec aliquam. Mauris sed tempus orci. </td>
                                                            <td> <input class="form-control" type="number" placeholder=""></td>
                                                            <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    -->
                    <!-- <div class="col-md-12">
                                        <hr style="border-bottom:2px solid #333333;" />
                                        <div class="form-group">
                                            <label>Upload de Arquivos</label>
                                            <div class="col-md-6 mb-5">
                                                <label>Icone</label>
                                                <input type="file" class="form-control-file form-control height-auto">
                                            </div>
                                            <div class="col-md-6 mb-5">
                                                <label>Arquivo</label>
                                                <input type="file" class="form-control-file form-control height-auto">
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <input class="form-control mb-5" type="number" placeholder="ordem">
                                            </div>
                                            <div class="col-md-3 mb-5">
                                                <button type="button" class="btn btn-primary" id="sa-custom-position">Incluir</button>
                                            </div>
                                            <div class="col-md-6 mb-5">
                                                <div class="table-responsive">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Icone</th>
                                                                <th scope="col">Link</th>
                                                                <th scope="col">Ordem</th>
                                                                <th scope="col">Ação</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><img src="vendors/images/product-3.jpg" width="70" height="70" alt=""></td>
                                                                <td>/servicos/nome-do-arquivo.pdf</td>
                                                                <td> <input class="form-control" type="number" placeholder=""></td>
                                                                <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                            </tr>
                                                            <tr>
                                                                <td><img src="vendors/images/product-3.jpg" width="70" height="70" alt=""></td>
                                                                <td>/servicos/nome-do-arquivo.pdf</td>
                                                                <td> <input class="form-control" type="number" placeholder=""></td>
                                                                <td><button type="button" class="btn btn-success btn-sm" id="sa-custom-position">Salvar</button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                    <div
                      class="col-md-8"
                      v-if="
                        Configuracao.uploadImagem && InstitucionalEditandoId > 0
                      "
                    >
                      <div class="form-group">
                        <label v-if="Configuracao.qtdeImagem > 1"
                          >{{ Configuracao.qtdeImagem }} Imagens -
                          {{
                            Configuracao.larguraImagem +
                            "x" +
                            Configuracao.alturaImagem
                          }}</label
                        >
                        <label v-else
                          >Imagem -
                          {{
                            Configuracao.larguraImagem +
                            "x" +
                            Configuracao.alturaImagem
                          }}
                        </label>
                        <input
                          v-if="Configuracao.qtdeImagem > 1"
                          @change="Upload"
                          type="file"
                          class="form-control-file form-control height-auto"
                          multiple
                        />
                        <input
                          v-else
                          @change="Upload"
                          type="file"
                          class="form-control-file form-control height-auto"
                        />
                        <img
                          src="/images/cloud-upload.gif"
                          v-if="Enviando > Enviado"
                        />
                        <div v-else class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">imagem</th>
                                <th scope="col">Ordem</th>
                                <th scope="col">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="Imagem in Imagens"
                                :key="Imagem.imagemInstitucionalId"
                              >
                                <td>
                                  <img
                                    style="max-width: 90px"
                                    :src="Imagem.urlImagem"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control"
                                    type="number"
                                    v-model="Imagem.ordem"
                                    placeholder=""
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-warning btn-sm"
                                    id="sa-custom-position"
                                    @click="ApagaImagem(Imagem)"
                                  >
                                    Apagar
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-success btn-sm"
                                    @click="SalvarOrdem"
                                  >
                                    Salvar Ordem
                                  </button>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        @click="$redirect_reload(Tela.linkTela)"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <!-- <button type="button" class="btn btn-info">Pesquisar</button> -->
                      <button
                        type="button"
                        v-if="InstitucionalEditandoId != 0"
                        @click="Alterar"
                        class="btn btn-success"
                        id="sa-custom-position"
                      >
                        Alterar
                      </button>
                      <button
                        type="button"
                        v-else
                        @click="Salvar"
                        class="btn btn-success"
                        id="sa-custom-position"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30" v-if="!EscondeGrid">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <!-- <th>Nivel 1</th>
                            <th>Nivel 2</th>
                            <th>Nivel 3</th>
                            <th>Nivel 4</th> -->
                <th>Ordem</th>
                <th v-if="Configuracao.destaque">Destaque</th>
                <th>Visível</th>
                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="Institucional in Institucionais"
                :key="Institucional.institucionalId"
              >
                <td>{{ Institucional.institucionalId }}</td>
                 <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            Tela.linkTela + '/' + Institucional.institucionalId
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >
                      <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                      <a
                        class="dropdown-item"
                        @click="Remove(Institucional)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Institucional.nome }}</td>
                <!-- <td> </td>
                            <td></td>
                            <td> </td>
                            <td> </td> -->
                <td>{{ Institucional.ordem }}</td>
                <td v-if="Configuracao.destaque">
                  <span v-if="Institucional.destaque">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Institucional.visivel">Sim</span
                  ><span v-else>Não</span>
                </td>
               
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
//import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
import moment from "moment";
import { VMoney } from "v-money";

export default {
  data() {
    return {
      EscondeGrid: false,
      ChaveTela: "",
      TelaId: "",
      InstitucionalEditandoId: "",
      Tela: {},
      Configuracao: {},
      Institucional: {},
      Segmento1: 0,
      Segmento2: 0,
      Segmento3: 0,
      Segmento4: 0,
      Segmentos1: [],
      Segmentos2: [],
      Segmentos3: [],
      Segmentos4: [],
      Data1: "",
      Data2: "",
      Data3: "",
      Data4: "",
      Institucionais: [],
      Enviando: 0,
      Enviado: 0,
      Imagens: [],
      T: {},
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: true,
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    MudaSegmento1() {
      this.Segmentos2 = [];
      this.Segmentos3 = [];
      this.Segmentos4 = [];
          this.Segmento2 = 0;
          this.Segmento3 = 0;
          this.Segmento4 = 0;
      if(this.Segmento1 > 0)
      {
        this.Institucional.segmentoInstNivel1 =  this.Segmentos1
        .find(x => x.segmentoInstNivel1Id == this.Segmento1);
        this.$http
        .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + this.Segmento1)
        .then((response) => {
          this.Segmentos2 = response.body;
          if (
            this.InstitucionalEditandoId > 0 &&
            this.Institucional.segmentoInstNivel2 != null
          ) {
            // this.Segmento2 =
            //   this.Institucional.segmentoInstNivel2.segmentoInstNivel2Id;
            this.Institucional.segmentoInstNivel2 = this.Segmentos2
            .find(x => x.segmentoInstNivel2Id == this.Institucional.segmentoInstNivel2.segmentoInstNivel2Id);
            this.Segmento2 = this.Institucional.segmentoInstNivel2.segmentoInstNivel2Id;
            this.MudaSegmento2();
          }
        });
      }
      else
      {
        this.Segmento1 = 0;
        this.Institucional.segmentoInstNivel1 = null;
      }
    },
    MudaSegmento2() {
      this.Segmentos3 = [];
        this.Segmentos4 = [];
        
        this.Segmento3 = 0;
        this.Segmento4 = 0;
      if(this.Segmento2 > 0)
      {
        this.Institucional.segmentoInstNivel2 =  this.Segmentos2
        .find(x => x.segmentoInstNivel2Id == this.Segmento2);
        this.$http
        .get(this.$apiUrl + "/segmentoInstnivel3/nivel2/" + this.Segmento2)
        .then((response) => {
          this.Segmentos3 = response.body;
          if (
            this.InstitucionalEditandoId > 0 &&
            this.Institucional.segmentoInstNivel3 != null
          ) {
            // this.Segmento3 =
            //   this.Institucional.segmentoInstNivel3.segmentoInstNivel3Id;
            this.Institucional.segmentoInstNivel3 = this.Segmentos3
            .find(x => x.segmentoInstNivel3Id == this.Institucional.segmentoInstNivel3.segmentoInstNivel3Id);
            this.Segmento3 = this.Institucional.segmentoInstNivel3.segmentoInstNivel3Id;
              this.MudaSegmento3();
          }
        });
      }
      else
      {
        this.Segmento2 = 0;
        this.Institucional.segmentoInstNivel2 = null;
      }
    },
    MudaSegmento3() {
        
        this.Segmento4 = 0;
      this.Segmentos4 = [];
      if(this.Segmento3 > 0)
      {
        this.Institucional.segmentoInstNivel3 =  this.Segmentos3
        .find(x => x.segmentoInstNivel3Id == this.Segmento3);
        this.$http
        .get(this.$apiUrl + "/segmentoInstnivel4/nivel3/" + this.Segmento3)
        .then((response) => {
          
          this.Segmentos4 = response.body;
          if (
            this.InstitucionalEditandoId > 0 &&
            this.Institucional.segmentoInstNivel4 != null
          ) {
            this.Institucional.segmentoInstNivel4 = this.Segmentos4
            .find(x => x.segmentoInstNivel4Id == this.Institucional.segmentoInstNivel4.segmentoInstNivel4Id);
            this.Segmento4 = this.Institucional.segmentoInstNivel4.segmentoInstNivel4Id;
            this.MudaSegmento4();
          }
        });
      }
      else
      {
        this.Segmento3 = 0;
        this.Institucional.segmentoInstNivel3 = null;
      }
    },
    MudaSegmento4(){
      if(this.Segmento4 > 0)
      {
        this.Institucional.segmentoInstNivel4 =  this.Segmentos4
        .find(x => x.segmentoInstNivel4Id == this.Segmento4);
      }
      else
      {
        this.Segmento4 = 0;
        this.Institucional.segmentoInstNivel4 = null;
      }
    },
    SalvarOrdem() {
      this.Imagens.forEach((element) => {
        // console.log("---");
        // console.log(element);
        element.ordem = parseInt(element.ordem, 10);
        this.$http
          .put(
            this.$apiUrl + "/institucionalimg/" + element.institucionalImgId,
            element
          )
          .then((response) => {
            console.log(response.body);
          });
      });
      this.$mensagem_sucesso("Ordem salva");
      this.Imagens = this.Imagens.sort(function (a, b) {
        return a.ordem - b.ordem;
      });
    },
    ApagaImagem(Imagem) {
      this.$http
        .delete(this.$apiUrl + "/InstitucionalImg/" + Imagem.institucionalImgId)
        .then(
          (response) => {
            console.log(response);
            this.$mensagem_sucesso("Removido com sucesso");
            var i = this.Imagens.indexOf(Imagem);
            this.Imagens.splice(i, 1);
          },
          (response) => {
            console.log(response);
          }
        );
    },
    Upload(event) {
      this.EnviandoImagem = true;
      var files = event.target.files;
      var x = 0;
      var imageType = /image.*/;
      for (x = 0; x < files.length; x++) {
        if (!files[x].type.match(imageType)) {
          continue;
        }
        if (this.Imagens.length + x + 1 > this.Configuracao.qtdeImagem) {
          this.$mensagem_erro("Número maximo de imagens atingido");
          return;
        }
        this.Enviando++;
        let data = new FormData();
        data.append("arquivo", files[x]);
        console.log(files[x]);
        this.$http
          .post(
            this.$apiUrl +
              "/institucionalimg/upload/" +
              this.InstitucionalEditandoId,
            data
          )
          .then(
            (response) => {
              console.log(response.body);
              this.Enviado++;
              this.Imagens.push(JSON.parse(response.bodyText));
            },
            (response) => {
              console.log(response);
            }
          );
      }
      //setInterval(function(){ alert("Hello"); }, 3000);
      // while(fileOk < tamanho)
      // {
      //   console.log("aguardando");
      // }
      // this.EnviandoImagem = false;
      this.Imagens = this.Imagens.sort(function (a, b) {
        return a.ordem - b.ordem;
      });
    },
    MudaData1() {
      this.Data1 = moment(this.Institucional.data1)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    MudaData2() {
      this.Data2 =  (this.Institucional.data2)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    MudaData3() {
      this.Data3 = moment(this.Institucional.data3)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    MudaData4() {
      this.Data4 = moment(this.Institucional.data4)
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
    },
    Salvar() {
      console.log("Objeto para salvar: ");
      
      this.Institucional.tela = this.Tela;
      if (
        this.Institucional.valor1 != null &&
        this.Institucional.valor1.length > 0
      )
        this.Institucional.valor1 = parseFloat(
          this.Institucional.valor1.replace(".", "").replace(",", ".")
        );
      if (
        this.Institucional.valor2 != null &&
        this.Institucional.valor2.length > 0
      )
        this.Institucional.valor2 = parseFloat(
          this.Institucional.valor2.replace(".", "").replace(",", ".")
        );
      if (
        this.Institucional.valor3 != null &&
        this.Institucional.valor3.length > 0
      )
        this.Institucional.valor3 = parseFloat(
          this.Institucional.valor3.replace(".", "").replace(",", ".")
        );
      if (
        this.Institucional.valor4 != null &&
        this.Institucional.valor4.length > 0
      )
        this.Institucional.valor4 = parseFloat(
          this.Institucional.valor4.replace(".", "").replace(",", ".")
        );

      if (this.Data1.length > 0) this.Institucional.data1 = this.Data1;
      if (this.Data2.length > 0) this.Institucional.data2 = this.Data2;
      if (this.Data3.length > 0) this.Institucional.data3 = this.Data3;
      if (this.Data4.length > 0) this.Institucional.data4 = this.Data4;
      console.log(this.Institucional);

      // this.$http
      //   .get(this.$apiUrl + "/segmentoInstNivel1/" + this.Segmento1)
      //   .then((response) => {
      //     this.Institucional.segmentoInstNivel1 = response.body;
      //     console.log("s1:");
      //     console.log(this.Institucional.segmentoInstNivel1);
      //   })
      //   .then(
      //     this.$http
      //       .get(this.$apiUrl + "/segmentoInstNivel2/" + this.Segmento2)
      //       .then((response2) => {
      //         this.Institucional.segmentoInstNivel2 = response2.body;
      //         console.log("s2:");
      //         console.log(this.Institucional.segmentoInstNivel2);
      //       })
      //       .then(
      //         this.$http
      //           .get(this.$apiUrl + "/segmentoInstNivel3/" + this.Segmento3)
      //           .then((response3) => {
      //             this.Institucional.segmentoInstNivel3 = response3.body;
      //             console.log("s3:");
      //             console.log(this.Institucional.segmentoInstNivel3);
      //           })
      //           .then(
      //             this.$http
      //               .get(this.$apiUrl + "/segmentoInstnivel4/" + this.Segmento4)
      //               .then((response4) => {
      //                 this.Institucional.segmentoInstNivel4 = response4.body;
      //                 console.log("s4:");
      //                 console.log(this.Institucional.segmentoInstNivel4);
      //                 console.log("inst:");
      //                 console.log(this.Institucional);
      //               })
      //               .then(() => {
                      this.$http
                        .post(
                          this.$apiUrl + "/Institucional/",
                          this.Institucional
                        )
                        .then(
                          (response) => {
                            console.log(response);
                            this.Institucional = response.body;
                            this.$mensagem_sucesso(" Salvo com sucesso");
                            this.$redirect(
                              this.Tela.linkTela +
                                "/" +
                                this.Institucional.institucionalId
                            );
                          },
                          (response) => {
                            this.$mensagem_erro(response.body);
                          }
                        )
                        .then(this.atualiza());
        //             })
        //         )
        //     )
        // );
    },
    Alterar() {
      console.log("Objeto para alterar:");

      this.Institucional.ordem = parseInt(this.Institucional.ordem, 10);
      if (
        this.Institucional.valor1 != null &&
        this.Institucional.valor1.length > 0
      )
        this.Institucional.valor1 = parseFloat(
          this.Institucional.valor1.replace(".", "").replace(",", ".")
        );
      if (
        this.Institucional.valor2 != null &&
        this.Institucional.valor2.length > 0
      )
        this.Institucional.valor2 = parseFloat(
          this.Institucional.valor2.replace(".", "").replace(",", ".")
        );
      if (
        this.Institucional.valor3 != null &&
        this.Institucional.valor3.length > 0
      )
        this.Institucional.valor3 = parseFloat(
          this.Institucional.valor3.replace(".", "").replace(",", ".")
        );
      if (
        this.Institucional.valor4 != null &&
        this.Institucional.valor4.length > 0
      )
        this.Institucional.valor4 = parseFloat(
          this.Institucional.valor4.replace(".", "").replace(",", ".")
        );

      if (this.Data1.length > 0) this.Institucional.data1 = this.Data1;
      if (this.Data2.length > 0) this.Institucional.data2 = this.Data2;
      if (this.Data3.length > 0) this.Institucional.data3 = this.Data3;
      if (this.Data4.length > 0) this.Institucional.data4 = this.Data4;
      console.log(this.Institucional);

      // this.$http
      //   .get(this.$apiUrl + "/segmentoInstNivel1/" + this.Segmento1)
      //   .then((response) => {
      //     this.Institucional.segmentoInstNivel1 = response.body;
      //     console.log("s1:");
      //     console.log(this.Institucional.segmentoInstNivel1);
      //   })
      //   .then(
      //     this.$http
      //       .get(this.$apiUrl + "/segmentoInstNivel2/" + this.Segmento2)
      //       .then((response2) => {
      //         this.Institucional.segmentoInstNivel2 = response2.body;
      //         console.log("s2:");
      //         console.log(this.Institucional.segmentoInstNivel2);
      //       })
      //       .then(
      //         this.$http
      //           .get(this.$apiUrl + "/segmentoInstNivel3/" + this.Segmento3)
      //           .then((response3) => {
      //             this.Institucional.segmentoInstNivel3 = response3.body;
      //             console.log("s3:");
      //             console.log(this.Institucional.segmentoInstNivel3);
      //           })
      //           .then(
      //             this.$http
      //               .get(this.$apiUrl + "/segmentoInstnivel4/" + this.Segmento4)
      //               .then((response4) => {
      //                 this.Institucional.segmentoInstNivel4 = response4.body;
      //                 console.log("s4:");
      //                 console.log(this.Institucional.segmentoInstNivel4);
      //                 console.log("inst:");
      //                 console.log(this.Institucional);
      //               })
      //               .then(() => {
                      this.$http
                        .put(
                          this.$apiUrl +
                            "/Institucional/" +
                            this.InstitucionalEditandoId,
                          this.Institucional
                        )
                        .then(
                          (response) => {
                            console.log(response);
                            this.$mensagem_sucesso(
                              "#" +
                                this.Institucional.institucionalId +
                                " Alterado com sucesso"
                            );
                            this.$redirect(this.Tela.linkTela);
                          },
                          (response) => {
                            this.$mensagem_erro(response.body);
                          }
                        )
                        .then(this.atualiza());
        //             })
        //         )
        //     )
        // );
    },
    Remove(tmp) {
      if (tmp != null) {
        this.$http
          .delete(this.$apiUrl + "/institucional/" + tmp.institucionalId)
          .then(
            (response) => {
              console.log(response);
              this.$mensagem_sucesso("Removido com sucesso");
              this.$router.go(this.$router.currentRoute);
            },
            (response) => {
              this.$mensagem_erro(response.body);
            }
          );
      }
    },
    atualiza() {
      
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 2000);
    },
    CarregaInstitucional(id) {
      if (id != null) {
        this.$http
          .get(this.$apiUrl + "/Institucional/" + id)
          .then((response) => {
            this.Institucional = response.body;
            this.Imagens = this.Institucional.institucionalImg;
            this.Imagens = this.Imagens.sort(function (a, b) {
              return a.ordem - b.ordem;
            });
            console.log("Editando: ");
            this.InstitucionalEditandoId = this.Institucional.institucionalId;
            this.Data1 = this.Institucional.data1 = moment(
              this.Institucional.data1
            ).format("YYYY-MM-DD");
            this.Data2 = this.Institucional.data2 = moment(
              this.Institucional.data2
            ).format("YYYY-MM-DD");
            this.Data3 = this.Institucional.data3 = moment(
              this.Institucional.data3
            ).format("YYYY-MM-DD");
            this.Data4 = this.Institucional.data4 = moment(
              this.Institucional.data4
            ).format("YYYY-MM-DD");

            if (this.Institucional.segmentoInstNivel1 != null) {
              this.Segmento1 =
                this.Institucional.segmentoInstNivel1.segmentoInstNivel1Id;
            }
            if (this.Institucional.segmentoInstNivel2 != null) {
              this.Segmento2 =
                this.Institucional.segmentoInstNivel2.segmentoInstNivel2Id;
            }
            if (this.Institucional.segmentoInstNivel3 != null) {
              this.Segmento3 =
                this.Institucional.segmentoInstNivel3.segmentoInstNivel3Id;
            }
            if (this.Institucional.segmentoInstNivel4 != null) {
              this.Segmento4 =
                this.Institucional.segmentoInstNivel4.segmentoInstNivel4Id;
            }
            this.$http
              .get(
                this.$apiUrl +
                  "/segmentoInstNivel1/tela/" +
                  this.Tela.telaInstitucionalId
              )
              .then((responseg) => {
                this.Segmentos1 = responseg.body;
                this.MudaSegmento1();
              });

            // this.MudaData1();this.MudaData2();this.MudaData3();this.MudaData4();
            console.log(this.Institucional);
          });
      }
    },
  },
  components: {
    vSelect,
  },
  created() {
    if (this.$route.params.chavetela != null) {
      this.ChaveTela = this.$route.params.chavetela;
    } else {
      this.ChaveTela = "";
    }
    this.TelaId = "";
    this.ChaveTela = this.$route.fullPath.replace("/controle/telas/", "");
    let _tmpArr = this.ChaveTela.split("-");
    this.TelaId = parseInt(_tmpArr[0]);
    this.$http.get(this.$apiUrl + "/TelaInstitucional/" + this.TelaId).then(
      (response) => {
        this.Tela = response.body;
        this.Tela.grupoTela = null;

        this.$http
          .get(
            this.$apiUrl +
              "/ConfiguraInstitucional/tela/" +
              this.Tela.telaInstitucionalId
          )
          .then(
            (response2) => {
              this.Configuracao = response2.body;

              if (this.$route.params.id) {
                //editando por id
                this.CarregaInstitucional(this.$route.params.id);
              } else if (this.Configuracao.multi) {
                //multidocumento buscar repeat tabela
                this.$http
                  .get(
                    this.$apiUrl + "/Institucional/telas/" + this.Tela.nomeTela
                  )
                  .then((response3) => {
                    this.Institucionais = response3.body;
                    console.log("Multi:");
                    console.log(this.Institucionais);
                  })
                  .then(this.atualiza());
              } else {
                //editando tela unica sem multidocumento
                this.EscondeGrid = true;
                this.$http
                  .get(
                    this.$apiUrl + "/Institucional/tela/" + this.Tela.nomeTela
                  )
                  .then((response4) => {
                    this.Institucional = response4.body;
                    this.InstitucionalEditandoId =
                      this.Institucional.institucionalId;
                    
                    this.CarregaInstitucional(this.InstitucionalEditandoId);
                  })
                  .then(this.atualiza());
              }
            },
            (response2) => {
              this.$mensagem_erro(response2.body);
            }
          )
          .then(
            this.$http
              .get(
                this.$apiUrl +
                  "/segmentoInstNivel1/tela/" +
                  this.Tela.telaInstitucionalId
              )
              .then((responseg) => {
                this.Segmentos1 = responseg.body;
                this.MudaSegmento1();
              })
          );
      },
      (response) => {
        // error callback
        this.$mensagem_erro(response.body);
      }
    );
    this.EscondeGrid = this.Configuracao.multi;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
