<template>
  <div>
    <div class="header">
      <div class="header-left">
        <div class="menu-icon dw dw-menu" style="padding-left: 20px"></div>
        <div class="dropdown" style="padding-left: 40px; display: none">
          <a
            class="btn btn-outline-secondary dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
          >
            Idioma
          </a>
          <div class="dropdown-menu dropdown-menu-left">
            <a class="dropdown-item" href="#">Português</a>
            <a class="dropdown-item" href="#">Inglês</a>
            <a class="dropdown-item" href="#">Espanhol</a>
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="user-info-dropdown">
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
            >
              <span class="user-icon">
                <img
                  :src="ImagemUrl"
                  style="height: 100%; width: 100%"
                  alt=""
                />
              </span>
              <!-- <span class="user-name" id="nomeusuario"></span> -->
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
              <a class="dropdown-item" @click="Logout()"><i class="dw dw-logout"></i> Sair</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="left-side-bar">
      <div class="brand-logo" style="background-color: #ffffff">
        <a @click="$redirect_reload('/controle/default')">
          <img
            src="/images/avance-logo-transparente.png"
            alt=""
            style="width: 257px; height: auto"
          />
          <!--<img src="images/MOBILE-LOVE-CESTAS-logo.png" alt="" style="width:257px;height:auto;">-->
        </a>
        <div class="close-sidebar" data-toggle="left-sidebar-close">
          <i class="ion-close-round"></i>
        </div>
      </div>
      <div class="menu-block customscroll">
        <div class="sidebar-menu">
            <ul id="menuMotorista" style="display:none;padding:20px;color:white;font-size:16px;">
                <li>
                    <a @click="$redirect_reload('/controle/motoristasEntregas')">
                      <i class="micon dw dw-map" style="padding:5px"></i>Motoristas x Entregas
                  </a>
                </li>
            </ul>
            <ul id="accordion-menu" style="padding-top: 20px">
               <li  v-if="Admin">
                <a  @click="$redirect_reload('/controle/dashboard')" class="dropdown-toggle tiraseta" style="    display: block;
    padding: 18px 15px 11px 67px;
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    letter-spacing: .03em;
    position: relative;
    font-family: 'Inter',sans-serif;">
                        <span style="" class="micon dw dw-analytics-21"></span><span class="mtext">Dashboard</span>
                    </a>
              </li>
                <li class="dropdown" v-if="Admin">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-settings"></span><span class="mtext">Configurações</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/default', $event)">Inicio</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/gruposInstitucional', $event)">Grupos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/telasInstitucional', $event)">Telas</a>
                        </li>
                        <li>
                            <a @click="
                      redirect_cabecalho('/controle/configuraInstitucional', $event)
                    ">Configuração de telas</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/segmentosinst', $event)">Controle Segmentos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/cadoperadora', $event)">Cadastros Operadoras</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/cadbanco', $event)">Cadastros Bancos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/cadpix', $event)">Cadastros Pix</a>
                        </li>
                          <li>
                            <a @click="redirect_cabecalho('/controle/localoperadora', $event)">Local Operadoras</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/localbanco', $event)">Local Bancos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/localpix', $event)">Local Pix</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/idiomas', $event)">Idiomas</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/moedas', $event)">Moedas</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/cotacoes', $event)">Cotações</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/TiposRedeSocial', $event)">Tipos de Redes Sociais</a>
                        </li>

                        <!--<li><a href="ControleAcessos.html">Controle de Acesso</a></li>-->
                        <li>
                            <a @click="redirect_cabecalho('/controle/controlesmtp', $event)">Configurações Contato</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/filtrocaracteristica', $event)">Tipo de Fonte</a>
                        </li>
                       <li><a @click="redirect_cabecalho('/controle/filtrofabrica', $event)">Customizados</a></li>
                       <li><a @click="redirect_cabecalho('/controle/filtroMateriaPrima', $event)">Qtd Linha</a></li>
                       <li>
                            <a @click="redirect_cabecalho('/controle/filtrovalor', $event)">Valores</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-house-1"></span><span class="mtext">Geral</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/default', $event)">Inicio</a>
                        </li>

                        <li>
                            <a @click="redirect_cabecalho('/controle/usuarios', $event)">Usuários</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/tiposusuarios', $event)">Tipos de Usuários</a>
                        </li>

                        <li>
                            <a @click="redirect_cabecalho('/controle/estados', $event)">Estados</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/cidades', $event)">Cidades</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/bairros', $event)">Bairros</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/empresas', $event)">Empresas</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-startup"></span><span class="mtext">SEO</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/seometahome', $event)">Código Inicial Metas</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/seometapagina', $event)">Metas por Página</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/Seoanalitycs', $event)">Código Analytics</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/seohead', $event)">Códigos Head</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/seobody', $event)">Códigos Body</a>
                        </li>
                    </ul>
                </li>

                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-user-3"></span><span class="mtext">Clientes</span>
                    </a>
                    <ul class="submenu">
                        <!-- <li>
                            <a @click="redirect_cabecalho('/controle/segmentoscli1', $event)">Segmentos 1</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/segmentoscli2', $event)">Segmentos 2</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/segmentoscli3', $event)">Segmentos 3</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/segmentoscli4', $event)">Segmentos 4</a>
                        </li> -->
                        <li>
                            <a @click="redirect_cabecalho('/controle/clientes', $event)">Clientes</a>
                        </li>
                        <!-- <li>
                            <a @click="redirect_cabecalho('/controle/arquivoscli', $event)">Arquivos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/fotoscli', $event)">Fotos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/videoscli', $event)">Vídeos</a>
                        </li> -->
                    </ul>
                </li>
                <li class="dropdown" style="display:none;">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-user-3"></span><span class="mtext">Planos Clientes</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/planoscadastro', $event)">Cadastro de Planos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/planosatuacaocadastro', $event)">Cadastros de Atuação</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/planosservicocadastro', $event)">Cadastros de Serviços</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/clientes', $event)">Clientes</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/planoscliente', $event)">Planos x Clientes</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-list3"></span><span class="mtext">Customizados</span>
                    </a>
                    <ul class="submenu child">
                        <li>
                            <a @click="redirect_cabecalho('/controle/filtromarca', $event)">Fontes Opcionais</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/filtrotamanho', $event)">Fontes Produtos Gr</a>
                        </li>
                        <li><a @click="redirect_cabecalho('/controle/filtrocor', $event)">Cores</a></li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/filtrovoltagem', $event)">Fontes Produtos Pq</a>
                        </li>
                       
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-diamond"></span><span class="mtext">Produtos</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/segmentosprod1', $event)">Segmentos 1</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/segmentosprod2', $event)">Segmentos 2</a>
                        </li>
                        <li style="display:none;">
                            <a @click="redirect_cabecalho('/controle/segmentosprod3', $event)">Segmentos 3</a>
                        </li>
                        <li style=" display:none">
                            <a @click="redirect_cabecalho('/controle/segmentosprod4', $event)">Segmentos 4</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/Produtos', $event)">Cad. Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/listaprodutos', $event)">Lista Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/itensavulsos', $event)">Itens Avulsos</a>
                        </li>
                        <li style="display:none;">
                            <a @click="redirect_cabecalho('/controle/tabelaspreco', $event)">Tabela Preços</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/cupons', $event)">Cupons</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/textosgerais', $event)">Textos Gerais</a>
                        </li>

                        <!--<li><a href="ProdSegmentos.html">Cad. Segmentos</a></li>

            <li><a href="ProdCatalogos.html">Catalogos</a></li>
            <li><a href="ProdListaProdutos.html">Lista Produtos</a></li>
            <li><a href="ProdCupons.html">Cupons</a></li>
            <li><a href="ProdTextosProdGerais.html">Textos Gerais</a></li>
            <li>
                <a href="ProdConfiguraAvulsos.html">Configura Avulsos</a>
            </li>
            <li>
                <a href="ProdConfiguraPromo.html">Configura Promoções</a>
            </li>
            <li>
                <a href="ProdConfiguraSegmentos.html">Configura Segmentos</a>
            </li>-->
                    </ul>
                </li>
                   <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-table"></span><span class="mtext">Alterações Em Massa</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/SetaAvulsos', $event)">Avulsos</a>
                        </li>
                         <li>
                            <a @click="redirect_cabecalho('/controle/SetaProdutosAvulsos', $event)">Produtos x Avulsos</a>
                        </li>
                         <li>
                            <a @click="redirect_cabecalho('/controle/SetaProdutosSegmento', $event)">Produtos x Segmento</a>
                        </li>
                         <li>
                            <a @click="redirect_cabecalho('/controle/SetaSegmentosProduto', $event)">Segmentos x Produto</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">   
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-shopping-basket-1"></span><span class="mtext">Pedidos</span>
                    </a>
                    <ul class="submenu">
                        <li><a @click="redirect_cabecalho('/controle/listapedidos', $event)">Lista de Pedidos</a></li>
                        <li v-if="!isLoveCestas"><a @click="redirect_cabecalho('/controle/pedidomanual', $event)">Pedido Manual</a></li>
                        <li v-else ><a @click="redirect_cabecalho('/controle/pedidomanualmensagem', $event)">Pedido Manual</a></li>
            <!-- <li><a href="PedPedidoEntregas.html">Entregas por dia</a></li> -->
                    </ul>
                </li>
                   <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-money"></span><span class="mtext">Financeiro</span>
                    </a>
                    <ul class="submenu">
                        <li><a @click="redirect_cabecalho('/controle/listapedidosfinanceiro', $event)">Lista de Pedidos Financeiro</a></li>
                        <li><a @click="redirect_cabecalho('/controle/taxas', $event)">Taxas</a></li>
                        <li><a @click="redirect_cabecalho('/controle/relatoriosvenda', $event)">Relatórios de Venda</a></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-calendar"></span><span class="mtext">Agenda</span>
                    </a>
                    <ul class="submenu">
                      <li><a @click="redirect_cabecalho('/controle/listaentregas', $event)">Entregas Analítico</a></li>
                        <li><a @click="redirect_cabecalho('/controle/motoristasentregas', $event)">Motoristas x Entregas</a></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-paint-brush"></span><span class="mtext">Personalizados</span>
                    </a>
                    <ul class="submenu">
                     
                        <li>
                            <a @click="redirect_cabecalho('/controle/mensagemavulsa', $event)">Mensagens Avulsas</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/fotoavulsa', $event)">Fotos Avulsas</a>
                        </li>
                         <!--<li>
                            <a @click="redirect_cabecalho('/controle/Bling', $event)">Integração Bling</a>
                        </li>-->
                         <!--<li>
                            <a @click="redirect_cabecalho('/controle/Ibr', $event)">Integração Ibr Sistemas</a>
                        </li>-->
                    </ul>
                </li>
           
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-credit-card"></span><span class="mtext">Conf. Pagamentos</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/configoperadora', $event)">Operadoras</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/configbanco', $event)">Bancos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/configpix', $event)">Pix</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-delivery-truck-2"></span><span class="mtext">Entregas</span>
                    </a>
                    <ul class="submenu">
                        <li style="display:none;">
                            <a @click="redirect_cabecalho('/controle/entregacorreio', $event)">Correios</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/entregahorario', $event)">Horários Geral</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/entregafrete', $event)">Opções de Frete</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/entregaexcecao', $event)">Exceções</a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-flash"></span><span class="mtext">Monta Home</span>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a @click="redirect_cabecalho('/controle/montabannerslide', $event)">Slider</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/montabannercarro', $event)">Carrossel Banner</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta1banner', $event)">1 Banner</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta2banner', $event)">2 Banner</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta3banner', $event)">3 Banner</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta4banner', $event)">4 Banner</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta6banner', $event)">6 Banner</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/montabannerlinha', $event)">Banner Linha</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/montaprodutocarro', $event)">Carrossel Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta4produtocarro', $event)">Carrossel 4 Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta1produto', $event)">1 Produto</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta2produto', $event)">2 Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta3produto', $event)">3 Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta4produto', $event)">4 Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/monta6produto', $event)">6 Produtos</a>
                        </li>
                        <li>
                          <a @click="redirect_cabecalho('/controle/montabannerproduto', $event)">Banner Com Lista Produtos</a>
                        </li>
                        <li>
                            <a @click="redirect_cabecalho('/controle/montahomeitem', $event)">Monta Itens Home</a>
                        </li>
                        
                    </ul>
                </li>
                <li class="dropdown">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-edit2"></span>
                        <span class="mtext">Segmentos</span>
                    </a>
                    <ul class="submenu">
                        <li v-if="Segmentacao > 0">
                            <a @click="redirect_cabecalho('/controle/segmentosinst1', $event)">Segmentação Nível 1</a>
                        </li>
                        <li v-if="Segmentacao > 1">
                            <a @click="redirect_cabecalho('/controle/segmentosinst2', $event)">Segmentação Nível 2</a>
                        </li>
                        <li v-if="Segmentacao > 2">
                            <a @click="redirect_cabecalho('/controle/segmentosinst3', $event)">Segmentação Nível 3</a>
                        </li>
                        <li v-if="Segmentacao > 3">
                            <a @click="redirect_cabecalho('/controle/segmentosinst4', $event)">
                                Segmentação Nível 4
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="dropdown"
                    v-for="Grupo in Grupos"
                    :key="Grupo.grupoTelaInstitucionalId">
                    <a href="javascript:;" class="dropdown-toggle">
                        <span class="micon dw dw-edit-2"></span><span class="mtext">{{ Grupo.nomeSegmentoTela }}</span>
                    </a>
                    <ul class="submenu">
                        <li v-for="Tela in Grupo.telas" :key="Tela.telaInstitucionalId">
                            <a @click="redirect_cabecalho(Tela.linkTela, $event)">
                                {{ Tela.nomeTela }}
                            </a>
                        </li>
                    </ul>
                </li>

                <!-- <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-edit2"></span
        ><span class="mtext">Institucional</span>
      </a>
      <ul class="submenu">
        <li><a href="InstSegmentos.html">Segmentos</a></li>
        <li>
          <a href="TelasInstitucionais.html">Telas Informações</a>
        </li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-meeting"></span
        ><span class="mtext">Redes Sociais</span>
      </a>
      <ul class="submenu">
        <li><a href="TelaRedes.html">Facebook</a></li>
        <li><a href="TelaRedes.html">Instagram</a></li>
        <li><a href="TelaRedes.html">WhatsApp</a></li>
        <li><a href="TelaRedes.html">Youtube</a></li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-startup"></span
        ><span class="mtext">SEO</span>
      </a>
      <ul class="submenu">
        <li><a href="MetaTagsInicial.html">Código Inicial Metas</a></li>
        <li><a href="MetaTagsPagina.html">Metas por Página</a></li>
        <li><a href="CodAnalytics.html">Código Analytics</a></li>
        <li><a href="CodHead.html">Códigos Head</a></li>
        <li><a href="CodBody.html">Códigos Body</a></li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-user-3"></span
        ><span class="mtext">Clientes</span>
      </a>
      <ul class="submenu">
        <li><a href="CliSegmentos.html">Segmentos</a></li>
        <li><a href="CliClientes.html">Clientes</a></li>
        <li><a href="CliArquivos.html">Arquivos</a></li>
        <li><a href="CliVideos.html">Vídeos</a></li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-list3"></span
        ><span class="mtext">Filtros</span>
      </a>
      <ul class="submenu child">
        <li><a href="ProdFiltroValores.html">Valores</a></li>
        <li><a href="ProdFiltroMarcas.html">Marcas</a></li>
        <li><a href="ProdFiltroTamanhos.html">Tamanhos</a></li>
        <li><a href="ProdFiltroCores.html">Cores</a></li>
        <li><a href="ProdFiltroVoltagens.html">Voltagens</a></li>
        <li>
          <a href="ProdFiltroCaracteristicas.html">Características</a>
        </li>
        <li><a href="ProdFiltroFabricas.html">Fábricas</a></li>
        <li><a href="ProdFiltroMateriaPrima.html">Matéria Prima</a></li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-diamond"></span
        ><span class="mtext">Produtos</span>
      </a>
      <ul class="submenu">
        <li><a href="ProdTabelaPrecos.html">Tabela Preços</a></li>
        <li><a href="ProdSegmentos.html">Cad. Segmentos</a></li>
        <li><a href="ProdItensAvulsos.html">Itens Avulsos</a></li>
        <li><a href="ProdCatalogos.html">Catalogos</a></li>
        <li><a href="ProdProdutos.html">Cad. Produtos</a></li>
        <li><a href="ProdListaProdutos.html">Lista Produtos</a></li>
        <li><a href="ProdCupons.html">Cupons</a></li>
        <li><a href="ProdTextosProdGerais.html">Textos Gerais</a></li>
        <li>
          <a href="ProdConfiguraAvulsos.html">Configura Avulsos</a>
        </li>
        <li>
          <a href="ProdConfiguraPromo.html">Configura Promoções</a>
        </li>
        <li>
          <a href="ProdConfiguraSegmentos.html">Configura Segmentos</a>
        </li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-credit-card"></span
        ><span class="mtext">Conf. Pagamentos</span>
      </a>
      <ul class="submenu">
        <li><a href="PagOperadoras.html">Operadoras</a></li>
        <li><a href="PagBancos.html">Bancos</a></li>
        <li><a href="PagPix.html">Pix</a></li>
      </ul>
    </li>
    <li class="dropdown">
      <a href="javascript:;" class="dropdown-toggle">
        <span class="micon dw dw-shopping-basket-1"></span
        ><span class="mtext">Pedidos</span>
      </a>
      <ul class="submenu">
        <li><a href="PedListaPedidos.html">Lista de Pedidos</a></li>
        <li><a href="PedPedidoManual.html">Pedido Manual</a></li>
        <li><a href="PedPedidoEntregas.html">Entregas por dia</a></li>
      </ul>
    </li> -->
            </ul>
        </div>
      </div>
    </div>
    <div class="mobile-menu-overlay"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        Admin: false,
      usuarioTmp: {
        emailUsuario: "",
        senha: "",
      },
      Segmentacao: 0,
      Grupos: JSON.parse(localStorage.getItem("menusgcad")),
      ImagemUrl: "",
      isLoveCestas: false,
    };
  },
  methods: {
    redirect_cabecalho(path, event)
    {
      localStorage.menu = event.target.innerText;
      localStorage.menupai = event.target.parentElement.parentElement.parentElement.firstChild.lastChild.innerText;
      var as = document.getElementsByTagName('A');
      as.forEach(element => {
        element.classList.remove("active");
      });
      event.target.className = "active";
      this.$router.push(path);
    },
      CheckLogin() {
          this.Admin = false;
      let usuario = {};
      this.usuarioTmp = JSON.parse(this.$cookie.get("usuarioAvance"));
      if (this.usuarioTmp != null) {
        this.$http
          .get(this.$apiUrl + "/usuarios/" + this.usuarioTmp.usuarioId)
          .then(
            (response) => {
              // get body data
              usuario = response.body;
              this.ImagemUrl = usuario.urlImagem;

              if (
                this.usuarioTmp.emailUsuario == usuario.emailUsuario &&
                this.usuarioTmp.senha == usuario.senha
              ) {
                //logado show de bola
                  this.usuarioTmp.imagemUsuario = usuario.urlImagem;
                  if (this.usuarioTmp.usuarioId == 1) {
                      this.Admin = true;
                  }
                  if(usuario.tipoUsuario != null && usuario.tipoUsuario.tipoUsuarioId == 3){
                    //motorista
                    document.getElementById("accordion-menu").style.display = "none";
                    document.getElementById("menuMotorista").style.display = "block";
                  }
              } else {
                this.$cookie.delete("usuarioAvance");
                this.$redirect_reload("/controle/login");
              }
            },
            (response) => {
              // error callback
              console.log(response);
              this.$cookie.delete("usuarioAvance");
              this.$redirect_reload("/controle/login");
            }
          );
      } else {
        this.$redirect_reload("/controle/login");
      }
    },
    Logout() {
      this.$cookie.delete("usuarioAvance");
      this.$cookie.delete("update1");
      this.$redirect_reload("/controle/login");
    },
    CarregaScripts() {
      var scripts = ["/vendors/scripts/core.js"];
      var i = 0;
      for (i = 0; i < scripts.length; i++) {
        let script = document.createElement("script");
        script.setAttribute("src", scripts[i]);
        document.head.appendChild(script);
      }
    },
  },
  created() {
    //document.title = "SGCAD 21 - LOCALHOST";
    //document.title = "SGCAD 21 - Sangelli";
    //document.title = "SGCAD 21 - Kemp";
    //document.title = "SGCAD 21 - DryStore";
    document.title = "SGCAD - Love Cestas";
    //document.title = "SGCAD 21 - Ei Compre";
    //document.title = "SGCAD 21 - Jus Virtua";
    //document.title = "SGCAD - La Bodeguita";
    //document.title = "SGCAD 21 - Comercial Fazenda Vila Nova";
    //document.title = "SGCAD - Maki & Letícia Hortifrutigranjeiros";
    //document.title = "SGCAD - FA Mundo Animal";
    //document.title = "SGCAD - Basic Store Kids";
    //document.title = "SGCAD - Termaco";
    //document.title = "SGCAD - Proeed";
    //document.title = "SGCAD - Eurolatte";
    //document.title = "SGCAD - Eisteel Sorvetes";
    //document.title = "SGCAD - ACE Acoplamentos";
    //document.title = "SGCAD - Pet Em Movimento";
    //document.title = "SGCAD - Artur e Aline Negócios Imobíliarios";
    /*document.title = "SGCAD - Mundo Do Site";*/

    if(document.title == "SGCAD - Love Cestas")
    {
      this.isLoveCestas = true;
    }

    this.CheckLogin();
    try {
      this.Grupos = JSON.parse(localStorage.getItem("menusgcad"));
    } catch {
      this.$atualiza_cabecalho();
      this.Grupos = JSON.parse(localStorage.getItem("menusgcad"));
    }

    console.log("LIDO DO COOKIE");
    console.log(localStorage.getItem("menusgcad"));
    if (this.Grupos == null) {
      this.$atualiza_cabecalho();
    }
    this.$http
      .get(this.$apiUrl + "/configuraInstitucional")
      .then((response) => {
        var i = 0;
        console.log("DEBUGA SEGS:");
        console.log(response.body);
        for (i = 0; i < response.body.length; i++) {
          var tmp = response.body[i];
          if (tmp != null) {
            if (tmp.segmentoInstNivel1) {
              if (this.Segmentacao < 1) this.Segmentacao = 1;
            }
            if (tmp.segmentoInstNivel2) {
              if (this.Segmentacao < 2) this.Segmentacao = 2;
            }
            if (tmp.segmentoInstNivel3) {
              if (this.Segmentacao < 3) this.Segmentacao = 3;
            }
            if (tmp.segmentoInstNivel4) {
              if (this.Segmentacao < 4) this.Segmentacao = 4;
            }
          }
        }
      });

     

  },
  mounted() {
     if(localStorage.menu != null && localStorage.menu.toString().length > 0 && localStorage.menupai != null && localStorage.menupai.length > 0)
      {
        var cont = 0;
        var subs = document.getElementsByClassName("submenu");
        for(cont = 0; cont < subs.length; cont++)
        {
          var sub = subs[cont];
          if(sub.parentElement.firstChild.lastChild.innerText == localStorage.menupai)
          {
            sub.children.forEach(element => {
            if(element.firstChild.innerText != localStorage.menu.toString())
              {
                element.firstChild.className = "";
                console.log("0");
              }
              else
              {
                sub.style.display = "block";
                element.firstChild.className = "active"
                console.log("1");
              }
          });
          }
        }
      }

  },
  head: {
    script: [
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/jquery-3.2.1.min.js",
      //   body: true,
      // },
      // { type: "text/javascript", src: "/vendors2/popper.min.js", body: true },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/revolution/js/extensions/revolution.extension.slideanims.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/revolution/js/extensions/revolution.extension.slideanims.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/counterup/jquery.waypoints.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/counterup/jquery.counterup.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/owl-carousel/owl.carousel.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/image-dropdown/jquery.dd.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/isotope/imagesloaded.pkgd.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/isotope/isotope.pkgd.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/magnify-popup/jquery.magnific-popup.min.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/vertical-slider/js/jQuery.verticalCarousel.js",
      //   body: true,
      // },
      // {
      //   type: "text/javascript",
      //   src: "/vendors2/jquery-ui/jquery-ui.js",
      //   body: true,
      // },
      // { type: "text/javascript", src: "/vendors2/theme.js", body: true },
    ],
  },
};
</script>

<style>
.tiraseta::after{
  content: '';
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import "/vendors/styles/core.css";
@import "/vendors/styles/icon-font.min.css";
@import "/src/plugins/datatables/css/dataTables.bootstrap4.min.css";
@import "/src/plugins/datatables/css/responsive.bootstrap4.min.css";
@import "/vendors/styles/style.css";
@import "~vue-select/dist/vue-select.css";
@import "/src/plugins/jquery-steps/jquery.steps.css/";


a {
  cursor: pointer;
}
.card-box {
  overflow-x: scroll;
}
.dataTables_wrapper {
  padding: 50px 0px;
}
.ql-container{
  min-height: -webkit-fill-available !important;
  height: 200px !important;
}
</style>