<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="pd-20 card-box mb-30">
          <div class="clearfix">
            <div class="pull-left">
              <h4 class="text-black h4">Configurar Telas Institucionais</h4>
              <p class="mb-30">
                Configure os campos serão utilizados nas telas institucionais do
                sistema SGCAD.
              </p>
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="tab">
                    <div class="row clearfix">
                      <div class="col-md-3 col-sm-12">
                        <ul
                          class="nav flex-column vtabs nav-tabs"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <v-select
                              v-model="GrupoId"
                              :options="Grupos"
                              single-line
                              :reduce="(x) => x.grupoTelaInstitucionalId"
                              @input="MudaGrupo"
                              label="nomeSegmentoTela"
                              class="style-chooser"
                            ></v-select>
                          </li>
                          <li
                            v-for="(Tela, index) in Telas"
                            :key="index"
                            style="padding: 10px"
                          >
                            <hr />
                            <a
                              @click="Seleciona(Tela.telaInstitucionalId)"
                              v-bind:class="
                                Configuracao.tela != null &&
                                Configuracao.tela.telaInstitucionalId ==
                                  Tela.telaInstitucionalId
                                  ? 'actv'
                                  : ''
                              "
                            >
                              {{ Tela.nomeTela }}
                            </a>
                            <hr />
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-9 col-sm-12">
                        <div class="tab-content">
                          <div class="tab-pane fade show active">
                            <!-- <div  v-for="Segmento in Segmentos" :key="Segmento.segmentoInstTelaId"
                            v-bind:class="{ 'tab-pane fade': true, 'show active': Segmento.segmentoInstTelaId == Segmentos[0].segmentoInstTelaId}"
                            v-bind:id="Segmento.segmentoInstTelaId"
                            role="tabpanel"
                          > -->
                            <div
                              class="pd-20"
                              v-if="Configuracao != null && Configuracao != {}"
                            >
                              <div class="row">
                                <div class="col-md-12">
                                  <h3>
                                    Tela:
                                    {{
                                      Configuracao != null &&
                                      Configuracao.tela != null
                                        ? Configuracao.tela.nomeTela
                                        : "#"
                                    }}
                                  </h3>
                                  <br />
                                  <div
                                    class="custom-control custom-checkbox mb-5"
                                  >
                                    <input
                                      type="checkbox"
                                      v-model="Configuracao.multi"
                                      class="custom-control-input"
                                      id="customCheck1-2"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customCheck1-2"
                                      >Multi-Documento</label
                                    >
                                  </div>
                                  <table>
                                    <colgroup>
                                      <col span="1" style="width: 10%" />
                                      <col
                                        span="1"
                                        class="hidden-xs"
                                        style="width: 20%"
                                      />
                                      <col span="1" style="width: 50%" />
                                      <col span="1" style="width: 30%" />
                                    </colgroup>
                                    <thead>
                                      <th>Ativo</th>
                                      <th class="hidden-xs">Campo</th>
                                      <th>Nome</th>
                                      <th>Largura</th>
                                    </thead>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.visivel"
                                            class="custom-control-input"
                                            id="customCheck1-9"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-9"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Visível</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.visivelLabel"
                                          placeholder="Visível"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.visivelCol"
                                          @change="
                                            Configuracao.visivelCol = parseInt(
                                              Configuracao.visivelCol,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.ordem"
                                            class="custom-control-input"
                                            id="customCheck1-10"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-10"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Ordem</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.ordemLabel"
                                          placeholder="Ordem"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.ordemCol"
                                          @change="Configuracao.ordemCol.length > 0
                                            Configuracao.ordemCol = parseInt(
                                              Configuracao.ordemCol,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.destaque"
                                            class="custom-control-input"
                                            id="customCheck1-11"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-11"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Destaque</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.destaqueLabel"
                                          placeholder="Destaque"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.destaqueCol"
                                          @change="
                                            Configuracao.destaqueCol = parseInt(
                                              Configuracao.destaqueCol,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.destaqueExtra"
                                            class="custom-control-input"
                                            id="customCheck1-12"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-12"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Destaque Extra</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.destaqueExtraLabel
                                          "
                                          placeholder="Destaque Extra"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="
                                            Configuracao.destaqueExtraCol
                                          "
                                          @change="
                                            Configuracao.destaqueExtraCol =
                                              parseInt(
                                                Configuracao.destaqueExtraCol,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="
                                              Configuracao.segmentoInstNivel1
                                            "
                                            class="custom-control-input"
                                            id="customCheck1-13"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-13"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">
                                        Segmento Nível 1
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel1Label
                                          "
                                          placeholder="Segmento 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel1Col
                                          "
                                          @change="
                                            Configuracao.segmentoInstNivel1Col =
                                              parseInt(
                                                Configuracao.segmentoInstNivel1Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="
                                              Configuracao.segmentoInstNivel2
                                            "
                                            class="custom-control-input"
                                            id="customCheck1-14"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-14"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">
                                        Segmento Nível 2
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel2Label
                                          "
                                          placeholder="Segmento 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel2Col
                                          "
                                          @change="
                                            Configuracao.segmentoInstNivel2Col =
                                              parseInt(
                                                Configuracao.segmentoInstNivel2Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="
                                              Configuracao.segmentoInstNivel3
                                            "
                                            class="custom-control-input"
                                            id="customCheck1-15"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-15"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">
                                        Segmento Nível 3
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel3Label
                                          "
                                          placeholder="Segmento 3"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel3Col
                                          "
                                          @change="
                                            Configuracao.segmentoInstNivel3Col =
                                              parseInt(
                                                Configuracao.segmentoInstNivel3Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="
                                              Configuracao.segmentoInstNivel4
                                            "
                                            class="custom-control-input"
                                            id="customCheck1-16"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-16"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">
                                        Segmento Nível 4
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel4Label
                                          "
                                          placeholder="Segmento 4"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="
                                            Configuracao.segmentoInstNivel4Col
                                          "
                                          @change="
                                            Configuracao.segmentoInstNivel4Col =
                                              parseInt(
                                                Configuracao.segmentoInstNivel4Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.nome"
                                            class="custom-control-input"
                                            id="customCheck1-1"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-1"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Nome</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.nomeLabel"
                                          placeholder="Nome"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.nomeCol"
                                          @change="
                                            Configuracao.nomeCol = parseInt(
                                              Configuracao.nomeCol,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.data1"
                                            class="custom-control-input"
                                            id="customCheck1-47"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-47"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Data 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.data1Label"
                                          placeholder="Data 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.data1Col"
                                          @change="
                                            Configuracao.data1Col = parseInt(
                                              Configuracao.data1Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.referencia"
                                            class="custom-control-input"
                                            id="customCheck1-3"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-3"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Referência</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.referenciaLabel"
                                          placeholder="Referência"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.referenciaCol"
                                          @change="
                                            Configuracao.referenciaCol =
                                              parseInt(
                                                Configuracao.referenciaCol,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra1"
                                            class="custom-control-input"
                                            id="customCheck1-17"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-17"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra1Label
                                          "
                                          placeholder="Campo extra 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra1Col"
                                          @change="
                                            Configuracao.campoExtra1Col =
                                              parseInt(
                                                Configuracao.campoExtra1Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra2"
                                            class="custom-control-input"
                                            id="customCheck1-18"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-18"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra2Label
                                          "
                                          placeholder="Campo extra 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra2Col"
                                          @change="
                                            Configuracao.campoExtra2Col =
                                              parseInt(
                                                Configuracao.campoExtra2Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra3"
                                            class="custom-control-input"
                                            id="customCheck1-19"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-19"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 3</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra3Label
                                          "
                                          placeholder="Campo extra 3"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra3Col"
                                          @change="
                                            Configuracao.campoExtra3Col =
                                              parseInt(
                                                Configuracao.campoExtra3Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra4"
                                            class="custom-control-input"
                                            id="customCheck1-20"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-20"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 4</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra4Label
                                          "
                                          placeholder="Campo extra 4"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra4Col"
                                          @change="
                                            Configuracao.campoExtra4Col =
                                              parseInt(
                                                Configuracao.campoExtra4Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra5"
                                            class="custom-control-input"
                                            id="customCheck1-21"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-21"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 5</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra5Label
                                          "
                                          placeholder="Campo extra 5"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra5Col"
                                          @change="
                                            Configuracao.campoExtra5Col =
                                              parseInt(
                                                Configuracao.campoExtra5Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra6"
                                            class="custom-control-input"
                                            id="customCheck1-22"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-22"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 6</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra6Label
                                          "
                                          placeholder="Campo extra 6"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra6Col"
                                          @change="
                                            Configuracao.campoExtra6Col =
                                              parseInt(
                                                Configuracao.campoExtra6Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.campoExtra7"
                                            class="custom-control-input"
                                            id="customCheck1-23"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-23"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Campo Extra 7</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="
                                            Configuracao.campoExtra7Label
                                          "
                                          placeholder="Campo extra 7"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.campoExtra7Col"
                                          @change="
                                            Configuracao.campoExtra7Col =
                                              parseInt(
                                                Configuracao.campoExtra7Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.resumo1"
                                            class="custom-control-input"
                                            id="customCheck1-24"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-24"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Resumo 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.resumo1Label"
                                          placeholder="Resumo 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.resumo1Col"
                                          @change="
                                            Configuracao.resumo1Col = parseInt(
                                              Configuracao.resumo1Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.resumo2"
                                            class="custom-control-input"
                                            id="customCheck1-25"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-25"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Resumo 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.resumo2Label"
                                          placeholder="Resumo 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.resumo2Col"
                                          @change="
                                            Configuracao.resumo2Col = parseInt(
                                              Configuracao.resumo2Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.descricao1"
                                            class="custom-control-input"
                                            id="customCheck1-26"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-26"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Descrição 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.descricao1Label"
                                          placeholder="Descrição 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.descricao1Col"
                                          @change="
                                            Configuracao.descricao1Col =
                                              parseInt(
                                                Configuracao.descricao1Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.descricao2"
                                            class="custom-control-input"
                                            id="customCheck1-27"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-27"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Descrição 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.descricao2Label"
                                          placeholder="Descrição 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.descricao2Col"
                                          @change="
                                            Configuracao.descricao2Col =
                                              parseInt(
                                                Configuracao.descricao2Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.descricao3"
                                            class="custom-control-input"
                                            id="customCheck1-28"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-28"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Descrição 3</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.descricao3Label"
                                          placeholder="Descrição 3"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.descricao3Col"
                                          @change="
                                            Configuracao.descricao3Col =
                                              parseInt(
                                                Configuracao.descricao3Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.descricao4"
                                            class="custom-control-input"
                                            id="customCheck1-29"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-29"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Descrição 4</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.descricao4Label"
                                          placeholder="Descrição 4"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.descricao4Col"
                                          @change="
                                            Configuracao.descricao4Col =
                                              parseInt(
                                                Configuracao.descricao4Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.descricao5"
                                            class="custom-control-input"
                                            id="customCheck1-30"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-30"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Descrição 5</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.descricao5Label"
                                          placeholder="Descrição 5"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.descricao5Col"
                                          @change="
                                            Configuracao.descricao5Col =
                                              parseInt(
                                                Configuracao.descricao5Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.descricao6"
                                            class="custom-control-input"
                                            id="customCheck1-33"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-33"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Descrição 6</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.descricao6Label"
                                          placeholder="Descrição 6"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.descricao6Col"
                                          @change="
                                            Configuracao.descricao6Col =
                                              parseInt(
                                                Configuracao.descricao6Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.textoBtn1"
                                            class="custom-control-input"
                                            id="customCheck1-34"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-34"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Texto Botão 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.textoBtn1Label"
                                          placeholder="Texto Botão 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.textoBtn1Col"
                                          @change="
                                            Configuracao.textoBtn1Col =
                                              parseInt(
                                                Configuracao.textoBtn1Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.textoBtn2"
                                            class="custom-control-input"
                                            id="customCheck1-35"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-35"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Texto Botão 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.textoBtn2Label"
                                          placeholder="Texto Botão 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.textoBtn2Col"
                                          @change="
                                            Configuracao.textoBtn2Col =
                                              parseInt(
                                                Configuracao.textoBtn2Col,
                                                10
                                              )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.linkBtn1"
                                            class="custom-control-input"
                                            id="customCheck1-37"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-37"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Link Botão 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.linkBtn1Label"
                                          placeholder="Link Botão 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.linkBtn1Col"
                                          @change="
                                            Configuracao.linkBtn1Col = parseInt(
                                              Configuracao.linkBtn1Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.linkBtn2"
                                            class="custom-control-input"
                                            id="customCheck1-38"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-38"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Link Botão 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.linkBtn2Label"
                                          placeholder="Link Botão 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.linkBtn2Col"
                                          @change="
                                            Configuracao.linkBtn2Col = parseInt(
                                              Configuracao.linkBtn2Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.cor1"
                                            class="custom-control-input"
                                            id="customCheck1-39"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-39"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Cor 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.cor1Label"
                                          placeholder="Cor 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.cor1Col"
                                          @change="
                                            Configuracao.cor1Col = parseInt(
                                              Configuracao.cor1Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.cor2"
                                            class="custom-control-input"
                                            id="customCheck1-40"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-40"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Cor 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.cor2Label"
                                          placeholder="Cor 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.cor2Col"
                                          @change="
                                            Configuracao.cor2Col = parseInt(
                                              Configuracao.cor2Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.cor3"
                                            class="custom-control-input"
                                            id="customCheck1-41"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-41"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Cor 3</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.cor3Label"
                                          placeholder="Cor 3"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.cor3Col"
                                          @change="
                                            Configuracao.cor3Col = parseInt(
                                              Configuracao.cor3Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.cor4"
                                            class="custom-control-input"
                                            id="customCheck1-42"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-42"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Cor 4</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.cor4Label"
                                          placeholder="Cor 4"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.cor4Col"
                                          @change="
                                            Configuracao.cor4Col = parseInt(
                                              Configuracao.cor4Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.valor1"
                                            class="custom-control-input"
                                            id="customCheck1-43"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-43"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Valor 1</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.valor1Label"
                                          placeholder="Valor 1"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.valor1Col"
                                          @change="
                                            Configuracao.valor1Col = parseInt(
                                              Configuracao.valor1Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.valor2"
                                            class="custom-control-input"
                                            id="customCheck1-44"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-44"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Valor 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.valor2Label"
                                          placeholder="Valor 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.valor2Col"
                                          @change="
                                            Configuracao.valor2Col = parseInt(
                                              Configuracao.valor2Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.valor3"
                                            class="custom-control-input"
                                            id="customCheck1-45"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-45"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Valor 3</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.valor3Label"
                                          placeholder="Valor 3"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.valor3Col"
                                          @change="
                                            Configuracao.valor3Col = parseInt(
                                              Configuracao.valor3Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.valor4"
                                            class="custom-control-input"
                                            id="customCheck1-46"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-46"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Valor 4</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.valor4Label"
                                          placeholder="Valor 4"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.valor4Col"
                                          @change="
                                            Configuracao.valor4Col = parseInt(
                                              Configuracao.valor4Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.data2"
                                            class="custom-control-input"
                                            id="customCheck1-48"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-48"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Data 2</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.data2Label"
                                          placeholder="Data 2"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.data2Col"
                                          @change="
                                            Configuracao.data2Col = parseInt(
                                              Configuracao.data2Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.data3"
                                            class="custom-control-input"
                                            id="customCheck1-49"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-49"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Data 3</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.data3Label"
                                          placeholder="Data 3"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.data3Col"
                                          @change="
                                            Configuracao.data3Col = parseInt(
                                              Configuracao.data3Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div
                                          class="
                                            custom-control custom-checkbox
                                            mb-5
                                          "
                                        >
                                          <input
                                            type="checkbox"
                                            v-model="Configuracao.data4"
                                            class="custom-control-input"
                                            id="customCheck1-50"
                                          />
                                          <label
                                            class="custom-control-label"
                                            for="customCheck1-50"
                                          ></label>
                                        </div>
                                      </td>
                                      <td class="hidden-xs">Data 4</td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="Configuracao.data4Label"
                                          placeholder="Data 4"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          min="0"
                                          max="12"
                                          class="form-control"
                                          v-model="Configuracao.data4Col"
                                          @change="
                                            Configuracao.data4Col = parseInt(
                                              Configuracao.data4Col,
                                              10
                                            )
                                          "
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                  <br />
                                  <div class="row" style="width:100%">
                                    <div
                                      class="
                                        custom-control custom-checkbox
                                        mb-5
                                      "
                                    >
                                      <input
                                        type="checkbox"
                                        v-model="Configuracao.uploadImagem"
                                        class="custom-control-input"
                                        id="customCheck1-99"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck1-99"
                                        >Upload de Imagem</label
                                      >
                                    </div>
                                    <div style="float:right" class="mb-5 custom-control">
                                      <label>Quantidade: </label>
                                      <input  @change="
                                            Configuracao.qtdeImagem = parseInt(
                                              Configuracao.qtdeImagem,
                                              10
                                            )
                                          " type="number" class="inputimagem" style="width:50px;margin-left:10px" v-model="Configuracao.qtdeImagem">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div style="float:right" class="mb-5 custom-control">
                                      <label>Tamanho (Largura x Altura): </label>
                                      <input  @change="
                                            Configuracao.larguraImagem = parseInt(
                                              Configuracao.larguraImagem,
                                              10
                                            )
                                          " type="number" class="inputimagem" style="width:100px;margin-left:10px" v-model="Configuracao.larguraImagem">
                                      x
                                      <input @change="
                                            Configuracao.alturaImagem = parseInt(
                                              Configuracao.alturaImagem,
                                              10
                                            )
                                          " type="number" class="inputimagem" style="width:100px;" v-model="Configuracao.alturaImagem">
                                    </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 text-center">
                                  <button
                                    @click="
                                      $redirect_reload(
                                        '/controle/configurainstitucional'
                                      )
                                    "
                                    type="button"
                                    class="btn btn-link"
                                  >
                                    Cancelar
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-success"
                                    id="sa-custom-position"
                                    @click="Salvar"
                                  >
                                    Salvar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
//import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      Grupos: [],
      GrupoId: 0,
      Telas: [],
      Tela: {},
      Configuracao: {},
    };
  },
  methods: {
    Seleciona(id) {
      this.$http
        .get(this.$apiUrl + "/ConfiguraInstitucional/tela/" + id)
        .then((response) => {
          this.Configuracao = response.body;
        });
    },
    Salvar() {
      if (
        this.Configuracao != {} &&
        this.Configuracao.configuraInstitucionalId > 0
      ) {
        this.$http
          .put(
            this.$apiUrl +
              "/configurainstitucional/" +
              this.Configuracao.configuraInstitucionalId,
              this.Configuracao
          )
          .then(
            (response) => {
              if (response.ok) {
                this.$mensagem_sucesso(
                  "Configuração da tela " +
                    this.Configuracao.tela.nomeTela +
                    " alterada com sucesso"
                );
              }
            },
            (error) => {
              if (!error.ok) {
                this.$mensagem_erro(error.bodyText);
              }
            }
          );
      }
    },
    MudaGrupo() {
      if (this.GrupoId > 0) {
        this.$http
          .get(this.$apiUrl + "/TelaInstitucional/grupo/" + this.GrupoId)
          .then((response) => {
            this.Telas = response.body;
          });
      }
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/grupoTelaInstitucional")
      .then((response) => {
        this.Grupos = response.body;
      });
  },
};
/*
 na o segmentoinst
 telaacesso string link
 crirar tela configura institucional com bool e label
 criar grupo de telas e tela instiucional
*/
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  margin: 10px;
}
td {
  padding: 10px;
}
tr {
  border-bottom: 1px solid rgb(102, 101, 101);
}
.actv {
  font-weight: bolder;
  text-decoration: underline;
}
.inputimagem{
  border: 1px solid rgb(189, 189, 189);
  border-radius: 3px;
}
@media all and (max-width: 600px) {
  /* styles here */
  .hidden-xs {
    display: none;
  }
}
</style>

