<template>
  
    <!-- <router-view></router-view> -->
    <div>
        <Cabecalho v-if="checkCabecalho()"></Cabecalho>
        <router-view :key="$route.fullPath"></router-view>
        <Rodape></Rodape>
    </div>
    
    
  
</template>

<script>
import Cabecalho from "./components/_shared/Cabecalho.vue";
import Rodape from "./components/_shared/Rodape.vue";

export default{
    components: {
    Cabecalho,
    Rodape,
  },
  mounted(){
    
  },
   methods: {
            checkCabecalho() {
                if (this.$route.fullPath == '/controle/login') {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
  
}
</script>

<style>



/* @import '/vendors/scripts/core.js';
@import '/vendors/scripts/script.min.js';
@import '/vendors/scripts/process.js';
@import '/vendors/scripts/layout-settings.js';
@import '/assets/plugins/apexcharts/apexcharts.min.js'; */
/*     
    
    <script src="vendors/scripts/layout-settings.js"></script>
    <script src="src/plugins/apexcharts/apexcharts.min.js"></script>
    <script src="src/plugins/datatables/js/jquery.dataTables.min.js"></script>
    <script src="src/plugins/datatables/js/dataTables.bootstrap4.min.js"></script>
    <script src="src/plugins/datatables/js/dataTables.responsive.min.js"></script>
    <script src="src/plugins/datatables/js/responsive.bootstrap4.min.js"></script>
    <script src="vendors/scripts/dashboard.js"></script> */
</style>
