<template>
  <div>
    <!-- <Cabecalho></Cabecalho> -->

    <div class="main-container">
      <div class="pd-ltr-20">
        <div class="faq-wrap">
          <ul>
            <li>
              Nome Empresa: <input type="text" v-model="Contato.NomeEmpresa" />
            </li>
            <li>Assunto: <input type="text" v-model="Contato.Assunto" /></li>
            <li>
              Destinatario(email):
              <input type="text" v-model="Contato.Destinatario" />
            </li>
            <li>
              Resposta(email):
              <input type="text" v-model="Contato.EmailResposta" />
            </li>
            <li>Texto: <textarea v-model="Contato.TextoEmail"></textarea></li>
            <li>
              <input type="file" id="arquivo" />
              <br /><br />
              <button type="button" @click="teste">Enviar</button>
            </li>
          </ul>
        </div>
        <!-- <Rodape></Rodape> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

export default {
  data() {
    return {
      Contato: {
        NomeEmpresa: "nome",
        Assunto: "Assunto",
        Destinatario: "joaokussler@gmail.com",
        EmailResposta: "joao.vitor@zepol.com",
        TextoEmail: "AAAAAABBBBBBBCCCCCCCCCCC",
        Anexo: "",
        NomeAnexo: "",
        TipoAnexo: "",
      },
      nome: "",
      update: "",
    };
  },
  components: {
    // Cabecalho,
    // Rodape,
  },
  methods: {
    teste() {
      var arquivos = document.getElementById("arquivo").files;
      //SE TIVER ANEXO:
      if (arquivos != null && arquivos.length > 0) {
        var arq = "";
        var reader = new FileReader();
        var arquivoAnexo = arquivos[arquivos.length - 1];
        reader.readAsDataURL(arquivoAnexo);
        reader.onload = () => {
          arq = reader.result;
          this.Contato.Anexo = arq.toString().split(",")[1];
          this.Contato.NomeAnexo = arquivoAnexo.name;
          this.Contato.TipoAnexo = arquivoAnexo.type;
          this.$http
            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
            .then(
              (response) => {
                if (response.ok) alert("Enviado");
              },
              (error) => {
                alert(error.bodyText);
              }
            );
        };
      }
      else
      {
        //SE NAO TIVER ANEXO:
         this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
          .then(response => {
            if(response.ok)
              alert("Enviado");
          },error => {
            alert(error.bodyText)
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
