<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <h4 class="text-black h4" style="padding-left: 10px">Tela:</h4>
                <v-select
                  v-model="TelaSelecionada"
                  :options="TelasSegmento"
                  single-line
                  :reduce="(x) => x.configuraInstitucionalId"
                  @input="CarregaTela"
                  label="nomeTela"
                  class="col-md-4"
                ></v-select>
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4
                        class="text-black h4"
                        v-if="ConfiguraTela.tela != null"
                      >
                        Cadastro de
                        {{ ConfiguraSegmento.nomeSegmentoCliente }} da tela
                        {{ ConfiguraTela.tela.nomeTela }}
                      </h4>
                      <p class="mb-30" v-if="ConfiguraTela.tela != null">
                        Tela para cadastro dos
                        {{ ConfiguraSegmento.nomeSegmentoCliente }} da tela
                        {{ ConfiguraTela.tela.nomeTela }}.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <!-- <div class="col-md-4">
                      <div class="form-group">
                        <label>Nivel 1</label>
                        <select class="custom-select col-12">
                          <option selected="">Selecione...</option>
                          <option value="1">Segmento Nivel 1</option>
                          <option value="2">Segmento Nivel 1</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nivel 2</label>
                        <select class="custom-select col-12">
                          <option selected="">Selecione...</option>
                          <option value="1">Segmento Nivel 2</option>
                          <option value="2">Segmento Nivel 2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Nivel 3</label>
                        <select class="custom-select col-12">
                          <option selected="">Selecione...</option>
                          <option value="1">Segmento Nivel 3</option>
                          <option value="2">Segmento Nivel 3</option>
                        </select>
                      </div>
                    </div> -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >Nome
                          {{ ConfiguraSegmento.nomeSegmentoCliente }}</label
                        >
                        <input
                          class="form-control mb-5"
                          type="text"
                          v-bind:placeholder="
                            'Nome ' + ConfiguraSegmento.nomeSegmentoCliente
                          "
                          v-model="Segmento.nomeSegmento"
                        />
                      </div>
                    </div>
                    <div class="col-md-2" v-if="ConfiguraSegmento.visivel">
                      <div class="form-group">
                        <label>Visível</label>
                        <v-select
                          v-model="Segmento.visivel"
                          :options="[
                            { nome: 'Sim', visivel: true },
                            { nome: 'Não', visivel: false },
                          ]"
                          single-line
                          :reduce="(x) => x.visivel"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2" v-if="ConfiguraSegmento.destaque">
                      <div class="form-group">
                        <label>Destaque</label>
                        <v-select
                          v-model="Segmento.destaque"
                          :options="[
                            { nome: 'Sim', destaque: true },
                            { nome: 'Não', destaque: false },
                          ]"
                          single-line
                          :reduce="(x) => x.destaque"
                          label="nome"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2" v-if="ConfiguraSegmento.ordem">
                      <div class="form-group">
                        <label>Ordem</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Ordem"
                          v-model="Segmento.ordem"
                        />
                      </div>
                    </div>

                    <div class="col-md-12" v-if="ConfiguraSegmento.resumo">
                      <div class="form-group">
                        <label>Resumo</label>
                        <quill-editor
                          v-model="Segmento.resumo"
                          placeholder="Resumo..."
                        ></quill-editor>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="ConfiguraSegmento.descricao_1">
                      <div class="form-group">
                        <label>Descrição 1</label>
                        <quill-editor
                          v-model="Segmento.descricao1"
                          placeholder="Descrição 1..."
                        ></quill-editor>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="ConfiguraSegmento.descricao_2">
                      <div class="form-group">
                        <label>Descrição 2</label>
                        <quill-editor
                          v-model="Segmento.descricao2"
                          placeholder="Descrição 2..."
                        ></quill-editor>
                      </div>
                    </div>

                    <div
                      class="col-md-8"
                      v-if="ConfiguraSegmento.uploadImg == true && SegmentoId > 0"
                    >
                      <div class="form-group">
                        <label v-if="ConfiguraSegmento.qtdeImagem != null && ConfiguraSegmento.qtdeImagem > 1"
                          >{{ ConfiguraSegmento.qtdeImagem }} Imagens -
                          {{
                            ConfiguraSegmento.larguraImg +
                            "x" +
                            ConfiguraSegmento.alturaImg
                          }}</label
                        >
                        <label v-else
                          >Imagem -
                          {{
                            ConfiguraSegmento.larguraImg +
                            "x" +
                            ConfiguraSegmento.alturaImg
                          }}
                        </label>
                        <input
                          v-if="ConfiguraSegmento.qtdeImagem > 1"
                          @change="Upload"
                          type="file"
                          class="form-control-file form-control height-auto"
                          multiple
                        />
                        <input
                          v-else
                          @change="Upload"
                          type="file"
                          class="form-control-file form-control height-auto"
                        />
                        <img
                          src="/images/cloud-upload.gif"
                          v-if="Enviando > Enviado"
                        />
                        <div v-else class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">imagem</th>
                                <th scope="col">Ordem</th>
                                <th scope="col">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="Imagem in Imagens"
                                :key="Imagem.segmentoInstNivel1ImgId"
                              >
                                <td>
                                  <img
                                    style="max-width: 90px"
                                    :src="Imagem.urlImagem"
                                    alt=""
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control"
                                    type="number"
                                    v-model="Imagem.ordem"
                                    placeholder=""
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-warning btn-sm"
                                    id="sa-custom-position"
                                    @click="ApagaImagem(Imagem)"
                                  >
                                    Apagar
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-success btn-sm"
                                    @click="SalvarOrdem"
                                  >
                                    Salvar Ordem
                                  </button>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        @click="$redirect('/controle/segmentosinst1')"
                        type="button"
                        class="btn btn-link"
                      >
                        Cancelar
                      </button>
                      <button
                        v-if="SegmentoId == null || SegmentoId <= 0"
                        type="button"
                        @click="Pesquisar()"
                        class="btn btn-info"
                      >
                        Pesquisar
                      </button>
                      <button
                        v-if="SegmentoId == null || SegmentoId <= 0"
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Salvar"
                      >
                        Salvar
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Alterar"
                      >
                        Alterar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Default Basic Forms End -->
        <div class="card-box mb-30">
          <h2 class="h4 pd-20">Resultado</h2>
          <table class="table hover data-table nowrap">
            <thead>
              <tr>
                <th>Id</th>
                <th class="datatable-nosort">Ação</th>
                <th>Nome</th>
                <th>Ordem</th>
                <th>Destaque</th>
                <th>Visível</th>
                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="Segmento in Segmentos"
                :key="Segmento.segmentoInstNivel1Id"
              >
                <td>{{ Segmento.segmentoInstNivel1Id }}</td>
                <td>
                  <div class="dropdown">
                    <a
                      class="
                        btn btn-link
                        font-24
                        p-0
                        line-height-1
                        no-arrow
                        dropdown-toggle
                      "
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="dw dw-more"></i>
                    </a>
                    <div
                      class="
                        dropdown-menu
                        dropdown-menu-right
                        dropdown-menu-icon-list
                      "
                    >
                      <a
                        class="dropdown-item"
                        @click="
                          $redirect(
                            '/controle/segmentoinst1/' +
                              Segmento.segmentoInstNivel1Id
                          )
                        "
                        ><i class="dw dw-edit2"></i> Editar</a
                      >

                      <a
                        class="dropdown-item"
                        @click="Remover(Segmento)"
                        id="sa-warning"
                        ><i class="dw dw-delete-3"></i> Excluir</a
                      >
                    </div>
                  </div>
                </td>
                <td>{{ Segmento.nomeSegmento }}</td>
                <td>{{ Segmento.ordem }}</td>
                <td>
                  <span v-if="Segmento.destaque">Sim</span
                  ><span v-else>Não</span>
                </td>
                <td>
                  <span v-if="Segmento.visivel">Sim</span
                  ><span v-else>Não</span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";

export default {
  data() {
    return {
      SegmentoId: 0,
      NomeSegmento: "",
      Segmento: {},
      Segmentos: [],
      TelasSegmento: [],
      TelaSelecionada: {},
      ConfiguraTela: {},
      ConfiguraSegmento: {},

      Enviando: 0,
      Enviado: 0,
      Imagens: [],
      
      T: $(".data-table").DataTable(),
    };
  },
  methods: {
    Upload(event) {
      this.EnviandoImagem = true;
      var files = event.target.files;
      var x = 0;
      var imageType = /image.*/;
      for (x = 0; x < files.length; x++) {
        if (!files[x].type.match(imageType)) {
          continue;
        }
        if (this.Imagens.length + x + 1 > this.ConfiguraSegmento.qtdeImg) {
          this.$mensagem_erro("Número maximo de imagens atingido");
          return;
        }
        this.Enviando++;
        let data = new FormData();
        data.append("arquivo", files[x]);
        console.log("arquivo:");  
        console.log(files[x]);
        this.$http
          .post(
            this.$apiUrl +
              "/SegmentoInstNivel1Img/upload/" +
              this.SegmentoId,
            data
          )
          .then(
            (response) => {
              console.log(response.body);
              this.Enviado++;
              this.Imagens.push(JSON.parse(response.bodyText));
            },
            (response) => {
              console.log(response);
            }
          );
      }
      //setInterval(function(){ alert("Hello"); }, 3000);
      // while(fileOk < tamanho)
      // {
      //   console.log("aguardando");
      // }
      // this.EnviandoImagem = false;
      this.Imagens = this.Imagens.sort(function (a, b) {
        return a.ordem - b.ordem;
      });
    },
    ApagaImagem(Imagem) {
      this.$http.delete(this.$apiUrl + "/SegmentoInstNivel1Img/" + Imagem.segmentoInstNivel1ImgId)
      .then(response => {
        console.log(response);
        this.$mensagem_sucesso("Removido com sucesso");
        var i = this.Imagens.indexOf(Imagem);
        this.Imagens.splice(i, 1);
      },response =>{
        console.log(response);
      });
    },
    SalvarOrdem() {
      this.Imagens.forEach(element => {
        // console.log("---");
        // console.log(element);
        element.ordem = parseInt(element.ordem, 10);
        this.$http.put(this.$apiUrl + "/SegmentoInstNivel1Img/" + element.segmentoInstNivel1ImgId, element)
        .then(response => {
          console.log(response.body);
        });
      });
      this.$mensagem_sucesso("Ordem salva");
      this.Imagens = this.Imagens.sort(function(a,b) {
        return a.ordem - b.ordem;
      });
    },
    Remover(seg) {
      this.$http
        .delete(
          this.$apiUrl + "/SegmentoInstNivel1/" + seg.segmentoInstNivel1Id
        )
        .then(
          (response) => {
            console.log(response);
            this.$mensagem_sucesso("Removido com sucesso");
          },
          (error) => {
            this.$mensagem_erro(error.body);
          }
        );
    },
    Salvar() {
      this.Segmento.telaInstitucional = this.ConfiguraTela.tela;
      this.Segmento.ordem = parseInt(this.Segmento.ordem, 10);
      console.log("POSTANDO:");
      console.log(this.Segmento);
      this.$http.post(this.$apiUrl + "/SegmentoInstNivel1", this.Segmento).then(
        (response) => {
          if (response.ok) {
            this.$mensagem_sucesso(
              this.ConfiguraSegmento.nomeSegmento + " cadastrado com sucesso"
            );
            this.$redirect(
              "/controle/SegmentoInst1/" + response.body.segmentoInstNivel1Id
            );
          }
        },
        (error) => {
          this.$mensagem_erro(error.bodyText);
        }
      );
    },
    Alterar() {
      this.Segmento.ordem = parseInt(this.Segmento.ordem, 10);
      console.log("POSTANDO:");
      console.log(this.Segmento);
      this.$http
        .put(
          this.$apiUrl + "/SegmentoInstNivel1/" + this.SegmentoId,
          this.Segmento
        )
        .then(
          (response) => {
            if (response.ok) {
              this.$mensagem_sucesso(
                this.ConfiguraSegmento.nomeSegmento + " alterado com sucesso"
              );
              this.$redirect("/controle/SegmentosInst1");
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
    },
    Pesquisar() {},
    CarregaTela() {
      console.log(this.TelaSelecionada);
      this.$cookie.set("telaseg1", this.TelaSelecionada.toString(), "60m");
      this.$router.go(this.$router.currentRoute);
      this.$router.go(this.$router.currentRoute);
    },
    Limpar() {
      this.$redirect_reload("/controle/cadastrasegmentos/");
    },
    atualiza() {
      setTimeout(function () {
        this.T = $(".data-table").DataTable({
          scrollCollapse: true,
          autoWidth: true,
          responsive: true,
          pageLength: 500,
          searching: true,
          bDestroy: true,
        });
      }, 1000);
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/ConfiguraInstitucional")
      .then((response) => {
        var i = 0;
        for (i = 0; i < response.body.length; i++) {
          var tmp = response.body[i];
          if (tmp.tela != null && tmp.segmentoInstNivel1) {
            var insert = {
              nomeTela: tmp.tela.nomeTela,
              configuraInstitucionalId: tmp.configuraInstitucionalId,
            };
            this.TelasSegmento.push(insert);
          }
        }
        var cok = this.$cookie.get("telaseg1");
        if (cok != null) {
          console.log("cookie " + cok);
          cok = parseInt(cok);
          this.TelaSelecionada = cok;
          this.Segmentos = null;
         
          this.$http
            .get(
              this.$apiUrl + "/configurainstitucional/" + this.TelaSelecionada
            )
            .then(
              (response) => {
                this.ConfiguraTela = response.body;
                console.log(this.ConfiguraTela);
                //1 fixo por ser o id do nivel 1
                this.$http
                  .get(this.$apiUrl + "/segmentoinsttelas/1")
                  .then((response2) => {
                    this.ConfiguraSegmento = response2.body;
                    console.log("configuracao:");
                    console.log(response2.body);
                  });

                   this.$http
                    .get(
                      this.$apiUrl + "/SegmentoInstNivel1/tela/" + this.ConfiguraTela.tela.telaInstitucionalId
                    )
                    .then((response3) => {
                      console.log("REPEAT:");
                      console.log(response3.body);
                      this.Segmentos = response3.body;
                    })
                    .then(this.atualiza());
              },
              (response) => {
                console.log(response);
              }
            );
        } else {
          this.TelaSelecionada = this.TelasSegmento[0].configuraInstitucionalId;
          this.CarregaTela();
        }
      });

    if (this.$route.params.pesquisa != null) {
      this.NomeSegmento = this.$route.params.pesquisa;
    } else {
      this.NomeSegmento = "";
    }
    if (this.SegmentoId == 0) {
      if (this.$route.params.id != null) {
        this.SegmentoId = parseInt(this.$route.params.id, 10);
      }
    }
    console.log("EDITANDO:" + this.SegmentoId);
    if (this.SegmentoId > 0) {
      this.SegmentoId = parseInt(this.SegmentoId, 10);
      this.$http
        .get(this.$apiUrl + "/SegmentoInstNivel1/" + this.SegmentoId)
        .then(
          (response) => {
            console.log("Editando:");
            console.log(response.body);

            this.Segmento = response.body;
            this.Imagens = this.Segmento.imagens;
            this.Imagens = this.Imagens.sort(function (a, b) {
              return a.ordem - b.ordem;
            });
          },
          (error) => {
            console.log("Erro ao carregar:");
            console.log(error);
          }
        );
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
