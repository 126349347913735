<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela Invoice do Pedido</h4>
                    </div>
                  </div>
                </button>
              </div>
              <div
                id="Topo"
                class="collapse show"
                data-parent="#accordion"
                ref="content1"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-30">
                    <div class="invoice-wrap">
                      <div class="invoice-box">
                        <div class="invoice-header">
                          <div class="logo text-center">
                            <img
                              src="images/MOBILE-LOVE-CESTAS-logo.png"
                              alt=""
                              style="width: 190px"
                            />
                            <!--<img style="width:190px" :src="'images/logoLabodeguita.jpg'" />-->
                          </div>
                        </div>
                        <h4 class="text-center mb-30 weight-600">
                          Dados Pedido
                        </h4>
                        <div class="row pb-30">
                            <div class="col-md-6">
                                <p class="fonte-pedido mb-5"
                                   v-if="
                                   PedidoMensagem !=null &&
                                   PedidoMensagem.nomeDe.length>
                                    0
                                    "
                                    >
                                    De:
                                    <strong class="weight-600">
                                        {{
                                PedidoMensagem.nomeDe
                                        }}
                                    </strong>
                                </p>
                                <p class="fonte-pedido mb-5"
                                   v-if="
                                   Pedido.cliente !=null &&
                                   Pedido.cliente.telefone !=null &&
                                   Pedido.cliente.telefone.length>
                                    0 &&
                                    !Mostra

                                    "
                                    >
                                    Tel. Rem.:
                                    <strong class="weight-600">
                                        {{
                                        Pedido.cliente.telefone
                                        }}
                                    </strong>
                                </p>
                                <p class="fonte-pedido mb-5"
                                   v-if="
                                   Pedido.cliente !=null &&
                                   Pedido.cliente.whatsapp !=null &&
                                   Pedido.cliente.whatsapp.length>
                                    0 &&
                                    !Mostra

                                    "
                                    >
                                    Whats. Rem.:
                                    <strong class="weight-600">
                                        {{
                                        Pedido.cliente.whatsapp
                                        }}
                                    </strong>
                                </p>
                                <p class="fonte-pedido mb-5"
                                   v-if="
                                   PedidoMensagem !=null &&
                                   PedidoMensagem.nomePara.length>
                                    0
                                    "
                                    >
                                    Para:
                                    <strong class="weight-600">
                                        {{
                                PedidoMensagem.nomePara
                                        }}
                                    </strong>
                                </p>
                                <p class="fonte-pedido mb-5"
                                   v-if="
                                   PedidoMensagem !=null &&
                                   PedidoMensagem.telefoneRecebe.length>
                                    0
                                    "
                                    >
                                    Tel. Dest.:
                                    <strong class="weight-600">
                                        {{
                                PedidoMensagem.telefoneRecebe
                                        }}
                                    </strong>
                                </p>

                                <p class="fonte-pedido mb-5"
                                   v-if="PedidoAgendamento != null">
                                    Data Entrega:
                                    <strong class="weight-600">
                                        {{
                                  sqlToJsDate(PedidoAgendamento.diaEntrega) +
                                  " - " +
                                  PedidoAgendamento.horaIni.toLocaleString(
                                    "en-US",
                                    {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    }
                                  ) +
                                  ":" +
                                  PedidoAgendamento.minIni.toLocaleString(
                                    "en-US",
                                    {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    }
                                  ) +
                                  " as " +
                                  PedidoAgendamento.horaFim.toLocaleString(
                                    "en-US",
                                    {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    }
                                  ) +
                                  ":" +
                                  PedidoAgendamento.minFim.toLocaleString(
                                    "en-US",
                                    {
                                      minimumIntegerDigits: 2,
                                      useGrouping: false,
                                    }
                                  )
                                        }}
                                    </strong>
                                </p>
                                <p class="fonte-pedido mb-5">
                                    Pedido:
                                    <strong class="weight-600">
                                        {{
                                Pedido.pedidoId
                                        }}
                                    </strong>
                                </p>
                            </div>
                          <div class="col-md-6">
                          <div class="text-right" style="font-weight: bold;color:blue !important">
                              <p class="fonte-pedido mb-5"
                                 v-if="PedidoEndereco != null">
                                  {{
                                  PedidoEndereco.endereco +
                                  ", " +
                                  PedidoEndereco.numero +
                                  " "
                                  }}
                                  <br />
                                  Comp.:
                                  {{PedidoEndereco.complemento}}
                                  <br />
                                  {{
                                  PedidoEndereco.bairro != null &&
                                  PedidoEndereco.bairro.length > 0
                                    ? PedidoEndereco.bairro + ", "
                                    : ""
                                  }}
                                  <br />
                                  {{
                                  PedidoEndereco.cidade != null
                                    ? PedidoEndereco.cidade.nomeCidade
                                    : ""
                                  }}
                                  <br />
                                  {{
                                  PedidoEndereco.estado != null
                                    ? PedidoEndereco.estado.nomeEstado + " "
                                    : ""
                                  }}
                                  {{
                                  PedidoEndereco.cep != null &&
                                  PedidoEndereco.cep.length > 0
                                    ? "CEP: " + PedidoEndereco.cep
                                    : ""
                                  }}
                              </p>
                            
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="table-responsive">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th style="text-align: center">Ref.</th>
                                  <th
                                    class="datatable-nosort"
                                    style="width: 10%"
                                  >
                                    imagem
                                  </th>
                                  <th>Produto</th>
                                  <th class="datatable-nosort" v-if="Mostra">
                                    Unit
                                  </th>
                                  <th class="datatable-nosort">Qtde</th>
                                  <th class="datatable-nosort" v-if="Mostra">
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="Item in Pedido.listaPedidoItem.length >
                                  0
                                    ? Pedido.listaPedidoItem.filter(
                                        (x) => x.produto != null
                                      )
                                    : []"
                                  :key="Item.pedidoItemId"
                                >
                                  <td style="text-align: center">
                                    {{ Item.produto.referencia }}
                                  </td>
                                  <td>
                                    <img
                                      v-if="
                                        Item.produto.imagens != null &&
                                        Item.produto.imagens.length > 0
                                      "
                                      :src="Item.produto.imagens[0].urlImg"
                                      alt=""
                                    />
                                  </td>
                                  <td class="tamdado">
                                    {{ Item.produto.nomeProduto }}
                                  </td>

                                  <td class="tamdado" v-if="Mostra">
                                    R$
                                    {{
                                      Item.valorProduto
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                  <td class="tamdado">
                                    {{ Item.qtdeProduto }}
                                  </td>
                                  <td class="tamdado" v-if="Mostra">
                                    R$
                                    {{
                                      Item.valorTotalProduto
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                </tr>
                                <tr
                                  v-for="Item in (Pedido.listaPedidoItemAdicional !=
                                    null &&
                                    Pedido.listaPedidoItemAdicional.length) > 0
                                    ? Pedido.listaPedidoItemAdicional.filter(
                                        (x) => x.itemAvulso != null
                                      )
                                    : []"
                                  :key="Item.pedidoItemAdicionalId"
                                >
                                  <td style="text-align: center">
                                    AV{{ Item.itemAvulso.itemAvulsoId }}
                                  </td>
                                  <td>
                                    <img
                                      v-if="
                                        Item.itemAvulso.urlImg != null &&
                                        Item.itemAvulso.urlImg.length > 0
                                      "
                                      :src="Item.itemAvulso.urlImg"
                                      alt=""
                                    />
                                  </td>
                                  <td class="tamdado">
                                    {{ Item.itemAvulso.nomeItemAvulso }}
                                  </td>

                                  <td class="tamdado" v-if="Mostra">
                                    R$
                                    {{
                                      Item.valorProduto
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                  <td class="tamdado">
                                    {{ Item.qtdeProduto }}
                                  </td>
                                  <td class="tamdado" v-if="Mostra">
                                    R$
                                    {{
                                      Item.valorTotalProduto
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                </tr>
                                <tr
                                  v-for="Item in Pedido.listaPedidoItemManual !=
                                    null &&
                                  Pedido.listaPedidoItemManual.length > 0
                                    ? Pedido.listaPedidoItemManual.filter(
                                        (x) => x.itemAvulsoManual != null
                                      )
                                    : []"
                                  :key="Item.pedidoItemManualId"
                                >
                                  <td style="text-align: center">
                                    AVM{{
                                      Item.itemAvulsoManual.itemAvulsoManualId
                                    }}
                                  </td>
                                  <!-- <img style="" v-if="Item.itemAvulsoManual.itemAvulsoManualId == 1  && PedidoFoto != null" :src="(Item.itemAvulsoManual.itemAvulsoManualId == 1  && PedidoFoto != null) ? PedidoFoto.urlImagem:''"> -->
                                  <td></td>
                                  <td class="tamdado">
                                    {{ Item.itemAvulsoManual.nomeItem }}
                                  </td>
                                  <td class="tamdado" v-if="Mostra">
                                    R$
                                    {{
                                      Item.valorTotalProduto
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                  <td class="tamdado">
                                    {{ Item.qtdeProduto }}
                                  </td>
                                  <td class="tamdado" v-if="Mostra">
                                    R$
                                    {{
                                      Item.valorProduto
                                        .toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        .replace(".", ",")
                                        .replace("$", "")
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              class="col-lg-12"
                              style="text-align: right"
                              v-if="Mostra"
                            >
                              Frete ({{ Pedido.tipoFrete }}): R$
                              {{
                                Pedido.valorFrete
                                  .toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                  .replace(".", ",")
                                  .replace("$", "")
                              }}
                            </div>
                            <div
                              class="col-lg-12"
                              style="text-align: right"
                              v-if="Mostra"
                            >
                              Cupom: R$
                              {{
                                Pedido.valorCupom
                                  .toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                  .replace(".", ",")
                                  .replace("$", "")
                              }}
                            </div>
                            <div class="col-lg-12" v-if="Mostra">
                              <div
                                style="
                                  font-size: 18px;
                                  font-weight: 800;
                                  text-align: right;
                                "
                              >
                                Valor Total R$
                                {{
                                  CalculaValorPedido(Pedido)
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(".", ",")
                                    .replace("$", "")
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="infoCliente"
                          class="invoice-desc pb-30 mt-15"
                          style="padding-top: 50px"
                        >
                          <div class="invoice-desc-footer">
                            <div class="invoice-desc-head clearfix">
                              <div class="invoice-sub">
                                Informações complementares
                              </div>
                              <div class="invoice-rate"></div>
                              <div class="invoice-subtotal"></div>
                            </div>
                            <div class="invoice-desc-body">
                              <ul>
                                <li class="clearfix">
                                  <div class="invoice-sub">
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido.cliente != null &&
                                        Pedido.cliente.nomeEmpresa &&
                                        Mostra
                                      "
                                    >
                                      Comprador:
                                      <strong class="weight-600">{{
                                        Pedido.cliente.nomeEmpresa
                                      }}</strong>
                                    </p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido.cliente != null &&
                                        Pedido.cliente.cpf != null &&
                                        Pedido.cliente.cpf.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      CPF:
                                      <strong class="weight-600">{{
                                        Pedido.cliente.cpf
                                      }}</strong>
                                    </p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido.cliente != null &&
                                        Pedido.cliente.cnpj != null &&
                                        Pedido.cliente.cnpj.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      CNPJ:
                                      <strong class="weight-600">{{
                                        Pedido.cliente.cnpj
                                      }}</strong>
                                    </p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido.cliente != null &&
                                        Pedido.cliente.email != null &&
                                        Pedido.cliente.email.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      E-mail:
                                      <strong class="weight-600">{{
                                        Pedido.cliente.email
                                      }}</strong>
                                    </p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido.cliente != null &&
                                        Pedido.cliente.telefone != null &&
                                        Pedido.cliente.telefone.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      Telefone:
                                      <strong class="weight-600">{{
                                        Pedido.cliente.telefone
                                      }}</strong>
                                    </p>
                                     <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido.cliente != null &&
                                        Pedido.cliente.whatsapp != null &&
                                        Pedido.cliente.whatsapp.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      Whatsapp:
                                      <strong class="weight-600">{{
                                        Pedido.cliente.whatsapp
                                      }}</strong>
                                    </p>
                                  </div>
                                  <div class="invoice-rate">
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido != null &&
                                        Pedido.formaPgto != null &&
                                        Pedido.formaPgto.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      Forma Pgto:
                                      <strong class="weight-600">{{
                                        Pedido.formaPgto
                                      }}</strong>
                                    </p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido != null &&
                                        Pedido.origemPedido != null &&
                                        Pedido.origemPedido.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      Local Cadastro:
                                      <strong class="weight-600">{{
                                        Pedido.origemPedido
                                      }}</strong>
                                    </p>
                                    <p class="font-14 mb-5"></p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido != null &&
                                        Pedido.tipoFrete != null &&
                                        Pedido.tipoFrete.length > 0 &&
                                        Mostra
                                      "
                                    >
                                      Frete:
                                      <strong class="weight-600">{{
                                        Pedido.tipoFrete
                                      }}</strong>
                                    </p>
                                    <p
                                      class="font-14 mb-5"
                                      v-if="
                                        Pedido != null &&
                                        Pedido.valorCupom != null &&
                                        Mostra
                                      "
                                    >
                                      Cupom:
                                      <strong class="weight-600"
                                        >R$
                                        {{
                                          Pedido.valorCupom
                                            .toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            })
                                            .replace(".", ",")
                                            .replace("$", "")
                                        }}</strong
                                      >
                                    </p>
                                  </div>
                                  <div class="invoice-subtotal">
                                    <p class="font-14 mb-5">
                                      Mensagem:
                                      <strong
                                        class="weight-600"
                                        v-if="
                                          PedidoMensagem != null &&
                                          PedidoMensagem.pedidoMensagemId > 0
                                        "
                                        >Sim</strong
                                      >
                                      <strong class="weight-600" v-else
                                        >Não</strong
                                      >
                                    </p>
                                    <p class="font-14 mb-5">
                                      Foto:
                                      <strong
                                        class="weight-600"
                                        v-if="
                                          PedidoFoto != null &&
                                          PedidoFoto.pedidoFotoId > 0
                                        "
                                        >Sim</strong
                                      >
                                      <strong class="weight-600" v-else
                                        >Não</strong
                                      >
                                    </p>
                                    <p class="font-14 mb-5">
                                      Data Ped.:
                                      <strong class="weight-600">{{
                                        sqlToJsDate(
                                          PedidoAgendamento.diaEntrega
                                        ).toString()
                                      }}</strong>
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="Pedido.obs != null && Pedido.obs.length > 0"
                          class="invoice-sub"
                          style="
                            background: #eaeaea;
                            font-weight: 500;
                            padding: 10px;
                          "
                        >
                          Obs
                        </div>
                        <div
                          style="padding: 20px"
                          v-if="Pedido.obs != null && Pedido.obs.length > 0"
                          v-html="Pedido.obs"
                        ></div>
                          <div
                          v-if="Pedido.ibr != null && Pedido.ibr.length > 0"
                          class="invoice-sub"
                          style="
                            background: #eaeaea;
                            font-weight: 500;
                            padding: 10px;
                          "
                        >
                          Dados
                        </div>
                        <div
                          style="padding: 20px"
                          v-if="Pedido.ibr != null && Pedido.ibr.length > 0"
                          v-html="Dados"
                        ></div>
                        <div
                          class="container"
                          style="padding-top: 100px"
                          v-if="!Mostra"
                        >
                          <div class="row">
                            <div
                              class="
                                col-lg-6 col-md-6 col-sm-12 col-xs-12
                                text-right
                              "
                            >
                              <span style="font-weight: 800; font-size: 20px"
                                >Entregue para:</span
                              >
                            </div>
                            <div class="col-lg-6 text-left">
                              <div
                                style="
                                  border-bottom: 1px solid #333333;
                                  width: 320px;
                                  height: 25px;
                                  margin-left: -20px;
                                "
                              ></div>
                            </div>

                            <div
                              class="
                                col-lg-6 col-md-6 col-sm-12 col-xs-12
                                text-right
                              "
                            >
                              <span style="font-weight: 800; font-size: 20px"
                                >Data:</span
                              >
                            </div>
                            <div class="col-lg-6 text-left">
                              <div
                                style="
                                  border-bottom: 1px solid #333333;
                                  width: 320px;
                                  height: 25px;
                                  margin-left: -20px;
                                "
                              ></div>
                            </div>
                            <div
                              class="
                                col-lg-6 col-md-6 col-sm-12 col-xs-12
                                text-right
                              "
                            >
                              <span style="font-weight: 800; font-size: 20px"
                                >CPF:</span
                              >
                            </div>
                            <div class="col-lg-6 text-left">
                              <div
                                style="
                                  border-bottom: 1px solid #333333;
                                  width: 320px;
                                  height: 25px;
                                  margin-left: -20px;
                                "
                              ></div>
                            </div>
                            <div
                              class="
                                col-lg-6 col-md-6 col-sm-12 col-xs-12
                                text-right
                              "
                            >
                              <span style="font-weight: 800; font-size: 20px"
                                >RG:</span
                              >
                            </div>
                            <div class="col-lg-6 text-left">
                              <div
                                style="
                                  border-bottom: 1px solid #333333;
                                  width: 320px;
                                  height: 25px;
                                  margin-left: -20px;
                                "
                              ></div>
                            </div>
                            <div
                              class="
                                col-lg-6 col-md-6 col-sm-12 col-xs-12
                                text-right
                              "
                            >
                              <span style="font-weight: 800; font-size: 20px"
                                >Assinatura:</span
                              >
                            </div>
                            <div class="col-lg-6 text-left">
                              <div
                                style="
                                  border-bottom: 1px solid #333333;
                                  width: 320px;
                                  height: 25px;
                                  margin-left: -20px;
                                "
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <!-- <button
                    type="button"
                    class="btn btn-primary"
                    @click="downloadWithCSS2(Pedido.pedidoId)"
                  >
                    <i class="icon-copy dw dw-print"></i>Versão Cliente
                  </button> -->
                  <a target="_blank" :href="'#/controle/imprimirInvoice/' + Pedido.pedidoId"
                    class="btn btn-primary"
                    style="margin: 10px 20px"
                  >
                    <i class="icon-copy dw dw-print"></i>Versão Cliente
                  </a>
                   <a target="_blank" :href="'#/controle/imprimirInvoice/' + Pedido.pedidoId + '?tipo=1'"
                    class="btn btn-secondary"
                    style="margin: 10px 20px"
                  >
                    <i class="icon-copy dw dw-print"></i>Versão Completa
                  </a>
                  <!-- <button
                    type="button"
                    class="btn btn-secondary"
                    @click="downloadWithCSS1(Pedido.pedidoId)"
                  >
                    <i class="icon-copy dw dw-print"></i>Versão Completa
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
// import vSelect from "vue-select";
//import moment from "moment";
import * as momentTemp from "moment";
const moment = momentTemp["default"];

// import jsPDF from "jspdf";
// import domtoimage from "dom-to-image";

export default {
  data() {
    return {
      Dados: "",
      Mostra: true,
      Data1: "",
      Pedido: {
        pedidoId: 0,
        cliente: { clienteId: 0 },
        situacaoPedido: "",
        dataPedido: "",
        listaPedidoItemManual: [],
        listaPedidoitemAdicional: [],
        listaPedidoItem: [],
        valorFrete: 0.0,
        valorCupom: 0.0,
      },
      PedidoPagtoComprova: {
        pedidoPagtoComprovaId: 0,
        urlImg: "",
      },
      PedidoEndereco: {
        pedidoEndereco: 0,
        ensereco: "",
        pais: {},
        estado: {},
        cidadde: {},
        bairro: "",
        cep: "",
        numero: 0,
      },
      PedidoAgendamento: {
        pedidoAgendamentoId: 0,
        horaIni: 0,
        minIni: 0,
        horaFim: 0,
        minFim: 0,
        diaEntrega: "",
      },
      PedidoFoto: {
        pedidoFotoId: 0,
        urlImagem: "",
        valroFoto: 0.0,
      },
      PedidoMensagem: {
        pedidoMensagemId: 0,
        urlImagem: "",
        nomeDe: "",
        nomePara: "",
        texto: "",
        telefoneRecebe: "",
      },
    };
  },
  methods: {
    downloadWithCSS1(id) {
      //if (this.PedidoFoto.pedidoFotoId > 0) {
        localStorage.printCliente = "";
      this.$redirect('/controle/imprimirInvoice/' + id);
        // domtoimage
        //   .toPng(this.$refs.content1)
        //   .then(function (dataUrl) {
        //     var img = new Image();
        //     img.src = dataUrl;
        //     const doc = new jsPDF({
        //       orientation: "portrait",
        //       // unit: "pt",
        //       //format: [1772, 1240]
        //     });
        //     //doc.addImage(img, "JPEG", 22, -7, 165, 110);
        //     //doc.addImage(img, "JPEG", 24, -5, 162, 109);
        //     doc.addImage(img, "JPEG", -10, 15, 230, 231, "", "NONE");
        //     //doc.addImage(img, "JPEG", -10, -7, 0, 0, '', "false", 90);
        //     const filename = "invoice_pedido-" + id;
        //     (".pdf");
        //     doc.save(filename);
        //   })
        //   .catch(function (error) {
        //     console.error("oops, something went wrong!", error);
        //   });
      //}
    },
    downloadWithCSS2(id) {
      localStorage.printCliente = "true";
      this.$redirect('/controle/imprimirInvoice/' + id + '?printCliente=true');
      //if (this.PedidoFoto.pedidoFotoId > 0) {
        // domtoimage
        //   .toPng(this.$refs.content1)
        //   .then(function (dataUrl) {
        //     var img = new Image();
        //     img.src = dataUrl;
        //     const doc = new jsPDF({
        //       orientation: "portrait",
        //       // unit: "pt",
        //       //format: [1772, 1240]
        //     });
        //     //doc.addImage(img, "JPEG", 22, -7, 165, 110);
        //     //doc.addImage(img, "JPEG", 24, -5, 162, 109);
        //     doc.addImage(img, "JPEG", -10, 15, 230, 231, "", "NONE");
        //     //doc.addImage(img, "JPEG", -10, -7, 0, 0, '', "false", 90);
        //     const filename = "cliente_invoice_pedido-" + id;
        //     (".pdf");
        //     doc.save(filename);

        //     this.Mostra = true;
        //   })
        //   .catch(function (error) {
        //     console.error("oops, something went wrong!", error);
        //   });
      //}
    },
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    CalculaValorPedido(Pedido) {
      var total = 0;
      total += Pedido.valorFrete;
      total -= Pedido.valorCupom;

      if (Pedido.listaPedidoItem != null && Pedido.listaPedidoItem.length > 0) {
        Pedido.listaPedidoItem.forEach((item) => {
          total += item.valorProduto * item.qtdeProduto;
        });
      }

      if (
        Pedido.listaPedidoItemAdicional != null &&
        Pedido.listaPedidoItemAdicional.length > 0
      ) {
        Pedido.listaPedidoItemAdicional.forEach((item) => {
          total += item.valorProduto * item.qtdeProduto;
        });
      }

      if (
        Pedido.listaPedidoItemManual != null &&
        Pedido.listaPedidoItemManual.length > 0
      ) {
        Pedido.listaPedidoItemManual.forEach((item) => {
          total += item.valorProduto * item.qtdeProduto;
        });
      }

      return total;
    },
    Carregar() {
      if (this.$route.params.id != null) {
        this.$http
          .get(this.$apiUrl + "/estados/ativos")
          .then((res) => res.json())
          .then((tiu) => {
            this.Estados = tiu;
          });
        this.Pedido.pedidoId = parseInt(this.$route.params.id, 10);
        this.$http.get(this.$apiUrl + "/pedido/" + this.Pedido.pedidoId).then(
          (response) => {
            if (response.ok) {
              this.Pedido = response.body;
              if(this.Pedido.ibr != null && this.Pedido.ibr.length > 0){
                if(this.Pedido.ibr.includes('--')){
                  var tmp = "";
                  this.Pedido.ibr.split('--').forEach(element => {
                    var prefix = "<br /><br />";
                    if(element.startsWith(prefix)){
                      tmp += "<td style='padding:10px'>" + element.slice(prefix.length) + "</td>";
                    }
                    else
                    tmp += "<td style='padding:10px'>" + element + "</td>";
                  });
                  this.Dados = "<table><tr style='font-size:12px;line-height:13px'>"+tmp+"</tr></table>"
                } else {
                  this.Dados = this.Pedido.ibr;
                }
              }
            }
          }
          // ,
          // (error) => {
          //   this.$mensagem_erro(error.bodyText);
          // }
        );

        //--------------------------------------------------------------------------------

        this.$http
          .get(this.$apiUrl + "/pedidoendereco/pedido/" + this.Pedido.pedidoId)
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoEndereco = response.body;
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );

        //--------------------------------------------------------------------------------

        this.$http
          .get(
            this.$apiUrl + "/pedidopagtocomprova/pedido/" + this.Pedido.pedidoId
          )
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoPagtoComprova = response.body;
              }
            }
            // ,
            // (error) => {
            //   this.$mensagem_erro(error.bodyText);
            // }
          );

        //--------------------------------------------------------------------------------

        this.$http
          .get(
            this.$apiUrl + "/pedidoagendamento/pedido/" + this.Pedido.pedidoId
          )
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoAgendamento = response.body;
                this.Data1 = moment(this.PedidoAgendamento.diaEntrega).format(
                  "YYYY-MM-DD"
                );
              }
            },
            (error) => {
              console.log(error.bodyText);
            }
          );

        //-------------------------------------------------------------------------------
        this.$http
          .get(this.$apiUrl + "/pedidomensagem/pedido/" + this.Pedido.pedidoId)
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoMensagem = response.body;
              }
            }
            // ,
            // (error) => {
            //   console.log(error.bodyText);
            // }
          );

        //-------------------------------------------------------------------------------
        this.$http
          .get(this.$apiUrl + "/pedidofoto/pedido/" + this.Pedido.pedidoId)
          .then(
            (response) => {
              if (response.ok && response.bodyText.length > 0) {
                this.PedidoFoto = response.body;
              }
            }
            // ,
            // (error) => {
            //   console.log(error.bodyText);
            // }
          );
      }
    },
  },
  components: {},
  created() {
    this.Carregar();
  },
  mounted() {
    var b = document.body;
    var novo = document.createElement("style");
    novo.innerHTML = "@media print{.header, .footer-wrap, .left-side-bar{display:none}}";
    b.append(novo);
    b.style.backgroundColor = "white"
    
  },	
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}

@media (max-width: 600px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 100%;
    outline: none;
    height: 270px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 12px;
  }

  .imagemCartao {
    height: 270px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 90%;
  }
}

@media (min-width: 608px) and (max-width: 991px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 98%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
  .fonte-pedido {
    font-size: 21px !important;
  }
}
</style>
