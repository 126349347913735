<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Lista de Planos por cliente</h4>
                                            <p class="mb-30">
                                                Tela visualização dos planos por cliente.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <!--<form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Nome do Plano</label>
                                                <input v-model="NomePlano" class="form-control" type="text" placeholder="Nome Plano">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Visível</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Ordem</label>
                                                <input v-model="Ordem" class="form-control" type="number" placeholder="Ordem">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Valor Mensal</label>
                                                <money v-model.lazy="ValorPorPlano"
                                                       class="form-control decimal"
                                                       type="text"
                                                       v-bind="money" />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Plano Adicional</label>
                                                <v-select v-model="Adicionalselected" @input="MudaAdicional"
                                                          :options="Adicional"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Descrição Plano</label>
                                                <quill-editor v-model="TextoPlano" placeholder="Insira texto aqui ..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="PlanoId != null && PlanoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>-->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Cliente</th>
                                <th>Plano</th>
                                <th>Inicio</th>
                                <th>Fim</th>
                                <th>Email</th>
                                <th>Pago</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="PlanoCliente in PlanosCliente" :key="PlanoCliente.planosClienteId">
                                <td>{{ PlanoCliente.planosClienteId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/cliente/' + PlanoCliente.cliente.clienteId)"><i class="dw dw-edit2"></i> Detalhes</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                        </div>
                                    </div>
                                </td>
                                <td>{{ PlanoCliente.cliente.nomeEmpresa }}</td>
                                <td>{{ PlanoCliente.plano.nomePlano }}</td>
                                <td>{{ FormataData(PlanoCliente.dataInicial) }}</td>
                                <td>{{ FormataData(PlanoCliente.dataFinal) }}</td>
                                <td>{{ PlanoCliente.cliente.email }}</td>
                                <td><span v-if="PlanoCliente.pago">Sim</span><span v-else>Não</span></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import moment from "moment";

    export default {
        data() {
            return {
                PlanoId: this.$route.params.id,
                PlanosCliente: [],
                PlanoCliente: {},
                T: {},
            };
        },
        methods: {
            FormataData(value) {
                return moment(value).format('DD/MM/YYYY')
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
                this.$http
                    .get(this.$apiUrl + "/PlanosCliente")
                    .then((res) => res.json())
                    .then((moe) => {
                        this.PlanosCliente = moe.filter(x => x.plano.regra4 == 0)
                    })
                    .then(this.atualiza());

        },
        mounted() {

        },
        components: {
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
 == 0