<template>
    <div>
        <!-- <Cabecalho></Cabecalho> -->

        <div class="main-container">
            <div class="pd-ltr-20">
                <!-- Default Basic Forms Start -->
                <div class="faq-wrap">
                    <div id="accordion">
                        <div class="pd-20 card-box mb-30">
                            <div class="card-header">
                                <button class="btn btn-block"
                                        data-toggle="collapse"
                                        data-target="#Topo">
                                    <div class="clearfix">
                                        <div class="pull-left">
                                            <h4 class="text-black h4">Cadastro de Estados</h4>
                                            <p class="mb-30">
                                                Tela para cadastro dos Estados.
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div id="Topo" class="collapse show" data-parent="#accordion">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Estado</label>
                                                <input v-model="NomeEstado" class="form-control" type="text" placeholder="Estado">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Sigla</label>
                                                <input v-model="SiglaEstado" class="form-control" type="text" placeholder="Sigla">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <v-select v-model="Statuselected" @input="MudaStatus"
                                                          :options="Status"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome" class="style-chooser"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                            <button v-if="EstadoId == null || EstadoId.length <= 0"
                                                    type="button"
                                                    @click="$redirect_reload('/controle/estado/' + NomeEstado)"
                                                    class="btn btn-info">
                                                Pesquisar
                                            </button>
                                            <button v-if="EstadoId != null && EstadoId.length > 0"
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Altera()"
                                                    id="sa-custom-position2">
                                                Alterar
                                            </button>
                                            <button v-else
                                                    type="button"
                                                    class="btn btn-success"
                                                    @click="Add()"
                                                    id="sa-custom-position">
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Default Basic Forms End -->
                <div class="card-box mb-30">
                    <h2 class="h4 pd-20">Resultado</h2>
                    <table class="table hover data-table nowrap">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th class="datatable-nosort">Ação</th>
                                <th>Estado</th>
                                <th>Sigla</th>
                                <th>Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="Estado in Estados" :key="Estado.estadoId">
                                <td>{{ Estado.estadoId }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                           role="button"
                                           data-toggle="dropdown">
                                            <i class="dw dw-more"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                            <a class="dropdown-item"
                                               @click="$redirect('/controle/estados/' + Estado.estadoId)"><i class="dw dw-edit2"></i> Editar</a>
                                            <!-- <a class="dropdown-item" href="#"><i class="dw dw-copy"></i> Duplicar</a> -->
                                            <a class="dropdown-item"
                                               @click="Remove(Estado)"
                                               id="sa-warning"><i class="dw dw-delete-3"></i> Excluir</a>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ Estado.nomeEstado }}</td>
                                <td>{{ Estado.siglaEstado }}</td>
                                <td><span v-if="Estado.ativo">Ativo</span><span v-else>Inativo</span></td>
                                
                            </tr>
                            <!-- <tr>
                              <td
                                valign="top"
                                v-if="Idiomas.length <= 0"
                                colspan="5"
                                class="dataTables_empty"
                              >
                                Nenhum resultado encontrado
                              </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <!-- <Rodape></Rodape> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

    import $ from "jquery";
    import "datatables.net-dt";
    import "datatables.net-dt/css/jquery.dataTables.css";
    import vSelect from 'vue-select';

    export default {
        data() {
            return {
                EstadoId: this.$route.params.id,
                NomeEstado: "",
                SiglaEstado: "",
                Ativo: null,
                Estado: {},
                Estados: [],
                Statuselected: null,
                Status: [{ nome: "Ativo", valor: true }, { nome: "Inativo", valor: false }],
                T: {},
            };
        },
        methods: {
            MudaStatus(value) {
                this.Ativo = value;
            },
            Add() {
                let _Estados = {
                    estadoId: 0,
                    nomeEstado: this.NomeEstado,
                    siglaEstado: this.SiglaEstado,
                    ativo: null,
                };

                //validações
                if (this.NomeEstado.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o estado");
                } else if (this.SiglaEstado.length <= 0) {
                    this.$mensagem_normal("Por favor preencha a sigla");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {
                    _Estados.ativo = this.Ativo;

                    //chama o post da api
                    this.$http.post(this.$apiUrl + "/estados/", _Estados).then(
                        response2 => {
                            // get body data
                            _Estados = response2.body;
                            this.$redirect_reload("/controle/estados");
                        },
                        response2 => {
                            _Estados = response2.body;
                            // error callback
                            //const data = await response2.json();
                            //JSON.parse(data);
                            this.$mensagem_normal(_Estados);
                        }
                    );
                }
            },
            Remove(DadosRemover) {
                //chama o delete da api
                this.$http
                    .delete(this.$apiUrl + "/estados/" + DadosRemover.estadoId)
                    .then(
                        () => {
                            // get body data
                            this.$redirect_reload("/controle/estados");
                        },
                        (response) => {
                            // error callback
                            console.log(response);
                        }
                    );
            },
            Altera() {
                let _Estados = {
                    estadoId: 0,
                    nomeEstado: this.NomeEstado,
                    siglaEstado: this.SiglaEstado,
                    ativo: null,
                };

                //validações
                if (this.NomeEstado.length <= 0) {
                    this.$mensagem_normal("Por favor preencha o estado");
                } else if (this.SiglaEstado.length <= 0) {
                    this.$mensagem_normal("Por favor preencha a sigla");
                } else if (this.Ativo == null) {
                    this.$mensagem_normal("Por favor selecione o status");
                } else {

                    _Estados.ativo = this.Ativo;
                    _Estados.estadoId = parseInt(this.EstadoId, 10);

                    //chama o post da api
                    this.$http
                        .put(this.$apiUrl + "/estados/" + this.EstadoId, _Estados)
                        .then(
                            response2 => {
                                // get body data
                                _Estados = response2.body;
                                console.log(_Estados);
                                this.$mensagem_sucesso(
                                    "Estado #" + this.MoedaId + " alterado com sucesso"
                                );
                                this.$redirect("/controle/estados");
                            },
                            async response2 => {
                                _Estados = response2.body;
                                // error callback
                                //const data = await response2.json();
                                //JSON.parse(data);
                                this.$mensagem_normal(_Estados);
                            }
                        );
                }
            },
            Pesquisa() {
                try {
                    if (this.NomeEstado.length <= 0) {
                        this.$mensagem_normal("Por favor preencha o estado");
                    } else {
                        this.Estados = [];
                        let _Estados = {
                            NomeEstado: this.NomeEstado,
                        };
                        this.$http
                            .post(this.$apiUrl + "/estados/busca", _Estados)
                            .then((res) => res.json())
                            .then((tiu) => (this.Estados = tiu))
                            .then(this.atualiza());
                    }
                } catch {
                    this.$mensagem_normal("Nenhum resultado encontrado");
                }
            },
            Limpar() {
                this.$redirect_reload("/controle/estados");
            },
            atualiza() {
                setTimeout(function () {
                    this.T = $(".data-table").DataTable({
                        scrollCollapse: true,
                        autoWidth: false,
                        responsive: true,
                        pageLength: 500,
                        searching: true,
                        bDestroy: true
                    });
                }, 1000);
            },
        },
        created() {
            if (this.$route.params.pesquisa != null) {
                this.NomeEstado = this.$route.params.pesquisa;
            } else {
                this.NomeEstado = "";
            }

            if (this.EstadoId == null) {
                this.EstadoId = "";
            }

            //chama o get da api this.$apiUrl
            if (this.EstadoId != null && this.EstadoId.length > 0) {
                this.$http
                    .get(this.$apiUrl + "/estados/" + this.EstadoId)
                    .then((res) => res.json())
                    .then((idi) => {
                        this.Estado = idi;

                        this.NomeEstado = this.Estado.nomeEstado;
                        this.SiglaEstado = this.Estado.siglaEstado;
                        this.Statuselected = this.Estado.ativo;
                        this.Ativo = this.Estado.ativo;

                    });
            }
            else if (this.NomeEstado != null && this.NomeEstado.length > 0) {
                this.Pesquisa();
            }
            else {
                this.$http
                    .get(this.$apiUrl + "/estados")
                    .then((res) => res.json())
                    .then((moe) => (this.Estados = moe))
                    .then(this.atualiza());
            }
        },
        mounted() {

        },
        components: {
            vSelect,
            // Cabecalho,
            // Rodape,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    button {
        margin: 10px;
    }
</style>
