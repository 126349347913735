<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Tela de Pedidos</h4>
                      <p class="mb-30">
                        Tela da lista de pedidos manuais ou do site.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <div class="wizard-content">
                  <form
                    class="tab-wizard wizard-circle wizard vertical clearfix"
                    role="application"
                    id="steps-uid-0"
                  >
                    <div class="steps clearfix">
                      <ul role="tablist">
                        <li role="tab" :class="'first ' + getClasseLiStep(1)">
                          <a id="steps-uid-0-t-0"
                            ><span class="step">1</span> Produtos</a
                          >
                        </li>
                        <li :class="getClasseLiStep(2)">
                          <a id="steps-uid-0-t-1"
                            ><span class="step">2</span> Cliente</a
                          >
                        </li>
                        <li :class="getClasseLiStep(3)">
                          <a id="steps-uid-0-t-2"
                            ><span class="step">3</span> Entrega</a
                          >
                        </li>

                        <li :class="'last ' + getClasseLiStep(4)">
                          <a id="steps-uid-0-t-4"
                            ><span class="step">4</span> Finalização</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="content clearfix">
                      <!-- <h5
                        v-if="Step == 1"
                        :class="'title current'"
                      >
                        Produtos
                      </h5> -->
                      <section v-if="Step == 1" :class="'body'">
                        <div class="row" style="display: none">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label>Itens Adicionais</label>
                              <v-select
                                v-model="ItemManual"
                                :options="ItensManuais"
                                single-line
                                label="nomeItem"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="InserirManualSelect(ItemManual)"
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label>Produtos</label>
                              <v-select
                                v-model="ItemPedido"
                                :options="ItensPedido"
                                single-line
                                label="nomeProduto"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="InserirProdutoSelect(ItemPedido)"
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label>Itens Avulsos</label>
                              <v-select
                                v-model="ItemAdicional"
                                :options="ItensAdicionais"
                                single-line
                                label="nomeItemAvulso"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="InserirAvulsoSelect(ItemAdicional)"
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <label>Outros</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="ItemManualNovo.nomeItem"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Valor</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="ItemManualNovo.valor"
                                @change="
                                  ItemManualNovo.valor != null
                                    ? (ItemManualNovo.valor = parseFloat(
                                        ItemManualNovo.valor.replace(',', '.'),
                                        10
                                      ))
                                    : (ItemManualNovo.valor = 0.0)
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  @click="InserirManualNovo()"
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                >
                                  Inserir
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <div class="table-responsive">
                              <table class="table table-hover">
                                <thead>
                                  <tr>
                                    <th>Cód Produto</th>
                                    <th>Nome produto</th>
                                    <th>Qtde</th>
                                    <th>Valor</th>
                                    <th>Excluir</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="Item in ListaPedidoItem.length > 0
                                      ? ListaPedidoItem.filter(
                                          (x) => x.produto != null
                                        )
                                      : []"
                                    :key="Item.pedidoItemId"
                                  >
                                    <td>{{ Item.produto.referencia }}</td>

                                    <td class="tamdado">
                                      {{ Item.produto.nomeProduto }}
                                    </td>
                                       <td class="tamdado">
                                               <input style="width:50%;float:left" type="number" 
                                                 @change="Item.qtdeProduto = parseInt($event.target.value.replace(',', '.'));
                                                 Item.valorTotalProduto = Item.valorProduto*Item.qtdeProduto"
                                                  :value="Item.qtdeProduto">
                                              </td>
                                   <td class="tamdado">
                                                <span style="float:left;padding-top:2px">R$</span>
                                                <input style="width:50%;float:left" type="number" step=".01"
                                                 @change="Item.valorProduto = parseFloat($event.target.value.replace(',', '.'));
                                                 Item.valorTotalProduto = Item.valorProduto*Item.qtdeProduto"
                                                  :value="Item.valorProduto">
                                              </td>
                                           
                                    <td>
                                      <a
                                        @click="
                                          ApagarProdutoSelect(
                                            Item.produto.produtoId
                                          )
                                        "
                                        ><i
                                          class="fa fa-trash red"
                                          style="font-size: 20px"
                                        ></i
                                      ></a>
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(
                                      Item, Index
                                    ) in ListaPedidoItemAdicional"
                                    :key="Index"
                                  >
                                    <td>
                                      AV{{ Item.itemAvulso.itemAvulsoId }}
                                    </td>
                                    <td>
                                      {{ Item.itemAvulso.nomeItemAvulso }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.qtdeProduto }}
                                    </td>

                                    <td class="tamdado">
                                      R$
                                      {{
                                        Item.valorTotalProduto
                                          .toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                          .replace(".", ",")
                                          .replace("$", "")
                                      }}
                                    </td>
                                    <td>
                                      <a
                                        @click="
                                          ApagarAdicionalSelect(
                                            Item.itemAvulso.itemAvulsoId
                                          )
                                        "
                                        ><i
                                          class="fa fa-trash red"
                                          style="font-size: 20px"
                                        ></i
                                      ></a>
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(
                                      Item, Index
                                    ) in ListaPedidoItemManual"
                                    :key="Index"
                                  >
                                    <td>
                                      AVM{{
                                        Item.itemAvulsoManual.itemAvulsoManualId
                                      }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.itemAvulsoManual.nomeItem }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.qtdeProduto }}
                                    </td>
                                    <td class="tamdado">
                                      R$
                                      {{
                                        Item.valorTotalProduto
                                          .toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                          .replace(".", ",")
                                          .replace("$", "")
                                      }}
                                    </td>

                                    <td>
                                      <a
                                        @click="
                                          ApagarAvulsoManual(
                                            Item.itemAvulsoManual
                                              .itemAvulsoManualId
                                          )
                                        "
                                        ><i
                                          class="fa fa-trash red"
                                          style="font-size: 20px"
                                        ></i
                                      ></a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                      <!-- Step 2 -->
                      <!-- <h5 id="steps-uid-0-h-1" tabindex="-1" class="title">
                        Cliente
                      </h5> -->
                      <section v-if="Step == 2" class="body">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Tipo</label>
                              <v-select
                                v-model="Cliente.tipoCliente"
                                :options="TiposCliente"
                                single-line
                                label="nomeTipoCliente"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div
                              class="form-group"
                              v-if="
                                Cliente.tipoCliente != null &&
                                Cliente.tipoCliente.tipoClienteId == 2
                              "
                            >
                              <label>CNPJ</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Cliente.cnpj"
                              />
                            </div>
                            <div class="form-group" v-else>
                              <label>CPF</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Cliente.cpf"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Whatsapp</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Cliente.whatsapp"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 30px"
                              >
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="VerificarCliente()"
                                >
                                  Verificar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>E-mail</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Cliente.email"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Nome</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Cliente.nomeEmpresa"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <!-- Step 3 -->
                      <!-- <h5 id="steps-uid-0-h-2" tabindex="-1" class="title">
                        Entrega
                      </h5> -->
                      <section v-if="Step == 3" class="body">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <label>Endereço</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="PedidoEndereco.endereco"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Nº</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                @change="
                                  PedidoEndereco.numero != null
                                    ? (PedidoEndereco.numero = parseInt(
                                        PedidoEndereco.numero,
                                        10
                                      ))
                                    : (PedidoEndereco.numero = 0)
                                "
                                v-model="PedidoEndereco.numero"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Compl.</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="PedidoEndereco.complemento"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Estado</label>
                              <v-select
                                v-model="Estado"
                                @input="MudaEstado"
                                :options="Estados"
                                single-line
                                label="nomeEstado"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Cidade</label>
                              <v-select
                                v-model="Cidade"
                                @input="MudaCidade"
                                :options="Cidades"
                                single-line
                                label="nomeCidade"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Bairro</label>
                              <!-- <v-select
                                v-model="Bairro"
                                @input="MudaBairro"
                                :options="Bairros"
                                single-line
                                label="nomeBairro"
                                class="style-chooser"
                              ></v-select> -->
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="PedidoEndereco.bairro"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>CEP</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="PedidoEndereco.cep"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <div
                                class="col-md-12 text-center"
                                style="padding-top: 20px"
                              >
                                <button
                                  type="button"
                                  class="btn btn-info"
                                  id="sa-custom-position"
                                  @click="VerificarFrete()"
                                >
                                  Verificar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-8">
                            <label v-if="OpcoesFrete.length > 0"
                              >Selecione o frete:</label
                            >
                            <ul
                              style="
                                text-align: left;
                                max-height: 200px;
                                overflow-y: scroll;
                              "
                            >
                              <li
                                v-for="(Opcao, Index) in OpcoesFrete"
                                :key="Index"
                                style="padding: 3px; font-weight: bold"
                              >
                                <input
                                  class="radioFrete"
                                  type="radio"
                                  name="frete"
                                  :value="JSON.stringify(Opcao)"
                                  @change="SelecionarFrete"
                                />
                                {{ Opcao.nome }} -
                                {{
                                  "R$" +
                                  Opcao.valor
                                    .toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                    .replace(".", ",")
                                    .replace("$", "")
                                }}
                                - {{ Opcao.prazo + " Dias" }}
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Valor Frete</label>
                              R$
                              <input
                                disabled
                                class="form-control"
                                type="text"
                                placeholder=""
                                enab
                                v-model="Pedido.valorFrete"
                                @change="
                                  Pedido.valorFrete != null
                                    ? (Pedido.valorFrete = parseFloat(
                                        Pedido.valorFrete.replace(',', '.'),
                                        10
                                      ))
                                    : (Pedido.valorFrete = 0.0)
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <!-- Step 4 -->
                      <!-- <h5 id="steps-uid-0-h-3" tabindex="-1" class="title">
                        Mensagem
                      </h5> -->
                      <!-- <section v-if="Step == 4" class="body">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Mensagem Avulsa</label>
                              <v-select
                                v-model="MensagemSelecionada"
                                :options="Mensagens"
                                @input="MudaMensagem()"
                                single-line
                                label="nome"
                                class="style-chooser"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>De</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Mensagem.nomeDe"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Para</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Mensagem.nomePara"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Telefone</label>
                              <input
                                class="form-control"
                                type="text"
                                placeholder=""
                                v-model="Mensagem.telefoneRecebe"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Foto Avulsa</label>
                              <v-select
                                v-model="Foto"
                                :options="Fotos"
                                single-line
                                label="urlImagem"
                                class="style-chooser"
                              ></v-select>
                              <br />
                              <img
                                style="width: 100px"
                                v-if="Foto != null && Foto.urlImagem.length > 0"
                                :src="$baseUrl + Foto.urlImagem"
                              />
                            </div>
                          </div>
                        </div>
                      </section> -->
                      <!-- Step 5 -->
                      <!-- <h5 id="steps-uid-0-h-4" tabindex="-1" class="title">
                        Finalização
                      </h5> -->
                      <section v-if="Step == 4" class="body">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <label>Forma de pagamento</label>
                              <v-select
                                v-model="Pedido.formaPgto"
                                :options="ListaFormasPagamento"
                                single-line
                                :reduce="(x) => x.texto"
                                label="texto"
                                class="col-12"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Valor</label>
                              <input
                                class="form-control"
                                type="text"
                                :value="
                                  'R$ ' +
                                  CalculaValorPedido()
                                    .toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })
                                    .replace('.', ',')
                                    .replace('$', '')
                                "
                                disabled=""
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12" style="padding-bottom: 40px">
                            <h2 class="h4 pd-20">Resultado</h2>
                            <div class="table-responsive">
                              <table class="table table-hover">
                                <thead>
                                  <tr>
                                    <th>Cód Produto</th>
                                    <th>Nome produto</th>
                                    <th>Qtde</th>
                                    <th>Valor</th>
                                    <th>Excluir</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="Item in ListaPedidoItem.length > 0
                                      ? ListaPedidoItem.filter(
                                          (x) => x.produto != null
                                        )
                                      : []"
                                    :key="Item.pedidoItemId"
                                  >
                                    <td>{{ Item.produto.referencia }}</td>

                                    <td class="tamdado">
                                      {{ Item.produto.nomeProduto }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.qtdeProduto }}
                                    </td>

                                    <td class="tamdado">
                                      R$
                                      {{
                                        Item.valorTotalProduto
                                          .toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                          .replace(".", ",")
                                          .replace("$", "")
                                      }}
                                    </td>
                                    <td>
                                      <a
                                        @click="
                                          ApagarProdutoSelect(
                                            Item.produto.produtoId
                                          )
                                        "
                                        ><i
                                          class="fa fa-trash red"
                                          style="font-size: 20px"
                                        ></i
                                      ></a>
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(
                                      Item, Index
                                    ) in ListaPedidoItemAdicional"
                                    :key="Index"
                                  >
                                    <td>
                                      AV{{ Item.itemAvulso.itemAvulsoId }}
                                    </td>
                                    <td>
                                      {{ Item.itemAvulso.nomeItemAvulso }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.qtdeProduto }}
                                    </td>

                                    <td class="tamdado">
                                      R$
                                      {{
                                        Item.valorTotalProduto
                                          .toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                          .replace(".", ",")
                                          .replace("$", "")
                                      }}
                                    </td>
                                    <td>
                                      <a
                                        @click="
                                          ApagarAdicionalSelect(
                                            Item.itemAvulso.itemAvulsoId
                                          )
                                        "
                                        ><i
                                          class="fa fa-trash red"
                                          style="font-size: 20px"
                                        ></i
                                      ></a>
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(
                                      Item, Index
                                    ) in ListaPedidoItemManual"
                                    :key="Index"
                                  >
                                    <td>
                                      AVM{{
                                        Item.itemAvulsoManual.itemAvulsoManualId
                                      }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.itemAvulsoManual.nomeItem }}
                                    </td>
                                    <td class="tamdado">
                                      {{ Item.qtdeProduto }}
                                    </td>
                                    <td class="tamdado">
                                      R$
                                      {{
                                        Item.valorTotalProduto
                                          .toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                          .replace(".", ",")
                                          .replace("$", "")
                                      }}
                                    </td>

                                    <td>
                                      <a
                                        @click="
                                          ApagarAvulsoManual(
                                            Item.itemAvulsoManual
                                              .itemAvulsoManualId
                                          )
                                        "
                                        ><i
                                          class="fa fa-trash red"
                                          style="font-size: 20px"
                                        ></i
                                      ></a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div class="col-lg-12" style="text-align: right">
                      Frete ({{ Pedido.tipoFrete }}):
                      {{
                        "R$ " +
                        Pedido.valorFrete
                          .toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                          .replace(".", ",")
                          .replace("$", "")
                      }}
                    </div>
                    <div class="col-lg-12">
                      <div
                        style="
                          font-size: 15px;
                          font-weight: 800;
                          text-align: right;
                        "
                      >
                        Valor Total
                        {{
                          "R$ " +
                          CalculaValorPedido()
                            .toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                            .replace(".", ",")
                            .replace("$", "")
                        }}
                      </div>
                    </div>
                    <div class="actions clearfix" style="padding-top: 20px">
                      <ul role="menu" aria-label="Paginador">
                        <li class="disabled" aria-disabled="true">
                          <a
                            @click="Step--"
                            role="menuitem"
                            v-if="Step > 1"
                            style="color: white"
                            >Anterior</a
                          >
                        </li>
                        <li aria-hidden="false" aria-disabled="false">
                          <a
                            @click="ProximoStep()"
                            role="menuitem"
                            v-if="Step < 4"
                            style="color: white"
                            >Próximo</a
                          >
                        </li>
                        <li aria-hidden="true" v-if="Step == 4">
                          <a
                            @click="Finalizar()"
                            role="menuitem"
                            style="color: white"
                            >Concluir</a
                          >
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- success Popup html Start -->
        <!-- <div
          class="modal fade"
          id="success-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body text-center font-18">
                <h3 class="mb-20">Pedido XXXXXXXXXXX</h3>
                <div class="mb-30 text-center">
                  <img src="vendors/images/success.png" />
                </div>
                Pedido XXXXXXXXXXX criado com sucesso!
              </div>
              <div class="modal-footer justify-content-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- success Popup html End -->
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import dateFormat from "../../public/date.format.js";
import vSelect from "vue-select";
import moment from "moment";
// import * as momentTemp from "moment";
// const moment = momentTemp["default"];

export default {
  data() {
    return {
      ListaFormasPagamento: [],
      ListaFormasPagamentoBanco: [],
      ListaFormasPagamentoPix: [],
      ListaFormasPagamentoOperadora: [],
      OpcoesFrete: [],
      Produtos: [],
      //step5
      TipoPagamento: "",
      //step5
      //step4
      Mensagens: [],
      MensagemSelecionada: 0,
      Mensagem: {
        mensagemAvulsaId: 0,
        nomeDe: "",
        nomePara: "",
        texto: "",
        telefoneRecebe: "",
        urlImagem: "",
      },
      Fotos: [],
      Foto: {
        fotoAvulsaId: 0,
        urlImagem: "",
        nomeDe: "",
        nomePara: "",
        telefoneRecebe: "",
      },
      //step4
      //step3
      DataEntrega: "",

      ValorFrete: 0.0,

      Bairros: [],
      Bairro: {},

      Cidades: [],
      Cidade: {},

      Estados: [],
      Estado: {},

      Horarios: [],
      Horario: {},
      //step3
      //step2
      TiposCliente: [],
      Cliente: {
        cpf: "",
        cnpj: "",
        whatsapp: "",
        nome: "",
        email: "",
        clienteId: 0,
      },
      //step2
      //step1
      ItemManualNovo: {
        valor: 0.0,
        nomeItem: "",
      },
      ItemManual: {},
      ItensManuais: [],

      ItemAdicional: {},
      ItensAdicionais: [],

      ItemPedido: {},
      ItensPedido: [],

      ListaPedidoItemManual: [],
      ListaPedidoItemAdicional: [],
      ListaPedidoItem: [],
      //step1
      Step: 1,
      Data1: "",
      Pedido: {
        pedidoId: 0,
        cliente: { clienteId: 0 },
        situacaoPedido: "Aguardando",
        dataPedido: "",
        listaPedidoItemManual: [],
        listaPedidoitemAdicional: [],
        listaPedidoItem: [],
        valorFrete: 0.0,
        valorCupom: 0.0,
        tipoFrete: "Frete Manual",
        formaPgto: this.TipoPagamento,
        origemPedido: "Pedido Manual",
      },
      PedidoPagtoComprova: {
        pedidoPagtoComprovaId: 0,
        urlImg: "",
      },
      PedidoEndereco: {
        pedidoEnderecoId: 0,
        endereco: "",
        pais: {},
        estado: {},
        cidadde: {},
        bairro: "",
        cep: "",
        numero: 0,
      },
      PedidoAgendamento: {
        pedidoAgendamentoId: 0,
        horaIni: 0,
        minIni: 0,
        horaFim: 0,
        minFim: 0,
        diaEntrega: "",
      },
      PedidoFoto: {
        pedidoFotoId: 0,
        urlImagem: "",
        valroFoto: 0.0,
      },
      PedidoMensagem: {
        pedidoMensagemId: 0,
        urlImagem: "",
        nomeDe: "",
        nomePara: "",
        texto: "",
        telefoneRecebe: "",
      },
      Frete: {},
    };
  },
  methods: {
    SelecionarFrete(event) {
      var sender = event.target;
      if (sender != null && sender.value != null && sender.value.length > 0) {
        var obj = JSON.parse(sender.value);
        if (obj != null) {
          this.Pedido.tipoFrete = obj.nome + " - " + obj.prazo + " Dias";
          this.Pedido.valorFrete = obj.valor;
          this.Frete = obj;
        }
      }
    },
    VerificarFrete() {
      if (
        this.PedidoEndereco.cep != null &&
        this.PedidoEndereco.cep.length > 0
      ) {
        var dados;
        var cepOrigem = "";
        this.$http.get(this.$apiUrl + "/entregacorreio/").then((response) => {
          if (response.ok && response.body.length > 0) {
            dados = response.body[0];
            cepOrigem = dados.cep;
            var freteEnvia = {
              produtos: this.Produtos,
              cepOrigem: cepOrigem,
              cepDestino: this.PedidoEndereco.cep,
              correios: true,
              entregas: true,
            };
            this.$http
              .post(this.$apiUrl + "/EntregaFrete/Calcular", freteEnvia)
              .then(
                (response) => {
                  if (response.ok) {
                    this.OpcoesFrete = response.body;
                  }
                },
                (error) => {
                  this.$mensagem_erro(error.bodyText);
                }
              );
          }
        });
      } else {
        this.$mensagem_erro("Preencha o cep para calcular o frete");
      }
    },
    Limpar() {
      localStorage.clear();
      this.$redirect_reload("/controle/pedido/" + this.Pedido.pedidoId);
      // this.Step = 1;
    },
    MudaMensagem() {
      if (
        this.MensagemSelecionada != null &&
        this.MensagemSelecionada.valor > 0
      ) {
        this.$http
          .get(
            this.$apiUrl + "/mensagemavulsa/" + this.MensagemSelecionada.valor
          )
          .then((response) => {
            if (response.ok) {
              this.Mensagem = response.body;
            }
          });
      } else {
        this.Mensagem = {
          mensagemAvulsaId: 0,
          nomeDe: "",
          nomePara: "",
          texto: "",
          telefoneRecebe: "",
          urlImagem: "",
        };
      }
    },
    ProximoStep() {
      var ok = true;
      if(this.Step == 1)
      {
        localStorage.ListaPedidoItem = JSON.stringify(this.ListaPedidoItem);
      }
      if (this.Step == 2) {
        localStorage.Cliente = JSON.stringify(this.Cliente);
      }
      if (this.Step == 3) {
        ok = false;
        localStorage.PedidoAgendamento = JSON.stringify(this.PedidoAgendamento);
        localStorage.PedidoEndereco = JSON.stringify(this.PedidoEndereco);
        localStorage.Pedido = JSON.stringify(this.Pedido);
        var all = document.getElementsByClassName("radioFrete");
        if(this.OpcoesFrete != null && this.OpcoesFrete.length > 0 && all.length > 0 && Object.keys(this.Frete).length > 0)
        {
          ok = true;
        }
      }
      // if (this.Step == 4) {
      //   localStorage.Mensagem = JSON.stringify(this.Mensagem);
      //   localStorage.Foto = JSON.stringify(this.Foto);
      // }
      if (ok) this.Step++;
      else if (this.Step == 3) {
        this.$mensagem_erro("Selecione o frete antes de seguir.");
      }
    },
    // MudaData() {
    //   this.PedidoAgendamento.diaEntrega = moment(this.DataEntrega)
    //     .format("YYYY-MM-DD HH:mm:ss")
    //     .replace(" ", "T");
    // },
    MudaHorario() {
      if (this.Horario != null && this.Horario.valor > 0) {
        this.$http
          .get(this.$apiUrl + "/entregahorario/" + this.Horario.valor)
          .then((response) => {
            var tmp = response.body;
            console.log(response);
            if (tmp != null) {
              this.PedidoAgendamento.horaIni = tmp.horaIni;
              this.PedidoAgendamento.horaFim = tmp.horaFim;
              this.PedidoAgendamento.minIni = tmp.minIni;
              this.PedidoAgendamento.minFim = tmp.minFim;
            }
          });
      }
    },
    MudaEstado() {
      this.Cidades = [];
      this.Cidade = null;
      this.$http
        .get(
          this.$apiUrl +
            "/cidades/ativosEstados/" +
            parseInt(this.Estado.estadoId)
        )
        .then((res) => res.json())
        .then((tiu) => (this.Cidades = tiu));
      this.PedidoEndereco.estado = this.Estado;
    },
    MudaCidade() {
      this.Bairros = [];
      this.Bairro = null;
      this.$http
        .get(
          this.$apiUrl +
            "/bairro/ativosBairros/" +
            parseInt(this.Cidade.cidadeId)
        )
        .then((res) => res.json())
        .then((tiu) => (this.Bairros = tiu));
      this.PedidoEndereco.cidade = this.Cidade;
    },
    // MudaBairro() {
    //   this.PedidoEndereco.bairro = this.Bairro;
    // },
    VerificarCliente() {
      if (
        (this.Cliente.cpf != null && this.Cliente.cpf.length > 0) ||
        (this.Cliente.cnpj != null && this.Cliente.cnpj.length > 0) ||
        (this.Cliente.whatsapp != null && this.Cliente.whatsapp.length > 0)
      ) {
        this.$http.post(this.$apiUrl + "/clientes/busca", this.Cliente).then(
          (response) => {
            if (response.ok) {
              if (response.bodyText.length > 0) this.Cliente = response.body;
            }
          },
          (error) => {
            if (!error.ok) {
              this.$mensagem_erro(
                "Nenhum cliente com estes dados foi encontrado"
              );
            }
          }
        );
      } else
        this.$mensagem_erro(
          "Preencha o CPF/CNPJ ou o Whatsapp para verificar a existência do cliente na base de dados"
        );
    },
    ApagarProdutoSelect(produtoId) {
      var index = this.ListaPedidoItem.indexOf(
        this.ListaPedidoItem.find(
          (x) => x.produto != null && x.produto.produtoId == produtoId
        )
      );
      this.ListaPedidoItem.splice(index, 1);
      localStorage.ListaPedidoItem = JSON.stringify(this.ListaPedidoItem);
      this.Produtos = this.Produtos.filter((x) => x.produtoId != produtoId);
    },
    ApagarAdicionalSelect(itemAvulsoId) {
      var index = this.ListaPedidoItemAdicional.indexOf(
        this.ListaPedidoItemAdicional.find(
          (x) => x.item != null && x.item.itemAvulsoId == itemAvulsoId
        )
      );
      this.ListaPedidoItemAdicional.splice(index, 1);
      localStorage.ListaPedidoItemAdicional = JSON.stringify(
        this.ListaPedidoItemAdicional
      );
    },
    ApagarAvulsoManual(itemAvulsoManualId) {
      var index = this.ListaPedidoItemManual.indexOf(
        this.ListaPedidoItemManual.find(
          (x) =>
            x.itemAvulsoManual != null &&
            x.itemAvulsoManual.itemAvulsoManualId == itemAvulsoManualId
        )
      );
      this.ListaPedidoItemManual.splice(index, 1);
      localStorage.ListaPedidoItemManual = JSON.stringify(
        this.ListaPedidoItemManual
      );
    },
    InserirManualNovo() {
      if (this.ItemManualNovo.nomeItem.length > 0) {
        this.$http
          .post(this.$apiUrl + "/ItemAvulsoManual", this.ItemManualNovo)
          .then(
            (response) => {
              if (response.ok) {
                var tmp = response.body;
                this.InserirManualSelect(tmp);
              }
            },
            (error) => {
              console.log(error);
              this.$mensagem_erro("Algo deu errado ao adicionar o item");
            }
          );
      } else this.$mensagem_erro("Preencha o nome do item a ser adicionado.");
    },
    InserirAvulsoSelect(Item) {
      if (Item != null && Item.itemAvulsoId > 0) {
        var novo = {
          pedido: null,
          itemAvulso: Item,
          qtdeProduto: 1,
          valorProduto: Item.valorDe,
          valorTotalProduto: Item.valorDe,
          dataCadastro: new Date().toISOString().split("T")[0],
        };
        this.ListaPedidoItemAdicional.push(novo);
        localStorage.ListaPedidoItemAdicional = JSON.stringify(
          this.ListaPedidoItemAdicional
        );
      }
    },
    InserirProdutoSelect(Item) {
      if (Item != null && Item.produtoId > 0) {
        this.Produtos.push(Item);
        var novo = {
          pedidoItemId: 0,
          pedido: null,
          produto: Item,
          qtdeProduto: 1,
          valorProduto: Item.valorAte,
          valorTotalProduto: Item.valorAte,
          dataCadastro: new Date().toISOString().split("T")[0],
        };
        this.ListaPedidoItem.push(novo);
        localStorage.ListaPedidoItem = JSON.stringify(this.ListaPedidoItem);
      }
    },
    InserirManualSelect(Item) {
      if (Item != null && Item.itemAvulsoManualId > 0) {
        var novo = {
          pedidoItemManualId: 0,
          pedidoId: null,
          qtdeProduto: 1,
          valorProduto: Item.valor,
          valorTotalProduto: Item.valor,
          dataCadastro: new Date().toISOString().split("T")[0],
          itemAvulsoManual: Item,
        };
        this.ListaPedidoItemManual.push(novo);
        localStorage.ListaPedidoItemManual = JSON.stringify(
          this.ListaPedidoItemManual
        );
      }
    },
    getClasseLiStep(sender) {
      var retorno = "";
      if (sender == this.Step) {
        retorno = "current";
      }
      if (sender < this.Step) {
        retorno = "done disabled";
      }
      if (sender > this.Step) {
        retorno = "disabled";
      }
      return retorno;
    },
    Finalizar() {
      //AQUI script de finalização
      this.Pedido.dataPedido = moment(new Date())
        .format("YYYY-MM-DD HH:mm:ss")
        .replace(" ", "T");
      //valida
      var itens =
        this.ListaPedidoItemManual.length +
        this.ListaPedidoItemAdicional.length +
        this.ListaPedidoItem.length;
      if (itens <= 0) {
        this.$mensagem_erro("Por favor selecione ao menos um item.");
        this.Step = 1;
      } else if (
        this.PedidoEndereco == null ||
        this.PedidoEndereco.endereco.length <= 0 ||
        this.Pedido.tipoFrete == null ||
        this.Pedido.tipoFrete.length == 0
      ) {
        this.$mensagem_erro(
          "Por favor preencha o endereço e selecione o horário."
        );
        this.Step = 2;
      } else if (
        this.Cliente == null ||
        (this.Cliente.cpf.length <= 0 && this.Cliente.cnpj.length <= 0) ||
        this.Cliente.email.length <= 0 ||
        this.Cliente.nomeEmpresa.length <= 0
      ) {
        this.$mensagem_erro("Por favor preencha os dados do cliente.");
        this.Step = 3;
      } else if (
        this.Pedido.formaPgto == null &&
        this.Pedido.formaPgto.length <= 0
      ) {
        this.$mensagem_erro("Por favor selecione o modo de pagamento.");
      } else {
        //cadastra
        this.Pedido.cliente = null;
        this.$http.post(this.$apiUrl + "/pedido", this.Pedido).then(
          (response) => {
            if (response.ok) this.Pedido = response.body;
            if (this.Pedido.pedidoId > 0) {
              //AQUI pedido ja foi cadastrado ligar ele e cadastrar:
              //listapedidoitem - listapedidoitemadicional- listapedidaitemmanual - Cliente - PedidoEndereco - PedidoAgendamento - Mensagem - Foto
              var cont = 0;
              var erros = "";
              var ok = 0;
              ok += this.ListaPedidoItemManual.length;
              ok += this.ListaPedidoitemAdicional;
              ok += this.ListaPedidoItem.length;
              ok += 4;
              //listapedidoitem
              for (cont = 0; cont < this.ListaPedidoItem.length; cont++) {
                var tmp = this.ListaPedidoItem[cont];
                if (tmp != null) {
                  tmp.pedido = {
                    pedidoId: this.Pedido.pedidoId,
                  };
                  this.$http.post(this.$apiUrl + "/pedidoitem", tmp).then(
                    (response) => {
                      if (response.ok) {
                        ok--;
                      }
                    },
                    (error) => {
                      erros += "\n\n" + error.bodyText;
                    }
                  );
                }
              }
              //listapedidoitemAdicional
              for (
                cont = 0;
                cont < this.ListaPedidoItemAdicional.length;
                cont++
              ) {
                var adicional = this.ListaPedidoItemAdicional[cont];
                adicional.pedido = {
                  pedidoId: this.Pedido.pedidoId,
                };
                this.$http
                  .post(this.$apiUrl + "/pedidoitemadicional", adicional)
                  .then(
                    (response) => {
                      if (response.ok) {
                        ok--;
                      }
                    },
                    (error) => {
                      erros += "\n\n" + error.bodyText;
                    }
                  );
              }
              //listapedidoitemManual
              for (cont = 0; cont < this.ListaPedidoItemManual.length; cont++) {
                var manual = this.ListaPedidoItemManual[cont];
                manual.pedido = {
                  pedidoId: this.Pedido.pedidoId,
                };

                this.$http
                  .post(this.$apiUrl + "/PedidoItemManual", manual)
                  .then(
                    (response) => {
                      if (response.ok) {
                        ok--;
                      }
                    },
                    (error) => {
                      erros += "\n\n" + error.bodyText;
                    }
                  );
              }
              //cliente
              console.log("CLIENTE:");
              console.log(this.Cliente);

              if (this.Cliente.clienteId == 0) {
                //novo cliente
                this.$http
                  .post(this.$apiUrl + "/clientes/semvalidacao", this.Cliente)
                  .then(
                    (response) => {
                      if (response.ok) {
                        console.log(
                          "novo cliente - " + response.body.clienteId
                        );
                        console.log(response);
                        this.Cliente = response.body;
                        this.Pedido.Cliente = this.Cliente;
                        this.Pedido.clienteId = response.bodyText.clienteId;
                        this.$http.put(
                          this.$apiUrl + "/pedido/" + this.Pedido.pedidoId,
                          this.Pedido
                        );
                        ok--;
                      } else {
                        console.log("ERRO NOVO CLIENTE:" + response.bodyText);
                      }
                    },
                    (error) => {
                      erros += "\n\n" + error.bodyText;
                    }
                  );
              } else {
                //atualiza cliente
                this.$http
                  .put(
                    this.$apiUrl +
                      "/clientes/semvalidacao/" +
                      this.Cliente.clienteId,
                    this.Cliente
                  )
                  .then(
                    (response) => {
                      if (response.ok) {
                        console.log("atualiza - " + this.Cliente.clienteId);
                        console.log(response);
                        //this.Cliente = response.body;
                        this.Pedido.Cliente = this.Cliente;
                        this.Pedido.clienteId = response.bodyText.clienteId;
                        this.$http.put(
                          this.$apiUrl + "/pedido/" + this.Pedido.pedidoId,
                          this.Pedido
                        );
                        ok--;
                      }
                    },
                    (error) => {
                      erros += "\n\n" + error.bodyText;
                    }
                  );
              }
              //endereco
              this.PedidoEndereco.estado = this.Estado;
              this.PedidoEndereco.cidadde = this.Cidade;
              this.PedidoEndereco.bairro = this.Bairro;
              this.PedidoEndereco.pedido = this.Pedido;

              console.log("ENDERECO:");
              console.log(this.PedidoEndereco);

              this.$http
                .post(this.$apiUrl + "/pedidoendereco", this.PedidoEndereco)
                .then(
                  (response) => {
                    if (response.ok) {
                      ok--;
                    }
                  },
                  (error) => {
                    erros += "\n\n" + error.bodyText;
                  }
                );
              //agendamento
              this.PedidoAgendamento.pedido = this.Pedido;
              //this.PedidoAgendamento.horaIni = this
              this.$http
                .post(
                  this.$apiUrl + "/pedidoagendamento",
                  this.PedidoAgendamento
                )
                .then(
                  (response) => {
                    if (response.ok) {
                      ok--;
                    }
                  },
                  (error) => {
                    erros += "\n\n" + error.bodyText;
                  }
                );
              //mensagem
              if (this.Mensagem.mensagemAvulsaId > 0) {
                var PedidoMensagem = {
                  pedidoMensagemId: 0,
                  nomeDe: this.Mensagem.nomeDe,
                  nomePara: this.Mensagem.nomePara,
                  telefoneRecebe: this.Mensagem.telefoneRecebe,
                  texto: this.Mensagem.texto,
                  urlImagem: this.Mensagem.urlImagem,
                  pedido: this.Pedido,
                };
                this.$http
                  .post(this.$apiUrl + "/pedidomensagem", PedidoMensagem)
                  .then(
                    (response) => {
                      if (response.ok) {
                        ok--;
                      }
                    },
                    (error) => {
                      erros += "\n\n" + error.bodyText;
                    }
                  );
              }
              //foto
              if (this.Foto.fotoAvulsaId > 0) {
                var FotoAvulsa = {
                  urlImagem: this.Foto.urlImagem,
                  pedido: this.Pedido,
                };
                this.$http.post(this.$apiUrl + "/pedidofoto", FotoAvulsa).then(
                  (response) => {
                    if (response.ok) {
                      ok--;
                    }
                  },
                  (error) => {
                    erros += "\n\n" + error.bodyText;
                  }
                );
              }
              //atualiza pedido.cliente
              this.Pedido.Cliente = this.Cliente;
              console.log("Pedido Atualiza final");
              console.log(this.Pedido);
              this.$http
                .put(
                  this.$apiUrl + "/pedido/" + this.Pedido.pedidoId,
                  this.Pedido
                )
                .then(
                  (response) => {
                    console.log(response);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              //erros
              if (erros.length > 0 && ok != 0) console.log(erros + "\n\n" + ok);
              else this.Limpar();
            }
          },
          (error) => {
            this.$mensagem_erro(error.bodyText);
          }
        );
        // .then({
        //   if(this.Pedido.pedidoId > 0){

        //   }
        // });
      }
    },
    sqlToJsDate(sqlDate) {
      //alert(sqlDate)
      sqlDate = sqlDate.replace("T", " ");
      if (sqlDate != null && sqlDate.length > 0) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        var sSecond = sqlDateArr3[2];
        // var sqlDateArr4 = sqlDateArr3[2].split(".");
        // //format of sqlDateArr4[] = ['ss','ms']
        // var sSecond = sqlDateArr4[0];
        // var sMillisecond = sqlDateArr4[1];

        return new Date(
          sYear,
          sMonth,
          sDay,
          sHour,
          sMinute,
          sSecond,
          0
        ).toLocaleDateString("pt-BR");
      } else {
        return "";
      }
    },
    CalculaValorPedido() {
      var total = 0;
      total += this.Pedido.valorFrete;
      total -= this.Pedido.valorCupom;

      if (this.ListaPedidoItem != null && this.ListaPedidoItem.length > 0) {
        this.ListaPedidoItem.forEach((item) => {
          total += (item.valorProduto * item.qtdeProduto);
        });
      }

      if (
        this.ListaPedidoItemAdicional != null &&
        this.ListaPedidoItemAdicional.length > 0
      ) {
        this.ListaPedidoItemAdicional.forEach((item) => {
          total += (item.valorProduto * item.qtdeProduto);
        });
      }

      if (
        this.ListaPedidoItemManual != null &&
        this.ListaPedidoItemManual.length > 0
      ) {
        this.ListaPedidoItemManual.forEach((item) => {
          total += (item.valorProduto * item.qtdeProduto);
        });
      }

      return total;
    },
    Carregar() {
      if (localStorage.Pedido != null) {
        this.Pedido = JSON.parse(localStorage.Pedido);
        this.Pedido.tipoFrete = "Frete";
        this.Pedido.valorFrete = 0;
      }
      if (localStorage.Cliente != null) {
        this.Cliente = JSON.parse(localStorage.Cliente);
      }

      if (localStorage.PedidoAgendamento != null) {
        this.PedidoAgendamento = JSON.parse(localStorage.PedidoAgendamento);
        this.DataEntrega = this.PedidoAgendamento.diaEntrega = moment(
          this.PedidoAgendamento.diaEntrega
        ).format("YYYY-MM-DD");
        if (localStorage.Horario != null) {
          this.Horario = JSON.parse(localStorage.Horario);
        }
      }

      if (localStorage.PedidoFoto != null) {
        this.PedidoFoto = JSON.parse(localStorage.PedidoFoto);
      }
      if (localStorage.PedidoMensagem != null) {
        this.PedidoMensagem = JSON.parse(localStorage.PedidoMensagem);
      }
      if (localStorage.ListaPedidoItemManual != null) {
        this.ListaPedidoItemManual = JSON.parse(
          localStorage.ListaPedidoItemManual
        );
      }
      if (localStorage.ListaPedidoItemAdicional != null) {
        this.ListaPedidoItemAdicional = JSON.parse(
          localStorage.ListaPedidoItemAdicional
        );
      }
      if (localStorage.ListaPedidoItem != null) {
        this.ListaPedidoItem = JSON.parse(localStorage.ListaPedidoItem);
        var count = 0;
        for (count = 0; count < this.ListaPedidoItem.length; count++) {
          var tmp = this.ListaPedidoItem[count];
          if (tmp != null && tmp.produto != null) {
            this.Produtos.push(tmp.produto);
          }
        }
        if (localStorage.PedidoEndereco != null) {
          this.PedidoEndereco = JSON.parse(localStorage.PedidoEndereco);

          if (this.PedidoEndereco.estado != null) {
            this.Estado = this.PedidoEndereco.estado;
            this.MudaEstado();
          }
          if (this.PedidoEndereco.cidade != null) {
            this.Cidade = this.PedidoEndereco.cidade;
            this.MudaCidade();
          }
          // if (
          //   this.PedidoEndereco.cep != null &&
          //   this.PedidoEndereco.cep.length > 0
          // ) {
          //   this.VerificarFrete();
          //PAREI AQUI, TA DANDO ERRO AO LER O VALUE, PARECE Q POR CONTA O ASYNC
          //OU POR CONTA DE ESTAR LENDO DOCUMENT.GETELEMNTS EM ELEMENTOS DENTRO DO V-FOR
          //SE N DER CERTO BLOQUEIA O PASSO ATÉ SELECIONAR O FRET
          // if (localStorage.Frete != null) {
          //   this.Frete = JSON.parse(localStorage.Frete);
          //   if (this.Frete != null) {
          //     this.Pedido.tipoFrete =
          //     this.Frete.nome + " - " + this.Frete.prazo + " Dias";
          //     this.Pedido.valorFrete = this.Frete.valor;
          //     var all = document.getElementsByClassName("radioFrete");
          //     var cont = 0;
          //     for (cont = 0; cont <= all.length; cont++) {
          //       var element = all[cont];
          //       if (element.value == localStorage.Frete) {
          //         all[cont].checked = true;
          //       }
          //     }
          //   }
          // }
          //}
          // if (this.PedidoEndereco.bairro != null) {
          //   this.Bairro = this.PedidoEndereco.bairro;
          //   this.MudaBairro();
          // }
        }
      }

      this.$http.get(this.$apiUrl + "/ItemAvulsoManual").then((response) => {
        this.ItensManuais = response.body;
      });

      this.$http.get(this.$apiUrl + "/Produto").then((response) => {
        this.ItensPedido = response.body;
      });

      this.$http.get(this.$apiUrl + "/ItemAvulso").then((response) => {
        this.ItensAdicionais = response.body;
      });

      this.$http.get(this.$apiUrl + "/TipoClientes").then((response) => {
        this.TiposCliente = response.body;
      });

      this.$http
        .get(this.$apiUrl + "/estados/ativos")
        .then((res) => res.json())
        .then((tiu) => {
          this.Estados = tiu;
        });

      // this.$http.get(this.$apiUrl + "/EntregaHorario").then((response) => {
      //   var temp = response.body;
      //   var cont = 0;
      //   for (cont = 0; cont < temp.length; cont++) {
      //     var item = temp[cont];
      //     if (item != null) {
      //       var novo = {
      //         nome:
      //           item.horaIni.toLocaleString("en-US", {
      //             minimumIntegerDigits: 2,
      //             useGrouping: false,
      //           }) +
      //           ":" +
      //           item.minIni.toLocaleString("en-US", {
      //             minimumIntegerDigits: 2,
      //             useGrouping: false,
      //           }) +
      //           " as " +
      //           item.horaFim.toLocaleString("en-US", {
      //             minimumIntegerDigits: 2,
      //             useGrouping: false,
      //           }) +
      //           ":" +
      //           item.minFim.toLocaleString("en-US", {
      //             minimumIntegerDigits: 2,
      //             useGrouping: false,
      //           }),
      //         valor: item.entregaHorarioId,
      //       };
      //       this.Horarios.push(novo);
      //     }
      //   }
      // });
      // this.$http.get(this.$apiUrl + "/FotoAvulsa").then((response) => {
      //   this.Fotos = response.body;
      // });
      // this.$http.get(this.$apiUrl + "/MensagemAvulsa").then((response) => {
      //   var tmp = response.body;
      //   var cont = 0;
      //   for (cont = 0; cont < tmp.length; cont++) {
      //     var i = tmp[cont];
      //     var novo = {
      //       valor: i.mensagemAvulsaId,
      //       nome:
      //         i.mensagemAvulsaId +
      //         " - De: " +
      //         i.nomeDe +
      //         " - Para: " +
      //         i.nomePara,
      //     };
      //     this.Mensagens.push(novo);
      //   }
      // });

      // if (localStorage.Foto != null) {
      //   this.Foto = JSON.parse(localStorage.Foto);
      // }
      // if (localStorage.Mensagem != null) {
      //   this.Mensagem = JSON.parse(localStorage.Mensagem);
      //   this.MensagemSelecionada = {
      //     valor: this.Mensagem.mensagemAvulsaId,
      //     nome:
      //       this.Mensagem.mensagemAvulsaId +
      //       " - De: " +
      //       this.Mensagem.nomeDe +
      //       " - Para: " +
      //       this.Mensagem.nomePara,
      //   };
      // }
    },
  },
  components: {
    vSelect,
  },
  created() {
    this.Carregar();
    var _formasPagamento = [];
    this.$http
      .get(this.$apiUrl + "/configoperadora")
      .then((res2) => res2.json())
      .then((tiu2) => {
        //carrega informações da lista de opções de pagamento
        this.$http
          .get(this.$apiUrl + "/configpix")
          .then((res2) => res2.json())
          .then((tiu3) => {
            this.ListaFormasPagamentoPix = tiu3.filter((x) => x.status);
            if (this.ListaFormasPagamentoPix != null) {
              this.ListaFormasPagamentoPix.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadPix.cadPixId,
                  Operacao: "cadPix",
                  texto: item.cadPix.nomePix,
                });
              });
            }

            this.ListaFormasPagamentoOperadora = tiu2.filter((x) => x.status);
            if (this.ListaFormasPagamentoOperadora != null) {
              this.ListaFormasPagamentoOperadora.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadOperadora.cadOperadoraId,
                  Operacao: "cadOperadora",
                  texto: item.cadOperadora.nomeOperadora,
                });
              });
            }

            //carrega informações da lista de opções de pagamento
            this.$http
              .get(this.$apiUrl + "/configbanco")
              .then((res2) => res2.json())
              .then((tiu2) => {
                this.ListaFormasPagamentoBanco = tiu2.filter((x) => x.status);
                if (this.ListaFormasPagamentoBanco != null) {
                  this.ListaFormasPagamentoBanco.forEach((item) => {
                    _formasPagamento.push({
                      OperacaoId: item.cadBanco.cadBancoId,
                      Operacao: "cadBanco",
                      texto: item.cadBanco.nomeBanco,
                    });
                  });
                }
              });
          });
      });

    this.ListaFormasPagamento = _formasPagamento;
    this.ListaFormasPagamento.sort(function (a, b) {
      return a.texto - b.texto;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
.tamdado {
  font-size: 13px !important;
}

.tamicone {
  font-size: 20px !important;
}

@media (max-width: 600px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 100%;
    outline: none;
    height: 270px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 12px;
  }

  .imagemCartao {
    height: 270px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 90%;
  }
}

@media (min-width: 608px) and (max-width: 991px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 98%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .textareacartao {
    background: transparent;
    color: #000000;
    resize: none;
    border: 0 none;
    width: 90%;
    outline: none;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    font-family: Dancing Script !important;
    font-size: 21px;
  }

  .imagemCartao {
    height: 450px;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .imgcel {
    width: 70%;
  }
}
.wizard-content .wizard > .steps > ul > li:after,
.wizard-content .wizard > .steps > ul > li:before {
  content: "";
  z-index: 9;
  display: block;
  position: absolute;
}

.wizard-content .wizard {
  width: 100%;
  overflow: hidden;
}

.wizard-content .wizard .content {
  margin-left: 0 !important;
}

.wizard-content .wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.wizard-content .wizard > .steps .current-info {
  position: absolute;
  left: -99999px;
}

.wizard-content .wizard > .steps > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}

.wizard-content .wizard > .steps > ul > li {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}

.wizard-content .wizard > .steps > ul > li a {
  position: relative;
  padding-top: 52px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.wizard-content .wizard > .steps > ul > li:before {
  left: 0;
}

.wizard-content .wizard > .steps > ul > li:after {
  right: 0;
}

.wizard-content .wizard > .steps > ul > li:first-child:before,
.wizard-content .wizard > .steps > ul > li:last-child:after {
  content: none;
}

.wizard-content .wizard > .steps > ul > li.current > a {
  color: #2f3d4a;
  cursor: default;
}

.wizard-content .wizard > .steps > ul > li.current .step {
  border-color: #009efb;
  background-color: #fff;
  color: #009efb;
}

.wizard-content .wizard > .steps > ul > li.disabled a,
.wizard-content .wizard > .steps > ul > li.disabled a:focus,
.wizard-content .wizard > .steps > ul > li.disabled a:hover {
  color: #999;
  cursor: default;
}

.wizard-content .wizard > .steps > ul > li.done a,
.wizard-content .wizard > .steps > ul > li.done a:focus,
.wizard-content .wizard > .steps > ul > li.done a:hover {
  color: #999;
}

.wizard-content .wizard > .steps > ul > li.done .step {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.wizard-content .wizard > .steps > ul > li.error .step {
  border-color: #f62d51;
  color: #f62d51;
}

.wizard-content .wizard > .steps .step {
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -24px;
  z-index: 10;
  text-align: center;
}

.wizard-content .wizard > .content {
  overflow: hidden;
  position: relative;
  width: auto;
  padding: 0;
  margin: 0;
}

.wizard-content .wizard > .content > .title {
  position: absolute;
  left: -99999px;
}

.wizard-content .wizard > .content > .body {
  padding: 0 10px;
}

.wizard-content .wizard > .content > iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.wizard-content .wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  padding: 0 10px 20px;
}

.wizard-content .wizard > .actions > ul {
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
}

.wizard-content .wizard > .actions > ul:after {
  content: "";
  display: table;
  clear: both;
}

.wizard-content .wizard > .actions > ul > li {
  float: left;
}

.wizard-content .wizard > .actions > ul > li + li {
  margin-left: 10px;
}

.wizard-content .wizard > .actions > ul > li > a {
  background: #009efb;
  color: #fff;
  display: block;
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  min-width: 100px;
  text-align: center;
}

.wizard-content .wizard > .actions > ul > li > a:focus,
.wizard-content .wizard > .actions > ul > li > a:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}

.wizard-content .wizard > .actions > ul > li > a:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}

.wizard-content .wizard > .actions > ul > li > a[href="#previous"] {
  background-color: #fff;
  color: #009efb;
  border: 1px solid #009efb;
}

.wizard-content .wizard > .actions > ul > li > a[href="#previous"]:focus,
.wizard-content .wizard > .actions > ul > li > a[href="#previous"]:hover {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}

.wizard-content .wizard > .actions > ul > li > a[href="#previous"]:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
}

.wizard-content .wizard > .actions > ul > li.disabled > a,
.wizard-content .wizard > .actions > ul > li.disabled > a:focus,
.wizard-content .wizard > .actions > ul > li.disabled > a:hover {
  color: #999;
}

.wizard-content .wizard > .actions > ul > li.disabled > a[href="#previous"],
.wizard-content
  .wizard
  > .actions
  > ul
  > li.disabled
  > a[href="#previous"]:focus,
.wizard-content
  .wizard
  > .actions
  > ul
  > li.disabled
  > a[href="#previous"]:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.wizard-content .wizard.wizard-circle > .steps > ul > li:after,
.wizard-content .wizard.wizard-circle > .steps > ul > li:before {
  top: 45px;
  width: 50%;
  height: 3px;
  background-color: #009efb;
}

.wizard-content .wizard.wizard-circle > .steps > ul > li.current:after,
.wizard-content .wizard.wizard-circle > .steps > ul > li.current ~ li:after,
.wizard-content .wizard.wizard-circle > .steps > ul > li.current ~ li:before {
  background-color: #f3f3f3;
}

.wizard-content .wizard.wizard-circle > .steps .step {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 3px solid #f3f3f3;
  font-size: 1.3rem;
  border-radius: 50%;
}

.wizard-content .wizard.wizard-notification > .steps > ul > li:after,
.wizard-content .wizard.wizard-notification > .steps > ul > li:before {
  top: 39px;
  width: 50%;
  height: 2px;
  background-color: #009efb;
}

.wizard-content .wizard.wizard-notification > .steps > ul > li.current .step {
  border: 2px solid #009efb;
  color: #009efb;
  line-height: 36px;
}

.wizard-content
  .wizard.wizard-notification
  > .steps
  > ul
  > li.current
  .step:after,
.wizard-content
  .wizard.wizard-notification
  > .steps
  > ul
  > li.done
  .step:after {
  border-top-color: #009efb;
}

.wizard-content .wizard.wizard-notification > .steps > ul > li.current:after,
.wizard-content
  .wizard.wizard-notification
  > .steps
  > ul
  > li.current
  ~ li:after,
.wizard-content
  .wizard.wizard-notification
  > .steps
  > ul
  > li.current
  ~ li:before {
  background-color: #f3f3f3;
}

.wizard-content .wizard.wizard-notification > .steps > ul > li.done .step {
  color: #fff;
}

.wizard-content .wizard.wizard-notification > .steps .step {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1.3rem;
  border-radius: 15%;
  background-color: #f3f3f3;
}

.wizard-content .wizard.wizard-notification > .steps .step:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  margin-bottom: -8px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #f3f3f3;
}

.wizard-content .wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 20%;
}

.wizard-content .wizard.vertical > .steps > ul > li {
  display: block;
  width: 100%;
}

.wizard-content .wizard.vertical > .steps > ul > li.current:after,
.wizard-content .wizard.vertical > .steps > ul > li.current:before,
.wizard-content .wizard.vertical > .steps > ul > li.current ~ li:after,
.wizard-content .wizard.vertical > .steps > ul > li.current ~ li:before,
.wizard-content .wizard.vertical > .steps > ul > li:after,
.wizard-content .wizard.vertical > .steps > ul > li:before {
  background-color: transparent;
}

@media (max-width: 768px) {
  .wizard-content .wizard > .steps > ul {
    margin-bottom: 20px;
  }
  .wizard-content .wizard > .steps > ul > li {
    display: block;
    float: left;
    width: 50%;
  }
  .wizard-content .wizard > .steps > ul > li > a {
    margin-bottom: 0;
  }
  .wizard-content .wizard > .steps > ul > li:first-child:before {
    content: "";
  }
  .wizard-content .wizard > .steps > ul > li:last-child:after {
    content: "";
    background-color: #009efb;
  }
  .wizard-content .wizard.vertical > .steps {
    width: 15%;
  }
}

@media (max-width: 480px) {
  .wizard-content .wizard > .steps > ul > li {
    width: 50%;
  }
  .wizard-content .wizard > .steps > ul > li.current:after {
    background-color: #009efb;
  }
  .wizard-content .wizard.vertical > .steps > ul > li {
    display: block;
    float: left;
    width: 50%;
  }
  .wizard-content .wizard.vertical > .steps {
    width: 100%;
    float: none;
  }
}
</style>
