<template>
  <div>
    <div class="main-container">
      <div class="pd-ltr-20">
        <!-- Default Basic Forms Start -->
        <div class="faq-wrap">
          <div id="accordion">
            <div class="pd-20 card-box mb-30">
              <div class="card-header">
                <button
                  class="btn btn-block"
                  data-toggle="collapse"
                  data-target="#Topo"
                >
                  <div class="clearfix">
                    <div class="pull-left">
                      <h4 class="text-black h4">Confirmação de Cadastro</h4>
                      <p class="mb-30">
                        Tela para confirmação do cadastro do cliente no site.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div id="Topo" class="collapse show" data-parent="#accordion">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Titulo</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Titulo"
                          v-model="Confirma.titulo"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Texto Botão</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Texto Btn"
                          v-model="Confirma.textoBtn"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Link Botão</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Link Btn"
                          v-model="Confirma.linkBbtn"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Descrição 1</label>
                        <quill-editor v-model="Confirma.descricao"></quill-editor>
                        <!-- <textarea
                          class="textarea_editor1 form-control border-radius-0"
                          placeholder="Descrição ..."
                        ></textarea> -->
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Imagem</label>
                        <input
                          type="file"
                          class="form-control-file form-control height-auto"
                          @change="Upload"
                        />
                      </div>
                      <img v-if="Confirma.urlImg != null && Confirma.urlImg.length > 0" :src="Confirma.urlImg" style="max-width:400px" />
                      <button type="button" style="background-color:red;border-radius:15px;padding:10px;color:white;" class="btn" v-if="Confirma.urlImg != null && Confirma.urlImg.length > 0" @click="ApagaImagem(Confirma)">Apagar</button>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Quem recebe o aviso do cadastro</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="E-mail"
                          v-model="Confirma.email"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="button" class="btn btn-link" @click="$redirect_reload('/controle/confirmacadastrocli')">
                        Cancelar
                      </button>
                      <!--<button type="button" class="btn btn-info">Pesquisar</button>-->
                      <button
                        type="button"
                        class="btn btn-success"
                        id="sa-custom-position"
                        @click="Altera()"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cabecalho from "./_shared/Cabecalho.vue";
// import Rodape from "./_shared/Rodape.vue";

//import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
// import vSelect from 'vue-select';

export default {
  data() {
    return {
      ConfirmaId: 0,
      Confirma: {},
      Eviando: 0,
      Enviado: 0
    };
  },
  methods: {
    Upload(event) {
      var files = event.target.files;
      var x = 0;
      for (x = 0; x < files.length; x++) {
        this.Enviando++;
        let data = new FormData();
        data.append("arquivo", files[x]);
        console.log(files[x]);
        this.$http
          .post(
            this.$apiUrl +
              "/confirmacad/upload/",
            data
          )
          .then(
            (response) => {
              console.log(response.body);
              this.Enviado++;
              this.Confirma.urlImg = response.bodyText;
            },
            (response) => { 
              console.log(response);
              this.Confirma.urlImg = response.bodyText;
            }
          );
      }
    },
    ApagaImagem(Confirma) {
        Confirma.urlImg = "";
        this.Confirma.urlImg = "";
        this.$http
          .put(
            this.$apiUrl + "/ConfirmaCad/" + this.ConfirmaId,
            Confirma
          )
          .then(
            (response) => {
              if (response.ok) {
                console.log("ok");
              }
            },
            (error) => {
              this.$mensagem_sucesso(error.bodyText);
            }
          );
    },
    Altera() {
      if (this.ConfirmaId > 0) {
        this.$http
          .put(
            this.$apiUrl + "/ConfirmaCad/" + this.ConfirmaId,
            this.Confirma
          )
          .then(
            (response) => {
              if (response.ok) {
                this.$mensagem_sucesso("Dados salvos com sucesso");
              }
            },
            (error) => {
              this.$mensagem_sucesso(error.bodyText);
            }
          );
      } else {
        this.$http
          .post(this.$apiUrl + "/ConfirmaCad/", this.Confirma)
          .then(
            (response) => {
              if (response.ok) {
                this.$mensagem_sucesso("Dados salvos com sucesso");
              }
            },
            (error) => {
              this.$mensagem_sucesso(error.bodyText);
            }
          );
      }
    },
  },
  created() {
    this.$http.get(this.$apiUrl + "/ConfirmaCad/").then((response) => {
      var list = response.body;
      console.log(list);
      if (list.length > 0) {
        this.Confirma = list[0];
        if (this.Confirma.confirmaCadId != null)
          this.ConfirmaId = this.Confirma.confirmaCadId;
      }
    });
  },
  mounted() {},
  components: {
    //vSelect,
    // Cabecalho,
    // Rodape,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button {
  margin: 10px;
}
</style>
